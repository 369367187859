import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import CreateEmailAccountsTab from './create-email-accounts-tab';

import { uploadDomainProfilePictureRequest } from '../../../../extra-actions';
import {
  addEmailAccountToEmailAccountInfrastructureCart,
  removeEmailAccountFromEmailAccountInfrastructureCart,
  resetUploadDomainProfilePictureRequest,
  updateEmailAccountProfileInEmailAccountInfrastructureCart,
} from '../../../../email-accounts-slice';
import {
  EmailAccountProfile,
  EmailAccountType,
} from '../../../../types/create-email-infrastructure';

const mapStateToProps = (state: RootState) => ({
  emailAccounts: state.emailAccount.emailAccounts,
  domains: state.emailAccount.domains,
  domainPlans: state.emailAccount.domainPlans,

  uploadDomainProfilePictureResponse:
    state.emailAccount.uploadDomainProfilePictureResponse,
  uploadDomainProfilePictureRequestStatus:
    state.emailAccount.uploadDomainProfilePictureRequest.status,
  uploadDomainProfilePictureRequestMessage:
    state.emailAccount.uploadDomainProfilePictureRequest.message,
  uploadDomainProfilePictureRequestError:
    state.emailAccount.uploadDomainProfilePictureRequest.error,

  emailAccountInfrastructureCart:
    state.emailAccount.emailAccountInfrastructureCart,
});

const mapDispatchToProps = {
  addEmailAccountToCart: (payload: EmailAccountType) =>
    addEmailAccountToEmailAccountInfrastructureCart(payload),
  removeEmailAccountFromCart: (payload: string) =>
    removeEmailAccountFromEmailAccountInfrastructureCart(payload),
  sendUploadDomainProfilePictureRequest: (payload: FormData) =>
    uploadDomainProfilePictureRequest(payload),
  updateEmailAccountProfile: (payload: EmailAccountProfile) =>
    updateEmailAccountProfileInEmailAccountInfrastructureCart(payload),
  resetUploadDomainProfilePictureRequest: () =>
    resetUploadDomainProfilePictureRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  nudgeUserToUploadProfileImageModal: boolean;
  setNudgeUserToUploadProfileImageModal: (value: boolean) => void;
  handleNextButtonClickOnCreateEmailAccounts: () => void;
};

export default connector(CreateEmailAccountsTab);
