import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Modal from '../../../../../shared/design-system/components/atoms/modal';
import Button from '../../../../../shared/design-system/components/atoms/button';
import { Images } from '../../../../../shared/app-constants';
import { contactAdmin } from '../../../../../shared/utils/contact-admin';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';
import { ModifySubscriptionSteps } from '../../../../settings/components/billing-subscription/components/modify-subscription/types';
import { Routes } from '../../../../../shared/routes';

const NoSeatAvailableForEmailModal = ({ show, onClose, firstName }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = () => {
    const emailSubject = `${t(
      'messages.no_seat_available_email_subject_1',
    )} ${t('messages.no_seat_available_email_subject_2')}`;

    const emailBody = `Hello ${firstName}, %0D%0A %0D%0A ${t(
      'messages.no_seat_available_email_body_1',
    )} ${t('messages.no_seat_available_email_body_2')} ${t(
      'messages.no_seat_available_email_body_3',
    )} ${t('messages.no_seat_available_email_body_4')}`;

    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      history.push({
        pathname: Routes.Setting_Billing_Upgrade_Email_Outreach_Plan,
        state: {
          isRedirectedFromEmailAccounts: true,
          modifySubscriptionStep: ModifySubscriptionSteps.SelectSlots,
        },
      });
    } else {
      contactAdmin(emailSubject, emailBody);
    }
  };

  return (
    <Modal
      show={show}
      className="confirm-modal-width confirmation-modal no-seat-confirmation-modal"
      titleContent={
        <div className="confirmation-modal-header">
          <img src={Images.AlertTriangle} alt="Alert" />
          <h2>{t('messages.no_seat_available_email_modal_title')}</h2>
        </div>
      }
      showCloseIcon
      onSubmit={onSubmit}
      onClose={onClose}
      submitButtonText={
        hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
          ? 'Buy Now'
          : t('labels.contact_admin')
      }
      cancelButtonText={t('labels.cancel')}
      cancelButtonVarient={Button.Variant.Outlined}
      submitButtonClassName="header-btn"
      backdrop="static"
    >
      <div className="confirmation-modal-content">
        <p className="confirmation-modal-text">
          {t('messages.no_seat_available_email_modal__content_1')}
        </p>
        <p className="confirmation-modal-text">
          {t('messages.no_seat_available_email_modal__content_2')}
        </p>
      </div>
    </Modal>
  );
};

export default NoSeatAvailableForEmailModal;
