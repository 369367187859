import React from 'react';
import ImageIcon from '../../../shared/components/images/image-icon';
import { Images } from '../../../shared/app-constants';

export const NoSearchResult = () => (
  <div className="domains-empty-list">
    <div className="empty-list">
      <ImageIcon src={Images.NoDomainSearchResult} />
      <div className="empty-list-header">No Search Result</div>
      <div className="empty-list-note">
        We can’t find any email accounts matching your search.
      </div>
    </div>
  </div>
);

export default NoSearchResult;
