/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/react-in-jsx-scope */
import {
  Briefcase,
  Building,
  BuildingArch,
  Calendar,
  ChartLine,
  GraduationHat,
  Mail,
  MapPin,
  Search,
  Share,
  Tool,
  User,
  UserList,
  Users,
} from '@saleshandy/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { FilterComponentType, LeadFinderTabKey } from '../../type';
import {
  DEGREE_DATA,
  DEPARTMENT_DATA,
  INDUSTRY_DATA,
  LOCATION_DATA,
  MAJOR_DATA,
  CHANGE_JOB_DATA,
  EMPLOYEE_COUNT_DATA,
  MANAGEMENT_LEVELS_DATA,
  REVENUE_DATA,
  YEARS_OF_EXPERIENCE_DATA,
  CONTACT_METHOD_DATA,
} from '../../helpers/filter-data';
import { getFilterValues, getLeadsActiveTab } from '../../helpers/helper';

let timeout: any;

export const FILTER_LIST: any = [
  {
    id: 'filter-name',
    key: 'name',
    label: 'Name',
    icon: <User />,
    type: [
      {
        id: 'filter-name-search-input',
        key: 'name',
        value: FilterComponentType.SEARCH_INPUT,
        placeholder: 'name',
      },
    ],
  },
  {
    id: 'filter-location',
    key: 'location',
    label: 'Location',
    icon: <MapPin />,
    type: [
      {
        id: 'filter-location-checkboxes',
        key: 'location',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeLocation',
        inputClassName: 'location-input',
        inputPlaceHolder: 'Enter location',
        list: LOCATION_DATA,
      },
    ],
  },
  {
    id: 'filter-role-and-department',
    key: 'role_and_department',
    label: 'Role & Department',
    icon: <Briefcase />,
    type: [
      {
        id: 'filter-rol-and-department-search-input-1',
        key: 'jobTitle',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeJobTitle',
        inputClassName: 'job-title-input',
        inputPlaceHolder: 'Enter job title',
        inputLabel: 'Job Title',
        includeMaxLimit: 100,
        expandedMaxFilterTags: 5,
      },
      {
        id: 'filter-rol-and-department-checkbox-1',
        key: 'department',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeDepartment',
        inputClassName: 'department-search-input',
        inputPlaceHolder: 'Enter department',
        inputLabel: 'Department',
        list: DEPARTMENT_DATA,
      },
      {
        id: 'filter-rol-and-department-search-checkbox-1',
        key: 'managementLevels',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeManagementLevels',
        inputLabel: 'Management Levels',
        inputClassName: 'management-levels-label',
        hideSearchBox: true,
        list: MANAGEMENT_LEVELS_DATA,
      },
      {
        id: 'filter-rol-and-department-dropdown-1',
        key: 'jobChangeRange',
        value: FilterComponentType.DROP_DOWN,
        excludeKey: 'excludeJobChangeRange',
        placeholder: 'Select Job Change',
        list: CHANGE_JOB_DATA,
        name: 'Changed Jobs Within',
        className: 'job-change-dropdown',
      },
    ],
  },
  {
    id: 'filter-skills',
    key: 'skills',
    label: 'Skills',
    icon: <Tool />,
    type: [
      {
        id: 'filter-skills-search-checkbox-1',
        key: 'skills',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeSkills',
        inputClassName: 'skills-input',
        inputPlaceHolder: 'Enter skill',
        includeMaxLimit: 100,
        expandedMaxFilterTags: 5,
      },
    ],
  },
  {
    id: 'filter-years-experience',
    key: 'yearsExperience',
    label: 'Years Of Experience',
    icon: <Calendar />,
    type: [
      {
        id: 'filter-years-experience-dropdown-1',
        key: 'yearsExperience',
        value: FilterComponentType.DROP_DOWN,
        excludeKey: 'excludeYearsExperience',
        placeholder: 'Select experience',
        list: YEARS_OF_EXPERIENCE_DATA,
      },
    ],
  },
  {
    id: 'filter-company-name',
    key: 'employer',
    label: 'Company Name / Domain',
    icon: <Building />,
    type: [
      {
        id: 'filter-company-name-search-checkbox',
        key: 'employer',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeEmployer',
        inputPlaceHolder: 'Enter company name',
        inputClassName: 'employer-input',
        includeMaxLimit: 100,
        expandedMaxFilterTags: 5,
      },
    ],
  },
  {
    id: 'filter-company-size',
    key: 'companySize',
    label: 'Employee Count',
    icon: <Users />,
    type: [
      {
        id: 'filter-company-size-dropdown-1',
        key: 'companySize',
        value: FilterComponentType.DROP_DOWN,
        excludeKey: 'excludeCompanySize',
        list: EMPLOYEE_COUNT_DATA,
      },
    ],
  },
  {
    id: 'filter-revenue',
    key: 'companyRevenue',
    label: 'Revenue',
    icon: <ChartLine />,
    type: [
      {
        id: 'filter-revenue-drop-down',
        key: 'companyRevenue',
        value: FilterComponentType.DROP_DOWN,
        excludeKey: 'excludeCompanyRevenue',
        list: REVENUE_DATA,
        isPrefix: true,
      },
    ],
  },
  {
    id: 'filter-industry',
    key: 'industry',
    label: 'Industry',
    icon: <BuildingArch />,
    type: [
      {
        id: 'filter-industry-checkbox',
        key: 'industry',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeIndustry',
        inputClassName: 'industry-search-input',
        inputPlaceHolder: 'Enter industry',
        list: INDUSTRY_DATA,
        includeMaxLimit: 100,
        expandedMaxFilterTags: 5,
      },
      {
        id: 'filter-industry-search-checkbox-1',
        key: 'sicCode',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeSicCode',
        inputClassName: 'sic-code-input',
        inputPlaceHolder: 'Enter a SIC code',
        inputLabel: 'SIC Code',
      },
      {
        id: 'filter-industry-search-checkbox-2',
        key: 'naicsCode',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeNaicsCode',
        inputClassName: 'naics-code-input',
        inputPlaceHolder: 'Enter a NAICS code',
        inputLabel: 'NAICS Code',
      },
    ],
  },
  {
    id: 'filter-contact-method',
    key: 'contact-method',
    label: 'Contact Method',
    icon: <UserList />,
    type: [
      {
        id: 'filter-contact-method-checkbox',
        key: 'contactMethod',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        hideSearchBox: true,
        removeTopMargin: true,
        hideIncludeExclude: true,
        infoCircleToolTipMapping: {
          Phone: 'Includes work, office, and HQ phone numbers',
        },
        list: CONTACT_METHOD_DATA,
      },
    ],
  },
  {
    id: 'filter-education',
    key: 'education',
    label: 'Education',
    icon: <GraduationHat />,
    type: [
      {
        id: 'filter-education-search-checkbox-1',
        key: 'major',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeMajor',
        inputClassName: 'major-input',
        inputPlaceHolder: 'Enter major',
        inputLabel: 'Major',
        list: MAJOR_DATA,
      },
      {
        id: 'filter-education-search-checkbox-2',
        key: 'school',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeSchool',
        inputClassName: 'major-input-school',
        inputPlaceHolder: 'Enter school',
        inputLabel: 'School',
      },
      {
        id: 'filter-education-search-checkbox-3',
        key: 'degree',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        excludeKey: 'excludeDegree',
        inputClassName: 'major-input-degree',
        inputPlaceHolder: 'Enter degree',
        inputLabel: 'Degree',
        list: DEGREE_DATA,
      },
    ],
  },
  {
    id: 'filter-contact-info',
    key: 'contactInfo',
    label: 'Contact Info',
    icon: <Mail />,
    type: [
      {
        id: 'filter-contact-info-search-input',
        key: 'contactInfo',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        placeholder: 'email or phone',
        includeMaxLimit: 100,
        expandedMaxFilterTags: 5,
      },
    ],
  },
  {
    id: 'filter-social-link',
    key: 'link',
    label: 'Social Link',
    icon: <Share />,
    type: [
      {
        id: 'filter-social-link-search-input',
        key: 'link',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        placeholder: 'any social profile url',
        includeMaxLimit: 100,
        expandedMaxFilterTags: 5,
      },
    ],
  },
  {
    id: 'filter-keywords',
    key: 'keyword',
    label: 'Keywords',
    icon: <Search />,
    type: [
      {
        id: 'filter-keywords-search-input-1',
        key: 'keyword',
        value: FilterComponentType.MULTILEVEL_CHECKBOX,
        inputPlaceHolder: 'keywords',
        excludeKey: 'excludeKeyword',
        inputClassName: 'keyword-input',
        includeMaxLimit: 100,
        expandedMaxFilterTags: 5,
      },
    ],
  },
];

export const useLeadFilter = (activeTabKey) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);

  const __handleDeleteSpecificValueFromQuery = ({ key, value }) => {
    const paramValues = queryParams
      .getAll(key)
      ?.map((item) => item.replace(/"/g, ''));
    // Remove the specified parameter value
    const updatedValues = paramValues.filter((v) => v !== value);

    // Update the URL with the modified parameter values
    queryParams.delete(key);
    updatedValues.forEach((v) => queryParams.append(key, v));
  };

  const __handleSetDeletePaginationParams = (action) => {
    if (action === 'set') {
      queryParams.set('start', '1');
      queryParams.set('take', queryParams.get('take') || '25');
    } else if (
      action === 'delete' &&
      activeTabKey === LeadFinderTabKey.PEOPLE_TAB
    ) {
      const queryParamLength = Array.from(queryParams.entries()).length;
      if (
        queryParamLength === 2 &&
        queryParams.has('start') &&
        queryParams.has('take')
      ) {
        queryParams.delete('start');
        queryParams.delete('take');
      }
    }
  };

  const __handleSetUniqueKeys = ({
    filterKey,
    uniqueKeys,
  }: {
    filterKey: string;
    uniqueKeys: any;
  }) => {
    queryParams
      .getAll(filterKey)
      ?.map((item) => item.replace(/"/g, ''))
      ?.forEach((param) => {
        switch (filterKey) {
          case 'location':
          case 'excludeLocation':
            const locationValues = getFilterValues([param], LOCATION_DATA);
            uniqueKeys.add({
              key: filterKey,
              label: locationValues?.length > 0 ? locationValues[0] : param,
              value: param,
            });
            break;
          case 'department':
          case 'excludeDepartment':
            const departMentValues = getFilterValues([param], DEPARTMENT_DATA);
            uniqueKeys.add({
              key: filterKey,
              label: departMentValues?.length > 0 ? departMentValues[0] : param,
              value: param,
            });
            break;
          case 'industry':
          case 'excludeIndustry':
            const industryValues = getFilterValues([param], INDUSTRY_DATA);
            uniqueKeys.add({
              key: filterKey,
              label: industryValues?.length > 0 ? industryValues[0] : param,
              value: param,
            });
            break;
          case 'companyRevenue':
          case 'excludeCompanyRevenue':
            const revenueValues = getFilterValues([param], REVENUE_DATA);
            uniqueKeys.add({
              key: filterKey,
              label: revenueValues?.length > 0 ? revenueValues[0] : param,
              value: param,
            });
            break;
          case 'managementLevels':
          case 'excludeManagementLevels':
            const managementLevelsValues = getFilterValues(
              [param],
              MANAGEMENT_LEVELS_DATA,
            );
            uniqueKeys.add({
              key: filterKey,
              label:
                managementLevelsValues?.length > 0
                  ? managementLevelsValues[0]
                  : param,
              value: param,
            });
            break;
          case 'jobChangeRange':
          case 'excludeJobChangeRange':
            const changeJobValues = getFilterValues([param], CHANGE_JOB_DATA);
            uniqueKeys.add({
              key: filterKey,
              label: changeJobValues?.length > 0 ? changeJobValues[0] : param,
              value: param,
            });
            break;
          case 'yearsExperience':
          case 'excludeYearsExperience':
            const yearsOfExperienceValues = getFilterValues(
              [param],
              YEARS_OF_EXPERIENCE_DATA,
            );
            uniqueKeys.add({
              key: filterKey,
              label:
                yearsOfExperienceValues?.length > 0
                  ? yearsOfExperienceValues[0]
                  : param,
              value: param,
            });
            break;
          case 'companySize':
          case 'excludeCompanySize':
            const employeeCountValues = getFilterValues(
              [param],
              EMPLOYEE_COUNT_DATA,
            );
            uniqueKeys.add({
              key: filterKey,
              label:
                employeeCountValues?.length > 0
                  ? employeeCountValues[0]
                  : param,
              value: param,
            });
            break;
          default:
            uniqueKeys.add({
              key: filterKey,
              label: param,
              value: param,
            });
        }
      });
  };

  const handleGetAllKeysByFilterFromQuery = (filter: any) => {
    const uniqueKeys = new Set();

    if (filter.type?.length > 0) {
      // eslint-disable-next-line array-callback-return
      filter.type.map((type) => {
        if (type.key) {
          __handleSetUniqueKeys({ filterKey: type?.key, uniqueKeys });
        }
        if (type?.excludeKey) {
          __handleSetUniqueKeys({ filterKey: type?.excludeKey, uniqueKeys });
        }
      });
    }
    return uniqueKeys;
  };

  const handleIndividualTag = (tag: any) => {
    __handleDeleteSpecificValueFromQuery({ key: tag?.key, value: tag?.value });
    // handle delete pagination params
    __handleSetDeletePaginationParams('delete');

    history.push({
      search: queryParams.toString(),
      hash: getLeadsActiveTab(location),
    });
  };

  const handleFoundValueExceptFromList = (key) => {
    let foundedValue = '';
    const paramValues =
      queryParams.getAll(key)?.map((item) => item.replace(/"/g, '')) || [];
    let list = [];
    if (key === 'location') {
      list = LOCATION_DATA;
    } else if (key === 'department') {
      list = DEPARTMENT_DATA;
    } else if (key === 'industry') {
      list = INDUSTRY_DATA;
    }
    if (paramValues?.length > 0) {
      // eslint-disable-next-line array-callback-return
      paramValues.map((value) => {
        const listValues = getFilterValues([value], list);
        if (listValues?.length === 0) {
          foundedValue = value;
        }
      });
    }
    return foundedValue;
  };

  const handleQuery = ({
    filterKey,
    filterValue,
    filterType,
    filterExcludeKey,
    showExactMatch,
  }: {
    filterKey: string;
    filterValue: any;
    filterType: string;
    filterExcludeKey?: string;
    showExactMatch?: boolean;
  }) => {
    if (filterKey) {
      // handle add pagination params
      __handleSetDeletePaginationParams('set');
    }

    // eslint-disable-next-line default-case
    switch (filterType) {
      case FilterComponentType.SEARCH_INPUT:
        if (filterValue !== '') {
          if (!showExactMatch) {
            queryParams.set(filterKey, filterValue.replace(/"/g, ''));
          } else {
            queryParams.set(filterKey, [`"${filterValue}"`].toString());
          }
        }
        break;
      case FilterComponentType.DROP_DOWN:
        if (filterValue !== '') {
          queryParams.set(filterKey, filterValue);
        } else {
          const findValue = handleFoundValueExceptFromList(filterKey);
          if (findValue) {
            __handleDeleteSpecificValueFromQuery({
              key: filterKey,
              value: findValue,
            });
          } else {
            queryParams.delete(filterKey);
          }
        }
        break;
      case FilterComponentType.MULTILEVEL_CHECKBOX:
        queryParams.delete(filterKey);
        if (filterExcludeKey) {
          queryParams.delete(filterExcludeKey);
        }
        if (filterValue?.includedKeys?.length > 0) {
          filterValue?.includedKeys.forEach((includeKey) => {
            if (!showExactMatch) {
              if (queryParams.has(filterKey)) {
                queryParams.append(filterKey, includeKey);
              } else {
                queryParams.set(filterKey, includeKey);
              }
            } else {
              if (queryParams.has(filterKey)) {
                queryParams.append(filterKey, `"${includeKey}"`);
              } else {
                queryParams.set(filterKey, `"${includeKey}"`);
              }
            }
          });
        }
        if (filterValue?.excludedKeys?.length > 0) {
          filterValue?.excludedKeys.forEach((excludeKey) => {
            if (!showExactMatch) {
              if (queryParams.has(filterExcludeKey)) {
                queryParams.append(filterExcludeKey, excludeKey);
              } else {
                queryParams.set(filterExcludeKey, excludeKey);
              }
            } else {
              if (queryParams.has(filterExcludeKey)) {
                queryParams.append(filterExcludeKey, `"${excludeKey}"`);
              } else {
                queryParams.set(filterExcludeKey, `"${excludeKey}"`);
              }
            }
          });
        }
        break;
    }

    // handle delete pagination params
    __handleSetDeletePaginationParams('delete');

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      history.push({
        search: queryParams.toString(),
        hash: getLeadsActiveTab(location),
      });
    }, 0);
  };

  const handleBulkQuery = (
    bulkQueryInputs: {
      filterKey: string;
      filterValue: any;
      filterType: string;
      filterExcludeKey?: string;
      showExactMatch?: boolean;
    }[],
  ) => {
    __handleSetDeletePaginationParams('set');
    bulkQueryInputs.forEach(
      ({
        filterKey,
        filterValue,
        filterType,
        filterExcludeKey,
        showExactMatch,
      }) => {
        // eslint-disable-next-line default-case
        switch (filterType) {
          case FilterComponentType.SEARCH_INPUT:
            if (filterValue === '') break;
            const formattedValue = showExactMatch
              ? `"${filterValue}"`
              : filterValue.replace(/"/g, '');
            queryParams.set(filterKey, formattedValue);
            break;
          case FilterComponentType.DROP_DOWN:
            if (filterValue !== '') {
              queryParams.set(filterKey, filterValue);
              break;
            }
            const findValue = handleFoundValueExceptFromList(filterKey);
            if (!findValue) {
              queryParams.delete(filterKey);
              break;
            }
            __handleDeleteSpecificValueFromQuery({
              key: filterKey,
              value: findValue,
            });
            break;
          case FilterComponentType.MULTILEVEL_CHECKBOX:
            queryParams.delete(filterKey);
            if (filterExcludeKey) {
              queryParams.delete(filterExcludeKey);
            }
            filterValue?.includedKeys?.forEach((includeKey) => {
              const includeValue = showExactMatch
                ? `"${includeKey}"`
                : includeKey;
              if (!queryParams.has(filterKey)) {
                queryParams.set(filterKey, includeValue);
                return;
              }
              queryParams.append(filterKey, includeValue);
            });
            filterValue?.excludedKeys?.forEach((excludeKey) => {
              const excludeValue = showExactMatch
                ? `"${excludeKey}"`
                : excludeKey;
              if (!queryParams.has(filterExcludeKey)) {
                queryParams.set(filterExcludeKey, excludeValue);
                return;
              }
              queryParams.append(filterExcludeKey, excludeValue);
            });
            break;
        }
      },
    );

    // handle delete pagination params
    __handleSetDeletePaginationParams('delete');

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      history.push({
        search: queryParams.toString(),
        hash: getLeadsActiveTab(location),
      });
    }, 0);
  };

  const handleDeleteFilter = (filter) => {
    if (filter) {
      filter?.type.forEach((type) => {
        queryParams.delete(type?.key);
        queryParams.delete(type?.excludeKey);
      });
      const queryParamLength = Array.from(queryParams.entries()).length;
      if (
        queryParamLength === 2 &&
        queryParams.has('start') &&
        queryParams.has('take')
      ) {
        queryParams.delete('start');
        queryParams.delete('take');
      }
      history.push({
        search: queryParams.toString(),
        hash: getLeadsActiveTab(location),
      });
    }
  };

  return {
    handleQuery,
    handleDeleteFilter,
    handleGetAllKeysByFilterFromQuery,
    handleIndividualTag,
    handleFoundValueExceptFromList,
    handleBulkQuery,
  };
};
