import React from 'react';

export const NoProfileImageSVG = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#EFF6FF"
    />
    <mask
      id="mask0_27759_210137"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_27759_210137)">
      <path
        d="M20.1598 21.8218C20.1598 24.7336 3.83984 24.7336 3.83984 21.8218C3.83984 18.91 10.2859 17.7656 11.993 17.7656C13.7138 17.7656 20.1598 18.91 20.1598 21.8218Z"
        fill="#BFDBFE"
      />
      <path
        d="M11.9476 16.2232C9.83944 16.2232 8.13672 14.6545 8.13672 12.7124V9.27637C8.13672 7.33426 9.83944 5.76562 11.9476 5.76562C14.0557 5.76562 15.7584 7.33426 15.7584 9.27637V12.7124C15.7584 14.6545 14.0422 16.2232 11.9476 16.2232Z"
        fill="#BFDBFE"
      />
    </g>
  </svg>
);
