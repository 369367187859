import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';

import { deleteDomainRequest, getDomainsRequest } from './extra-actions';

import { Domain, DomainsFilters } from './types';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  getDomainsRequest: RequestState;
  deleteDomainRequest: RequestState;

  domains: Domain[];
  domainsFilters: DomainsFilters;
}

const initialState: State = {
  getDomainsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteDomainRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  domains: [],
  domainsFilters: {
    page: 1,
    pageSize: 25,
    search: '',
    total: 0,
  },
};

const domainsSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    resetGetDomainsRequestState: (state) => {
      state.getDomainsRequest = initialState.getDomainsRequest;
    },
    resetDeleteDomainRequestState: (state) => {
      state.deleteDomainRequest = initialState.deleteDomainRequest;
    },

    updateDomainsFilters: (state, action) => {
      state.domainsFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Domains
    builder.addCase(getDomainsRequest.pending, (state) => {
      state.getDomainsRequest.status = RequestStatus.Pending;
      state.getDomainsRequest.error = null;
      state.getDomainsRequest.message = null;
    });
    builder.addCase(getDomainsRequest.fulfilled, (state, action) => {
      state.getDomainsRequest.status = RequestStatus.Succeeded;
      state.domains = action.payload.payload.domains;
      state.domainsFilters = action.payload.payload.meta;
    });
    builder.addCase(getDomainsRequest.rejected, (state, action) => {
      state.getDomainsRequest.status = RequestStatus.Failed;
      state.getDomainsRequest.message = action.payload.message;
      state.getDomainsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Domain
    builder.addCase(deleteDomainRequest.pending, (state) => {
      state.deleteDomainRequest.status = RequestStatus.Pending;
      state.deleteDomainRequest.error = null;
      state.deleteDomainRequest.message = null;
    });
    builder.addCase(deleteDomainRequest.fulfilled, (state, action) => {
      state.deleteDomainRequest.status = RequestStatus.Succeeded;
      state.deleteDomainRequest.message = action.payload.message;
    });
    builder.addCase(deleteDomainRequest.rejected, (state, action) => {
      state.deleteDomainRequest.status = RequestStatus.Failed;
      state.deleteDomainRequest.message = action.payload.message;
      state.deleteDomainRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetGetDomainsRequestState,
  resetDeleteDomainRequestState,
  updateDomainsFilters,
} = domainsSlice.actions;

export default domainsSlice.reducer;
