import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';

export const getDomainsLoadingData = () => [
  {
    name: <SkeletonLoading width={100} height={20} />,
    price: <SkeletonLoading width={50} height={20} />,
    isSelected: <SkeletonLoading width={20} height={20} />,
    isLoading: true,
  },
  {
    name: <SkeletonLoading width={90} height={20} />,
    price: <SkeletonLoading width={40} height={20} />,
    isSelected: <SkeletonLoading width={20} height={20} />,
    isLoading: true,
  },
  {
    name: <SkeletonLoading width={110} height={20} />,
    price: <SkeletonLoading width={60} height={20} />,
    isSelected: <SkeletonLoading width={20} height={20} />,
    isLoading: true,
  },
  {
    name: <SkeletonLoading width={80} height={20} />,
    price: <SkeletonLoading width={30} height={20} />,
    isSelected: <SkeletonLoading width={20} height={20} />,
    isLoading: true,
  },
];
