export enum ScoreFactorStatus {
  Valid = 'valid',
  PartiallyValid = 'partially-valid',
  NotValid = 'not-valid',
  InProgress = 'in-progress',
}

export interface ScoreFactorDetailItemProps {
  scoreFactorStatus: ScoreFactorStatus;
  isAllowedToViewYTVideoGuide?: boolean;
  hideYoutubeIcon?: boolean;
  youtubeIconTooltipText?: string;
  onViewYoutubeVideo?: VoidFunction;
  title: string;
  description: React.ReactElement<HTMLParagraphElement>;
  hasActionable?: boolean;
  actionableText?: string;
  actionableHandler?: (item?: any) => void;
  showActionableList?: boolean;
  showActionWithListOnly?: boolean;
  actionableList?: any[];
  actionableType?: 'link-btn' | 'switch' | 'select';
  isToggledChecked?: boolean;
  dropdownSelectedKey?: string;
  isChevronRightVisible?: boolean;
}

export type ActionableSelectDropdown = {
  key: string;
  label: string;
  value: number;
};
