import React from 'react';

import DomainsContent from './components/domains-content';

const Domains: React.FC = () => (
  <div className="domains-container">
    <DomainsContent />
  </div>
);

export default Domains;
