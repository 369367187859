import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import store from '../../../../../store';
import { getProspectAndEmailSentLimit } from '../../../../home/components/header/utils/helper';
import {
  isAgencyUser,
  getAgencyUserRole,
} from '../../../../../shared/utils/user-details';
import { UserRole } from '../../../../../shared/enums/user-roles';
import { ConversationType } from '../../../enums/prospect';

export const getRestrictionPlanModalBodyContent = () => {
  const { totalProspectLimit } = getProspectAndEmailSentLimit();

  if (isAgencyUser()) {
    const msg1 = `Your current plans allows you to store  ${totalProspectLimit}  prospects.`;
    const msg2 =
      'Contact your admin and request to upgrade your subscription to continue adding more prospects';
    return [msg1, msg2];
  }

  const msg1 = `Your current plans allows you to store ${totalProspectLimit} prospects.`;
  const msg2 = hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
    ? 'Please upgrade your subscription to add more prospects to your account.'
    : 'Contact your admin and request to upgrade your subscription to continue adding more prospects';

  return [msg1, msg2];
};

export const getRestrictionPlanWarningEmailBody = (): string => {
  const state = store.getState();
  const senderFirstName = state.home?.firstName;
  const { totalProspectLimit } = getProspectAndEmailSentLimit();

  if (isAgencyUser()) {
    const agencyUserRole = getAgencyUserRole();
    if (agencyUserRole && agencyUserRole === UserRole.AGENCYOWNER) {
      return `It looks like the Saleshandy account has reached the maximum prospect limit - ${totalProspectLimit}. It requires the upgrade to add more prospects into account. Please do the needful.%0D%0A %0D%0A Thanks,%0D%0A %0D%0A  ${senderFirstName}`;
    }
  }

  return `It looks like the Saleshandy account has reached the maximum prospect limit - ${totalProspectLimit}. It requires the upgrade to add more prospects into account. Please do the needful.%0D%0A %0D%0A Thanks,%0D%0A %0D%0A  ${senderFirstName}`;
};

export const getEmailSendingRestrictionModalContent = () => {
  const { totalEmailSentLimit } = getProspectAndEmailSentLimit();

  const msg1 = `Your current plan allows you to send ${totalEmailSentLimit} emails in a month.`;
  const msg2 = hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
    ? 'Please upgrade your subscription to resume your email-sending service for your account.'
    : 'Contact your admin and request to upgrade your subscription to resume email-sending service for your account.';

  return [msg1, msg2];
};

export const getOverLimitConnectedEmailErrorMsg = () => {
  const msg1 =
    'Your current plan allows you to connect only 10 email for each sequence.';
  const msg2 = hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
    ? 'Please upgrade your subscription to resume your sequence or remove extra emails from this sequence.'
    : 'Contact your admin and request to upgrade your subscription to resume this sequence or remove extra emails from this sequence.';

  return [msg1, msg2];
};

export const getConversationTypeLabel = (type: ConversationType): string => {
  let label = '';

  switch (type) {
    case ConversationType.External:
      label = 'External';
      break;
    case ConversationType.System:
      label = 'Sequence';
      break;
    default:
      label = 'All';
  }

  return label;
};
