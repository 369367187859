import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../../../store/root-reducer';
import {
  addContactsFromCSVRequest,
  addContactsFromCSVRequestWithoutStep,
  getAdminSettingsRequest,
  getContactFieldsRequest,
  getSequenceStepsRequest,
} from '../../../../../../extra-actions';
import ImportContactsModal from './import-contacts-modal';
import { AddContactsFromCSVRequestPayload } from '../../../../../../types';
import { SHModules } from '../../../../../../../../shared/enums/module';
import {
  getEmailVerificationPlanRequest,
  purchaseEmailVerificationCreditsRequest,
  getEmailVerificationCreditsRequest,
} from '../../../../../../../settings/components/billing-subscription/extra-actions';
import { resetPurchaseEmailVerificationCreditsResponse } from '../../../../../../../settings/components/billing-subscription/billing-subscription-slice';
import { PurchaseEmailVerificationCreditsRequest } from '../../../../../../../settings/components/billing-subscription/types/purchase-email-verification-credits';
import updateProfileProgressRequest from '../../../../../../../../shared/components/getting-started-onboarding/extra-actions';
import { UpdateProfileProgressPayload } from '../../../../../../../../shared/types/user-setting';
import {
  disableBulkActionRequestPolling,
  enableBulkActionRequestPolling,
  getUserSettingsRequest,
} from '../../../../../../../home/home-slice';

const mapStateToProps = (state: RootState) => ({
  addContactsFromCSVRequestStatus:
    state.sequence.addContactsFromCSVRequest.status,
  addContactsFromCSVRequestMessage:
    state.sequence.addContactsFromCSVRequest.message,
  addContactsFromCSVRequestError:
    state.sequence.addContactsFromCSVRequest.error,
  importCSVReport: state.sequence.importCSVReport,
  contactFields: state.sequence.contactFields,
  steps: state.sequence.steps ? state.sequence.steps : [],
  sequenceId: state.sequence.sequence ? state.sequence.sequence.id : 0,
  adminSettings: state.sequence.adminSettings,
  cancelEmailVerificationPlanRequest:
    state.billingAndSubscription.cancelEmailVerificationPlanRequest.status,
  sendPurchaseEmailVerificationCreditsRequestStatus:
    state.billingAndSubscription.purchaseEmailVerificationCreditsRequest.status,
  sendPurchaseEmailVerificationCreditsRequestError:
    state.billingAndSubscription.purchaseEmailVerificationCreditsRequest.error,
  sendPurchaseEmailVerificationCreditsRequestMessage:
    state.billingAndSubscription.purchaseEmailVerificationCreditsRequest
      .message,
  getEmailVerificationPlanResponse:
    state.billingAndSubscription.getEmailVerificationPlanResponse,
  getPurchaseEmailVerificationCreditsResponse:
    state.billingAndSubscription.purchaseEmailVerificationCreditsResponse,

  profileProgress: state.home.profileProgress,

  updateProfileProgressRequestStatus:
    state.home.updateProfileProgressRequest.status,
  updateProfileProgressRequestMessage:
    state.home.updateProfileProgressRequest.message,
  updateProfileProgressRequestError:
    state.home.updateProfileProgressRequest.error,

  remainingProspectLimit: parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'PROSPECT.ADD'
    ],
    10,
  ),
  currentPlanName: state.home?.subscription?.planName,

  getAdminSettingsRequestStatus: state.sequence.getAdminSettings.status,
});

const mapDispatchToProps = {
  sendGetContactFieldsRequest: () => getContactFieldsRequest(),
  sendGetSequenceStepsRequest: (sequenceId: number) =>
    getSequenceStepsRequest(sequenceId),
  sendAddContactsFromCSVRequest: (payload: AddContactsFromCSVRequestPayload) =>
    addContactsFromCSVRequest({ ...payload }),
  sendAddContactsFromCSVRequestWithoutStep: (
    payload: AddContactsFromCSVRequestPayload,
  ) => addContactsFromCSVRequestWithoutStep({ ...payload }),
  sendPurchaseEmailVerificationCreditsRequest: (
    payload: PurchaseEmailVerificationCreditsRequest,
  ) => purchaseEmailVerificationCreditsRequest(payload),
  sendGetEmailVerificationCreditsRequest: () =>
    getEmailVerificationCreditsRequest(),
  sendGetEmailVerificationCreditPlansRequest: () =>
    getEmailVerificationPlanRequest(),
  resetPurchaseEmailVerificationCreditsResponse: () =>
    resetPurchaseEmailVerificationCreditsResponse(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  updateProfileProgressRequest: (payload: UpdateProfileProgressPayload) =>
    updateProfileProgressRequest(payload),
  sendGetAdminSettingsRequest: () => getAdminSettingsRequest(),
  enableBulkActionRequestPolling: () => enableBulkActionRequestPolling(),
  disableBulkActionRequestPolling: () => disableBulkActionRequestPolling(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onClose: () => void;
  fromModule: SHModules;
  warning: string;
};

export default connector(ImportContactsModal);
