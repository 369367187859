import React, { useEffect, useRef, useState } from 'react';

import Input from '../../../shared/design-system/components/input';
import Modal from '../../../shared/design-system/ui/modal';
import toaster from '../../../shared/toaster';

export type IProps = {
  show: boolean;
  onClose: () => void;
  emailAccountInfrastructureForwardingDomain: string;
  updateEmailAccountInfrastructureForwardingDomain: (value: string) => void;
};

const ForwadingDomainModal: React.FC<IProps> = ({
  show,
  onClose,
  emailAccountInfrastructureForwardingDomain,
  updateEmailAccountInfrastructureForwardingDomain,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState(
    emailAccountInfrastructureForwardingDomain || '',
  );
  const [isDomainCorrect, setIsDomainCorrect] = useState<boolean>(true);
  const [isBlurred, setIsBlurred] = useState<boolean>(false);

  const domainRegex = /^\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z,A-Z]+)\s*(?:,\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z,A-Z]+)\s*)*$/;

  const domainCheck = () => domainRegex.test(inputValue.trim());

  const onSave = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    setIsBlurred(true);

    if (domainCheck()) {
      updateEmailAccountInfrastructureForwardingDomain(inputValue.trim());
      toaster.success('Forwarding domain set successfully');
    } else {
      setIsDomainCorrect(false);
    }
  };

  const handleBlur = () => {
    setIsBlurred(true);
    if (!domainCheck()) {
      setIsDomainCorrect(false);
    }
  };

  const handleChange = (value: string) => {
    setInputValue(value);

    if (!isDomainCorrect) {
      setIsDomainCorrect(true);
    }
  };

  useEffect(() => {
    if (show) {
      setInputValue(emailAccountInfrastructureForwardingDomain ?? '');
      inputRef.current?.focus();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      className="forwarding-domain-modal"
      title={`${
        emailAccountInfrastructureForwardingDomain ? 'Change' : 'Set'
      } Forwarding Domain`}
      primaryBtnClickedHandler={onSave}
      secondaryBtnClickedHandler={onClose}
      primaryBtnLabel="Save"
      secondaryBtnLabel="Cancel"
      primaryBtnDisabled={!domainCheck()}
      onHide={onClose}
    >
      <form onSubmit={onSave}>
        <Input
          key="forwarding-domain"
          placeholder="Enter Forwarding Domain"
          className="w-100"
          onChange={handleChange}
          onBlur={handleBlur}
          value={inputValue}
          variant={
            (!isDomainCorrect && Input.Variant.Error) || Input.Variant.Default
          }
          ref={inputRef}
        />
        {!isDomainCorrect && isBlurred && (
          <p className="forwarding-domain-error-message">
            Enter a valid domain name with extension (e.g. .com, .net, .org)
          </p>
        )}
      </form>
    </Modal>
  );
};

export default ForwadingDomainModal;
