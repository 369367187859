import React from 'react';

export const Google = ({ width = 16, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clipPath="url(#clip0_29244_5911)">
      <path
        d="M13.16 7.14583C13.16 6.69083 13.1192 6.25333 13.0433 5.83333H7V8.31833H10.4533C10.3017 9.11749 9.84667 9.79416 9.16417 10.2492V11.865H11.2467C12.46 10.745 13.16 9.09999 13.16 7.14583Z"
        fill="#4285F4"
      />
      <path
        d="M7.00006 13.4167C8.73256 13.4167 10.1851 12.845 11.2467 11.865L9.16423 10.2492C8.59256 10.6342 7.8634 10.8675 7.00006 10.8675C5.33173 10.8675 3.91423 9.74167 3.40673 8.22501H1.27173V9.88167C2.32756 11.9758 4.49173 13.4167 7.00006 13.4167Z"
        fill="#34A853"
      />
      <path
        d="M3.40659 8.21916C3.27825 7.83416 3.20242 7.42583 3.20242 6.99999C3.20242 6.57416 3.27825 6.16583 3.40659 5.78083V4.12416H1.27159C0.834085 4.98749 0.583252 5.96166 0.583252 6.99999C0.583252 8.03833 0.834085 9.01249 1.27159 9.87583L2.93409 8.58083L3.40659 8.21916Z"
        fill="#FBBC05"
      />
      <path
        d="M7.00006 3.13833C7.94506 3.13833 8.78506 3.46499 9.45589 4.09499L11.2934 2.25749C10.1792 1.21916 8.73256 0.583328 7.00006 0.583328C4.49173 0.583328 2.32756 2.02416 1.27173 4.12416L3.40673 5.78083C3.91423 4.26416 5.33173 3.13833 7.00006 3.13833Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_29244_5912">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
