/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ChevronDown, ChevronUp, InfoCircle } from '@saleshandy/icons';
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from 'country-state-city';
import { Button } from '@saleshandy/design-system';
import Input from '../../../../../../shared/design-system/components/input';
import type { IProps } from './contact-details-tab-container';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';

import InputLabel from '../../../../../../shared/design-system/components/input/input-label';
import BillingSummaryModal from '../../../../modals/billing-summary-modal';
import { formatCreateEmailInfrastructurePayload } from '../../utils/format-create-email-infrastructure-payload';
import toaster from '../../../../../../shared/toaster';
import {
  clearAllEmailAccountInfrastructureKeysFromLocalStorage,
  getFromLocalStorage,
  LOCAL_STORAGE_KEYS,
  saveToLocalStorage,
} from '../../../../helpers/email-accounts-infra-local-storage';
import Transfer from '../../../../../../shared/design-system/components/transfer';
import { Placement } from '../../../../../../shared/design-system/components/overlay';

const ContactDetailsForm: React.FC<IProps> = ({
  sendDomainOwnerContactDetailsRequest,
  domainOwnerContactDetails,
  hasErrorsInContactDetailsForm,

  updateHasErrorsInContactDetailsForm,
  showBillingSummaryModal,
  setShowBillingSummaryModal,
  emailAccountInfrastructureServiceProvider,
  emailAccountInfrastructureForwardingDomain,
  emailAccountInfrastructureCart,
  purchaseDomainRequestStatus,
  purchaseDomainRequestError,
  purchaseDomainResponse,
  sendPurchaseDomainRequest,
  resetPurchaseDomainRequest,
  resetEmailAccountInfrastructure,
}) => {
  const history = useHistory();

  const [values, setValues] = useState({
    domainOwnerFirstname: '',
    domainOwnerLastname: '',
    companyName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    phoneNumber: '',
  });

  const [errors, setErrors] = useState({
    domainOwnerFirstname: '',
    domainOwnerLastname: '',
    companyName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    phoneNumber: '',
  });

  const fieldLabels: Record<string, string> = {
    domainOwnerFirstname: 'First name',
    domainOwnerLastname: 'Last name',
    companyName: 'Company name',
    address: 'Address',
    city: 'City',
    state: 'State',
    country: 'Country',
    zipcode: 'ZIP / Postal code',
    phoneNumber: 'Phone number',
  };

  // Api initialization check
  const [isInitialized, setIsInitialized] = useState(false);

  const [countryCode, setCountryCode] = useState('us');
  const phoneInputField = useRef<HTMLInputElement>(null);

  const [countries, setCountries] = useState(Country.getAllCountries());
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [showCountry, setShowCountry] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showCity, setShowCity] = useState(false);

  // Validator function with dynamic rules
  const validate = (fieldName: string, value: string) => {
    if (fieldName === 'state' && states.length <= 0) {
      setErrors((prev) => ({ ...prev, state: '', city: '' }));
    } else if (fieldName === 'state' && states.length > 0 && !values.state) {
      setErrors((prev) => ({ ...prev, state: 'State should not be empty.' }));
    }

    if (fieldName === 'city' && cities.length <= 0) {
      setErrors((prev) => ({ ...prev, city: '' }));
    } else if (fieldName === 'city' && cities.length > 0 && !values.city) {
      setErrors((prev) => ({ ...prev, city: 'City should not be empty.' }));
    }

    if (!value || value.trim() === '') {
      return `${fieldLabels[fieldName]} should not be empty.`;
    }

    return '';
  };

  const handleInputChange = (name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const toUpdateCompletePaymentButton = () => {
    if (
      !values.domainOwnerFirstname ||
      !values.domainOwnerLastname ||
      !values.companyName ||
      !values.address ||
      !values.country ||
      !values.zipcode ||
      !values.phoneNumber
    ) {
      if (!hasErrorsInContactDetailsForm) {
        updateHasErrorsInContactDetailsForm(true);
      }

      return;
    }

    if (states.length > 0 && !values.state) {
      if (!hasErrorsInContactDetailsForm) {
        updateHasErrorsInContactDetailsForm(true);
      }

      return;
    }

    if (cities.length > 0 && !values.city) {
      if (!hasErrorsInContactDetailsForm) {
        updateHasErrorsInContactDetailsForm(true);
      }

      return;
    }

    if (hasErrorsInContactDetailsForm) {
      updateHasErrorsInContactDetailsForm(false);
    }
  };

  const handleInputBlur = (name: string) => {
    setErrors((prev) => ({
      ...prev,
      [name]: validate(name, values[name]),
    }));

    saveToLocalStorage(
      LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_CONTACT_DETAILS,
      values,
    );

    toUpdateCompletePaymentButton();
  };

  const handlePhoneInputChange = (value: string, data: any) => {
    setValues((prev) => ({ ...prev, phoneNumber: value }));
    setErrors((prev) => ({ ...prev, phoneNumber: '' }));

    if (data && data.countryCode !== countryCode) {
      setCountryCode(data.countryCode);
      phoneInputField.current?.focus();
    }
  };

  const handleCountryChange = (_country: any) => {
    if (!_country || !_country.name) {
      setValues((prev) => ({
        ...prev,
        country: '',
        state: '',
        city: '',
      }));
      setStates([]);
      setCities([]);
      setErrors((prev) => ({
        ...prev,
        country: 'Country should not be empty.',
        state: '',
        city: '',
      }));
      return;
    }
    setValues((prev) => ({
      ...prev,
      country: _country.name || '',
      state: '',
      city: '',
    }));

    setErrors((prev) => ({
      ...prev,
      country: '',
      state: '',
      city: '',
    }));
    setStates(State.getStatesOfCountry(_country.isoCode));
    setCities([]);
    setShowCountry(false);
  };

  const handleStateChange = (_state: any) => {
    if (!_state || !_state.name) {
      setValues((prev) => ({
        ...prev,
        state: '',
        city: '',
      }));
      setCities([]);
      setErrors((prev) => ({
        ...prev,
        state: 'State should not be empty.',
        city: '',
      }));
      return;
    }

    setValues((prev) => ({
      ...prev,
      state: _state.name || '',
      city: '',
    }));

    setErrors((prev) => ({
      ...prev,
      state: '',
      city: '',
    }));

    const selectedCountry = countries.find((c) => c.name === values.country);
    setCities(City.getCitiesOfState(selectedCountry.isoCode, _state.isoCode));
    setShowState(false);
  };

  const handleCityChange = (cityName: string) => {
    if (!cityName) {
      setValues((prev) => ({
        ...prev,
        city: '',
      }));
      setErrors((prev) => ({
        ...prev,
        city: 'City should not be empty.',
      }));
      return;
    }

    setValues((prev) => ({ ...prev, city: cityName }));
    setErrors((prev) => ({ ...prev, city: '' }));
    setShowCity(false);
  };

  const handlePayNowButtonClick = () => {
    const payload = formatCreateEmailInfrastructurePayload(
      emailAccountInfrastructureServiceProvider,
      emailAccountInfrastructureForwardingDomain,
      emailAccountInfrastructureCart,
      values,
    );

    sendPurchaseDomainRequest(payload);
  };

  useEffect(() => {
    if (domainOwnerContactDetails && !isInitialized) {
      const initializeLocation = async () => {
        setValues({
          domainOwnerFirstname:
            domainOwnerContactDetails.domainOwnerFirstname || '',
          domainOwnerLastname:
            domainOwnerContactDetails.domainOwnerLastname || '',
          companyName: domainOwnerContactDetails.companyName || '',
          address: domainOwnerContactDetails.address || '',
          city: domainOwnerContactDetails.city || '',
          state: domainOwnerContactDetails.state || '',
          country: domainOwnerContactDetails.country || '',
          zipcode: domainOwnerContactDetails.zipcode || '',
          phoneNumber:
            domainOwnerContactDetails.phoneNumber.replaceAll('+', '') || '',
        });

        const country = Country.getAllCountries().find(
          (c) => c.name === domainOwnerContactDetails.country,
        );
        if (country) {
          setStates(State.getStatesOfCountry(country.isoCode));

          const state = State.getStatesOfCountry(country.isoCode).find(
            (s) => s.name === domainOwnerContactDetails.state,
          );

          if (state) {
            setCities(City.getCitiesOfState(country.isoCode, state.isoCode));
          }
        }

        setIsInitialized(true);
      };

      initializeLocation();
    }
  }, [domainOwnerContactDetails, isInitialized]);

  useEffect(() => {
    const storedContactDetails = getFromLocalStorage(
      LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_CONTACT_DETAILS,
    );

    if (!storedContactDetails) {
      sendDomainOwnerContactDetailsRequest();
    }
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: purchaseDomainRequestStatus,
      onSuccess: () => {
        window.open(purchaseDomainResponse?.stripeCheckoutUrl || '', '_blank');
        resetPurchaseDomainRequest();
        setShowBillingSummaryModal(false);
        clearAllEmailAccountInfrastructureKeysFromLocalStorage();
        resetEmailAccountInfrastructure();
        history.push('/email-accounts');
      },
      onFailed: () => {
        resetPurchaseDomainRequest();
        executeOnErrorWithErrorCheck({
          error: purchaseDomainRequestError,
          onError: () => {
            toaster.error(purchaseDomainRequestError?.message || '');
          },
        });
      },
    });
  }, [purchaseDomainRequestStatus]);

  useEffect(() => {
    if (isInitialized) {
      toUpdateCompletePaymentButton();
    }
  }, [isInitialized]);

  const countryOptions = useMemo(
    () =>
      countries.map((coun) => ({
        key: coun.name.toString(),
        ...coun,
      })),
    [countries],
  );

  const stateOptions = useMemo(
    () =>
      states.map((coun) => ({
        key: coun.name.toString(),
        ...coun,
      })),
    [states],
  );

  const cityOptions = useMemo(
    () =>
      cities.map((coun) => ({
        key: coun.name.toString(),
        ...coun,
      })),
    [cities],
  );

  return (
    <div className="contact-form-wrapper">
      <form className="contact-form-container">
        <div className="form-content">
          <div className="notification-box">
            <div className="notification-content">
              <InfoCircle width={16} height={16} className="info-icon" />
              <p className="notification-message">
                These contact details will be used to purchase domain and email
                accounts.
              </p>
            </div>
          </div>

          <div className="contact-form">
            <div className="name-fields">
              <Input
                label="First Name"
                placeholder="John"
                value={values.domainOwnerFirstname}
                onChange={(value?: string) =>
                  handleInputChange('domainOwnerFirstname', value)
                }
                onBlur={() => handleInputBlur('domainOwnerFirstname')}
                variant={errors.domainOwnerFirstname && Input.Variant.Error}
                caption={errors.domainOwnerFirstname}
              />
              <Input
                label="Last Name"
                placeholder="Doe"
                value={values.domainOwnerLastname}
                onChange={(value?: string) =>
                  handleInputChange('domainOwnerLastname', value)
                }
                onBlur={() => handleInputBlur('domainOwnerLastname')}
                variant={errors.domainOwnerLastname && Input.Variant.Error}
                caption={errors.domainOwnerLastname}
              />
            </div>

            <div className="company-name">
              <Input
                label="Company Name"
                placeholder="Acme"
                value={values.companyName}
                onChange={(value?: string) =>
                  handleInputChange('companyName', value)
                }
                onBlur={() => handleInputBlur('companyName')}
                variant={errors.companyName && Input.Variant.Error}
                caption={errors.companyName}
              />
            </div>
          </div>

          <div className="address-field">
            <Input
              label="Address"
              placeholder="Street Number Name, Apartment, Unit"
              value={values.address}
              onChange={(value?: string) => handleInputChange('address', value)}
              onBlur={() => handleInputBlur('address')}
              variant={errors.address && Input.Variant.Error}
              caption={errors.address}
            />
          </div>

          <div className="country-state-fields">
            <div className="country-field">
              <InputLabel text="Country" place={InputLabel.Place.Before} />

              <Transfer<ICountry>
                show={showCountry}
                onToggle={(value) => setShowCountry(value)}
                className="country-filter-dropdown country-filter"
                showSearch
                searchPlaceholder="Search"
                multiSelect={false}
                options={countryOptions}
                showOptionsSeparator={true}
                optionRenderer={(option) => <span>{option.name}</span>}
                filterOption={(value, option) =>
                  option?.name?.toLowerCase().includes(value.toLowerCase())
                }
                selectedOptionKey={countries.map((key) => key.toString())}
                onChange={(selectedOptions) => {
                  if (!selectedOptions || selectedOptions.length === 0) return;

                  const selectedCountry = countries.find(
                    (c) => c.name === selectedOptions[0].name,
                  );

                  if (selectedCountry) {
                    handleCountryChange(selectedCountry);
                  }
                }}
                placement={Placement.BottomEnd}
                onExit={() => handleInputBlur('country')}
              >
                <Button
                  className={`country-select-btn ${
                    errors.country ? 'has-error' : ''
                  }`}
                  variant="tertiary"
                  onClick={() => setShowCountry(!showCountry)}
                >
                  {values.country === '' ? (
                    <div className="country-city-state-placeholder">
                      Select Country
                    </div>
                  ) : (
                    <div className="country-city-state-value">
                      {values.country}
                    </div>
                  )}

                  <div>{showCountry ? <ChevronUp /> : <ChevronDown />}</div>
                </Button>
              </Transfer>
              {errors.country && (
                <InputLabel
                  text={errors.country}
                  place={InputLabel.Place.After}
                />
              )}
            </div>

            <div className="state-field">
              <InputLabel
                text="State / Province"
                place={InputLabel.Place.Before}
              />

              <Transfer<IState>
                show={showState}
                onToggle={(value) => setShowState(value)}
                className="state-filter-dropdown state-filter"
                showSearch
                searchPlaceholder="Search"
                multiSelect={false}
                options={stateOptions}
                showOptionsSeparator={true}
                optionRenderer={(option) => <span>{option.name}</span>}
                filterOption={(value, option) =>
                  option?.name?.toLowerCase().includes(value.toLowerCase())
                }
                selectedOptionKey={states.map((key) => key.toString())}
                onChange={(selectedOptions) => {
                  if (!selectedOptions || selectedOptions.length === 0) return;

                  const selectedState = states.find(
                    (c) => c.name === selectedOptions[0].name,
                  );

                  if (selectedState) {
                    handleStateChange(selectedState);
                  }
                }}
                placement={Placement.BottomEnd}
                onExit={() => handleInputBlur('state')}
              >
                <Button
                  className={`state-select-btn ${
                    errors.state ? 'has-error' : ''
                  }`}
                  variant="tertiary"
                  onClick={() => setShowState(!showState)}
                >
                  {values.state === '' ? (
                    <div className="country-city-state-placeholder">
                      Select State / Province
                    </div>
                  ) : (
                    <div className="country-city-state-value">
                      {values.state}
                    </div>
                  )}
                  <div>{showState ? <ChevronUp /> : <ChevronDown />}</div>
                </Button>
              </Transfer>
              {errors.state && (
                <InputLabel
                  text={errors.state}
                  place={InputLabel.Place.After}
                />
              )}
            </div>
          </div>

          <div className="city-zip-fields">
            <div className="city-field">
              <InputLabel text="City" place={InputLabel.Place.Before} />
              <Transfer<ICity>
                show={showCity}
                onToggle={(value) => setShowCity(value)}
                className="city-filter-dropdown city-filter"
                showSearch
                searchPlaceholder="Search"
                multiSelect={false}
                options={cityOptions}
                showOptionsSeparator={true}
                optionRenderer={(option) => <span>{option.name}</span>}
                filterOption={(value, option) =>
                  option?.name?.toLowerCase().includes(value.toLowerCase())
                }
                selectedOptionKey={cities.map((key) => key.toString())}
                onChange={(selectedOptions) => {
                  if (!selectedOptions || selectedOptions.length === 0) return;

                  const selectedCity = cities.find(
                    (c) => c.name === selectedOptions[0].name,
                  );

                  if (selectedCity) {
                    handleCityChange(selectedCity.name);
                  }
                }}
                placement={Placement.BottomEnd}
                onExit={() => handleInputBlur('city')}
              >
                <Button
                  className={`city-select-btn ${
                    errors.city ? 'has-error' : ''
                  }`}
                  variant="tertiary"
                  onClick={() => setShowCity(!showCity)}
                >
                  {values.city === '' ? (
                    <div className="country-city-state-placeholder">
                      Select City
                    </div>
                  ) : (
                    <div className="country-city-state-value">
                      {values.city}
                    </div>
                  )}
                  <div>{showCity ? <ChevronUp /> : <ChevronDown />}</div>
                </Button>
              </Transfer>
              {errors.city && (
                <InputLabel text={errors.city} place={InputLabel.Place.After} />
              )}
            </div>

            <Input
              label="ZIP / Postal Code"
              placeholder="E.g 10001"
              value={values.zipcode}
              onChange={(value?: string) => handleInputChange('zipcode', value)}
              onBlur={() => handleInputBlur('zipcode')}
              variant={errors.zipcode && Input.Variant.Error}
              caption={errors.zipcode}
              className="zip-field"
            />
          </div>

          <div className="phone-field-container">
            <div className="phone">
              <InputLabel text="Phone Number" place={InputLabel.Place.Before} />
              <PhoneInput
                country={countryCode}
                value={values.phoneNumber}
                onChange={handlePhoneInputChange}
                onBlur={() => handleInputBlur('phoneNumber')}
                containerClass={classNames([
                  'phone-form-input',
                  { 'input-error': errors.phoneNumber },
                ])}
                inputProps={{
                  tabIndex: 0,
                  name: 'phone',
                  ref: phoneInputField,
                }}
              />
              {errors.phoneNumber && (
                <InputLabel
                  text={errors.phoneNumber}
                  place={InputLabel.Place.After}
                />
              )}
            </div>
          </div>
        </div>
      </form>

      <BillingSummaryModal
        show={showBillingSummaryModal}
        onClose={() => setShowBillingSummaryModal(false)}
        onSubmit={handlePayNowButtonClick}
        emailAccountInfrastructureCart={emailAccountInfrastructureCart}
        isLoading={getIsRequestPending(purchaseDomainRequestStatus)}
      />
    </div>
  );
};

export default ContactDetailsForm;
