export enum DomainStatus {
  Pending = 'pending',
  InProgress = 'inProgress',
  Active = 'active',
  Deleted = 'deleted',
  Failed = 'failed',
}

export enum DomainAction {
  DELETE = 'delete',
  REVOKE = 'revoke',
}
