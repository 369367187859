import React from 'react';
import { Link } from 'react-router-dom';

import { supportUrls } from '../../utils/urls';
import {
  CustomDomainScore,
  DailySendingQuotaScore,
  DkimScore,
  DmarcScore,
  DomainBlacklistScore,
  EmailAgeScore,
  IPBlacklistScore,
  MXRecordsScore,
  SendingIntervalScore,
  SpfScore,
} from './enums';
import Alert from '../../design-system/components/alert';
import { AlertTriangle, Checks, Copy, InfoCircle } from '@saleshandy/icons';
import CopyButton from './components/factor-copy-button';
import { getBlacklistedCount } from './helper';
import { OverlayPopover, OverlayTooltip, Placement } from '../../design-system/components/overlay';
import { Images } from '../../app-constants';
import { accessibleOnClick } from '../../utils/accessible-on-click';

export const getSPFDescription = (score: number) =>
  score === SpfScore.min ? (
    <p>
      Uh-oh! The SPF record is not set for this email account. It's critically
      important, so we recommend urgently setting it up, as mentioned{' '}
      <a href={supportUrls.spfBlog} target="_blank" rel="noreferrer">
        here
      </a>
      .
    </p>
  ) : (
    <p>Great news! Your SPF record is set up correctly.</p>
  );

export const getCTDDescription = (score: number) =>
  score === CustomDomainScore.min ? (
    <p>
      Oops! The custom tracking domain is not set.{' '}
      <a href={supportUrls.ctdBLog} target="_blank" rel="noreferrer">
        Learn how
      </a>{' '}
      to set up a custom tracking domain & set it up{' '}
      <Link to="/settings/custom-domain">here</Link>.
    </p>
  ) : (
    <p>Congratulations! Your custom tracking domain is set up correctly.</p>
  );

export const getDSQDescription = (score: number) => {
  if (score === DailySendingQuotaScore.min) {
    return (
      <p>
        Your email account has a high daily sending quota, which can increase
        the risk of hitting spam filters. We strongly recommend it to keep below
        50.
      </p>
    );
  }

  if (score === DailySendingQuotaScore.mid) {
    return (
      <p>
        Your email account has a moderate daily sending quota. It's important to
        follow proper email-sending practices to avoid hitting your quota and
        maintain a good sender reputation. Consider segmenting your email list
        and sending emails in batches to stay within your sending quota.
      </p>
    );
  }

  return (
    <p>
      Excellent! Your email account has an ideal daily sending quota, a good
      practice to prevent spam issues.
    </p>
  );
};

export const getDMARCDescription = (score: number) =>
  score === DmarcScore.min ? (
    <p>
      The DMARC record is not set. It helps prevent email spoofing, so we
      suggest setting it up, as mentioned{' '}
      <a href={supportUrls.dmarcBlog} target="_blank" rel="noreferrer">
        here
      </a>
      .
    </p>
  ) : (
    <p>Excellent! Your DMARC record is set up correctly.</p>
  );

  export const getDkimDescription = (score: number) => 
    score && score > DkimScore.min ? (
      <p>
        Excellent! Your DKIM record is set up correctly.
      </p>
    ) : (
      <p>
        DKIM is not set up. It ensures your emails are secure and trusted. Set it up as mentioned here.
      </p>
    );

  export const getDBLDescription = (score: number) =>
    score && score > DomainBlacklistScore.min ? (
      <p>Excellent! Your Domain was not found blacklisted.</p>
    ) : (
      <p>
        This domain was found blacklisted it may cause your emails to land in spam, 
        to know how to delist the domain{' '}
        <a href={supportUrls.dmarcBlog} target="_blank" rel="noreferrer">
          click here
        </a>
        .
      </p>
    );

  export const getIPBLDescription = (score: number) =>
    score && score > IPBlacklistScore.min ? (
      <p>Excellent! Your IP was not found blacklisted.</p>
    ) : (
      <p>
        This IP was found blacklisted it may cause your emails to land in spam, to know how to delist IPs{' '}
        <a href={supportUrls.dmarcBlog} target="_blank" rel="noreferrer">
          click here
        </a>
        .
      </p>
    );

  export const getMXRecordsDescription = (score: number) =>
    score && score > MXRecordsScore.min ? (
      <p>Excellent! Your MX record is set up correctly.</p>
    ) : (
      <p>
        MX record is missing. It's essential for receiving emails. Set it up as mentioned{' '}
        <a href={supportUrls.dmarcBlog} target="_blank" rel="noreferrer">
          here.
        </a>
        .
      </p>
    );

export const getEmailAgeDescription = (score: number) => {
  if (score === EmailAgeScore.min) {
    return (
      <p>
        This email account is less than 3 months old. Please send fewer emails,
        enable ramp-up & use email warm-up for this email account.
      </p>
    );
  }

  if (score === EmailAgeScore.mid) {
    return (
      <p>
        This email account is between 3-6 months old. It's still relatively new,
        but with proper email sending practices, you can improve your sender
        reputation and email deliverability. We recommend you to keep the daily
        sending quota between 25-75 to ensure your email account is not flagged
        for spam. We also suggest you to use email warm-up to maintain a optimum
        deliverability.
      </p>
    );
  }

  return <p>Great! The email account is more than 6 months old.</p>;
};

export const getSendingIntervalDescription = (score: number) => {
  if (score === SendingIntervalScore.min) {
    return (
      <p>
        Uh-oh! The time interval between two emails is too short. It can affect
        the deliverability; therefore, we suggest you increase it to 200
        seconds.
      </p>
    );
  }

  if (score === SendingIntervalScore.mid) {
    return (
      <p>
        Your email account is sending emails at a moderate pace, but it's still
        important to follow proper email-sending practices to maintain a good
        sender reputation. Consider increasing the interval beyond 180 seconds
        to further improve deliverability and prevent spam issues.
      </p>
    );
  }

  return (
    <p>
      Excellent! The email-sending speed is reasonable, which can help prevent
      spam issues.
    </p>
  );
};

export const getDomainBlacklistContent = (domainBlacklist: {domain: string, ip: string, data: {status: string, blacklist: string[]}[]}) => {
  const blacklistedCount = getBlacklistedCount(domainBlacklist?.data);
  return (
    <>
      <p className='modal-content-title'>
        Your domain is <span  className='modal-content-title-domain'>{domainBlacklist?.domain}</span> and IP address is <span className='modal-content-title-ip'>{domainBlacklist?.ip}</span>{' '}
      </p>
      {blacklistedCount > 0 ? (
        <Alert
          variant={Alert.Variant.Danger}
          contentContainer={
            <div className='d-flex'>
                <AlertTriangle className='mr-2' />
              <div>
                <span  className='modal-content-alert-title'>{blacklistedCount} Listed - You are listed on {blacklistedCount} blacklists.</span>
                <br />
                <span  className='modal-content-alert-sub-title'>We checked {domainBlacklist?.domain} across 100+ blacklists, if domain is blacklisted then it'll impact your email deliverability.{' '}
                  <OverlayTooltip
                    className="permission-popover"
                    placement={Placement.Bottom}
                    text="If your domain is newly purchased, it might be on one of the blacklists. Please wait for 4-5 weeks. If it's still listed, follow the steps to delist it."
                  >
                    <InfoCircle
                      className="info-icon-popover-btn cursor-pointer"
                    />
                  </OverlayTooltip>
                </span>
              </div>
            </div>
          }
          theme={Alert.Theme.New}
          className="m-auto"
        />
      ) : (
        <Alert
          variant={Alert.Variant.Success}
          contentContainer={
            <div className='d-flex align-items-center'>
              <Checks className='mr-2' />
              <div>
                <p><strong>100% Pass - You are not on any blacklists!</strong></p>
                <p>We checked {domainBlacklist?.domain} across all known blacklists using our blacklist checker.</p>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export const getIPBlacklistContent = (ipBlacklist: {ip: string, data: {status: string, blacklist: string[]}[]}) => {
  const blacklistedCount = getBlacklistedCount(ipBlacklist?.data);
  return (
    <>
      <p>
        Your Email sending IP is {ipBlacklist?.ip} 
      </p>
      {blacklistedCount > 0 ? (
        <Alert
          variant={Alert.Variant.Danger}
          contentContainer={
            <div className='d-flex align-items-center'>
              <AlertTriangle className='mr-2' />
              <div>
                <p><strong>{blacklistedCount} Listed - You are listed on {blacklistedCount} blacklists.</strong></p>
                <p>We checked {ipBlacklist?.ip} across 100+ blacklists, if IP is blacklisted then it'll impact your email deliverability.</p>
              </div>
            </div>
          }
          theme={Alert.Theme.New}
          className="m-auto"
        />
      ) : (
        <Alert
          variant={Alert.Variant.Success}
          contentContainer={
            <div className='d-flex align-items-center'>
              <Checks className='mr-2' />
              <div>
                <p><strong>100% Pass - You are not on any blacklists!</strong></p>
                <p>We checked {ipBlacklist?.ip} across all known blacklists using our blacklist checker.</p>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export const getDMARCContent = (dmarcRecord: string) => {
  return (
    <div className="dmarc-container">
      <div className='dmarc-content'>
        <code>{dmarcRecord}</code>
      </div>
      <div className='dmarc-content-footer'>
        <CopyButton text={dmarcRecord} />
      </div>
    </div>
  )
}

export const getSPFContent = (spfRecord: string) => {
  return (
    <div className='spf-container'>
      <div className='spf-content'>
        <code>{spfRecord}</code>
      </div>
      <div className='spf-content-footer'>
        <CopyButton text={spfRecord}></CopyButton>
      </div>
    </div>
  )
}

export const getDomainBlacklistFooter = (onViewYoutubeVideo: () => void) => {
  return (
    <div>
      <p className='blacklist-footer-title'>
        What to do after being blacklisted? 
        <img
          {...accessibleOnClick(onViewYoutubeVideo)}
          src={Images.YoutubeLogo}
          alt="Icon"
          className="ml-2"
        />
      </p>
      <p className='blacklist-footer-content'>
        <span className='blacklist-footer-content-title'>Request Removal:</span> Consult the blacklists where your domain is listed and follow the instructions to remove it.{' '}
        <a href={supportUrls.dmarcBlog} target="_blank" rel="noreferrer">
          Learn more
        </a>
      </p>
      <p className='blacklist-footer-content'>
        <span className='blacklist-footer-content-title'>Check Your Deliverability:</span> If you notice a sharp decline in email deliverability, it might be a good idea to switch to a new domain.
      </p>
    </div>
  )
}

export const getIPBlacklistFooter = (onViewYoutubeVideo: () => void) => {
  return (
    <div>
      <p className='blacklist-footer-title'>
        What to do after being blacklisted? 
        <img
          {...accessibleOnClick(onViewYoutubeVideo)}
          src={Images.YoutubeLogo}
          alt="Icon"
          className="ml-2"
        />
      </p>
      <p className='blacklist-footer-content'>
        <span className='blacklist-footer-content-title'>Contact ESP:</span> Consult your ESP and report them about low reputed IP addresses attached in sending emails.{' '}
        <a href={supportUrls.dmarcBlog} target="_blank" rel="noreferrer">
          Learn more
        </a>
      </p>
      <p className='blacklist-footer-content'>
        <span className='blacklist-footer-content-title'>Check Your Deliverability:</span> If you notice a sharp decline in email deliverability, it might be a good idea to switch to a new domain.
      </p>
    </div>
  )
}
