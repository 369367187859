import { CancelToken } from 'axios';
import api from '../../../api';
import { Source } from '../../../shared/enums/source';
import { TrialExtendSource } from '../../../shared/enums/trial-extend-source';
import { ResponseSuccess } from '../../../shared/types';
import {
  CreateSequenceRequestPayload,
  UpdateUserSettingsRequestPayload,
} from '../../sequence/types';
import {
  CompareAuthTokenPayload,
  UnreadEmailThreadsCountPayload,
  UpgradeOnboardingStepPayload,
} from '../types';

export const getUserSettings = async () =>
  api.get<ResponseSuccess>(`/user/meta`);

export const getAgencyConfig = async (args) =>
  api.get<ResponseSuccess>(`/agency/config`, {
    params: { baseUrl: args.baseUrl, email: args.email },
  });

export const updateUserSettings = async (
  payload: UpdateUserSettingsRequestPayload,
) => api.patch<ResponseSuccess>(`/user/meta`, payload);

export const compareAuthToken = async (payload: CompareAuthTokenPayload) =>
  api.post<ResponseSuccess>('/user/compare-user', {
    ...payload,
  });

export const upgradeOnboardingStep = async (
  payload: UpgradeOnboardingStepPayload,
) => api.patch<ResponseSuccess>('/user/onboarding/upgrade', payload);

export const getUserNotifications = async (args) =>
  api.get<ResponseSuccess>(`/notification`, {
    params: {
      page: args,
    },
  });

export const getPollingNotifications = async (cancelToken?: CancelToken) =>
  api.get<ResponseSuccess>('/notification/polling', {cancelToken});

export const updateHiddenNotification = async (notificationId: string) =>
  api.patch<ResponseSuccess>(`/notification/${notificationId}/mark-as-hidden`);

export const getUnreadNotifications = async () =>
  api.get<ResponseSuccess>(`/notification/get-unread-count`);

export const markNotificationsAsRead = async (notificationId?: string) =>
  api.post<ResponseSuccess>(`/notification/mark-as-read`, {
    notificationId,
  });

export const createSequence = async (
  source: Source,
  payload: CreateSequenceRequestPayload,
) =>
  api.post<ResponseSuccess>('/sequences/getting-started', payload, {
    headers: { 'sh-source': source },
  });

export const getPostLoadMeta = async () =>
  api.get<ResponseSuccess>(`/user/postload-meta`);

export const extendTrialPeriod = async (source: TrialExtendSource) =>
  api.patch<ResponseSuccess>(`/accounts/trial-extend`, {
    source,
  });

// Get Unread Email Threads Count
export const getUnreadEmailThreadsCount = async ({
  search,
  categoryId,
  sequenceIds,
  emailAccountIds,
  clientIds,
  owners,
  date,
  shouldCalculateFilterCount,
}: UnreadEmailThreadsCountPayload) =>
  api.post('/unibox/unread-email-threads-count', {
    ...(search !== '' && { search }),
    ...(categoryId > 0 && { categoryId }),
    ...(sequenceIds?.length > 0 && { sequenceIds }),
    ...(emailAccountIds?.length > 0 && { emailAccountIds }),
    ...(clientIds?.length > 0 && { clientIds }),
    ...(owners?.length > 0 && { owners }),
    ...(date?.startDate &&
      date?.endDate && { startDate: date.startDate, endDate: date.endDate }),
    shouldCalculateFilterCount,
  });
