/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import isNaN from 'lodash/isNaN';

import { IProps } from './email-accounts-filters-modal-container';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import Icon from '../../../../../../shared/design-system/components/atoms/icon';
import ProspectsFilter from '../../../../../../shared/design-system/components/molecules/prospects-filter';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import {
  isAgency,
  isAgencyUser,
} from '../../../../../../shared/utils/user-details';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { EmailServiceProvider } from '../../../../enums/email-account';
import {
  EmailAccountFilterStatus,
  EmailAccountsFilters,
  Filters,
} from '../../../../types/email-account';
import { getClientName } from '../../../../../agency-client-management/helpers/helpers';
import { emailAccountsFiltersInitialState } from '../../../../email-accounts-slice';

const EmailAccountsFiltersModal: React.FC<IProps> = ({
  show,
  onClose,
  sendGetSequenceList,
  sendGetAgencyClientsDropdownRequest,
  sendGetTagsRequest,
  sendGetOwnersList,
  sequences,
  clients,
  tags,
  owners,
  emailAccountsFilters,
  onFiltersChange,
}) => {
  const [filters, setFilters] = useState<EmailAccountsFilters>(
    emailAccountsFilters,
  );

  useEffect(() => {
    if (sequences.length === 0) {
      sendGetSequenceList();
    }
    if (tags.length === 0) {
      sendGetTagsRequest();
    }
    if (clients.length === 0) {
      if (hasPermission(Permissions.CLIENT_READ) && isAgency()) {
        sendGetAgencyClientsDropdownRequest({});
      }
    }
    if (owners.length === 0) {
      if (
        hasPermission(Permissions.ACCOUNT_USER_READ) ||
        hasPermission(Permissions.TEAM_USER_READ) ||
        isAgencyUser()
      ) {
        sendGetOwnersList();
      }
    }
  }, []);

  useEffect(() => {
    setFilters({
      ...emailAccountsFilters,
      clientIds: emailAccountsFilters.clientIds.map((id) =>
        id === 0
          ? { id: 0, email: 'None (Where no client is associated)' }
          : clients.find((client) => client.id === id) || {},
      ),
      tagIds: emailAccountsFilters.tagIds.map((id) =>
        id === 0
          ? { id: 0, name: 'None (Where no tag is associated)' }
          : tags.find((tag) => tag.id === id) || {},
      ),
      sequenceIds: emailAccountsFilters.sequenceIds.map((id) =>
        id === 0
          ? { id: 0, title: 'None (Not part of any sequence)' }
          : sequences.find((sequence) => sequence.id === id) || {},
      ),
      emailServiceProvider: emailAccountsFilters.emailServiceProvider.map(
        (provider) =>
          emailServiceProviderOptions.find(
            (option) => option.value === provider,
          ) || {},
      ),
      status:
        emailAccountsFilters.status.length > 0
          ? emailAccountsFilters.status.map(
              (status) =>
                emailAccountStatusOptions.find(
                  (option) => option.value === status,
                ) || {},
            )
          : [],
      owners: emailAccountsFilters.owners.map(
        (id) => mappedOwners.find((owner) => owner.id === id) || {},
      ),
    });
  }, [clients, sequences, tags, owners, emailAccountsFilters]);

  const sequenceOptions = () => [
    { id: 0, title: 'None (Not part of any sequence)', isNoneSequence: true },
    ...sequences,
  ];

  const clientsOptions = () => [
    { id: 0, email: 'None (Where no client is associated)' },
    ...clients.map((client) => ({
      email: getClientName(client, 60, {
        company: true,
        email: true,
        deleted: true,
      }),
      id: client?.id,
    })),
  ];
  const emailServiceProviderOptions = Object.values(EmailServiceProvider)
    .filter(
      (provider) =>
        provider !== EmailServiceProvider.Gmail &&
        provider !== EmailServiceProvider.O365,
    )
    .map((provider) => ({
      label: provider.charAt(0).toUpperCase() + provider.slice(1),
      value: provider,
    }));

  const emailAccountStatusOptions = Object.keys(EmailAccountFilterStatus)
    .filter((key) => isNaN(Number(key)))
    .map((status) => ({
      label: status.replace(/([A-Z])/g, ' $1').trim(),
      value:
        EmailAccountFilterStatus[
          status as keyof typeof EmailAccountFilterStatus
        ],
    }));

  const resolveAndSetFilters = (name: Filters, value: any[]) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value.length ? value : [],
    }));
  };

  const mappedOwners = useMemo(
    () =>
      owners?.length
        ? owners.map((item) => ({
            ...item,
            firstName:
              `${item.firstName}  ${item.lastName}`.trim() || item.email,
            value: item.id,
          }))
        : [],
    [owners],
  );

  const tagsOptions = () => [
    { id: 0, name: 'None (Where no tag is associated)' },
    ...tags,
  ];

  const setClientFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.ClientAssociated, f);
  };

  const setOwnersFilters = (f: any[]) => {
    const filteredValue = f.map((item) => ({
      firstName: item?.label || item?.firstName,
      id: item.id,
    }));
    resolveAndSetFilters(Filters.EmailAccountAddedBy, filteredValue);
  };

  const setSequenceFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.Sequences, f);
  };

  const setTagFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.Tags, f);
  };

  const setEmailAccountStatusFilters = (selectedStatus) => {
    if (selectedStatus.length > 1) {
      resolveAndSetFilters(Filters.EmailAccountStatus, [selectedStatus[1]]);
    } else {
      resolveAndSetFilters(
        Filters.EmailAccountStatus,
        selectedStatus.length > 0 ? [selectedStatus[0]] : [],
      );
    }
  };

  const setEmailServiceProvidersFilters = (f: any[]) => {
    resolveAndSetFilters(Filters.EmailServiceProviders, f);
  };

  const handleReset = () => {
    setFilters({ ...emailAccountsFiltersInitialState, page: 1 });

    onFiltersChange({
      ...emailAccountsFiltersInitialState,
      page: 1,
    });

    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleDone = () => {
    const appliedFilters = { ...filters };

    const transformedFilters = {
      ...emailAccountsFilters,
      page: 1,
      clientIds: appliedFilters.clientIds.map((client) => client.id),
      tagIds: appliedFilters.tagIds.map((tag) => tag.id),
      sequenceIds: appliedFilters.sequenceIds.map((sequence) => sequence.id),
      emailServiceProvider: appliedFilters.emailServiceProvider.map(
        (provider) => provider.value,
      ),
      status:
        appliedFilters.status.length > 0
          ? [appliedFilters.status[0].value]
          : [],
      owners: appliedFilters.owners.map((owner) => owner.id),
    };

    onFiltersChange(transformedFilters);

    onClose();
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      className="prospects-filters-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <span className="semibold-3">Filters</span>
        </Modal.Title>
        <Button className="close" onClick={onClose}>
          <Icon identifier="close" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {hasPermission(Permissions.CLIENT_READ) && isAgency() && (
          <ProspectsFilter
            label="Client Associated"
            containerClassName="bs-mb-20"
            onSelect={setClientFilters}
            options={clientsOptions()}
            labelKey="email"
            placeholder="Search and Select Client Associated"
            values={[...filters.clientIds]}
          />
        )}

        <ProspectsFilter
          label="Email Service Providers"
          containerClassName="bs-mb-20"
          onSelect={setEmailServiceProvidersFilters}
          options={emailServiceProviderOptions}
          labelKey="label"
          placeholder="Search and Select ESP"
          values={[...filters.emailServiceProvider]}
          uniqueKey="value"
        />
        <ProspectsFilter
          label="Tags"
          containerClassName="bs-mb-20"
          onSelect={setTagFilters}
          options={tagsOptions()}
          placeholder="Search and Select Tags"
          values={[...filters.tagIds]}
        />
        <ProspectsFilter
          label="Sequence"
          containerClassName="bs-mb-20"
          onSelect={setSequenceFilters}
          options={sequenceOptions()}
          labelKey="title"
          placeholder="Search and Select Sequence"
          values={[...filters.sequenceIds]}
        />
        <ProspectsFilter
          label="Email Account Status"
          containerClassName="bs-mb-20"
          onSelect={setEmailAccountStatusFilters}
          options={emailAccountStatusOptions}
          labelKey="label"
          placeholder="Select Status"
          values={
            filters.status && filters.status.length > 0 ? filters.status : []
          }
          uniqueKey="value"
          isMultiSelect={false}
        />
        <ProspectsFilter
          label="Email Account Added By"
          containerClassName="bs-mb-20"
          onSelect={setOwnersFilters}
          options={mappedOwners}
          labelKey="firstName"
          placeholder="Search and Select Owner"
          values={[...filters.owners]}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-row align-self-start flex-grow-1">
          <Button
            variant={Button.Variant.Light}
            theme={Button.Theme.Ghost}
            onClick={handleReset}
            className="rst-btn"
          >
            Clear All
          </Button>
          <div className="d-flex flex-row justify-content-end flex-grow-1">
            <Button
              variant={Button.Variant.Outlined}
              theme={Button.Theme.Ghost}
              onClick={handleCancel}
              className="email-account-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              variant={Button.Variant.Primary}
              onClick={handleDone}
              className="submit-btn"
            >
              Apply
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(EmailAccountsFiltersModal);
