import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { SidebarNavItem } from './types';

const navItems = (): SidebarNavItem[] => [
  {
    eventKey: 'sequence',
    route: '/sequence',
    text: 'Sequences',
    tooltipText: 'Sequences',
    permission: Permissions.SEQUENCE_READ,
  },
  {
    eventKey: 'tasks',
    route: '/tasks',
    text: 'Tasks',
    tooltipText: 'Tasks',
    permission: Permissions.TASK_READ,
  },
  {
    eventKey: 'unifiedInbox',
    route: '/conversations',
    text: 'Unified Inbox',
    tooltipText: 'Unified Inbox',
    permission: Permissions.UNIFIED_INBOX_READ,
  },
  {
    eventKey: 'agency-client',
    route: '/clients',
    text: 'Client Management',
    tooltipText: 'Client Management',
    permission: Permissions.CLIENT_READ,
  },
  {
    eventKey: 'leads',
    route: '/leads',
    text: 'Lead Finder',
    tooltipText: 'Lead Finder',
    permission: Permissions.LEAD_FINDER_READ,
  },
  {
    eventKey: 'prospects',
    route: '/prospects',
    text: 'Prospects',
    tooltipText: 'Prospects',
    permission: Permissions.PROSPECT_READ,
  },
  {
    eventKey: 'email-accounts',
    route: '/email-accounts',
    text: 'Email Accounts',
    tooltipText: 'Email Accounts',
    permission: Permissions.EMAIL_ACCOUNT_READ,
    hasDomains: true,
  },
  {
    eventKey: 'templates',
    route: '/templates',
    text: 'Templates',
    tooltipText: 'Templates',
    permission: Permissions.TEMPLATE_READ,
  },
  {
    eventKey: 'reports',
    route: '/reports',
    text: 'Analytics',
    tooltipText: 'Analytics',
    permission: Permissions.REPORT_READ,
  },
  {
    eventKey: 'setting',
    route: '/settings',
    text: 'Settings',
    tooltipText: 'Settings',
    permission: Permissions.SETTING_READ,
  },
];

const navItemsBottom = [
  {
    eventKey: 'referral',
    route: '',
    text: 'Refer A Friend',
    tooltipText: 'Refer A Friend',
    permission: Permissions.REFER_TAB_SHOW,
  },
  {
    eventKey: 'warmup',
    route: '/warmup',
    text: 'Email Warm-up',
    tooltipText: 'Email Warm-up',
    permission: Permissions.EMAIL_WARMUP_READ,
  },
  {
    eventKey: 'email-insights',
    route: '/email-insights',
    text: '1:1 Email Insights',
    tooltipText: '1:1 Email Insights',
    permission: Permissions.MAILBOX_EMAIL_READ,
  },
  {
    eventKey: 'growthHub',
    route: '/growth-hub',
    text: 'Growth Hub',
    tooltipText: 'Growth Hub',
    permission: Permissions.GROWTH_HUB_TAB_SHOW,
  },
  {
    eventKey: 'agency-client',
    route: '/clients',
    text: 'Client Management',
    tooltipText: 'Client Management',
    permission: Permissions.CLIENT_READ,
  },
];

export { navItems, navItemsBottom };
