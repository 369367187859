/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useRef, useState } from 'react';
import { FileCheck, Link, Pencil, Trash, Unlink } from '@saleshandy/icons';
import { SortOrder } from 'react-bootstrap-table-next';
import classNames from 'classnames';

import type { IProps } from './email-accounts-table-container';

import Table from '../../../../../../shared/design-system/components/organisms/table';
import EmailSelectedRowInfoBanner from './components/email-selected-info-banner';

import { getEmailAccountsColumns } from './utils/get-email-accounts-columns';
import { PaginationShowHide } from '../../../../../../shared/design-system/components/organisms/table/types';
import {
  executeOnRequestStatus,
  getIsRequestPending,
  SubscriptionPlans,
} from '../../../../../../shared/utils';
import { EmailAccountsSortBy } from '../../../../enums/email-account';
import {
  checkIsTableLastPage,
  getShouldShowSelectedRowInfoBanner,
  isCurrentPageUnderTheSelectAllPage,
} from './utils/helper';
import { constants } from '../../../../../../shared/enums/constants';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { getColDataFieldForSorting } from './utils/get-col-data-field-for-sorting';
import { useFilteredEffect } from './utils/use-filtered-effect-hook';
import { EmailAccountStatus } from '../../../../types/email-account';
import { Placement } from '../../../../../../shared/design-system/components/overlay';

const EmailAccountsTable: React.FC<IProps> = ({
  emailAccounts,
  isTiIntegrated,
  paginationOptions,
  getEmailAccountsRequestStatus,
  onFiltersChange,
  onAction,
  onEmailAccountsSwitchChangeHandler,
  onSmtpImapModalShow,
  redirectToGeneralSettings,
  selectedEmailAccounts,
  bulkSelectedEmailAccounts,
  onBulkSelectEmailAccounts,
  clearEmailAccountsSelection,
  selectAllEmailAccounts,
  selectSingleEmailAccount,
  resetSelected,
  handleResetSelected,
  addEmailAccountTagsRequestStatus,
  removeEmailAccountTagsRequestStatus,
  emailAccountTagsBulkAssignRequestStatus,
  emailAccountTagsBulkUnassignRequestStatus,
  filters,
  subscriptionPlan,
}) => {
  const emailAccountTableRef = useRef<any>(null);
  const isScrollingRef = useRef(false);
  const [
    isBulkEmailAccountsSelected,
    setIsBulkEmailAccountsSelected,
  ] = useState<boolean>(false);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  // * handle table pagination change
  const onPaginationOptionsChange = ({ page, limit }) => {
    onFiltersChange({ page, limit });
  };

  // * handle table sorting
  const onSort = (key: EmailAccountsSortBy, order: SortOrder) => {
    const newFilters = { sortByKey: key, order: order === 'asc' ? 1 : -1 };

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleClearSelection(true); // reset selected rows on sorting
    if (
      filters.order !== (order === 'asc' ? 1 : -1) ||
      filters.sortByKey !== key
    ) {
      onFiltersChange(newFilters);
    }
  };

  const isEmailSelected =
    selectedEmailAccounts.length > 0 ||
    bulkSelectedEmailAccounts?.isAllEmailAccountsSelected;

  // * handle select/deselect all email accounts within a page
  const handleSelectDeselectEmailAccounts = (rows: any, status: boolean) => {
    selectAllEmailAccounts({ rows, status });
    setIsBulkEmailAccountsSelected(false);
  };

  // * handle bulk select email accounts from banner
  const handleSelectAllEmailAccount = () => {
    if (
      checkIsTableLastPage(
        paginationOptions.currentPage,
        paginationOptions.itemsPerPage,
      )
    ) {
      emailAccountTableRef?.current?.selectAllCurrentPageRows();
    } else {
      emailAccountTableRef?.current?.resetSelectedRows();
    }

    onBulkSelectEmailAccounts({
      isAllEmailAccountsSelected: true,
      deSelectedEmailAccountIds: [],
    });
  };

  // * handle clear selected email accounts
  const handleClearSelection = (resetSelectedRows = false) => {
    clearEmailAccountsSelection();
    handleSelectDeselectEmailAccounts(emailAccounts, false);

    if (
      isCurrentPageUnderTheSelectAllPage(
        bulkSelectedEmailAccounts,
        paginationOptions?.currentPage,
        paginationOptions?.itemsPerPage,
      ) ||
      resetSelectedRows
    ) {
      emailAccountTableRef.current?.resetSelectedRows();
    }
  };

  // * handle reset email accounts selected via select all
  const handleResetSelectAllEmailAccounts = () => {
    const shouldResetSelection = isCurrentPageUnderTheSelectAllPage(
      bulkSelectedEmailAccounts,
      paginationOptions?.currentPage,
      paginationOptions?.itemsPerPage,
    );

    if (!shouldResetSelection && shouldResetSelection !== undefined) {
      handleClearSelection();
    }
  };

  // * handle select single email account
  const handleSelectSingleEmailAccount = (row: any, status: boolean) => {
    selectSingleEmailAccount({ row, status });
    handleResetSelectAllEmailAccounts();
    setIsBulkEmailAccountsSelected(false);
  };

  // * select all email accounts on current page
  const selectAllCurrentPageRows = () => {
    const lastPage =
      constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT / paginationOptions?.itemsPerPage;

    if (
      paginationOptions?.totalItems <=
        constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT ||
      lastPage > paginationOptions?.currentPage
    ) {
      emailAccountTableRef.current?.selectAllCurrentPageRows();
    } else {
      emailAccountTableRef.current?.resetSelectedRows();
    }
  };

  const handleScroll = () => {
    if (!isScrollingRef.current) {
      isScrollingRef.current = true;
      if (isScrollingRef.current) {
        setIsScrollable(true);
      }
    }
    setTimeout(() => {
      isScrollingRef.current = false;
    }, 150);
  };

  const getEmailAccountsActions = (row) => {
    const isDomainPurchased = row?.domainMailboxId !== null;
    const tooltipDeleteProps = {
      placement: Placement.Left,
      text:
        'Email Accounts purchased from Saleshandy can only be deleted via Domain Deletion',
    };

    const tooltipConnectDisconnectProps = {
      placement: Placement.Left,
      text:
        'Email Accounts purchased from Saleshandy cannot be manually connected or disconnected',
    };

    const actions = [
      {
        key: 'edit',
        iconElement: <Pencil />,
        displayName: 'Edit',
        ...(!hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) &&
        !hasPermission(Permissions.EMAIL_ACCOUNT_SET_AS_DEFAULT)
          ? {
              position: 'out',
            }
          : {}),
      },
      hasPermission(Permissions.EMAIL_ACCOUNT_CONNECT) &&
        row.status === EmailAccountStatus.InActive &&
        row.status !== EmailAccountStatus.Pause && {
          key: 'connect',
          iconElement: <Link />,
          displayName: 'Connect',
          ...(isDomainPurchased && {
            disabled: true,
            overlayTooltip: tooltipConnectDisconnectProps,
          }),
        },
      hasPermission(Permissions.EMAIL_ACCOUNT_DISCONNECT) &&
        row.status === EmailAccountStatus.Active &&
        row.status !== EmailAccountStatus.Pause && {
          key: 'disconnect',
          iconElement: <Unlink />,
          displayName: 'Disconnect',
          ...(isDomainPurchased && {
            disabled: true,
            overlayTooltip: tooltipConnectDisconnectProps,
          }),
        },
      hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) && {
        key: 'delete',
        iconElement: <Trash />,
        displayName: 'Delete',
        ...(isDomainPurchased && {
          disabled: true,
          overlayTooltip: tooltipDeleteProps,
        }),
      },
      hasPermission(Permissions.EMAIL_ACCOUNT_SET_AS_DEFAULT) && {
        key: 'setAsDefault',
        iconElement: <FileCheck />,
        displayName: 'Set as Default',
      },
    ];
    return actions.filter((opt) => opt);
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: getEmailAccountsRequestStatus,
      onSuccess: () => {
        if (bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
          selectAllCurrentPageRows();
        }
      },
    });
  }, [getEmailAccountsRequestStatus]);

  useEffect(() => {
    if (
      addEmailAccountTagsRequestStatus === RequestStatus.Succeeded ||
      removeEmailAccountTagsRequestStatus === RequestStatus.Succeeded ||
      emailAccountTagsBulkAssignRequestStatus === RequestStatus.Succeeded ||
      emailAccountTagsBulkUnassignRequestStatus === RequestStatus.Succeeded
    ) {
      emailAccountTableRef.current?.resetSelectedRows();
      handleClearSelection();
    }
  }, [
    addEmailAccountTagsRequestStatus,
    removeEmailAccountTagsRequestStatus,
    emailAccountTagsBulkAssignRequestStatus,
    emailAccountTagsBulkUnassignRequestStatus,
  ]);

  useFilteredEffect(filters, () => handleClearSelection(true));

  useEffect(
    () => () => {
      handleClearSelection();
    },
    [],
  );

  const isLoading = getIsRequestPending(getEmailAccountsRequestStatus);

  const tableWrapperClasses = classNames('email-accounts-table', {
    'is-scrollable': isScrollable,
  });

  return (
    <>
      {getShouldShowSelectedRowInfoBanner(
        selectedEmailAccounts,
        bulkSelectedEmailAccounts,
        isBulkEmailAccountsSelected,
      ) && (
        <div className="email-account-banner">
          <EmailSelectedRowInfoBanner
            selectedEmailAccounts={selectedEmailAccounts}
            bulkSelectedEmailAccounts={bulkSelectedEmailAccounts}
            totalEmailAccounts={paginationOptions?.totalItems}
            handleSelectAllEmailAccount={handleSelectAllEmailAccount}
            handleClearSelection={() => handleClearSelection(true)}
          />
        </div>
      )}
      <div
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          position: 'relative',
        }}
        onScroll={handleScroll}
        className={classNames('table-scroll-container', tableWrapperClasses, {
          'trial-plan': subscriptionPlan === SubscriptionPlans.TRIAL,
          selected: isEmailSelected,
          'not-selected': !isEmailSelected,
        })}
      >
        <Table
          columns={getEmailAccountsColumns({
            itemCount: paginationOptions?.itemCount ?? 0,
            onSort,
            onEmailAccountsSwitchChangeHandler,
            redirectToGeneralSettings,
            onSmtpImapModalShow,
            hideColumn: {
              isWarmupStatusVisible: isTiIntegrated,
              isDeliverabilityStatusVisible: isTiIntegrated,
            },
          })}
          data={emailAccounts}
          actions={(_, row) => {
            return getEmailAccountsActions(row);
          }}
          onAction={onAction}
          sort={{
            dataField: getColDataFieldForSorting(filters.sortByKey),
            order: filters.order === 1 ? 'asc' : 'desc',
          }}
          paginationOptions={{
            options: {
              limit: paginationOptions?.itemsPerPage,
              page: paginationOptions?.currentPage,
              totalElements: paginationOptions?.totalItems,
            },
          }}
          pagination={PaginationShowHide.SHOW}
          isNewPagination={true}
          onPaginationOptionsChange={onPaginationOptionsChange}
          headerVisibleForGenerateColumn={true}
          isLoading={isLoading}
          tableWrapperClasses={tableWrapperClasses}
          onRowSelect={handleSelectSingleEmailAccount}
          onRowSelectAll={handleSelectDeselectEmailAccounts}
          resetSelected={resetSelected}
          handleResetSelected={handleResetSelected}
          deselectedRows={bulkSelectedEmailAccounts?.deSelectedEmailAccountIds}
          tableRef={(refValue: any) => {
            emailAccountTableRef.current = refValue;
          }}
          hideActionColumnHeader={true}
        />
      </div>
    </>
  );
};

export default EmailAccountsTable;
