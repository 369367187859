import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';

import ConnectEmailAccountRestriction from './connect-email-account-restriction';

import { ReactivateResumeSubscriptionRequest } from '../../../settings/components/billing-subscription/types';

import {
  getConnectedUsersAndEmailAccountsRequest,
  reactivateSubscriptionRequest,
} from '../../../settings/components/billing-subscription/extra-actions';
import {
  resetConnectedUsersAndEmailAccountsRequest,
  resetReactivateLeadFinderSubscriptionResponse,
} from '../../../settings/components/billing-subscription/billing-subscription-slice';
import { getUserSettingsRequest } from '../../../home/extra-actions';

const mapStateToProps = (state: RootState) => ({
  firstName: state.home.adminDetails?.firstName,
  planType: state.home.subscription?.planType,
  subscriptionPlan: state.home.subscription?.planCode,
  subscriptionEndDate: state.home.subscription?.nextBillingAt,
  subscriptionPlanStatus: state.home.subscription?.status,
  totalEmailAccounts:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse
      ?.totalEmailAccounts,
  slots: state.home.subscription?.slots,
  customerId: state.home.subscription?.customerId,

  sendGetReactivateSubscriptionRequestStatus:
    state.billingAndSubscription.reactivateSubscriptionRequest.status,
  sendGetReactivateSubscriptionResponse:
    state.billingAndSubscription.reactivateSubscriptionResponse,
  sendGetReactivateSubscriptionRequestError:
    state.billingAndSubscription.reactivateSubscriptionRequest.error,

  getConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,
  getConnectedUsersAndEmailAccountsRequestError:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest.error,
});

const mapDispatchToProps = {
  sendGetConnectedUsersAndEmailAccountsRequest: () =>
    getConnectedUsersAndEmailAccountsRequest(),
  resetConnectedUsersAndEmailAccountsRequest: () =>
    resetConnectedUsersAndEmailAccountsRequest(),

  sendReactivateSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => reactivateSubscriptionRequest(payload),
  resetReactivateSubscriptionResponse: () =>
    resetReactivateLeadFinderSubscriptionResponse(),

  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  children: (props: {
    onClick: () => void;
    isLoading: boolean;
  }) => React.ReactNode;
  handleOnSubmit: VoidFunction;
};

export default connector(ConnectEmailAccountRestriction);
