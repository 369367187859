/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Tabs, { TabPane } from 'rc-tabs';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AlertTriangle } from '@saleshandy/icons';
import { RouteParams, UpdateEmailTabKeys } from './types';
import type { IProps } from './update-email-account-container';
import SendingSettings from './components/sending-settings';
import {
  getSettingsForEmailAccount,
  getIsRequestPending,
  getShouldSendingSettingsTabDisabled,
  handleLoading,
  onTabChange as onTabChangeTemp,
  connectEmailAccountHandler as connectEmailAccountHandlerTemp,
} from './helpers';
import EmailAccountSetup from './components/email-account-setup';
import GeneralSettings from './components/general-settings';
import BlockPage from '../../../../../../shared/components/block-page';
import UpdateEmailAccountHeader from './components/sub-components/update-email-account-header';
import ConnectSMTPIMAPAccountModal from '../connect-smtp-imap-account-modal';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
} from '../../../../../../shared/utils/execute-on-request-status';
import toaster, { Theme } from '../../../../../../shared/toaster/index';
import { SMTPIMAP } from '../../../../enums/smtp-or-imap';
import ErrorLogModal from '../error-log-modal';
import { AgencyResourceType } from '../../../../../agency-client-management/enums/agency-client';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { isAgency } from '../../../../../../shared/utils/user-details';
import {
  EmailAccount,
  EmailAccountStatus,
} from '../../../../types/email-account';
import { updateEmailAccountStatus } from '../../../../helpers/email-account.api';
import { isSavedSMTPAccount, getEmailAccountType } from '../../utils/helpers';
import {
  EmailAccountMethod,
  EmailAccountType,
} from '../../../../enums/email-account';
import hasResource from '../../../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../../../shared/utils/access-control/enums/resource-identifiers';
import { redirectWithRefresh } from '../../../../../../shared/utils';
import Alert from '../../../../../../shared/design-system/ui/alert';

const UpdateEmailAccount: React.FC<IProps> = (props) => {
  const {
    authUrl,
    isLoading,
    emailAccount,
    subscriptionPlan,
    emailAccountSettings,
    getEmailAccountRequestStatus,
    getEmailAccountRequestError,
    updateEmailAccountRequestStatus,
    customDomainList,
    disconnectEmailAccountRequestStatus,
    disconnectEmailAccountRequestError,
    connectEmailAccountRequestStatus,
    connectEmailAccountRequestError,
    updateEmailAccountRampUpSettingsRequestStatus,
    updateEmailAccountRampUpSettingsRequestMessage,
    updateEmailAccountRampUpSettingsRequestError,
    sendGetEmailAccountRequest,
    sendGetEmailAccountSettingsRequest,
    sendUpdateEmailAccountRampUpSettingsRequest,
    sendUpdateEmailAccountRequest,
    sendImageUploadRequest,
    sendDisconnectEmailAccountRequest,
    sendConnectEmailAccountRequest,
    sendGetCustomDomainsListRequest,
    resetEmailAccountRampUpSettingsRequestState,
    getEmailAccountSettingsRequestStatus,
    resetEmailAccountAndSettings,
    sendGetSmtpImapAccountDetailsRequest,
    getTestSMTPConnectionRequestStatus,
    getTestIMAPConnectionRequestStatus,
    getConnectSmtpImapRequestStatus,
    sendGetSmtpImapAccountDetailsRequestStatus,
    sendUpdateSmtpImapAccountRequestStatus,
    sendUpdateEmailAccountRequestMessage,
    resetSmtpImapTestConnection,
    resetSmtpIMapConnectResponse,
    resetUpdateSmtpImapAccountDetails,
    sendUpdateSmtpImapAccountDetailsResponse,
    agencyConfig,
    sendEmailTimeIntervalRequest,
    emailTimeIntervalSettingsRequestStatus,
    emailTimeIntervalSettingsRequestMessage,
    emailAccountSetup,
    getEmailAccountSetupScoreRequest,
    getEmailAccountSetupScoreRequestMessage,
    getEmailAccountSetupScoreRequestError,
    sendGetEmailAccountSetupScoreRequest,
    resetEmailAccountSetupState,
    sendGetAgencyClientsDropdownRequest,
    sendGetSelectedAgencyClientRequest,
    getSelectedAgencyClientsDropdownRequestStatus,
    clients,
    selectedAgencyClient,
    sendUpdateAssignAgencyClientResource,
    updateAssignAgencyClientResourceRequestStatus,
    resetAgencyClientAssignResource,
    featureUsages,
    getCustomDomainsListRequestStatus,
    getAgencyClientsDropdownRequestStatus,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const { hashId } = params;
  const emailAccountId = emailAccount?.id;

  const [doNotHaveSubscription] = useState<boolean>(false);
  const [
    showConnectAndSaveSMTPIMAPModel,
    setShowConnectAndSaveSMTPIMAPModel,
  ] = useState<boolean>(false);
  const [
    showSmtpModalOnDisconnect,
    setShowSmtpModalOnDisconnect,
  ] = useState<boolean>(false);
  const [isErrorLogModalVisible, setIsErrorLogModalVisible] = useState<boolean>(
    false,
  );
  const [errorLogAccountType, setErrorLogAccountType] = useState<SMTPIMAP>(
    SMTPIMAP.SMTP,
  );
  const [errorLog, setErrorLog] = useState<string>('');

  const { tab } = params;

  const rampUpSettings = getSettingsForEmailAccount(emailAccountSettings);

  const onTabChange = (tabKey: string) =>
    onTabChangeTemp(
      tabKey,
      UpdateEmailTabKeys,
      setShowSmtpModalOnDisconnect,
      history,
      hashId,
    );

  const showConnectAndSaveSmtpImapAccountModel = () => {
    setShowConnectAndSaveSMTPIMAPModel(true);
  };

  const hideConnectAndSaveSmtpImapAccountModel = () => {
    setShowConnectAndSaveSMTPIMAPModel(false);
    resetSmtpImapTestConnection();
    resetSmtpIMapConnectResponse();
    resetUpdateSmtpImapAccountDetails();
  };

  const showErrorLogModal = (accountType: SMTPIMAP, errLog: string) => {
    setIsErrorLogModalVisible(true);
    setErrorLogAccountType(accountType);
    setErrorLog(errLog);
  };

  const hideErrorLogModal = () => {
    setIsErrorLogModalVisible(false);
  };

  const connectEmailAccountHandler = () =>
    connectEmailAccountHandlerTemp(
      subscriptionPlan,
      emailAccount,
      emailAccountId,
      sendGetSmtpImapAccountDetailsRequest,
      sendConnectEmailAccountRequest,
    );

  useEffect(() => {
    sendGetEmailAccountRequest(hashId);
    sendGetEmailAccountSettingsRequest(hashId);
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getEmailAccountRequestStatus,
      onSuccess: () => {
        if (
          emailAccountId &&
          hasPermission(Permissions.CLIENT_READ) &&
          isAgency()
        ) {
          sendGetSelectedAgencyClientRequest({
            resourceId: emailAccountId.toString(),
            resourceType: AgencyResourceType.EmailAccount,
          });
        }
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: getEmailAccountRequestError,
          onError: () => {
            if (getEmailAccountRequestError?.code === 403) {
              history.push('/email-accounts');
            }
          },
        });
      },
    });
  }, [getEmailAccountRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: sendGetSmtpImapAccountDetailsRequestStatus,
      onSuccess: () => {
        showConnectAndSaveSmtpImapAccountModel();
      },
    });
  }, [sendGetSmtpImapAccountDetailsRequestStatus]);

  const onSuccessCallback = () => {
    if (!sendUpdateSmtpImapAccountDetailsResponse?.sequencePaused) {
      toaster.success(sendUpdateEmailAccountRequestMessage, {
        theme: Theme.New,
      });
      hideConnectAndSaveSmtpImapAccountModel();
      sendGetEmailAccountRequest(hashId);
      resetUpdateSmtpImapAccountDetails();
    } else {
      toaster.error(t('messages.update_smtp_imap_connect_failed'));
    }
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: sendUpdateSmtpImapAccountRequestStatus,
      onSuccess: () => onSuccessCallback(),
      onFailed: () => {
        toaster.error(sendUpdateEmailAccountRequestMessage);
      },
    });
  }, [sendUpdateSmtpImapAccountRequestStatus]);

  useEffect(() => {
    handleLoading(isLoading);
  }, [isLoading]);

  useEffect(
    () => () => {
      resetEmailAccountAndSettings();
    },
    [],
  );

  const onDeAssignClient = () => {
    sendUpdateAssignAgencyClientResource({
      resourceIds: [emailAccountId],
      resourceType: AgencyResourceType.EmailAccount,
      clientId: 0,
    });
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: updateAssignAgencyClientResourceRequestStatus,
      onSuccess: () => {
        toaster.success('Changes updated successfully', {
          theme: Theme.New,
        });

        sendGetSelectedAgencyClientRequest({
          resourceId: emailAccountId.toString(),
          resourceType: AgencyResourceType.EmailAccount,
        });
        resetAgencyClientAssignResource();
      },
      onFailed: () => {
        toaster.error('Something went wrong', {
          theme: Theme.New,
        });
        resetAgencyClientAssignResource();
      },
    });
  }, [updateAssignAgencyClientResourceRequestStatus]);

  const addEmailAccountHandler = (
    method: EmailAccountMethod,
    emailAccountId: number,
  ) => {
    hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
      sendConnectEmailAccountRequest(method, emailAccountId);
  };

  const initiateEmailAccountConnectionFlow = async (
    emailAccount: EmailAccount,
  ) => {
    if (
      isSavedSMTPAccount(emailAccount) ||
      emailAccount.type === EmailAccountType.SmtpImap
    ) {
      redirectWithRefresh(
        `/email-accounts?connect-smtp=1&emailAccountId=${emailAccount.id}&emailAccountType=${EmailAccountType.SmtpImap}`,
      );
      return;
    } else {
      addEmailAccountHandler(
        getEmailAccountType(emailAccount),
        emailAccount.id,
      );
    }
  };

  const onUpdateEmailAccountHandler = async (
    emailAccountStatus: EmailAccountStatus,
    emailAccount: EmailAccount,
  ) => {
    const response = await updateEmailAccountStatus(
      emailAccount.id,
      emailAccountStatus,
    );
    const newEmailStatus = response.payload.emailAccountStatus;
    if (newEmailStatus === EmailAccountStatus.InActive) {
      // initiate connection flow
      initiateEmailAccountConnectionFlow(emailAccount);
      return;
    }
    // call the email list api and return;
    sendGetEmailAccountRequest(hashId);
  };

  const isEmailAccountRequestPending = getIsRequestPending(
    getEmailAccountRequestStatus,
  );
  const isEmailAccountSettingsRequestPending = getIsRequestPending(
    getEmailAccountSettingsRequestStatus,
  );

  const isSelectedAgencyClientsRequestPending = getIsRequestPending(
    getSelectedAgencyClientsDropdownRequestStatus,
  );
  const isUpdateRequestPending = getIsRequestPending(
    updateAssignAgencyClientResourceRequestStatus,
  );

  const generalSettingsTab =
    isEmailAccountRequestPending ||
    isEmailAccountSettingsRequestPending ||
    isUpdateRequestPending ||
    isSelectedAgencyClientsRequestPending ||
    (hasPermission(Permissions.CLIENT_READ) &&
      isAgency() &&
      getSelectedAgencyClientsDropdownRequestStatus === RequestStatus.Ideal) ? (
      <div className="update-email-account-loading email-tab">
        <Spinner
          className="update-email-account-loading-spinner"
          animation="border"
        />
      </div>
    ) : (
      <GeneralSettings
        emailAccountId={emailAccountId}
        hashId={hashId}
        emailAccount={emailAccount}
        tabKey={params.tab}
        emailAccountSettings={emailAccountSettings}
        customDomainList={customDomainList}
        sendUpdateEmailAccountRequest={sendUpdateEmailAccountRequest}
        updateEmailAccountRequestStatus={updateEmailAccountRequestStatus}
        getEmailAccountRequestStatus={getEmailAccountRequestStatus}
        sendImageUploadRequest={(formData, onUploadProgress) =>
          sendImageUploadRequest(formData, onUploadProgress)
        }
        onCheckEmailScore={sendGetEmailAccountSetupScoreRequest}
        sendGetEmailAccountRequest={sendGetEmailAccountRequest}
        sendGetCustomDomainsListRequest={sendGetCustomDomainsListRequest}
        sendGetEmailAccountSettingsRequest={sendGetEmailAccountSettingsRequest}
        agencyClients={clients}
        selectedAgencyClient={selectedAgencyClient}
        sendUpdateAssignAgencyClientResource={onDeAssignClient}
        getCustomDomainsListRequestStatus={getCustomDomainsListRequestStatus}
        sendGetAgencyClientsDropdownRequest={
          sendGetAgencyClientsDropdownRequest
        }
        getAgencyClientsDropdownRequestStatus={
          getAgencyClientsDropdownRequestStatus
        }
      />
    );

  const sendingSettingsTab = isEmailAccountSettingsRequestPending ? (
    <div className="update-email-account-loading email-tab">
      <Spinner
        className="update-email-account-loading-spinner"
        animation="border"
      />
    </div>
  ) : (
    <SendingSettings
      emailAccountId={emailAccountId}
      emailAccountStatus={emailAccount?.status}
      rampUpSettings={rampUpSettings}
      onSave={sendUpdateEmailAccountRampUpSettingsRequest}
      rampUpSettingsRequestStatus={
        updateEmailAccountRampUpSettingsRequestStatus
      }
      rampUpSettingsRequestMessage={
        updateEmailAccountRampUpSettingsRequestMessage
      }
      rampUpSettingsRequestError={updateEmailAccountRampUpSettingsRequestError}
      sendGetEmailAccountSettingsRequest={sendGetEmailAccountSettingsRequest}
      sendEmailTimeIntervalRequest={sendEmailTimeIntervalRequest}
      emailTimeIntervalSettingsRequestStatus={
        emailTimeIntervalSettingsRequestStatus
      }
      emailTimeIntervalSettingsRequestMessage={
        emailTimeIntervalSettingsRequestMessage
      }
      resetEmailAccountRampUpSettingsRequestState={
        resetEmailAccountRampUpSettingsRequestState
      }
      agencyConfig={agencyConfig}
      sendGetEmailAccountSetupScoreRequest={
        sendGetEmailAccountSetupScoreRequest
      }
      emailAccountType={emailAccount?.type}
      featureUsages={featureUsages}
    />
  );

  const emailAccountSetupJsx = doNotHaveSubscription ? (
    <BlockPage
      redirectURL={`/email-accounts/${hashId}/${UpdateEmailTabKeys.EMAIL_SETUP_SCORE}`}
    />
  ) : (
    <>
      {!emailAccount?.status && (
        <Alert
          className="mb-4"
          description={
            <span className="font-14 font-medium">
              {t('messages.email_disconnected_message')}
            </span>
          }
          variant="alert"
          icon={AlertTriangle}
        />
      )}
      <EmailAccountSetup
        emailAccountId={emailAccountId}
        emailAccountSetup={emailAccountSetup}
        userTimezone={emailAccount?.user?.timeZone}
        agencyConfig={agencyConfig}
        getEmailAccountSetupScoreRequest={getEmailAccountSetupScoreRequest}
        getEmailAccountSetupScoreRequestMessage={
          getEmailAccountSetupScoreRequestMessage
        }
        getEmailAccountSetupScoreRequestError={
          getEmailAccountSetupScoreRequestError
        }
        sendGetEmailAccountSetupScoreRequest={
          sendGetEmailAccountSetupScoreRequest
        }
        resetEmailAccountSetupState={resetEmailAccountSetupState}
      />
    </>
  );

  return isEmailAccountRequestPending ? (
    <div className="update-email-account-loading">
      <Spinner
        className="update-email-account-loading-spinner"
        animation="border"
      />
    </div>
  ) : (
    <div className="update-email-account-container">
      <UpdateEmailAccountHeader
        emailAccountId={emailAccountId}
        emailAccount={emailAccount}
        authUrl={authUrl}
        connectEmailAccountHandler={connectEmailAccountHandler}
        sendDisconnectEmailAccountRequest={sendDisconnectEmailAccountRequest}
        disconnectEmailAccountRequestStatus={
          disconnectEmailAccountRequestStatus
        }
        disconnectEmailAccountRequestError={disconnectEmailAccountRequestError}
        connectEmailAccountRequestStatus={connectEmailAccountRequestStatus}
        connectEmailAccountRequestError={connectEmailAccountRequestError}
        sendGetSmtpImapAccountDetailsRequest={
          sendGetSmtpImapAccountDetailsRequest
        }
        isEditEmailSettingsButtonLoading={getIsRequestPending(
          sendGetSmtpImapAccountDetailsRequestStatus,
        )}
        showSmtpModalOnDisconnect={showSmtpModalOnDisconnect}
        updateEmailAccountHandler={onUpdateEmailAccountHandler}
      />
      <div className="update-email-tabs">
        <Tabs
          activeKey={tab}
          defaultActiveKey={tab}
          prefixCls="bs-tabs"
          className="bs-tabs-small"
          onChange={onTabChange}
        >
          {hasPermission(
            Permissions.EMAIL_ACCOUNT_SETTINGS_GENERAL_SETTING_UPDATE,
          ) && (
            <TabPane
              tab="General Settings"
              key={UpdateEmailTabKeys.GENERAL_SETTINGS}
            >
              {generalSettingsTab}
            </TabPane>
          )}
          {hasPermission(
            Permissions.EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE,
          ) && (
            <TabPane
              tab="Sending Settings"
              key={UpdateEmailTabKeys.SENDING_SETTINGS}
              disabled={getShouldSendingSettingsTabDisabled(emailAccount)}
            >
              {sendingSettingsTab}
            </TabPane>
          )}
          {hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_SETUP_SCORE) && (
            <TabPane
              tab="Email Account Health"
              key={UpdateEmailTabKeys.EMAIL_SETUP_SCORE}
            >
              {emailAccountSetupJsx}
            </TabPane>
          )}
        </Tabs>
      </div>

      <ConnectSMTPIMAPAccountModal
        show={showConnectAndSaveSMTPIMAPModel}
        onClose={hideConnectAndSaveSmtpImapAccountModel}
        isSMTPTestConnectionRequestPending={
          getTestSMTPConnectionRequestStatus === RequestStatus.Pending
        }
        isIMAPTestConnectionRequestPending={
          getTestIMAPConnectionRequestStatus === RequestStatus.Pending
        }
        isConnectAccountRequestPending={
          getConnectSmtpImapRequestStatus === RequestStatus.Pending
        }
        isUpdateSmtpImapAccountRequestPending={
          sendUpdateSmtpImapAccountRequestStatus === RequestStatus.Pending
        }
        emailAccountId={emailAccountId}
        agencyConfig={agencyConfig}
        showErrorLogModal={(accountType, errLog) =>
          showErrorLogModal(accountType, errLog)
        }
      />
      <ErrorLogModal
        show={isErrorLogModalVisible}
        onClose={hideErrorLogModal}
        accountType={errorLogAccountType}
        errorLog={errorLog}
      />
    </div>
  );
};

export default UpdateEmailAccount;
