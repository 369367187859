/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import type { IProps } from './trulyinbox-email-account-consent-container';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import { Variant } from '../../../../../../shared/design-system/components/atoms/button/button';
import Checkbox from '../../../../../../shared/design-system/components/atoms/checkbox';
import { trulyinboxEmailAccountConsent } from '../../../../helpers/email-account.api';
import toaster, { Theme } from '../../../../../../shared/toaster';
import { SystemErrors } from '../../../../../../shared/enums/system-errors';

const TrulyInboxEmailAccountConsent: React.FC<IProps> = ({
  show,
  onClose,
  syncConsentRequestResponse,
  sendTrulyinboxEmailAccountVerifyShApiKeyRequest,
}) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const onAuthorize = async (e) => {
    e.preventDefault();
    const isConsentPresent = syncConsentRequestResponse?.shConsent?.consent;
    if (isConsentPresent) {
      toaster.warning(
        'Email account syncing with Trulyinbox is already enabled for this account',
        {
          theme: Theme.New,
        },
      );
      setTimeout(() => onClose(), 100);
      return;
    }
    try {
      setIsSubmitLoading(true);
      const response = await trulyinboxEmailAccountConsent();
      console.log('onAuthorize', response);
      const data = response?.payload;
      if (data?.verified) {
        toaster.success('Consent given, email account syncing is enabled', {
          theme: Theme.New,
        });
        onClose();
        sendTrulyinboxEmailAccountVerifyShApiKeyRequest();
      } else {
        toaster.error(
          'Error giving consent, make sure that api key is added in trulyinbox',
          {
            theme: Theme.New,
          },
        );
      }
    } catch (error: any) {
      toaster.error(error?.message || SystemErrors.InternalServerError, {
        theme: Theme.New,
      });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      onHide={onClose}
      onSubmit={(e) => onAuthorize(e)}
      className="trulyinbox-email-account-consent-modal"
      titleContent="Consent for Sharing Email Credentials"
      showCloseIcon={true}
      submitButtonText="Authorise"
      submitButtonClassName="submit-btn"
      cancelButtonVarient={Variant.Outlined}
      isSubmitLoading={isSubmitLoading}
      isSubmitDisabled={!isAuthorized}
      extraModalProps={{
        'aria-labelledby': 'contained-modal-title-vcenter',
        centered: true,
        backdrop: 'static',
      }}
    >
      <div className="trulyinbox-email-account-consent-wrapper d-flex flex-column">
        <div className="gray-1 bs-border-radius-8 p-3 d-flex justify-content-between gap-16 flex-column">
          <p className="header-content">
            By proceeding, you authorize the sharing of your email account
            credentials (email address and password) between Saleshandy and
            Trulyinbox for synchronization purposes.
          </p>
          <ul className="header-points">
            <li>
              This applies to all existing and new email accounts created in
              your Saleshandy account.
            </li>
            <li>
              Your credentials will be securely used only for integration
              between the two platforms.
            </li>
            <li>
              To stop syncing, simply delete the API key from Saleshandy that is
              being used for Trulyinbox Integration, which will remove the
              connection.
            </li>
            <li>
              Your connected Email Accounts in Trulyinbox will not get affected
              by the connection status in Saleshandy.
            </li>
          </ul>
          <div className="consent-container">
            <p>By authorizing this consent, you confirm that:</p>
            <ul>
              <li>
                You understand the nature and purpose of the sharing of your
                email credentials between Saleshandy and Trulyinbox.
              </li>
              <li>
                You acknowledge that you can revoke this consent at any time by
                removing the API key from Saleshandy, effectively stopping the
                synchronization between the two services.
              </li>
            </ul>
          </div>
          <div className="terms-and-conditions">
            <Checkbox
              checked={isAuthorized}
              label="I agree with all the terms & condition"
              onChange={(check) => setIsAuthorized(check)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TrulyInboxEmailAccountConsent;
