import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Tabs, { TabPane } from 'rc-tabs';
import { SkeletonLoading } from '@saleshandy/design-system';
import type { IProps } from './bulk-email-update-container';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import {
  UpdateEmailTabKeys,
  CustomDomainDropdown,
} from '../update-email-account/types';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import Input from '../../../../../../shared/design-system/components/input/input';
import Select from '../../../../../../shared/design-system/components/select/select';

import Editor from '../../../../../../shared/editor/editor';
import validate from '../update-email-account/components/sub-components/validator';
import DailyRampUpContainer from '../update-email-account/components/sub-components/daily-ramp-up-contanier';

import TimeIntervalSetting from '../update-email-account/components/sub-components/time-interval-setting';
import DailyRampUpToggle from '../update-email-account/components/sub-components/daily-ramp-up-toggle';

import { EmailAccountSettingCode } from '../../../../enums/email-account-settings';
import ConfirmationModalV3 from '../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../../../shared/design-system/components/atoms/confirmation-modal/enum';
import AgencyClientDropdown from '../../../../../agency-client-management/components/agency-client-dropdown';
import { Placement } from '../../../../../../shared/design-system/components/overlay/enums';
import { getIsRequestPending } from '../../../../../../shared/utils';
import { AgencyClientStatus } from '../../../../../agency-client-management/enums/agency-client';
import { AgencyClientFilter } from '../../../../../agency-client-management/types/types';
import { isAgency } from '../../../../../../shared/utils/user-details';
import { getClientName } from '../../../../../agency-client-management/helpers/helpers';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import DefaultRampUpSettings from '../../../../enums/default-rampup-settings';
import EmailDailySendingLimit from '../email-daily-sending-limit';

const BulkEmailUpdateModal: React.FC<IProps> = ({
  customDomainList,
  show,
  onClose,
  filters,
  sendImageUploadRequest,
  emailTimeIntervalSettingsRequestStatus,
  agencyConfig,
  onShowBulkEmailUpdateConfirmationModal,
  showBulkEmailAccountUpdateConfirmationModal,
  onHideBulkEmailUpdateConfirmationModal,
  sendBulkEmailAccountUpdateRequest,
  sendGetCustomDomainsListRequest,
  selectedEmailAccounts,
  bulkSelectedEmailAccounts,
  getAgencyClientsDropdownRequestStatus,
  sendGetAgencyClientsDropdownRequest,
  clients: agencyClients,
  featureUsages,
}) => {
  const unique = {};
  selectedEmailAccounts = selectedEmailAccounts.filter((obj) => {
    if (!unique[obj.id]) {
      unique[obj.id] = true;
      return true;
    }
    return false;
  });

  const [selectedClient, setSelectedClient] = useState<AgencyClientFilter>({
    id: -1,
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    status: AgencyClientStatus.Active,
  });

  const [errors, setErrors] = useState({
    senderName: '',
    senderFirstName: '',
    senderLastName: '',
    bcc: '',
    signature: '',
  });
  const [values, setValues] = useState({
    senderName: '',
    senderFirstName: '',
    senderLastName: '',
    bcc: '',
    signature: '',
  });
  const [dirtyForGeneral, setDirtyForGeneral] = useState({
    senderName: false,
    senderFirstName: false,
    senderLastName: false,
    bcc: false,
    signature: false,
  });
  const [selectedCustomDomain, setSelectedCustomDomain] = useState({
    key: null,
    value: null,
  });

  const [dirty, setDirty] = useState({
    dailyRampUp: false,
    emailsPerDay: false,
    emailsPerDayPercent: false,
  });
  const { t } = useTranslation();

  // RamUp Settings State
  const [dailyRampUp, setDailyRampUp] = useState<boolean>(false);

  const [emailsPerDay, setEmailsPerDay] = useState<number>(
    DefaultRampUpSettings.InitialSendingLimit,
  );

  const [emailsPerDayPercent, setEmailsPerDayPercent] = useState<number>(
    DefaultRampUpSettings.RampUpPercent,
  );

  const [emailsPerDayError, setEmailsPerDayError] = useState<string>('');

  const [
    emailsPerDayPercentError,
    setEmailsPerDayPercentError,
  ] = useState<string>('');

  const [activeTab, setActiveTab] = useState('general-settings');

  // maximum email per day
  const [sendingLimit, setSendingLimit] = useState<number>(0);
  const [sendingLimitError, setSendingLimitError] = useState<boolean>(false);

  // time interval between two emails
  const [timeIntervalError, setTimeIntervalError] = useState<boolean>(false);
  const [minIntervalBulk, setMinTimeIntervalBulk] = useState<number>(0);
  const [maxIntervalBulk, setMaxTimeIntervalBulk] = useState<number>(0);

  const onInputChange = (value, e) => {
    const { name } = e.target;
    setValues({ ...values, [name]: value });
    setDirtyForGeneral({ ...dirtyForGeneral, [name]: true });
  };

  const onSignatureChange = (value) => {
    setValues({ ...values, signature: value });
    setDirtyForGeneral({ ...dirtyForGeneral, signature: true });
  };

  const onInputBlur = (e) => {
    const { name } = e.target;
    if (dirtyForGeneral[name]) {
      setErrors({
        ...errors,
        [name]: validate(name, values[name]),
      });
    }
  };

  const onCustomDomainChange = (option: CustomDomainDropdown) => {
    setSelectedCustomDomain({ key: option.key, value: option.domain });
  };

  const customDomainOptions: CustomDomainDropdown[] = customDomainList.map(
    (customDomain) => ({ ...customDomain, key: customDomain.id }),
  );

  const onKeyPress = (e) => {
    if (e.key === '.' || e.key === '+' || e.key === '-' || e.key === 'e') {
      e.preventDefault();
    }
  };

  const onDropAndPaste = (e) => {
    e.preventDefault();
  };

  const onDailyRampUpChange = (value) => {
    setDailyRampUp(value);
    if (!value) {
      setEmailsPerDay(DefaultRampUpSettings.InitialSendingLimit);
      setEmailsPerDayPercent(DefaultRampUpSettings.RampUpPercent);
      setEmailsPerDayError('');
      setEmailsPerDayPercentError('');
    }

    if (!dirty.dailyRampUp) {
      setDirty({ ...dirty, dailyRampUp: true });
    }
  };

  const onEmailsPerDayPercentChange = (value) => {
    if (Number(value) === 0 || Number(value) > 100) {
      setEmailsPerDayPercentError(
        'The percentage value must be between 1 and 100',
      );
    } else if (emailsPerDayPercentError) {
      setEmailsPerDayPercentError('');
    }
    setEmailsPerDayPercent(value);

    if (!dirty.emailsPerDayPercent) {
      setDirty({ ...dirty, emailsPerDayPercent: true });
    }
  };

  const onEmailsPerDayChange = (value, limit) => {
    if (Number(value) === 0) {
      setEmailsPerDayError('The email per day value must be greater than 0');
    } else if (Number(limit) > 0 && Number(value) > Number(limit)) {
      setEmailsPerDayError(
        'Could not be greater than the number of emails per day',
      );
    } else {
      setEmailsPerDayError('');
    }

    setEmailsPerDay(value);

    if (!dirty.emailsPerDay) {
      setDirty({ ...dirty, emailsPerDay: true });
    }
  };

  const onSaveHandler = (e) => {
    e.preventDefault();

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirty);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errors);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    if (isError) {
      setErrors(errorsRef);
      setDirty(dirtyRef);
      return;
    }

    let payload: Record<string, any> = {
      ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
        ? {
            allEmailsSelected: true,
            ignoreEmailAccountIds:
              bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
            ...(filters.search !== '' && { search: filters.search }),
          }
        : {
            emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
          }),
    };

    const updateGeneralSettings =
      values.senderFirstName !== '' ||
      values.bcc !== '' ||
      values.signature !== '' ||
      selectedCustomDomain?.value !== null;

    if (updateGeneralSettings && errors.bcc === '') {
      payload = {
        ...payload,
        updateGeneralSettings,
        generalSettingsToUpdate: {
          ...(values.senderName && { fromName: values.senderName }),
          ...(values.senderFirstName && {
            fromFirstName: values.senderFirstName,
          }),
          ...(values.senderLastName && { fromLastName: values.senderLastName }),
          ...((values.bcc || values.signature) && {
            settings: [
              ...(values.bcc && [
                { code: EmailAccountSettingCode.Bcc, value: values.bcc },
              ]),
              ...(values.signature && [
                {
                  code: EmailAccountSettingCode.Signature,
                  value: values.signature,
                },
              ]),
            ],
          }),
          ...(selectedCustomDomain?.key !== null && {
            userCustomDomainId: selectedCustomDomain.key,
          }),
        },
      };
    }

    const updateSendingLimitSetting = sendingLimit && sendingLimit !== 0;

    if (updateSendingLimitSetting && !sendingLimitError) {
      payload = {
        ...payload,
        updateSendingLimitSettng: updateSendingLimitSetting,
        sendingLimitsToUpdate: {
          limit: sendingLimit,
        },
      };
    }

    const updateIntervalSetting =
      minIntervalBulk.toString() !== '' &&
      minIntervalBulk !== 0 &&
      maxIntervalBulk.toString() !== '' &&
      maxIntervalBulk !== 0;

    if (
      updateIntervalSetting &&
      !timeIntervalError &&
      minIntervalBulk &&
      maxIntervalBulk
    ) {
      payload = {
        ...payload,
        updateIntervalSetting,
        intervalSettingsToUpdate: {
          minInterval: minIntervalBulk,
          maxInterval: maxIntervalBulk,
        },
      };
    }

    const updateRampupSetting = dailyRampUp === true;

    if (
      updateRampupSetting &&
      dailyRampUp &&
      !emailsPerDayError &&
      !emailsPerDayPercentError
    ) {
      payload = {
        ...payload,
        updateRampupSetting,
        rampUpSettingsToUpdate: {
          rampUpInitialSendingLimit: emailsPerDay,
          rampUpPercent: emailsPerDayPercent,
          rampUpStatus: dailyRampUp,
        },
      };
    }

    if (selectedClient && selectedClient.id >= 0) {
      payload = {
        ...payload,
        clientId: selectedClient.id,
        assignResources: true,
      };
    }

    sendBulkEmailAccountUpdateRequest(payload);
    onHideBulkEmailUpdateConfirmationModal();
    onClose();
  };

  useEffect(() => {
    if (!show) {
      setErrors({
        senderName: '',
        senderFirstName: '',
        senderLastName: '',
        bcc: '',
        signature: '',
      });
      setValues({
        senderName: '',
        senderFirstName: '',
        senderLastName: '',
        bcc: '',
        signature: '',
      });
      setDirtyForGeneral({
        senderName: false,
        senderFirstName: false,
        senderLastName: false,
        bcc: false,
        signature: false,
      });
      setSelectedCustomDomain({
        key: null,
        value: null,
      });
      setDirty({
        dailyRampUp: false,
        emailsPerDay: false,
        emailsPerDayPercent: false,
      });

      setDailyRampUp(false);
      setEmailsPerDay(DefaultRampUpSettings.InitialSendingLimit);
      setEmailsPerDayPercent(DefaultRampUpSettings.RampUpPercent);
      setEmailsPerDayError('');
      setEmailsPerDayPercentError('');
      setActiveTab('general-settings');
      setSendingLimit(0);
      setSendingLimitError(false);
      setTimeIntervalError(false);
      setMinTimeIntervalBulk(0);
      setMaxTimeIntervalBulk(0);
      setSelectedClient({
        id: -1,
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        status: AgencyClientStatus.Active,
      });
    } else {
      if (hasPermission(Permissions.CLIENT_READ) && isAgency()) {
        sendGetAgencyClientsDropdownRequest({
          status: AgencyClientStatus.Active,
          search: '',
        });
      }
      if (hasPermission(Permissions.CUSTOM_DOMAIN_READ)) {
        sendGetCustomDomainsListRequest();
      }
    }
  }, [show]);

  const isClientRequestPending = getIsRequestPending(
    getAgencyClientsDropdownRequestStatus,
  );

  return (
    <>
      <Modal
        show={show}
        className="bulk-email-update-modal"
        onHide={onClose}
        onCancel={onClose}
        titleContent={
          <div>
            <span>Bulk Update Email Account Settings</span>
          </div>
        }
        submitButtonText={t('labels.save')}
        isSubmitDisabled={
          emailsPerDayPercentError !== '' ||
          emailsPerDayError !== '' ||
          sendingLimitError ||
          timeIntervalError ||
          errors.bcc !== '' ||
          (values.senderFirstName === '' &&
            selectedClient.id === -1 &&
            values.bcc === '' &&
            values.signature === '' &&
            selectedCustomDomain?.value === null &&
            (!sendingLimit || sendingLimit === 0) &&
            (minIntervalBulk.toString() === '' || minIntervalBulk === 0) &&
            (maxIntervalBulk.toString() === '' || maxIntervalBulk === 0) &&
            dailyRampUp === false)
        }
        onSubmit={onShowBulkEmailUpdateConfirmationModal}
        submitButtonClassName="header-btn"
        extraModalProps={{
          enforceFocus: false,
        }}
      >
        <div className="update-email-tabs bulk-update-email-tabs">
          <Tabs
            defaultActiveKey={
              hasPermission(
                Permissions.EMAIL_ACCOUNT_SETTINGS_GENERAL_SETTING_UPDATE,
              )
                ? UpdateEmailTabKeys.GENERAL_SETTINGS
                : UpdateEmailTabKeys.SENDING_SETTINGS
            }
            prefixCls="bs-tabs"
            className="bs-tabs-small"
            onChange={(key) => setActiveTab(key)}
            activeKey={activeTab}
          >
            {hasPermission(
              Permissions.EMAIL_ACCOUNT_SETTINGS_GENERAL_SETTING_UPDATE,
            ) && (
              <TabPane
                tab="General Settings"
                key={UpdateEmailTabKeys.GENERAL_SETTINGS}
              >
                <div className="general-settings-wrapper">
                  <Row className="mb-2" hidden>
                    <Col xs={12} md={12}>
                      <Input
                        name="senderName"
                        label="Sender Name"
                        variant={errors.senderName && Input.Variant.Error}
                        caption={errors.senderName}
                        size={Input.Size.Medium}
                        value={values.senderName}
                        onChange={onInputChange}
                        placeholder="Enter sender name"
                        /* onBlur={onInputBlur} */
                        /* autoFocus */
                        className="general-settings-input"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} md={12}>
                      <Input
                        name="senderFirstName"
                        label="First Name"
                        variant={errors.senderFirstName && Input.Variant.Error}
                        caption={errors.senderFirstName}
                        size={Input.Size.Medium}
                        value={values.senderFirstName}
                        onChange={onInputChange}
                        placeholder="Enter first name"
                        onBlur={onInputBlur}
                        autoFocus
                        className="general-settings-input"
                      />
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={12} md={12}>
                      <Input
                        name="senderLastName"
                        label="Last Name"
                        variant={errors.senderLastName && Input.Variant.Error}
                        caption={errors.senderLastName}
                        size={Input.Size.Medium}
                        value={values.senderLastName}
                        onChange={onInputChange}
                        placeholder="Enter last name"
                        onBlur={onInputBlur}
                        className="general-settings-input"
                      />
                    </Col>
                  </Row>

                  {hasPermission(Permissions.CUSTOM_DOMAIN_READ) && (
                    <Row className="mb-2">
                      <Col xs={12} md={12}>
                        <div className="bs-input-container general-settings-input">
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label className="bs-input-label-prefix">
                            Custom Tracking Domain
                          </label>
                          <div className="select-container">
                            <Select<CustomDomainDropdown>
                              className="custom-domain-select"
                              placeholder="Select Custom Domain"
                              options={customDomainOptions}
                              selectedOptionKey={selectedCustomDomain.key}
                              selectedOptionRenderer={([option]) => (
                                <span>{option?.domain}</span>
                              )}
                              onChange={([option]) => {
                                onCustomDomainChange(option);
                              }}
                              optionRenderer={(option) => (
                                <span>{option?.domain}</span>
                              )}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    {hasPermission(Permissions.CLIENT_READ) && isAgency() && (
                      <Row className="mb-2">
                        <Col xs={12} md={12}>
                          <div className="bs-input-container general-settings-input bulk-agency-client-dropdown">
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className="bs-input-label-prefix">
                              Associate Client
                            </label>
                            <div className="select-container">
                              {!isClientRequestPending ? (
                                <AgencyClientDropdown
                                  options={agencyClients}
                                  initialSelectedOption={selectedClient}
                                  className="bulk-email-account-client"
                                  onChange={(option) => {
                                    if (option) {
                                      setSelectedClient(option);
                                    }
                                  }}
                                  optionRenderer={(option) => (
                                    <div className="d-flex align-items-center">
                                      <span>
                                        {getClientName(option, 60, {
                                          company: true,
                                          email: true,
                                          deleted: true,
                                        })}
                                      </span>
                                    </div>
                                  )}
                                  disabled={false}
                                  placement={Placement.TopStart}
                                />
                              ) : (
                                <SkeletonLoading height={38} width={406} />
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Col xs={12} md={12}>
                      <Input
                        name="bcc"
                        label="BCC"
                        placeholder="Enter your bcc email address"
                        value={values.bcc}
                        size={Input.Size.Medium}
                        variant={errors.bcc && Input.Variant.Error}
                        caption={errors.bcc}
                        onChange={onInputChange}
                        onBlur={onInputBlur}
                        className="general-settings-input"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} md={12}>
                      <div className="bs-input-container signature-editor general-settings-input">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="bs-input-label-prefix">
                          Signature
                        </label>
                        <Editor
                          value={values.signature}
                          onEditorChange={onSignatureChange}
                          imageUploader={({ formData, onUploadProgress }) =>
                            sendImageUploadRequest(formData, onUploadProgress)
                          }
                          showAddAttachments={false}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}
            {hasPermission(
              Permissions.EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE,
            ) && (
              <TabPane
                tab="Sending Settings"
                key={UpdateEmailTabKeys.SENDING_SETTINGS}
              >
                <div className="sending-settings-wrapper">
                  <EmailDailySendingLimit
                    isBulkUpdatingEmailAccounts={true}
                    setSendingLimit={setSendingLimit}
                    setSendingLimitError={setSendingLimitError}
                    featureUsages={featureUsages}
                  />

                  <TimeIntervalSetting
                    isRequestPending={
                      emailTimeIntervalSettingsRequestStatus ===
                      RequestStatus.Pending
                    }
                    onKeyPress={onKeyPress}
                    emailAccountId=""
                    sendEmailTimeIntervalRequest=""
                    minInterval=""
                    maxInterval=""
                    setMinTimeIntervalBulk={setMinTimeIntervalBulk}
                    setMaxTimeIntervalBulk={setMaxTimeIntervalBulk}
                    setTimerIntervalSettingErrors={setTimeIntervalError}
                    isBulkUpdatingEmailAccounts={true}
                    featureUsages={featureUsages}
                  />

                  <div className="daily-rampup-wrapper bulk-update-email-accounts-row">
                    <DailyRampUpToggle
                      isChecked={dailyRampUp}
                      onChange={onDailyRampUpChange}
                      rampUpStatus={false}
                      agencyConfig={agencyConfig}
                      isBulkUpdatingEmailAccounts={true}
                    />

                    <DailyRampUpContainer
                      t={t}
                      dailyRampUp={dailyRampUp}
                      onKeyPress={onKeyPress}
                      onDropAndPaste={onDropAndPaste}
                      emailsPerDay={emailsPerDay}
                      onEmailsPerDayChange={(value) =>
                        onEmailsPerDayChange(value, sendingLimit)
                      }
                      emailsPerDayError={emailsPerDayError}
                      emailsPerDayPercent={emailsPerDayPercent}
                      onEmailsPerDayPercentChange={onEmailsPerDayPercentChange}
                      emailsPerDayPercentError={emailsPerDayPercentError}
                      rampUpStatus={false}
                      rampUpSettings={{ rampUpLimit: 0 }}
                      rampUpLimitReached={false}
                      dirty={dirty}
                      isBulkUpdatingEmailAccounts={true}
                    />
                  </div>
                </div>
              </TabPane>
            )}
          </Tabs>
        </div>
      </Modal>

      {showBulkEmailAccountUpdateConfirmationModal && (
        <ConfirmationModalV3
          show={showBulkEmailAccountUpdateConfirmationModal}
          onClose={onHideBulkEmailUpdateConfirmationModal}
          onSubmit={onSaveHandler}
          title={t('labels.update_settings')}
          content={`Are you sure you want to update these settings for all ${selectedEmailAccounts.length} accounts?`}
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        />
      )}
    </>
  );
};

export default BulkEmailUpdateModal;
