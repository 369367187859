import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronRight } from '@saleshandy/icons';
import { useHistory } from 'react-router';
import { Google } from '../../../../../shared/svg/google';
import { Microsoft } from '../../../../../shared/svg/microsoft';
import { EmailAccountInfrastructureServiceProvider } from '../../../enums/create-email-infrastructure';
import { RootState } from '../../../../../store/root-reducer';
import { accessibleOnClick } from '../../../../../shared/utils/accessible-on-click';
import GoingBackToEmailAccountsModal from '../../../modals/going-back-to-email-accounts-modal';
import { resetEmailAccountInfrastructure } from '../../../email-accounts-slice';
import { clearAllEmailAccountInfrastructureKeysFromLocalStorage } from '../../../helpers/email-accounts-infra-local-storage';

const CreateEmailInfrastructureHeader: React.FC = () => {
  const [showClearAllCartModal, setShowClearAllCartModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const emailAccountInfrastructureServiceProvider = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountInfrastructureServiceProvider,
  );

  const emailAccountInfrastructureCart = useSelector(
    (state: RootState) => state.emailAccount.emailAccountInfrastructureCart,
  );

  const renderESPIcon = () => {
    if (
      emailAccountInfrastructureServiceProvider ===
      EmailAccountInfrastructureServiceProvider.Google
    ) {
      return <Google />;
    }
    if (
      emailAccountInfrastructureServiceProvider ===
      EmailAccountInfrastructureServiceProvider.Microsoft
    ) {
      return <Microsoft />;
    }
    if (
      emailAccountInfrastructureServiceProvider ===
      EmailAccountInfrastructureServiceProvider.MailDoso
    ) {
      return null;
    }
    return null;
  };

  const onShowClearAllCartModal = () => {
    setShowClearAllCartModal(true);
  };

  const onCloseClearAllCartModal = () => {
    setShowClearAllCartModal(false);
  };

  const handleOnSubmitClick = () => {
    dispatch(resetEmailAccountInfrastructure());

    clearAllEmailAccountInfrastructureKeysFromLocalStorage();
    history.push('/email-accounts/create');
    onCloseClearAllCartModal();
  };

  const onTitleClick = () => {
    if (
      emailAccountInfrastructureCart?.emailAccounts?.length > 0 ||
      emailAccountInfrastructureCart?.domains?.length > 0
    ) {
      onShowClearAllCartModal();
    } else {
      history.push('/email-accounts');
    }
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="create-email-infrastructure-header d-flex align-items-center">
        <p
          className="create-email-infrastructure-header--title"
          {...accessibleOnClick(onTitleClick)}
        >
          Email Accounts
        </p>
        <div className="create-email-infrastructure-header--icon d-flex justify-content-center align-items-center">
          <ChevronRight />
        </div>
        <p className="create-email-infrastructure-header--title">
          Create Email Infrastructure
        </p>
        <div className="create-email-infrastructure-header--icon d-flex justify-content-center align-items-center">
          {renderESPIcon()}
        </div>
      </div>

      <GoingBackToEmailAccountsModal
        show={showClearAllCartModal}
        onSubmit={handleOnSubmitClick}
        onClose={onCloseClearAllCartModal}
      />
    </div>
  );
};

export default CreateEmailInfrastructureHeader;
