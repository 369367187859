import React, { useEffect, useRef } from 'react';
import Modal from '../../../shared/design-system/components/atoms/modal';

type IProps = {
  show: boolean;
  onClose: VoidFunction;
  url: string;
  title: string;
};

const EmbeddedVideoModal: React.FC<IProps> = ({
  show,
  onClose,
  url,
  title,
}) => {
  const viewSampleSequenceBtnRef = useRef<HTMLButtonElement>();

  const getVideoURL = () => url;

  useEffect(() => {
    if (show && viewSampleSequenceBtnRef.current) {
      viewSampleSequenceBtnRef.current.focus();
    }
  }, [viewSampleSequenceBtnRef, show]);

  useEffect(() => {
    const scriptId = 'wistia-external-script';

    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.setAttribute('id', scriptId);
      script.setAttribute(
        'src',
        'https://fast.wistia.net/assets/external/E-v1.js',
      );
      script.setAttribute('async', '');

      document.body.appendChild(script);
    }
  }, []);

  return (
    <Modal
      show={show}
      showCloseIcon
      onCancel={onClose}
      onClose={onClose}
      onHide={onClose}
      className="video-modal"
      backdrop={true}
      hideHeader
      hideFooter
    >
      <div className="watch-video-modal-body">
        <div className="d-flex justify-content-center align-items-center">
          <iframe
            src={getVideoURL()}
            title={title}
            allow="autoplay; fullscreen"
            allowTransparency={true}
            className="wistia_embed"
            name="wistia_embed"
            allowFullScreen
            style={{
              border: 'none',
              width: '100%',
              height: '24rem',
              maxHeight: '700px',
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EmbeddedVideoModal;
