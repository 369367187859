import { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import SequenceSingleHeader from './sequence-single-header';
import { RootState } from '../../../../store/root-reducer';
import {
  getSequenceRequest,
  pauseResumeSequenceRequest,
  renameSequenceRequest,
} from '../../extra-actions';
import { SequenceAction } from '../../enums';
import { SequenceIdRouteComponentProps } from '../../types';
import { upgradeOnboardingStepRequest } from '../../../home/extra-actions';
import { UpgradeOnboardingStepPayload } from '../../../home/types';
import { connectEmailAccountRequest } from '../../../email-accounts/extra-actions';
import { EmailAccountMethod } from '../../../email-accounts/enums/email-account';

const mapStateToProps = (state: RootState) => ({
  renameSequenceRequestStatus: state.sequence.renameSequenceRequest.status,
  renameSequenceRequestMessage: state.sequence.renameSequenceRequest.message,
  renameSequenceRequestError: state.sequence.renameSequenceRequest.error,
  pauseResumeSequenceRequestStatus:
    state.sequence.pauseResumeSequenceRequest.status,
  pauseResumeSequenceRequestMessage:
    state.sequence.pauseResumeSequenceRequest.message,
  pauseResumeSequenceRequestError:
    state.sequence.pauseResumeSequenceRequest.error,
  title: state.sequence.sequence?.title,
  progress: state.sequence.sequence?.progress,
  isStepsListEmpty: state.sequence.sequence?.steps.length === 0,
  sequenceId: state.sequence.sequence?.id,
  isContactsEmpty: !state.sequence.contactStepFilterCount.totalContacts,
  meta: state.home.meta,
  connectEmailAccountRequestStatus:
    state.emailAccount.connectEmailAccountRequest.status,
  authUrl: state.emailAccount.authUrl,
  getUserSettingRequestStatus: state.home.getUserSettingsRequest.status,
  role: state.home.role,
  subscriptionPlan: state.home.subscription?.planCode,
  pausedReasonIdentifier: state.sequence.sequence?.pausedReasonIdentifier,
  remainingEmailSentLimit: parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'EMAIL.SEND'
    ],
    10,
  ),
  sequence: state.sequence.sequence,
  subscriptionDetails: state.home.subscription,
  verified: state.home.verified,
});

const mapDispatchToProps = {
  sendGetSequenceRequest: (sequenceId: number) =>
    getSequenceRequest(sequenceId),
  sendRenameSequenceRequest: (sequenceId: number, title: string) =>
    renameSequenceRequest({ sequenceId, title }),
  sendPauseResumeSequenceRequest: (
    sequenceId: number,
    action: SequenceAction,
  ) => pauseResumeSequenceRequest({ sequenceId, action }),
  sendUpgradeOnboardingStepRequest: (payload?: UpgradeOnboardingStepPayload) =>
    upgradeOnboardingStepRequest(payload),
  sendConnectEmailAccountRequest: (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => connectEmailAccountRequest({ method, emailAccountId }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  SequenceIdRouteComponentProps & {
    currentTab: string;
    t: (x: string) => ReactNode;
  };

export default withRouter(connector(SequenceSingleHeader));
