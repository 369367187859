import React from 'react';
import { Button } from '@saleshandy/design-system';
import { VariantsList } from '../../svg';
import { getSequenceScoreErrorMessage } from './helper';
import { SequenceScoreError as SequenceScoreErrorType } from '../../../components/sequence/types';

type IProps = {
  error: SequenceScoreErrorType;
  onClick?: VoidFunction;
  component?: 'email-writing-score';
};

const SequenceScoreError: React.FC<IProps> = ({
  error,
  onClick,
  component,
}) => {
  const {
    title,
    description,
    showBtn,
    btnLabel,
    btnHandler,
  } = getSequenceScoreErrorMessage(error, component);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (btnHandler) {
      btnHandler();
    }
  };

  return (
    <div className="sequence-score-error d-flex justify-content-center align-items-center flex-column h-100">
      <VariantsList />
      <div className="sequence-score-error--content d-flex justify-content-center align-items-center flex-column">
        <h1>{title}</h1>
        <p>{description}</p>
        {showBtn && <Button onClick={handleClick}>{btnLabel}</Button>}
      </div>
    </div>
  );
};

export default SequenceScoreError;
