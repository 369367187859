import api from '../../../../../api';
import { ResponseSuccess } from '../../../../../shared/types';
import {
  ApplyCouponCodeRequest,
  GetInvoiceURLRequest,
  PurchaseEmailVerificationCreditsRequest,
  ModifySubscriptionRequest,
  PurchaseSubscriptionRequest,
  PurchaseModifySubscriptionRequest,
  ReactivateResumeSubscriptionRequest,
  HandleSubscriptionRequest,
  CalculatePayRequest,
  UpgradePlanRequest,
  DowngradePlanRequest,
} from '../types';
import {
  LTDModifyPayload,
  LTDPurchasePayload,
  LTDUpgradePayload,
} from '../types/ltd-plans';
import { GetOrderDetailsByIdPayload } from '../types/purchase-history';

export const getAccountSubscription = () =>
  api.get<ResponseSuccess>('/subscription');

export const getAccountSubscriptionPlanDetail = (id: string) =>
  api.get<ResponseSuccess>(`retrive-upcoming-invoice?customerId=${id}`);

export const getLeadFinderSubscription = () =>
  api.get<ResponseSuccess>('/lead-finder/subscription');

export const purchaseSubscription = (payload: PurchaseSubscriptionRequest) =>
  api.post<ResponseSuccess>('/subscription', payload);

export const purchaseLeadFinderSubscription = (
  payload: PurchaseSubscriptionRequest,
) => api.post<ResponseSuccess>('/lead-finder/subscription', payload);

export const getPlans = () => api.get<ResponseSuccess>('/plans');

export const getLeadFinderPlans = () =>
  api.get<ResponseSuccess>('/lead-finder/plans');

export const getInvoiceUrl = (payload: GetInvoiceURLRequest) =>
  api.post<ResponseSuccess>('/invoice-url', payload);

export const getConnectedUsersAndEmailAccounts = () =>
  api.get<ResponseSuccess>('/accounts/connected-emails-and-users-count');

export const applyCouponCode = (payload: ApplyCouponCodeRequest) =>
  api.get<ResponseSuccess>(
    `/coupons/${payload.couponCode}/validate?planId=${payload.planId}&amount=${payload.amount}`,
  );

export const getEmailVerificationCredits = () =>
  api.get<ResponseSuccess>('/email-verification-credits');

export const getEmailVerificationPlan = () =>
  api.get<ResponseSuccess>('/email-verification-plans');

export const purchaseEmailVerificationCredits = (
  payload: PurchaseEmailVerificationCreditsRequest,
) => api.post<ResponseSuccess>('/email-verification-credits/purchase', payload);

export const modifySubscription = (payload: ModifySubscriptionRequest) =>
  api.get<ResponseSuccess>('/subscription/calculate-pay', {
    params: {
      accountSubscriptionPlanId: payload.accountSubscriptionPlanId,
      slots: payload.slots,
      stripeCardToken: payload.stripeCardToken,
    },
  });

export const purchaseModifySubscription = (
  payload: PurchaseModifySubscriptionRequest,
) => api.post<ResponseSuccess>('/subscription/modify', payload);

export const purchaseLeadFinderModifySubscription = (
  payload: PurchaseModifySubscriptionRequest,
) => api.post<ResponseSuccess>('/lead-finder/subscription/modify', payload);

export const reactivateSubscription = (
  payload: ReactivateResumeSubscriptionRequest,
) => api.post<ResponseSuccess>('/subscription/reactivate', payload);

export const reactivateLeadFinderSubscription = (
  payload: ReactivateResumeSubscriptionRequest,
) => api.post<ResponseSuccess>('/lead-finder/subscription/reactivate', payload);

export const resumeSubscription = (
  payload: ReactivateResumeSubscriptionRequest,
) => api.post<ResponseSuccess>('/subscription/resume', payload);

export const resumeLeadFinderSubscription = (
  payload: ReactivateResumeSubscriptionRequest,
) => api.post<ResponseSuccess>('/lead-finder/subscription/resume', payload);

export const handleSubscription = (payload: HandleSubscriptionRequest) =>
  api.post<ResponseSuccess>('/subscription/churnkey-event', payload);

export const handleLeadFinderChurnkeySubscription = (
  payload: HandleSubscriptionRequest,
) =>
  api.post<ResponseSuccess>(
    '/lead-finder/subscription/churnkey-event',
    payload,
  );

export const calculatePay = (payload: CalculatePayRequest) =>
  api.get<ResponseSuccess>('/subscription/calculate-pay', { params: payload });

export const upgradePlan = (payload: UpgradePlanRequest) =>
  api.post<ResponseSuccess>('/subscriptions/upgrade', payload);

export const downgradePlan = (payload: DowngradePlanRequest) =>
  api.post<ResponseSuccess>('/subscriptions/downgrade', payload);

export const purchaseLtdPlan = (payload: LTDPurchasePayload) =>
  api.post<ResponseSuccess>('/ltd-purchase', payload);

export const upgradeLtdPlan = (payload: LTDUpgradePayload) =>
  api.patch<ResponseSuccess>('/ltd-upgrade ', payload);

export const modifyLtdPlan = (payload: LTDModifyPayload) =>
  api.patch<ResponseSuccess>('/ltd-modify  ', payload);

export const purchaseHistory = () =>
  api.get<ResponseSuccess>('/domain/purchase-history');

export const purchaseDetailsById = (payload: GetOrderDetailsByIdPayload) =>
  api.get<ResponseSuccess>(`/domain/purchase-details/${payload.orderId}`);
