import React, { useEffect } from 'react';

interface SuggestedDomainsEmailsTableProps {
  columns: {
    key: string;
    header: string;
    component: (row: any) => JSX.Element;
  }[];
  data: any[];
  tableTitle: JSX.Element;
  actionComponent: JSX.Element;
  className?: string;
  rowClassName?: (row: any, rowIndex: number) => string;
  tableHeightClassname?: string;
}

const SuggestedDomainsEmailsTable: React.FC<SuggestedDomainsEmailsTableProps> = ({
  columns = [],
  data = [],
  tableTitle = null,
  actionComponent = null,
  className = '',
  rowClassName = () => '',
  tableHeightClassname = '',
}) => {
  useEffect(() => {
    const updateBannerHeight = () => {
      const banners = document.querySelectorAll('[data-banner]');
      const totalBannerHeight = Array.from(banners).reduce(
        (total, banner) => total + banner.getBoundingClientRect().height,
        0,
      );
      document.documentElement.style.setProperty(
        '--banner-height',
        `${totalBannerHeight}px`,
      );
    };

    updateBannerHeight();

    window.addEventListener('resize', updateBannerHeight);
    return () => {
      window.removeEventListener('resize', updateBannerHeight);
    };
  }, []);

  return (
    <div className="suggested-domains-emails-container">
      <div className="suggested-domains-emails">
        <div className="header">
          {tableTitle}
          {actionComponent}
        </div>
        <div className={`suggested-domains-emails-table-outer-container ${tableHeightClassname}`}>
          <div className="suggested-domains-emails-table-container">
            <table className={`domains-emails-table ${className}`}>
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.key}>
                      <span>{column.header}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr
                    // eslint-disable-next-line react/no-array-index-key
                    key={rowIndex}
                    className={`table-row ${rowClassName(row, rowIndex)}`}
                  >
                    {columns.map((column) => (
                      <td key={column.key}>{column.component(row)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestedDomainsEmailsTable;
