import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../../../store/root-reducer';
import {
  DeleteDomainRequestPayload,
  DomainsFilters,
  GetDomainsRequestPayload,
} from '../../types';
import { deleteDomainRequest, getDomainsRequest } from '../../extra-actions';
import {
  resetDeleteDomainRequestState,
  resetGetDomainsRequestState,
  updateDomainsFilters,
} from '../../domains-slice';

import DomainsContent from './domains-content';

const mapStateToProps = (state: RootState) => ({
  domains: state.domains.domains,
  domainsFilters: state.domains.domainsFilters,

  getDomainsRequestStatus: state.domains.getDomainsRequest.status,
  getDomainsRequestError: state.domains.getDomainsRequest.error,

  deleteDomainRequestStatus: state.domains.deleteDomainRequest.status,
  deleteDomainRequestError: state.domains.deleteDomainRequest.error,
});

const mapDispatchToProps = {
  sendGetDomainsRequest: (payload: GetDomainsRequestPayload) =>
    getDomainsRequest(payload),
  resetGetDomainsRequestState: () => resetGetDomainsRequestState(),

  sendDeleteDomainRequest: (payload: DeleteDomainRequestPayload) =>
    deleteDomainRequest(payload),
  resetDeleteDomainRequestState: () => resetDeleteDomainRequestState(),

  updateDomainsFilters: (filters: DomainsFilters) =>
    updateDomainsFilters(filters),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(DomainsContent);
