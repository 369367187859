import React from 'react';
import { Alert as ShAlert, AlertProps } from '@saleshandy/design-system';

interface CustomAlertProps extends AlertProps {
  dataBanner?: boolean;
}

const Alert: React.FC<CustomAlertProps> = ({ dataBanner = true, ...rest }) => (
  <div {...(dataBanner ? { 'data-banner': true } : {})}>
    <ShAlert {...rest} />
  </div>
);

export default Alert;
