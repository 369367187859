import React from 'react';
import produce from 'immer';
import classNames from 'classnames';
import queryString from 'query-string';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link } from 'react-router-dom';

import { validate } from './validator';
import type { IProps, IState } from './types';

import PrivacyPolicy from '../privacy-policy';
import Input from '../../../../shared/design-system/components/input';
import Button from '../../../../shared/design-system/ui/button';
import PasswordValidationChecklist from '../../../../shared/components/password-validation-checklist';
import { SHLogoDark } from '../../../../shared/svg';

import {
  getCurrentTimeZone,
  getIsRequestPending,
  redirectWithoutRefresh,
  initializeThirdPartyIntegrations,
  executeOnRequestStatusWithPrevStatusCheck,
  getUserLocationMetaData,
  AuthHelper,
  setToken,
  getCookie,
} from '../../../../shared/utils';
import { AnalyticsEvents } from '../../../../shared/enums/analytics';
import { isWhiteLabel } from '../../../../shared/utils/user-details';

class SignupForm extends React.PureComponent<IProps, IState> {
  private readonly phoneInputField = React.createRef<HTMLInputElement>();

  constructor(props: IProps) {
    super(props);

    const { location, email: propsEmail, isInvitedUser } = this.props;
    const { email: queryEmail } = queryString.parse(location.search);
    const disableEmailInputValue = isInvitedUser || queryEmail;

    this.state = {
      values: {
        firstName: '',
        lastName: '',
        email: propsEmail || queryEmail || '',
        phone: '',
        password: '',
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
      },
      dirty: {
        firstName: false,
        lastName: false,
        email: disableEmailInputValue || false,
        phone: true,
        password: false,
      },
      disableEmailInput: disableEmailInputValue,
      countryCode: 'us',
      dialCode: '',
      showPassword: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onPhoneInputChange = this.onPhoneInputChange.bind(this);
    this.onPasswordVisibilityChange = this.onPasswordVisibilityChange.bind(
      this,
    );
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
  }

  componentDidMount(): void {
    const { code } = getUserLocationMetaData();

    this.setState({ countryCode: code.toLowerCase() ?? 'us' });

    const phoneFormInput = document.querySelector('.phone-form-input');
    const formControl = document.querySelector('.form-control');
    const flagDropdown = document.querySelector('.flag-dropdown');
    phoneFormInput.insertBefore(flagDropdown, formControl);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      signupRequestStatus,
      acceptInvitationRequestStatus,
      trackingId,
    } = this.props;
    const { values } = this.state;

    executeOnRequestStatusWithPrevStatusCheck({
      status: signupRequestStatus,
      prevStatus: prevProps.signupRequestStatus,
      onSuccess: () => {
        this.handleSuccess();

        const { firstName, lastName, email } = values;

        initializeThirdPartyIntegrations({
          trackingId,
          firstName,
          lastName,
          email,
        });
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: acceptInvitationRequestStatus,
      prevStatus: prevProps.acceptInvitationRequestStatus,
      onSuccess: () => {
        this.handleSuccess();

        const { firstName, lastName, email } = values;

        initializeThirdPartyIntegrations({
          trackingId,
          firstName,
          lastName,
          email,
        });
      },
    });
  }

  componentWillUnmount() {
    const { hideError } = this.props;
    hideError?.();
  }

  onInputChange(value: string, e: React.ChangeEvent<HTMLInputElement>) {
    const { name } = e.target;
    const { dialCode } = this.state;

    this.setState((prevState) => ({
      dirty: {
        ...prevState.dirty,
        [name]: true,
      },
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: '',
      },
    }));

    if (name === 'password') {
      const error = validate(name, value, dialCode);
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [name]: error,
        },
      }));
    }
  }

  onInputBlur(e) {
    if (e?.target) {
      const { name } = e.target;
      const { dialCode, values } = this.state;

      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [name]: validate(name, values[name], dialCode),
        },
      }));
    }
  }

  onPhoneInputChange(value: string, data) {
    const { countryCode, dialCode, errors } = this.state;

    if (errors.phone && validate('phone', value, dialCode) === '') {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phone: '',
        },
      }));
    }

    if (data && dialCode !== data.dialCode) {
      this.setState({ dialCode: data.dialCode });
    }

    if (data && countryCode !== data.countryCode) {
      this.setState({ countryCode: data.countryCode, dialCode: data.dialCode });
      this.phoneInputField.current.focus();
    }

    this.setState(
      produce((draft) => {
        draft.values.phone = value;
        draft.dirty.phone = true;
      }),
    );
  }

  onPasswordVisibilityChange() {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { dirty, errors, values, dialCode } = this.state;
    const { location } = this.props;
    let { referralId } = queryString.parse(location.search);

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key], dialCode);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    let timeZone = getCurrentTimeZone();
    if (timeZone === 'Asia/Calcutta') {
      timeZone = 'Asia/Kolkata';
    }

    const {
      sendSignupRequest,
      sendAcceptInvitationRequest,
      isInvitedUser,
      invitationToken,
    } = this.props;

    if (!referralId && window.Rewardful) {
      referralId = window.Rewardful?.referral;
    }

    const strippedPhone = values.phone.replace(`${dialCode}`, '').trim();
    const phoneValue = strippedPhone ? values.phone : '';

    if (isInvitedUser) {
      sendAcceptInvitationRequest({
        firstName: values.firstName,
        lastName: values.lastName,
        ...(phoneValue && { phone: phoneValue }),
        password: values.password,
        token: invitationToken,
        timeZone,
      });
    } else {
      const { phone, ...rest } = values;
      sendSignupRequest({
        ...rest,
        ...(phoneValue && { phone: phoneValue }),
        timeZone,
        rewardfulReferralId: referralId,
        landingPage: getCookie('sh_ulp') || '',
        celloReferralCode: window?.CelloAttribution?.('getReferral') || '',
      });
    }
  }

  handleSuccess() {
    const { trackingId, token } = this.props;
    const { values } = this.state;

    if (token) {
      setToken(token);
      AuthHelper.login({ token });
    }

    const { firstName, lastName, email, phone } = values;

    if (trackingId) {
      window.analytics?.track?.({
        userId: trackingId,
        event: AnalyticsEvents.SignupCompleted,
        properties: {
          firstName,
          lastName,
          email,
          phoneNumber: phone,
          id: trackingId,
          source: 'v3',
        },
      });
    }

    redirectWithoutRefresh({
      pathname: '/sequence',
      search: 'signup=completed',
    });
  }

  hasErrors() {
    const { errors, dirty, values } = this.state;

    let isError = false;

    Object.keys(errors).forEach((key) => {
      if ((errors[key] !== '' || !dirty[key]) && key !== 'password') {
        isError = true;
      }
    });

    if (
      values.password.length === 0 ||
      errors.password !== '' ||
      !dirty.password
    ) {
      isError = true;
    }

    return isError;
  }

  render() {
    const {
      values,
      errors,
      dirty,
      countryCode,
      showPassword,
      disableEmailInput,
    } = this.state;
    const {
      signupRequestStatus,
      signupRequestError,
      showSignupRequestError,
      isInvitedUser = false,
    } = this.props;

    const isSignupBtnLoading = getIsRequestPending(signupRequestStatus);
    const isSignupBtnDisabled = isSignupBtnLoading || this.hasErrors();

    return (
      <div
        className={
          isWhiteLabel() ? 'auth-wrapper w-100 d-flex' : 'auth-wrapper'
        }
      >
        <div className="auth-container">
          <div className="auth-form">
            <div className="sh-logo">
              <SHLogoDark width={200} />
            </div>
            <h1 className="auth-container--title">
              {isInvitedUser ? (
                <>
                  <span>Let's go!</span>
                  <span>Your team is waiting for you.</span>
                </>
              ) : (
                <span>Create your account</span>
              )}
            </h1>

            <form onSubmit={this.onFormSubmit} className="auth-container--form">
              <div className="auth-form-row">
                <div className="auth-form-input">
                  <Input
                    name="firstName"
                    label="First name*"
                    placeholder="John"
                    value={values.firstName}
                    variant={errors.firstName && Input.Variant.Error}
                    caption={errors.firstName}
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    tabIndex={0}
                    autoFocus
                    autoComplete="current-firstName"
                  />
                </div>

                <div className="auth-form-input">
                  <Input
                    name="lastName"
                    label="Last name*"
                    placeholder="Doe"
                    value={values.lastName}
                    variant={errors.lastName && Input.Variant.Error}
                    caption={errors.lastName}
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    tabIndex={0}
                    autoComplete="current-lastName"
                  />
                </div>
              </div>

              <div className="auth-form-row">
                <div className="auth-form-input">
                  <Input
                    name="email"
                    label="Work email*"
                    type="email"
                    placeholder="johndoe@example.com"
                    value={values.email}
                    variant={errors.email && Input.Variant.Error}
                    caption={
                      errors.email ||
                      (showSignupRequestError ? signupRequestError.message : '')
                    }
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    disabled={disableEmailInput}
                    tabIndex={0}
                    autoComplete="current-email"
                  />
                </div>

                <div className="auth-form-input phone">
                  <p>Phone number</p>
                  <PhoneInput
                    country={countryCode}
                    value={values.phone}
                    onChange={(value, data) =>
                      this.onPhoneInputChange(value, data)
                    }
                    onBlur={this.onInputBlur}
                    containerClass={classNames([
                      'phone-form-input',
                      { 'input-error': errors.phone },
                    ])}
                    inputProps={{
                      tabIndex: 0,
                      name: 'phone',
                      ref: this.phoneInputField,
                    }}
                  />
                  <span className="phone-form-input-error">{errors.phone}</span>
                </div>
              </div>

              <div className="auth-form-row">
                <div className="auth-form-input password">
                  <Input
                    name="password"
                    label="Password*"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Minimum 8 Characters"
                    value={values.password}
                    variant={errors.password && Input.Variant.Error}
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    className="auth-form-input"
                    autoComplete="current-password"
                    icons={[
                      {
                        place: Input.IconPlace.Right,
                        identifier: showPassword ? 'eye-alt' : 'eye',
                        className: 'pointer',
                        onClick: this.onPasswordVisibilityChange,
                      },
                    ]}
                  />
                  {isSignupBtnDisabled ? (
                    <PasswordValidationChecklist
                      password={values.password}
                      isDirty={dirty.password}
                    />
                  ) : (
                    <span className="regular-1 font-medium green-txt-16 mt-1">
                      Your password is secured & You’re all set!
                    </span>
                  )}
                </div>
              </div>

              <Button
                type="submit"
                isFullWidth
                isLoading={isSignupBtnLoading}
                disabled={isSignupBtnDisabled}
                loadingText="Signing up, please wait..."
              >
                Sign up
              </Button>
            </form>
          </div>

          <div className="d-flex flex-column align-items-center text-center">
            {!isWhiteLabel() && <PrivacyPolicy />}

            <div className="bottom-navigation">
              <p>
                Already have an account?{' '}
                <Link to="/login" tabIndex={0}>
                  Log in!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupForm;
