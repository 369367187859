import React from 'react';
import { AlertCircle } from '@saleshandy/icons';

import Modal from '../../../shared/design-system/ui/modal';

interface RevokeCancellationModalProps {
  show: boolean;
  onSubmit: () => void;
  onClose: () => void;
  isLoading: boolean;
}

const RevokeCancellationModal: React.FC<RevokeCancellationModalProps> = ({
  show,
  onSubmit,
  onClose,
  isLoading,
}) => (
  <Modal
    show={show}
    className="revoke-cancellation-confirmation-modal"
    title={
      <div className="d-flex align-items-center gap-10">
        <AlertCircle width={24} height={24} color="#D97706" />
        <span className="line-height-1">Revoke Cancellation</span>
      </div>
    }
    onHide={onClose}
    primaryBtnLabel="Yes"
    secondaryBtnLabel="Cancel"
    primaryBtnClickedHandler={onSubmit}
    secondaryBtnClickedHandler={onClose}
    primaryBtnDisabled={isLoading}
    primaryBtnLoading={isLoading}
  >
    <p>
      Are you sure you want to revoke the cancellation of this deleted domain?
    </p>
  </Modal>
);

export default RevokeCancellationModal;
