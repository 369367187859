import React from 'react';
import { useHistory } from 'react-router';
import { ProgressBar } from '@saleshandy/design-system';
import { EmailAccount } from '../../../../../types/email-account';
import { EmailAccountSettings } from '../../../../../types/email-account-settings';
import { EmailAccountSettingCode } from '../../../../../enums/email-account-settings';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { UpdateEmailTabKeys } from '../../update-email-account/types';
import { setEmailAccountsSearchQuery } from '../../../utils/helpers';

type IProps = {
  emailAccount: EmailAccount;
  rowNumber: number;
  itemCount: number;
};

const getEmailSentAndDailySendingLimit = (settings: EmailAccountSettings[]) => {
  const quota = settings.find(
    (setting) => setting.code === EmailAccountSettingCode.AvailableQuota,
  )?.value;

  const limit = settings.find(
    (setting) => setting.code === EmailAccountSettingCode.DailySendingLimit,
  )?.value;

  const parsedQuota = parseInt(quota, 10) || 0;
  const dailySendingLimit = parseInt(limit, 10);

  const emailSent = dailySendingLimit - parsedQuota;

  const percentage = (emailSent * 100) / dailySendingLimit;

  return {
    percentage,
    emailSent,
    remaining: parsedQuota,
    dailySendingLimit,
    colors:
      percentage > 80
        ? {
            fillCls: 'red-bar',
            baseCls: 'red-19',
          }
        : {
            fillCls: 'blue-bar',
            baseCls: 'blue-13',
          },
  };
};

const EmailSentField: React.FC<IProps> = ({
  emailAccount: { hashId, settings },
  itemCount,
  rowNumber,
}) => {
  const history = useHistory();

  const redirectToSendingSetting = () => {
    setEmailAccountsSearchQuery(history.location.search);

    history.push({
      pathname: `/email-accounts/${hashId}/${UpdateEmailTabKeys.SENDING_SETTINGS}`,
    });
  };

  const getRowPosition = (): Placement => {
    if (rowNumber > itemCount - 2) {
      return Placement.Top;
    }
    return Placement.Bottom;
  };

  const {
    percentage,
    emailSent,
    remaining,
    dailySendingLimit,
    colors,
  } = getEmailSentAndDailySendingLimit(settings);

  return (
    <div>
      <OverlayTooltip
        className="email-sent-stat-tooltip"
        placement={getRowPosition()}
        text={
          <div className="email-sent-stat-tooltip-content">
            <span>Daily sending limit: {dailySendingLimit}</span>
            <span>Used today: {emailSent}</span>
            <span>Remaining: {remaining}</span>
          </div>
        }
      >
        <div className="email-sent-field">
          <div
            className={`email-sent-progress-bar ${colors.baseCls} ${colors.fillCls}`}
          >
            <ProgressBar percentage={percentage} />
          </div>
          <div className="email-sent-stat">
            <p {...accessibleOnClick(redirectToSendingSetting)}>
              <span>{emailSent}</span>/{dailySendingLimit}
            </p>
          </div>
        </div>
      </OverlayTooltip>
    </div>
  );
};

export default EmailSentField;
