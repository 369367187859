import React from 'react';
import ImageIcon from '../../../../../shared/components/images/image-icon';

interface NoSearchResultProps {
  imageSrc: string;
  headerText: string;
  messageText: string;
}

const NoSearchResult: React.FC<NoSearchResultProps> = ({
  imageSrc,
  headerText,
  messageText,
}) => (
  <div className="suggested-domains-emails-empty-list">
    <div className="empty-list">
      <ImageIcon src={imageSrc} />
      <div className="empty-list-header">{headerText}</div>
      <div className="empty-list-note">{messageText}</div>
    </div>
  </div>
);

export default NoSearchResult;
