import React, { memo, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@saleshandy/design-system';
import { ChevronDown, ChevronRight, ChevronUp, Cross } from '@saleshandy/icons';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

import { accessibleOnClick } from '../../../shared/utils/accessible-on-click';

import { CreateEmailAccountInfrastructureCart } from '../types/create-email-infrastructure';

interface IProps {
  show: boolean;
  onClose: () => void;
  emailAccountInfrastructureCart: CreateEmailAccountInfrastructureCart;

  isNextButtonDisabled: boolean;
  isNextButtonLoading: boolean;
  onNextButtonClick: () => void;
  nextButtonText: string;
  onClearAllClick: () => void;

  removeDomainFromCart: (payload: string) => void;
  removeEmailAccountFromCart: (payload: string) => void;
}

const CreateEmailInfrastructureCart: React.FC<IProps> = ({
  show,
  onClose,
  emailAccountInfrastructureCart,

  isNextButtonDisabled,
  isNextButtonLoading,
  onNextButtonClick,
  nextButtonText,
  onClearAllClick,

  removeDomainFromCart,
  removeEmailAccountFromCart,
}) => {
  const [animationClass, setAnimationClass] = useState('hide');

  useEffect(() => {
    if (show) {
      setAnimationClass('show');
    } else {
      setAnimationClass('hide');
    }
  }, [show]);

  return (
    <Modal
      show={show}
      className={`create-email-infrastructure-cart ${animationClass}`}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Cart</Modal.Title>
        <div className="close-icon-btn" {...accessibleOnClick(onClose)}>
          <Cross width={20} height={20} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Accordion
          allowZeroExpanded
          allowMultipleExpanded
          preExpanded={['domains', 'emailAccounts']}
          className="cart-accordion"
        >
          <AccordionItem uuid="domains">
            <AccordionItemHeading>
              <AccordionItemButton>
                <AccordionItemState>
                  {({ expanded }) => (
                    <div className="accordion-item-title">
                      <span className="accordion-item-title-text">Domains</span>
                      <div className="domains-badge">
                        <span>
                          {emailAccountInfrastructureCart.domains.length}
                        </span>
                      </div>
                      {expanded ? <ChevronUp /> : <ChevronDown />}
                    </div>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="cart-data-list">
                {emailAccountInfrastructureCart.domains.map((domain) => (
                  <div className="cart-data-item" key={domain.name}>
                    <span className="cart-data-item-name">{domain.name}</span>
                    <div className="cart-data-item-info">
                      <span className="cart-data-item-price">
                        ${domain.price}
                      </span>
                      <div
                        className="cart-data-item-remove"
                        {...accessibleOnClick(() =>
                          removeDomainFromCart(domain.name),
                        )}
                      >
                        <Cross />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          {emailAccountInfrastructureCart.emailAccounts.length > 0 && (
            <AccordionItem uuid="emailAccounts">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <div className="accordion-item-title">
                        <span className="accordion-item-title-text">
                          Email Accounts
                        </span>
                        <div className="domains-badge">
                          <span>
                            {
                              emailAccountInfrastructureCart.emailAccounts
                                .length
                            }
                          </span>
                        </div>
                        {expanded ? <ChevronUp /> : <ChevronDown />}
                      </div>
                    )}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="cart-data-list">
                  {emailAccountInfrastructureCart.emailAccounts.map(
                    (account) => (
                      <div className="cart-data-item" key={account.email}>
                        <span className="cart-data-item-name">
                          {account.email}
                        </span>
                        <div className="cart-data-item-info">
                          <span className="cart-data-item-price">
                            ${account.price}
                          </span>
                          <div
                            className="cart-data-item-remove"
                            {...accessibleOnClick(() =>
                              removeEmailAccountFromCart(account.email),
                            )}
                          >
                            <Cross />
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          )}
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <div className="cart-info">
          <div className="cart-info-left">
            <p>
              {emailAccountInfrastructureCart.domains.length}{' '}
              {emailAccountInfrastructureCart.domains.length === 1
                ? 'Domain'
                : 'Domains'}
            </p>
            {emailAccountInfrastructureCart.emailAccounts.length > 0 && (
              <p>
                {emailAccountInfrastructureCart.emailAccounts.length}{' '}
                {emailAccountInfrastructureCart.emailAccounts.length === 1
                  ? 'Email Account'
                  : 'Email Accounts'}
              </p>
            )}
          </div>

          <div className="cart-info-right">
            <p>
              {`$${emailAccountInfrastructureCart.domains.reduce(
                (total, domain) => total + Number(domain.price),
                0,
              )}/year`}
            </p>

            {emailAccountInfrastructureCart.emailAccounts.length > 0 && (
              <p>
                {`$${
                  emailAccountInfrastructureCart.emailAccounts.reduce(
                    (total, emailAccount) => total + Number(emailAccount.price),
                    0,
                  ) * 3
                }/quarter`}
              </p>
            )}
          </div>
        </div>

        <div className="cart-actions">
          <Button
            variant="link"
            className="clear-all-btn"
            onClick={onClearAllClick}
          >
            Clear all
          </Button>
          <Button
            disabled={isNextButtonDisabled}
            isLoading={isNextButtonLoading}
            onClick={onNextButtonClick}
            loadingText={nextButtonText}
            icon={ChevronRight}
            iconPlacement="right"
            className={`cart-next-button
              ${
                nextButtonText.toLowerCase() !== 'next' ? 'continue-btn-lg' : ''
              }`}
          >
            {nextButtonText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(CreateEmailInfrastructureCart);
