import React, { useEffect, useRef, useState } from 'react';
import { InfoCircle, Upload } from '@saleshandy/icons';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import Modal from '../../../shared/design-system/ui/modal';

import { Images } from '../../../shared/app-constants';
import toaster from '../../../shared/toaster';

interface UploadProfileImageProps {
  show: boolean;
  onSubmit: (file: File) => void;
  onClose: () => void;
  isUploading: boolean;
}

const UploadProfileImageModal: React.FC<UploadProfileImageProps> = ({
  show,
  onSubmit,
  onClose,
  isUploading,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  const [importFlowStage, setImportFlowStage] = useState<'upload' | 'crop'>(
    'upload',
  );
  const [profileImage, setProfileImage] = useState<string | null>(null);

  const handleOnSubmit = async () => {
    if (importFlowStage === 'crop') {
      if (typeof cropperRef.current?.cropper !== 'undefined') {
        const croppedImage = cropperRef.current?.cropper
          ?.getCroppedCanvas?.()
          ?.toDataURL?.();

        // Convert the cropped image URL (blob) to a File object
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        const file = new File([blob], 'profile.png', { type: 'image/png' });

        onSubmit(file);
      }
      return;
    }

    inputRef.current?.click();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      toaster.error('Please select a valid image file');
      return;
    }

    if (file.size > 1000000) {
      toaster.error('Image must be smaller than 1 MB');
      return;
    }

    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      toaster.error('Image must be a JPG, PNG, or GIF');
      return;
    }

    setProfileImage(URL.createObjectURL(file));
    setImportFlowStage('crop');
  };

  const handleOnCancel = () => {
    setProfileImage(null);
    setImportFlowStage('upload');
    onClose();
  };

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setImportFlowStage('upload');
        setProfileImage(null);
      }, 500);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      className="upload-profile-image-modal"
      title={
        <div className="d-flex align-items-center">Upload Profile Image</div>
      }
      onHide={onClose}
      primaryBtnLabel={
        importFlowStage === 'upload' ? 'Upload Image' : 'Save & Continue'
      }
      secondaryBtnLabel="Cancel"
      primaryBtnClickedHandler={handleOnSubmit}
      secondaryBtnClickedHandler={handleOnCancel}
      primaryBtnIcon={importFlowStage === 'upload' ? Upload : null}
      primaryBtnIconPlacement="left"
      primaryBtnLoading={isUploading}
      primaryBtnDisabled={isUploading}
      hideFooter={false}
    >
      {importFlowStage === 'upload' && (
        <>
          <input
            type="file"
            accept="image/*"
            hidden
            ref={inputRef}
            onChange={handleOnChange}
          />
          <div className="upload-profile-image-container">
            <div className="avatar-section">
              <img src={Images.NoGenderAvatar} alt="Default Avatar" />
            </div>

            <div className="info-section">
              <p className="image-note">
                We recommend a 512x512px JPG or PNG image of your headshot (Max
                1MB)
              </p>
              <div className="alert-box">
                <div className="alert-content">
                  <InfoCircle width={16} height={16} className="icon-info" />

                  <p className="alert-note">
                    Profile image cannot be changed, once email accounts are
                    created.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {importFlowStage === 'crop' && profileImage && (
        <div className="profile-image-crop-container">
          <Cropper
            ref={cropperRef}
            src={profileImage}
            style={{ height: 400, width: '100%' }}
            aspectRatio={1}
            guides={true}
            viewMode={1}
            dragMode="move"
            background={false}
          />
        </div>
      )}
    </Modal>
  );
};

export default UploadProfileImageModal;
