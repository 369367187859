import { find } from 'lodash';
import { EmailAccountInfrastructurePlanType } from '../../../enums/create-email-infrastructure';
import {
  CreateEmailAccountInfrastructureCart,
  DomainPlan,
  EmailAccountType,
} from '../../../types/create-email-infrastructure';

/**
 * Generates default email accounts for each domain in the cart that doesn't already have accounts.
 * It uses the provided first and last names to create email patterns and adds them to the cart.
 *
 * @param firstName - The first name to use in email patterns.
 * @param lastName - The last name to use in email patterns.
 * @param emailAccountInfrastructureCart - The cart containing domains and existing email accounts.
 * @param domainPlans - The available domain plans with pricing information.
 * @param addEmailAccountToEmailAccountInfrastructureCart - Function to add new email accounts to the cart.
 */
export const generateDefaultEmailAccountsForDomains = (
  firstName: string,
  lastName: string,
  emailAccountInfrastructureCart: CreateEmailAccountInfrastructureCart,
  domainPlans: DomainPlan[],
  addEmailAccountToEmailAccountInfrastructureCart: (
    payload: EmailAccountType[],
  ) => void,
) => {
  const { domains, emailAccounts } = emailAccountInfrastructureCart;
  const updatedEmailAccounts: EmailAccountType[] = [];

  // Find the quarterly price for the InboxSetup plan type
  const emailAccountQuarterlyPrice =
    find(domainPlans, {
      planType: EmailAccountInfrastructurePlanType.InboxSetup,
    })?.amount || 0;

  // Calculate the monthly price from the quarterly price
  const emailAccountPrice = Number(emailAccountQuarterlyPrice) / 3;

  // Iterate over each domain in the cart
  domains.forEach((domain) => {
    // Check if the domain already has email accounts
    const domainHasAccounts = emailAccounts.some(
      (account) => account.email.split('@')[1] === domain.name,
    );

    // If the domain doesn't have accounts, generate new email patterns
    if (!domainHasAccounts) {
      const emailPatterns = [];

      // Add standard patterns if names are long enough
      if (firstName.length > 1 && lastName.length > 1) {
        emailPatterns.push(
          `${firstName.toLowerCase()}.${lastName.toLowerCase()}@${domain.name}`,
          `${lastName.toLowerCase()}.${firstName.toLowerCase()}@${domain.name}`,
          `${firstName.toLowerCase()}.${lastName.charAt(0).toLowerCase()}@${
            domain.name
          }`,
        );
      } else {
        // Add simplified patterns for short names
        emailPatterns.push(
          `${firstName.charAt(0).toLowerCase()}.${lastName
            .charAt(0)
            .toLowerCase()}@${domain.name}`,
          `${firstName.charAt(0).toLowerCase()}@${domain.name}`,
        );
      }

      // Add new email accounts to the updated list
      emailPatterns.forEach((emailAddress) => {
        updatedEmailAccounts.push({
          email: emailAddress,
          firstName,
          lastName,
          profile: null,
          price: emailAccountPrice,
        });
      });
    }
  });

  // Add the newly generated email accounts to the cart
  addEmailAccountToEmailAccountInfrastructureCart(updatedEmailAccounts);
};
