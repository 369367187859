import React from 'react';

import Modal from '../../../shared/design-system/components/atoms/modal';
import { Images } from '../../../shared/app-constants';
import { accessibleOnClick } from '../../../shared/utils/accessible-on-click';
import ImageIcon from '../../../shared/components/images/image-icon';
import { ProductTour } from '../../../shared/enums/product-tour';
import { EmailAccountInfrastructureServiceProvider } from '../enums/create-email-infrastructure';

export type IProps = {
  show: boolean;
  onSubmit: (payload: EmailAccountInfrastructureServiceProvider) => void;
  onClose: () => void;
};

const EmailAccountService = ({
  id,
  logo,
  serviceName,
  serviceDetails,
  onClick,
}) => (
  <div id={id} className="service-card" {...accessibleOnClick(onClick)}>
    <div className="service-card-logo">
      <ImageIcon src={logo} />
    </div>

    {serviceName && <div className="service-card-title">{serviceName}</div>}
    {serviceDetails && (
      <div className="service-card-subtitle">{serviceDetails}</div>
    )}
  </div>
);

const ComingSoonEmailAccountService = ({ id, logo, serviceName }) => (
  <div id={id} className="coming-soon-service-card">
    <div className="coming-soon-service-card-logo">
      <ImageIcon src={logo} />
    </div>

    {serviceName && (
      <div className="coming-soon-service-card-title">{serviceName}</div>
    )}
    <div className="coming-soon-tag">Coming Soon!</div>
  </div>
);

const CreateNewSendingInfrastructureModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
}) => (
  <>
    <Modal
      show={show}
      className="create-new-sending-infrastructure-modal"
      titleContent="Create new sending infrastructure"
      onClose={onClose}
      showCloseIcon={true}
      hideFooter={true}
      backdrop="static"
      extraModalProps={{
        'aria-labelledby': 'contained-modal-title-vcenter',
        centered: true,
      }}
    >
      <>
        <div className="email-account-message regular-2">
          Build sending infra by acquiring domains and email accounts in
          minutes.
        </div>
        <div
          id={ProductTour.SelectEmailService}
          className="email-account-method-wrap"
        >
          <EmailAccountService
            id="google"
            logo={Images.Google30x30}
            serviceName="Google"
            serviceDetails="Google Workspace"
            onClick={() =>
              onSubmit(EmailAccountInfrastructureServiceProvider.Google)
            }
          />
          <ComingSoonEmailAccountService
            id="microsoft"
            logo={Images.MicrosoftDisabled}
            serviceName="Microsoft"
          />
          <ComingSoonEmailAccountService
            id="maidoso"
            logo={Images.MaildosoDisabled}
            serviceName="Maidoso"
          />
        </div>
        <div className="email-account-note regular-2 mt-4">
          Note: We don't store your mailbox data, we manage technical setup and
          connection with Saleshandy.
        </div>
      </>
    </Modal>
  </>
);

export default CreateNewSendingInfrastructureModal;
