export enum EmailAccountInfrastructureServiceProvider {
  Google = 'Google',
  Microsoft = 'Microsoft',
  MailDoso = 'MailDoso',
}

export enum EmailInfrastructureStep {
  PurchaseDomains = 'purchase-domains',
  CreateEmailAccounts = 'create-email-accounts',
  ContactDetails = 'contact-details',
}

export enum EmailAccountInfrastructurePlanType {
  InboxSetup = 'inbox-setup',
  DomainSetup = 'domain-setup',
}
