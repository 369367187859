import React, { useMemo } from 'react';
import Modal from '../../../shared/design-system/ui/modal';
import Button from '../../../shared/design-system/ui/button';
import { supportUrls } from '../../../shared/utils/urls';
import { CreateEmailAccountInfrastructureCart } from '../types/create-email-infrastructure';
import ConnectEmailAccountRestriction from '../components/connect-email-account-restriction';

interface BillingSummaryModalProps {
  show: boolean;
  onSubmit: () => void;
  onClose: () => void;
  isLoading: boolean;
  emailAccountInfrastructureCart: CreateEmailAccountInfrastructureCart;
}

const BillingSummaryModal: React.FC<BillingSummaryModalProps> = ({
  show,
  onSubmit,
  onClose,
  isLoading,
  emailAccountInfrastructureCart,
}) => {
  const domainSubTotal = useMemo(
    () =>
      emailAccountInfrastructureCart.domains.reduce(
        (total, domain) => total + Number(domain.price),
        0,
      ),
    [emailAccountInfrastructureCart.domains],
  );

  const emailAccountsSubTotal = useMemo(
    () =>
      emailAccountInfrastructureCart.emailAccounts.reduce(
        (total, emailAccount) => total + Number(emailAccount.price),
        0,
      ) * 3,
    [emailAccountInfrastructureCart.emailAccounts],
  );

  const totalAmount = useMemo(() => domainSubTotal + emailAccountsSubTotal, [
    domainSubTotal,
    emailAccountsSubTotal,
  ]);

  return (
    <Modal
      show={show}
      className="billing-summary-confirmation-modal"
      title={
        <div className="d-flex align-items-center gap-10">
          <span className="line-height-1">Billing Summary</span>
        </div>
      }
      onHide={onClose}
      showCloseButton={true}
      hideFooter={true}
    >
      <>
        <div className="billing-summary">
          <div className="pricing">
            <div className="price-details">
              <div className="price-list">
                <div className="price-item">
                  <p className="label">Sub Total</p>
                  <p className="value">{`$${totalAmount.toFixed(0)}`}</p>
                </div>
                <div className="price-item">
                  <p className="label">Domains (per year)</p>
                  <p className="value">{`$${domainSubTotal.toFixed(0)}`}</p>
                </div>
                <div className="price-item">
                  <p className="label">Email Accounts (per quarter)</p>
                  <p className="value">
                    {`$${emailAccountsSubTotal.toFixed(0)}`}
                  </p>
                </div>
              </div>
              <div className="price-details-divider" />
            </div>

            <div className="total-section">
              <div className="total-row">
                <div className="total-label">Total Amount to pay today</div>
                <div className="total-value">{`$${totalAmount.toFixed(
                  0,
                )}`}</div>
              </div>

              <div className="policy-text">
                By purchasing, you agree to our{' '}
                <a
                  href={supportUrls.refundPolicy}
                  target="_blank"
                  rel="noreferrer"
                  className="refund-policy-link"
                >
                  refund policy
                </a>
                .
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="footer-divider" />
            <ul className="policy-list">
              <li>Email Accounts auto-renew quarterly.</li>
              <li>Mid-quarter deletions are non-refundable.</li>
              <li>
                Additional email accounts cannot be added to an existing domain
                after purchase.
              </li>
              <li>
                Email Account credentials are not provided to prevent multiple
                logins and avoid IP/mailbox blocking.
              </li>
              <li>Email Account names cannot be changed once added.</li>
            </ul>

            <ConnectEmailAccountRestriction handleOnSubmit={onSubmit}>
              {({ isLoading: isSubmitButtonLoading, onClick }) => (
                <Button
                  className="pay-now"
                  onClick={onClick}
                  isLoading={isLoading || isSubmitButtonLoading}
                  disabled={isLoading || isSubmitButtonLoading}
                  loadingText="Processing..."
                >
                  Pay Now
                </Button>
              )}
            </ConnectEmailAccountRestriction>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default BillingSummaryModal;
