import React from 'react';

export const Microsoft = ({ width = 16, height = 16 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_29244_5901)">
      <path d="M0 0H14V14H0V0Z" fill="#F3F3F3" />
      <path
        d="M0.608643 0.608688H6.6956V6.69564H0.608643V0.608688Z"
        fill="#F35325"
      />
      <path
        d="M7.30444 0.608688H13.3914V6.69564H7.30444V0.608688Z"
        fill="#81BC06"
      />
      <path
        d="M0.608643 7.30435H6.6956V13.3913H0.608643V7.30435Z"
        fill="#05A6F0"
      />
      <path
        d="M7.30444 7.30435H13.3914V13.3913H7.30444V7.30435Z"
        fill="#FFBA08"
      />
    </g>
    <defs>
      <clipPath id="clip0_29244_5902">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
