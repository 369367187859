import React from 'react';

import { Button } from '@saleshandy/design-system';
import Input from '../../../../../shared/design-system/components/input';
import { DOMAIN_SEARCH_REGEX } from '../../../helpers/purchase-domain-tab-helpers';

interface NumberCircleProps {
  num: string;
}

const NumberCircle: React.FC<NumberCircleProps> = ({ num }) => (
  <span className="number-circle">{num}</span>
);

interface SearchDomainsProps {
  onSearchClick: (domain: string) => void;
  isLoading: boolean;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const SearchDomains: React.FC<SearchDomainsProps> = ({
  onSearchClick,
  isLoading,
  search,
  setSearch,
}) => {
  const handleOnSearch = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    onSearchClick(search);
  };

  const isSearchValid = DOMAIN_SEARCH_REGEX.test(search.trim());

  return (
    <form className="search-bar-container" onSubmit={handleOnSearch}>
      <div className="input-container">
        <Input
          placeholder="Enter Primary Domain (e.g., domain.com)"
          value={search}
          onChange={(
            value?: string,
            event?: React.ChangeEvent<HTMLInputElement>,
          ) => setSearch(event.target.value)}
        />
        <Button
          type="submit"
          className="search-button"
          disabled={!isSearchValid || isLoading}
          isLoading={isLoading}
          loadingText="Searching..."
        >
          Search
        </Button>
      </div>
      <div className="info-container">
        <span className="info-item">
          <NumberCircle num="1" /> Performance optimized setup is 5 domains and
          4 mailboxes for each domain
        </span>
        <span className="info-item">
          <NumberCircle num="2" /> Best deliverability and cheapest domains are
          .com
        </span>
      </div>
    </form>
  );
};

export default SearchDomains;
