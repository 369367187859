import React from 'react';
import { ForwardRef } from '../../../types/forward-ref';

enum StrokeLinecap {
  Butt = 'butt',
  Round = 'round',
  Square = 'square',
  Inherit = 'inherit',
}

type CircularProgressBarProps = {
  percentage: number;
  strokeWidth?: number;
  strokeLinecap?: StrokeLinecap;

  pathColor?: string;
  trailColor?: string;

  width?: string | number;
  height?: string | number;

  text: string;
  textColor?: string;
  textFontSize?: string;
  textFontWeight?: string;
};

type CircularProgressBarStatic = {
  StrokeLinecap: typeof StrokeLinecap;
};

const CircularProgressBar = React.forwardRef<
  SVGElement,
  CircularProgressBarProps
>(
  ({
    percentage = 0,
    strokeWidth = 0,
    strokeLinecap,
    pathColor,
    trailColor,
    width = 0,
    height = 0,
    text,
    textColor,
    textFontSize,
    textFontWeight,
  }) => {
    const radius = 50 - strokeWidth / 2;
    const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

    const diameter = Math.PI * 2 * radius;
    const progressStyle = {
      stroke: pathColor,
      strokeLinecap,
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
      transition: 'stroke-dashoffset 0.5s ease-in-out',
    };

    return (
      <svg
        className="circular-progressbar"
        viewBox="0 0 100 100"
        width={width}
        height={height}
      >
        <path
          className="circular-progressbar-trail"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={{
            stroke: trailColor,
          }}
        />

        <path
          className="circular-progressbar-path"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={progressStyle}
        />

        <text
          className="circular-progressbar-text"
          x={50}
          y={50}
          style={{
            fill: textColor,
            fontSize: textFontSize,
            fontWeight: textFontWeight,
            dominantBaseline: 'central',
            textAnchor: 'middle',
          }}
        >
          {text}
        </text>
      </svg>
    );
  },
) as ForwardRef<SVGElement, CircularProgressBarProps> &
  CircularProgressBarStatic;

CircularProgressBar.displayName = 'CircularProgressBar';

CircularProgressBar.StrokeLinecap = StrokeLinecap;

CircularProgressBar.defaultProps = {
  strokeWidth: 8,
  strokeLinecap: StrokeLinecap.Round,
  pathColor: '#0137fc',
  trailColor: '#d6d6d6',
  width: 100,
  height: 100,
  textColor: '#0137fc',
  textFontSize: '18px',
};

export default CircularProgressBar;
