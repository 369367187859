import React, { ReactNode } from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AlertTriangle, CircleCheck } from '@saleshandy/icons';
import classNames from 'classnames';

interface Column {
  title: string | ReactNode;
  dataIndex: string;
  key: string;
  render?: (cell, row) => ReactNode;
  sticky?: boolean;
  align?: string;
}

interface DataTableProps {
  columns: Column[];
  data: any[];
  className?: string;
}

const DataTable: React.FC<DataTableProps> = ({
  columns,
  data,
  className = ''
}) => {
  const { t } = useTranslation();

  const renderCell = (column: Column, record: any) => {
    // If column has a custom render function, use it
    if (column.render) {
      return column.render(record[column.dataIndex], record);
    }

    // Handle status column specially (keeping existing logic)
    if (column.dataIndex === 'status') {
      return record[column.dataIndex] === 'listed' ? (
        <span>
          <AlertTriangle className='mr-2' color='#B91C1C' />
          Listed
        </span>
      ) : (
        <span>
          <CircleCheck className='mr-2' color='#047857' />
          Ok
        </span>
      );
    }

    // Return the raw value for normal cells
    return record[column.dataIndex];
  };

  const getCellClassName = (column: Column, index: number) => classNames('table-cell', {
    'sticky-column': column.sticky,
    'border-left-none': index > 0 && columns[index - 1]?.sticky,
    'text-left': column.align === 'left',
    'text-center': column.align === 'center',
    'text-right': column.align === 'right'
  });

  return (
    <div className="table-container">
      <Table responsive className={`mb-0 ${className}`}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th 
                key={column.key} 
                className={getCellClassName(column, index)}
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, index) => (
                  <td
                    key={`${rowIndex}-${column.key}`}
                    className={getCellClassName(column, index)}
                  >
                    {renderCell(column, row)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns?.length} className="empty-message">
                {t('labels.no_data_available')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DataTable; 