import React, { useState } from 'react';
import { Cross } from '@saleshandy/icons';

import { Domain, Mailbox } from '../types';

import {
  OverlayPopover,
  Placement,
} from '../../../shared/design-system/components/overlay';

type DomainsMailboxesPopoverProps = {
  row: Domain;
};

const DomainsMailboxesPopover: React.FC<DomainsMailboxesPopoverProps> = ({
  row,
}) => {
  const [showPopover, setShowPopover] = useState(false);

  const renderMailbox = (mailbox: Mailbox) => (
    <div className="domains-mailbox-item" key={mailbox.id}>
      <img
        src={mailbox.profilePic}
        alt="Profile"
        className="domains-mailbox-item__profile"
      />
      <p className="domains-mailbox-item__email">{mailbox.email}</p>
    </div>
  );

  return (
    <OverlayPopover
      show={showPopover}
      onToggle={setShowPopover}
      className="domains-mailboxes-popover"
      content={
        <>
          <div className="domains-mailboxes">
            {row.mailboxes.map((mailbox) => renderMailbox(mailbox))}
          </div>
          <Cross
            className="cursor-pointer"
            color="#1F2937"
            onClick={() => setShowPopover(false)}
          />
        </>
      }
      placement={Placement.AutoStart}
      rootClose
    >
      <span
        className={`table-cell-text domains-mailboxes-count ${
          showPopover ? 'active' : ''
        }`}
      >
        {row.mailboxes?.length ?? '-'}
      </span>
    </OverlayPopover>
  );
};

export default DomainsMailboxesPopover;
