import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import { DomainSearchRequestPayload } from '../../../../types/request-payload';

import { getDomainSearchRequest } from '../../../../extra-actions';
import {
  addDomainToEmailAccountInfrastructureCart,
  removeDomainFromEmailAccountInfrastructureCart,
  resetGetDomainSearchRequest,
  setEmailAccountInfrastructureForwardingDomain,
} from '../../../../email-accounts-slice';
import PurchaseDomainsTab from './purchase-domains-tab';
import { Domain } from '../../../../types/create-email-infrastructure';

const mapStateToProps = (state: RootState) => ({
  domains: state.emailAccount.domains,
  domainPlans: state.emailAccount.domainPlans,
  emailAccountInfrastructureCart:
    state.emailAccount.emailAccountInfrastructureCart,
  emailAccountInfrastructureServiceProvider:
    state.emailAccount.emailAccountInfrastructureServiceProvider,

  getDomainSearchRequestStatus:
    state.emailAccount.getDomainSearchRequest.status,
  getDomainSearchRequestMessage:
    state.emailAccount.getDomainSearchRequest.message,
  emailAccountInfrastructureForwardingDomain:
    state.emailAccount.emailAccountInfrastructureForwardingDomain,
});

const mapDispatchToProps = {
  sendDomainSearchRequest: (payload: DomainSearchRequestPayload) =>
    getDomainSearchRequest(payload),
  resetGetDomainSearchRequest: () => resetGetDomainSearchRequest(),
  updateEmailAccountInfrastructureForwardingDomain: (payload: string) =>
    setEmailAccountInfrastructureForwardingDomain(payload),
  addDomainToCart: (payload: Domain) =>
    addDomainToEmailAccountInfrastructureCart(payload),
  removeDomainFromCart: (payload: string) =>
    removeDomainFromEmailAccountInfrastructureCart(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  showSetForwardingDomainModal: boolean;
  setShowSetForwardingDomainModal: (value: boolean) => void;
  handleNextButtonClickOnPurchaseDomains: () => void;
};

export default connector(PurchaseDomainsTab);
