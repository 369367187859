/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import { Button } from '@saleshandy/design-system';
import { Bulb, Check, InfoCircle, Play, Star } from '@saleshandy/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import type { IProps } from './create-connect-email-account-container';

import { SMTPIMAP } from '../../enums/smtp-or-imap';
import { EmailAccountMethod } from '../../enums/email-account';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { EmailAccountInfrastructureServiceProvider } from '../../enums/create-email-infrastructure';

import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';

import ErrorLogModal from '../email-accounts-content/components/error-log-modal';
import CreateNewSendingInfrastructureModal from '../../modals/add-sending-infrastructure-modal';
import AddEmailAccountModal from '../email-accounts-content/components/add-email-account-modal';
import AddBulkImportModal from '../email-accounts-content/components/add-email-bulk-import-modal';
import ConnectSMTPIMAPAccountModal from '../email-accounts-content/components/connect-smtp-imap-account-modal';
import ConnectEmailAccountRestriction from '../connect-email-account-restriction';

import hasResource from '../../../../shared/utils/access-control/has-resource';
import planPermissionError from '../../../../shared/utils/errors/plan-permission-error';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import { getEmailAccountId } from '../email-accounts-content/components/update-email-account/helpers';
import { Images } from '../../../../shared/app-constants';
import { isFreePlan } from '../../../../shared/utils/plans';
import {
  executeOnRequestStatus,
  redirectWithRefresh,
} from '../../../../shared/utils';
import { showToasterWithErrorCheck } from '../email-accounts-content/utils/helpers';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import toaster from '../../../../shared/toaster';
import {
  clearAllEmailAccountInfrastructureKeysFromLocalStorage,
  getEmailAccountInfrastructureCart,
  getEmailAccountInfrastructureLastUpdatedAt,
  getEmailAccountInfrastructureServiceProvider,
} from '../../helpers/email-accounts-infra-local-storage';
import { getUserEmailFromStore } from '../../../home/utils/helper';
import EmbeddedVideoModal from '../../modals/embedded-video-modal';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import { supportUrls } from '../../../../shared/utils/urls';

const CreateConnectEmailAccount: React.FC<IProps> = ({
  setEmailAccountInfrastructureServiceProvider,

  connectEmailAccountRequestStatus,
  getTestSMTPConnectionRequestStatus,
  getTestIMAPConnectionRequestStatus,
  getConnectSmtpImapRequestStatus,
  sendUpdateSmtpImapAccountRequestStatus,
  agencyConfig,
  sendConnectEmailAccountRequest,
  authUrl,
  connectEmailAccountRequestError,
  userEmail,
  sendConnectSmtpImapResponse,
  getConnectSmtpImapRequestMessage,

  importEmailAccountCsvRequestStatus,
  importEmailAccountCsvRequestMessage,
  resetImportEmailAccountCsvRequest,
  resetSmtpIMapConnectResponse,
  resetEmailAccountInfrastructure,
  setEmailAccountInfrastructureDataFromLS,
}) => {
  const [
    showAddNewEmailInfrastructureModal,
    setShowAddNewEmailInfrastructureModal,
  ] = useState(false);

  const [
    showConnectAndSaveSmtpImapAccountModel,
    setShowConnectAndSaveSmtpImapAccountModel,
  ] = useState(false);

  const [
    isEmbeddedVideoModalVisible,
    setIsEmbeddedVideoModalVisible,
  ] = React.useState<boolean>(false);

  const [showBulkModal, setShowBulkModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [isErrorLogModalVisible, setIsErrorLogModalVisible] = useState(false);
  const [errorLogAccountType, setErrorLogAccountType] = useState(SMTPIMAP.SMTP);
  const [errorLog, setErrorLog] = useState('');
  const [embeddedVideoInfo, setEmbeddedVideoInfo] = useState<{
    url: string;
    title: string;
  }>({ url: '', title: '' });

  const history = useHistory();
  const { t } = useTranslation();

  const { search } = window.location;
  const params = new URLSearchParams(search);

  const hashId = params.get('hashId');
  const emailAccountId = hashId && getEmailAccountId(hashId);
  const showAddEmailAccountModal = params.get('showAddEmailAccountModal');
  const showAddSmtpImapAccountModal = params.get('connect-smtp');

  const featuresGroup1 = [
    'We set up your accounts',
    'Automatic reconnects',
    'US IPs only',
  ];

  const featuresGroup2 = [
    'Email deliverability optimized',
    'SPF, DMARC & DKIM set accounts',
    'Starting at $4/mo',
  ];

  const FeatureItem = ({ text }) => (
    <li className="feature-item">
      <Check />
      <span className="feature-text">{text}</span>
    </li>
  );

  const FeaturesList = ({ features }) => (
    <div className="features-group">
      {features.map((feature, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FeatureItem key={index} text={feature} />
      ))}
    </div>
  );

  const openAddNewEmailInfrastructureModal = () => {
    setShowAddNewEmailInfrastructureModal(true);
  };

  const closeAddNewEmailInfrastructureModal = () => {
    setShowAddNewEmailInfrastructureModal(false);
  };

  const handleCreateEmailInfraClick = () => {
    const serviceProvider = getEmailAccountInfrastructureServiceProvider();
    const lastUpdatedAt = getEmailAccountInfrastructureLastUpdatedAt();
    const cart = getEmailAccountInfrastructureCart();
    const userEmailFromLS = getUserEmailFromStore();

    if (
      serviceProvider &&
      lastUpdatedAt &&
      userEmailFromLS === userEmail &&
      cart?.domains?.length > 0
    ) {
      const now = Date.now();
      const lastUpdatedTime = new Date(lastUpdatedAt).getTime();

      if (now - lastUpdatedTime < 10 * 60 * 1000) {
        setEmailAccountInfrastructureDataFromLS();
        history.push({
          pathname: '/email-accounts/create-email-accounts',
          state: {
            openCart: true,
          },
        });
      } else {
        clearAllEmailAccountInfrastructureKeysFromLocalStorage();
        resetEmailAccountInfrastructure();
        openAddNewEmailInfrastructureModal();
      }
    } else {
      clearAllEmailAccountInfrastructureKeysFromLocalStorage();
      resetEmailAccountInfrastructure();
      openAddNewEmailInfrastructureModal();
    }
  };

  const handleOnSubmit = (
    payload: EmailAccountInfrastructureServiceProvider,
  ) => {
    setEmailAccountInfrastructureServiceProvider(payload);
    history.push('/email-accounts/create-email-accounts');
  };

  const hideAddEmailAccountModal = () => {
    setEmailModal(false);
  };

  const hideConnectAndSaveSmtpImapAccountModel = () => {
    setShowConnectAndSaveSmtpImapAccountModel(false);
  };

  const hideAddBulkImportModal = () => {
    setShowBulkModal(false);
  };

  const hideErrorLogModal = () => {
    setIsErrorLogModalVisible(false);
  };

  const handlerToShowConnectAndSaveSmtpImapAccountModel = () => {
    if (
      !hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
      isFreePlan()
    ) {
      const title = 'Please upgrade to enable the sequence';
      const content = [
        'Your account is downgraded to a free plan. Upgrade to a paid plan to enable the sequence and resume email sending.',
      ];

      planPermissionError.showModal(title, content, true);
      return;
    }

    setShowConnectAndSaveSmtpImapAccountModel(true);
    setEmailModal(false);
  };

  const handlerToOpenCSVAccountModal = () => {
    setShowBulkModal(true);
    setEmailModal(false);
  };

  function addEmailAccountHandler(method: EmailAccountMethod) {
    hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
      sendConnectEmailAccountRequest(method, emailAccountId);
  }

  function showErrorLogModal(accountType: SMTPIMAP, errorLogVal: string) {
    setIsErrorLogModalVisible(true);

    setErrorLogAccountType(accountType);

    setErrorLog(errorLogVal);
  }

  const handleLearnMoreClick = (data: { url: string; title: string }) => {
    setEmbeddedVideoInfo(data);
    setIsEmbeddedVideoModalVisible(true);
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: connectEmailAccountRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(authUrl);
      },
      onFailed: () => {
        showToasterWithErrorCheck(connectEmailAccountRequestError);
      },
    });
  }, [connectEmailAccountRequestStatus]);

  // Import Email Account Via CSV
  useEffect(() => {
    executeOnRequestStatus({
      status: importEmailAccountCsvRequestStatus,
      onSuccess: () => {
        toaster.success(importEmailAccountCsvRequestMessage);
        resetImportEmailAccountCsvRequest();
        hideAddBulkImportModal();
        history.push('/email-accounts');
      },
      onFailed: () => {
        hideAddBulkImportModal();
        resetImportEmailAccountCsvRequest();
      },
    });
  }, [importEmailAccountCsvRequestStatus]);

  // Connect SMTP IMAP Account
  useEffect(() => {
    executeOnRequestStatus({
      status: getConnectSmtpImapRequestStatus,
      onSuccess: () => {
        if (
          sendConnectSmtpImapResponse?.smtpConnection
            ?.isConnectionEstablished &&
          sendConnectSmtpImapResponse?.imapConnection?.isConnectionEstablished
        ) {
          hideConnectAndSaveSmtpImapAccountModel();
          toaster.success(getConnectSmtpImapRequestMessage);
          history.push('/email-accounts');
          resetSmtpIMapConnectResponse();
        }
      },
      onFailed: () => {
        showToasterWithErrorCheck(getConnectSmtpImapRequestMessage);
      },
    });
  }, [getConnectSmtpImapRequestStatus]);

  useEffect(() => {
    if (showAddEmailAccountModal) {
      setEmailModal(true);
    }

    if (showAddSmtpImapAccountModal) {
      setShowConnectAndSaveSmtpImapAccountModel(true);
    }
  }, []);

  const isConnectEmailAccountRequestPending =
    connectEmailAccountRequestStatus === RequestStatus.Pending;

  return (
    <div className="main-container">
      <div className="create-connect-email-account-container">
        <div className="email-section-one">
          <img
            src={Images.EmailConfiguration}
            alt="Connect existing email accounts"
            width={72}
            height={72}
            className="p-1"
          />
          <p className="create-connect-email-account-title">
            Connect existing email accounts
          </p>
          <p className="create-connect-email-account-description">
            Connect Existing Google Workspace, Microsoft, Sendgrid or others via
            SMTP
          </p>
          <div
            className="create-connect-email-account-learn-more"
            {...accessibleOnClick(() =>
              handleLearnMoreClick({
                url: supportUrls.connectEmailAccountsVideoURL,
                title: 'Connect Email Accounts Video',
              }),
            )}
          >
            Learn More
            <Play />
          </div>

          <ConnectEmailAccountRestriction
            handleOnSubmit={() => {
              setEmailModal(true);
            }}
          >
            {({ isLoading, onClick }) => (
              <Button
                className="email-section-one-btn"
                onClick={onClick}
                disabled={
                  !hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT)
                }
                isLoading={isLoading}
                loadingText="Connecting..."
              >
                Connect Email Accounts
              </Button>
            )}
          </ConnectEmailAccountRestriction>
        </div>

        {hasPermission(Permissions.DOMAIN_READ) && (
          <>
            <div className="vertical-divider" />

            <div className="email-section-two">
              <img
                src={Images.CreateNewEmail}
                alt="Create new Sending infrastructure"
                width={72}
                height={72}
                className="p-1"
              />
              <p className="create-connect-email-account-title">
                Create new sending infrastructure
                <OverlayTooltip
                  placement={Placement.Bottom}
                  text="Create hundreds of domains & email accounts with improved deliverability and automated setup."
                >
                  <InfoCircle width={16} height={16} />
                </OverlayTooltip>
              </p>
              <p className="create-connect-email-account-description">
                Quickly set up sending infra using alternate lookalike domains!
              </p>
              <div
                className="create-connect-email-account-learn-more"
                {...accessibleOnClick(() =>
                  handleLearnMoreClick({
                    url: supportUrls.emailInfrastructureVideoURL,
                    title: 'Create New Email Infrastructure Video',
                  }),
                )}
              >
                Learn More
                <Play />
              </div>
              <Button
                className="email-section-two-btn"
                onClick={handleCreateEmailInfraClick}
              >
                Create New Email Infra
              </Button>
              <p className="features-title">
                <Star /> Why its best suitable for you?
              </p>
              <ul className="features-list">
                <FeaturesList features={featuresGroup1} />
                <FeaturesList features={featuresGroup2} />
              </ul>
            </div>
          </>
        )}
      </div>

      {hasPermission(Permissions.DOMAIN_READ) && (
        <div className="recommendation-container">
          <Bulb width={16} height={16} className="recommendation-icon" />

          <div className="recommendation-content">
            <p className="recommendation-header">Recommendation:</p>
            <p className="recommendation-description">
              Cold emailing may harm your primary domain's reputation if
              messages are marked as spam. Protect it by using a secondary
              lookalike domain for outreach, ensuring your main domain stays
              intact. If your primary domain is 'example.com', We recommend
              using 'getexample.com' or 'examplehq.com' for your outreach.
            </p>
          </div>
        </div>
      )}

      {showAddNewEmailInfrastructureModal && (
        <CreateNewSendingInfrastructureModal
          show={showAddNewEmailInfrastructureModal}
          onSubmit={handleOnSubmit}
          onClose={closeAddNewEmailInfrastructureModal}
        />
      )}

      <AddEmailAccountModal
        show={emailModal}
        onSubmit={addEmailAccountHandler}
        onClose={hideAddEmailAccountModal}
        isRequestPending={isConnectEmailAccountRequestPending}
        t={t}
        onCSVSelect={handlerToOpenCSVAccountModal}
        onSMTPAccountSelect={handlerToShowConnectAndSaveSmtpImapAccountModel}
      />

      <ConnectSMTPIMAPAccountModal
        show={showConnectAndSaveSmtpImapAccountModel}
        onClose={hideConnectAndSaveSmtpImapAccountModel}
        isSMTPTestConnectionRequestPending={
          getTestSMTPConnectionRequestStatus === RequestStatus.Pending
        }
        isIMAPTestConnectionRequestPending={
          getTestIMAPConnectionRequestStatus === RequestStatus.Pending
        }
        isConnectAccountRequestPending={
          getConnectSmtpImapRequestStatus === RequestStatus.Pending
        }
        isUpdateSmtpImapAccountRequestPending={
          sendUpdateSmtpImapAccountRequestStatus === RequestStatus.Pending
        }
        emailAccountId={emailAccountId}
        agencyConfig={agencyConfig}
        showErrorLogModal={(accountType, eLog) =>
          showErrorLogModal(accountType, eLog)
        }
      />

      <AddBulkImportModal
        show={showBulkModal}
        onCancel={hideAddBulkImportModal}
      />

      <ErrorLogModal
        show={isErrorLogModalVisible}
        onClose={() => hideErrorLogModal()}
        accountType={errorLogAccountType}
        errorLog={errorLog}
      />

      <EmbeddedVideoModal
        show={isEmbeddedVideoModalVisible}
        onClose={() => setIsEmbeddedVideoModalVisible(false)}
        url={embeddedVideoInfo.url}
        title={embeddedVideoInfo.title}
      />
    </div>
  );
};

export default CreateConnectEmailAccount;
