export enum SpfScore {
  min = 0,
  max = 20,
}

export enum DkimScore {
  min = 0,
  max = 12,
}

export enum CustomDomainScore {
  min = 0,
  max = 8,
}

export enum DailySendingQuotaScore {
  min = 0,
  mid = 4,
  max = 10,
}

export enum DmarcScore {
  min = 0,
  max = 10,
}

export enum DomainBlacklistScore {
  min = 0,
  mid = 4,
  max = 12,
}

export enum IPBlacklistScore {
  min = 0,
  mid = 2,
  max = 8,
}

export enum MXRecordsScore {
  min = 0,
  max = 6,
}

export enum EmailAgeScore {
  min = 0,
  mid = 2,
  max = 4,
}

export enum SendingIntervalScore {
  min = 0,
  mid = 4,
  max = 10,
}
