import React, { useEffect, useMemo, useState } from 'react';

import { Upload, Pencil } from '@saleshandy/icons';
import { Button } from '@saleshandy/design-system';

import EmailAddressComponent from './email-address-component';
import Icon from '../../../../../shared/design-system/components/atoms/icon/icon';

import {
  Domain,
  EmailAccountProfile,
  EmailAccountType,
} from '../../../types/create-email-infrastructure';
import Input from '../../../../../shared/design-system/components/input';

type EmailAccountFormProp = {
  handleAddEmailAccountToTableAndCart: (
    emailAccounts: EmailAccountType[],
  ) => void;
  domainsInCart: Domain[];
  emailsPrice: string | 0;
  uploadedProfile: EmailAccountProfile | null;
  uploadProfileImageForAllEmailAccounts: boolean;
  onShowUploadProfileImageModal: (forAll: boolean) => void;
  existingEmailAccounts: EmailAccountType[];
};

const CreateEmailAccountForm: React.FC<EmailAccountFormProp> = ({
  handleAddEmailAccountToTableAndCart,
  domainsInCart,
  emailsPrice,
  uploadedProfile,
  uploadProfileImageForAllEmailAccounts,
  onShowUploadProfileImageModal,
  existingEmailAccounts,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [user, setUser] = useState('');
  const [profile, setProfile] = useState<EmailAccountProfile | null>(null);
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);

  const limitError = useMemo(() => {
    if (!user) return '';
    const offendingDomains = selectedDomains.filter((domain) => {
      const count = existingEmailAccounts.filter((emailAccount) => {
        const domainPart = emailAccount.email.split('@')[1];
        return domainPart === domain;
      }).length;
      return count >= 5;
    });
    if (offendingDomains.length > 0) {
      return `Maximum 5 email accounts allowed per domain`;
    }
    return '';
  }, [user, selectedDomains, existingEmailAccounts]);

  const handleAddClick = () => {
    let generatedEmails = [];
    const name = user;

    generatedEmails = selectedDomains.map((domain) => `${name}@${domain}`);

    const emailAccounts: EmailAccountType[] = generatedEmails.map(
      (generatedEmail) => ({
        firstName,
        lastName,
        email: generatedEmail,
        profile,
        price:
          typeof emailsPrice === 'string'
            ? parseFloat(emailsPrice)
            : emailsPrice,
      }),
    );

    handleAddEmailAccountToTableAndCart(emailAccounts);

    setFirstName('');
    setLastName('');
    setProfile(null);
    setUser('');
  };

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
    setUser(`${value}${lastName ? `.${lastName}` : ''}`.toLowerCase());
  };

  const handleLastNameChange = (value: string) => {
    setLastName(value);
    setUser(`${firstName}${value ? `.${value}` : ''}`.toLowerCase());
  };

  useEffect(() => {
    if (uploadedProfile && !uploadProfileImageForAllEmailAccounts) {
      setProfile(uploadedProfile);
    }
  }, [uploadedProfile]);

  const isAddDisabled =
    !firstName ||
    !lastName ||
    !user ||
    selectedDomains.length === 0 ||
    Boolean(limitError);

  const isProfileDisabled =
    !firstName || !lastName || !user || selectedDomains.length === 0;

  return (
    <div className="create-manual-email-account-container">
      <div className="inputs">
        <Input
          label="First name*"
          placeholder="john"
          value={firstName}
          onChange={(value?: string) => handleFirstNameChange(value || '')}
        />

        <Input
          label="Last name*"
          placeholder="doe"
          value={lastName}
          onChange={(value?: string) => handleLastNameChange(value || '')}
        />

        <div className="email-address-component-wrapper">
          <EmailAddressComponent
            domainsInCart={domainsInCart}
            user={user}
            setUser={setUser}
            selectedDomains={selectedDomains}
            setSelectedDomains={setSelectedDomains}
            className={limitError ? 'email-address-border-error' : ''}
          />
          {limitError && <div className="email-limit-error">{limitError}</div>}
        </div>

        <Button
          className={`profile-image-btn ${profile ? 'uploaded' : ''}`}
          variant="secondary"
          onClick={() => onShowUploadProfileImageModal(false)}
          disabled={isProfileDisabled}
        >
          {profile === null ? (
            <>
              <Upload />
              <span>Profile Image</span>
            </>
          ) : (
            <>
              <span>Uploaded</span>
              <Pencil />
            </>
          )}
        </Button>
        <Button
          className="add-btn"
          variant="secondary"
          onClick={() => handleAddClick()}
          disabled={isAddDisabled}
        >
          <span>Add</span>
          <Icon
            identifier="math-plus"
            className="add-icon"
            width={16}
            height={16}
          />
        </Button>
      </div>
    </div>
  );
};

export default CreateEmailAccountForm;
