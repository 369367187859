import React from 'react';

import Modal from '../../atoms/modal';
import type { IProps } from './types';

const YoutubeVideoModal: React.FC<IProps> = ({
  show,
  titleContent = 'Video tutorial',
  youtubeVideoUrl,
  onClose,
}) => (
  <Modal
    show={show}
    className="video-modal"
    showCloseIcon
    hideHeader
    hideFooter
    onClose={onClose}
    onHide={onClose}
    onCancel={onClose}
    backdrop={true}
  >
    <div className="watch-video-modal-body">
      <div className="d-flex justify-content-center align-items-center">
        <iframe
          src={youtubeVideoUrl}
          title="Saleshandy Video Tutorial"
          allow="autoplay; fullscreen"
          allowFullScreen
          allowTransparency={true}
          className="wistia_embed"
          name="wistia_embed"
          style={{
            border: 'none',
            width: '100%',
            height: '24rem',
            maxHeight: '700px',
          }}
        />
      </div>
    </div>
  </Modal>
);

export default YoutubeVideoModal;
