import React, { useMemo } from 'react';
import { Button } from '@saleshandy/design-system';
import { useHistory } from 'react-router';
import { EmailAccount } from '../../../../../types/email-account';
import { UpdateEmailTabKeys } from '../../update-email-account/types';
import { setShouldRefreshEmailSetupScoreValue } from '../../update-email-account/helpers';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { setEmailAccountsSearchQuery } from '../../../utils/helpers';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay';

type IProps = {
  emailAccount: EmailAccount;
};

const SetupScoreField: React.FC<IProps> = ({
  emailAccount: { id, hashId, healthScore },
}) => {
  const history = useHistory();

  const redirectToEmailSetupScore = () => {
    setEmailAccountsSearchQuery(history.location.search);

    history.push({
      pathname: `/email-accounts/${hashId}/${UpdateEmailTabKeys.EMAIL_SETUP_SCORE}`,
      state: {
        emailAccountId: id,
      },
    });
    setShouldRefreshEmailSetupScoreValue(healthScore);
  };

  const scoreStatus = useMemo(() => {
    if (healthScore > 50 && healthScore <= 70) {
      return 'average';
    }

    if (healthScore > 70 && healthScore <= 100) {
      return 'excellent';
    }

    return 'poor';
  }, [healthScore]);

  if (!healthScore || healthScore < 10) {
    return (
      <Button variant="link" onClick={redirectToEmailSetupScore}>
        Check New Score
      </Button>
    );
  }

  return (
    <OverlayTooltip text={'Click to know more'}>
      <div
        className={`setup-score-field ${scoreStatus}`}
        {...accessibleOnClick(redirectToEmailSetupScore)}
      >
        <span>{healthScore}</span>
      </div>
    </OverlayTooltip>
  );
};

export default SetupScoreField;
