import React from 'react';
import classNames from 'classnames';
import InputNumber from '../../../../../../../shared/design-system/components/input-number';

const DailySendingLimitInput = ({
  t,
  sendingLimit,
  changeSendingLimit,
  zeroLimitError,
  isBulkUpdatingEmailAccounts,
  maxLimit,
}) => {
  const onKeyPress = (e) => {
    if (e.key === '.' || e.key === '+' || e.key === '-' || e.key === 'e') {
      e.preventDefault();
    }
  };

  const rowClasses = classNames([
    'row',
    {
      'bulk-update-email-accounts-row': isBulkUpdatingEmailAccounts,
      'align-items-center': !isBulkUpdatingEmailAccounts,
    },
  ]);

  return (
    <div className={rowClasses}>
      <div className="col-md-8">
        <p className="total-number-title mb-1">
          {t('labels.email_sending_limit_header')}
        </p>
        {!isBulkUpdatingEmailAccounts && (
          <p className="total-number-caption m-0">
            {t('labels.email_sending_limit_caption')}
          </p>
        )}
      </div>
      <div className="sending-limit-input">
        <InputNumber
          size={InputNumber.Size.Medium}
          min={0}
          max={maxLimit}
          value={sendingLimit}
          onChange={changeSendingLimit}
          variant={zeroLimitError && InputNumber.Variant.Error}
          onKeyPress={onKeyPress}
        />
      </div>
    </div>
  );
};

export default DailySendingLimitInput;
