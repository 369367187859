import React from 'react';
import { Cross } from '@saleshandy/icons';
import { Modal as ReactBootstrapModal } from 'react-bootstrap';
import classNames from 'classnames';

import Button from '../button';

const { Header, Title, Body, Footer } = ReactBootstrapModal;

type IProps = {
  show: boolean;
  className?: string;
  title: React.ReactNode;
  onHide: VoidFunction;
  variant?: 'border' | 'with-divider';
  showCloseButton?: boolean;

  primaryBtnIcon?: React.FC;
  primaryBtnIconPlacement?: 'left' | 'right';

  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;

  primaryBtnDisabled?: boolean;
  secondaryBtnDisabled?: boolean;

  primaryBtnLoading?: boolean;
  secondaryBtnLoading?: boolean;

  primaryBtnClassName?: string;
  secondaryBtnClassName?: string;

  primaryBtnClickedHandler?: VoidFunction;
  secondaryBtnClickedHandler?: VoidFunction;

  hideFooter?: boolean;
  footer?: React.ReactNode;
};

const Modal: React.FC<IProps> = ({
  show,
  className = '',
  title = '',
  onHide,
  variant = 'with-divider',
  showCloseButton = false,
  primaryBtnIcon,
  primaryBtnIconPlacement = 'right',
  primaryBtnLabel = 'Save',
  secondaryBtnLabel = 'Cancel',
  primaryBtnDisabled = false,
  secondaryBtnDisabled = false,
  primaryBtnLoading = false,
  secondaryBtnLoading = false,
  primaryBtnClassName = '',
  secondaryBtnClassName = 'cancel-btn',
  primaryBtnClickedHandler,
  secondaryBtnClickedHandler,
  children,
  hideFooter = false,
  footer,
}) => {
  const modalHeaderClassNames = classNames([
    'shd-modal--header',
    {
      'd-flex justify-content-between align-items-center': showCloseButton,
    },
  ]);

  return (
    <ReactBootstrapModal
      show={show}
      onHide={onHide}
      className={`shd-modal ${variant} ${className}`}
      centered
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      enforceFocus={false}
    >
      <Header className={modalHeaderClassNames}>
        <Title className="shd-modal--title">{title}</Title>
        {showCloseButton && (
          <button
            type="button"
            className="shd-modal--close-btn"
            onClick={onHide}
          >
            <Cross />
          </button>
        )}
      </Header>

      <Body className="shd-modal--body">{children}</Body>

      {variant === 'with-divider' && <div className="shd-modal--divider" />}

      {!hideFooter && (
        <Footer className={`shd-modal--footer ${footer ? 'with-content' : ''}`}>
          {footer && <div className="shd-modal--footer__left">{footer}</div>}

          <div className="shd-modal--footer__right">
            <Button
              variant="secondary"
              onClick={secondaryBtnClickedHandler}
              disabled={secondaryBtnDisabled}
              isLoading={secondaryBtnLoading}
              loadingText={`${secondaryBtnLabel}...`}
              className={secondaryBtnClassName}
            >
              {secondaryBtnLabel}
            </Button>
            <Button
              variant="primary"
              onClick={primaryBtnClickedHandler}
              disabled={primaryBtnDisabled}
              isLoading={primaryBtnLoading}
              loadingText={`${primaryBtnLabel}...`}
              className={primaryBtnClassName}
              icon={primaryBtnIcon}
              iconPlacement={primaryBtnIconPlacement}
            >
              {primaryBtnLabel}
            </Button>
          </div>
        </Footer>
      )}
    </ReactBootstrapModal>
  );
};

export default Modal;
