import { useState, useCallback, useEffect } from 'react';

interface UseCopyToClipboardOptions {
  resetDelay?: number;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export const useCopyToClipboard = ({
  resetDelay = 2000,
  onSuccess,
  onError
}: UseCopyToClipboardOptions = {}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = useCallback(async (text: string) => {
    if (!navigator?.clipboard) {
      onError?.(new Error('Clipboard API not supported'));
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      onSuccess?.();
    } catch (err) {
      console.error('Failed to copy text:', err);
      onError?.(err instanceof Error ? err : new Error('Failed to copy'));
    }
  }, [onSuccess, onError]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isCopied) {
      timeoutId = setTimeout(() => setIsCopied(false), resetDelay);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isCopied, resetDelay]);

  return { isCopied, copyToClipboard };
}; 