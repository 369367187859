import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button as SaleshnadyButton } from '@saleshandy/design-system';
import { Images } from '../../app-constants';
import { openChatSupport } from '../../utils/open-chat-support';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import type { IProps } from './email-account-setup-score-container';
import { getEmailAccountSetupProgress, getLastCheckedDate } from './helper';

import SequenceScoreError from '../sequence-score-error';
import EmailAccountSetupFactors from './email-account-setup-factors';
import CircularProgressBar from '../../design-system/components/atoms/circular-progressbar';
import {
  OverlayTooltip,
  Placement,
} from '../../design-system/components/overlay';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
} from '../../utils/execute-on-request-status';
import toaster, { Theme } from '../../toaster';

const EmailAccountSetupScore: React.FC<IProps> = ({
  emailAccountSetup,
  onRefreshEmailAccountSetupScore,
  userTimezone,
  agencyConfig,
  showNeedHelpCTA = true,
  showRefreshScoreBtn = true,
  showLastUpdatedDate = true,
  showDescription = false,
  showActionableList = false,
  showProgress = false,
  showMetaData = false,
  className = '',
  emailAccountId,

  sendUpdateEmailAgeRequest,
  resetUpdateEmailAgeRequest,
  updateEmailAgeRequestStatus,
  updateEmailAgeRequestError,
  updateEmailAgeRequestMessage,
}) => {
  const { t } = useTranslation();

  const {
    scoreStatus,
    pathColor,
    trailColor,
    textColor,
  } = getEmailAccountSetupProgress(emailAccountSetup);

  const lastChecked = getLastCheckedDate(emailAccountSetup, userTimezone);

  const handleTalkToDeliverabilityExpert = () => {
    openChatSupport();
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: updateEmailAgeRequestStatus,
      onSuccess: () => {
        toaster.success(updateEmailAgeRequestMessage, { theme: Theme.New });
        resetUpdateEmailAgeRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: updateEmailAgeRequestError,
          onError: () => {
            toaster.success(updateEmailAgeRequestError.message, {
              theme: Theme.New,
            });
            resetUpdateEmailAgeRequest();
          },
        });
      },
    });
  }, [updateEmailAgeRequestStatus]);

  if (emailAccountSetup && emailAccountSetup?.total?.error?.status) {
    return <SequenceScoreError error={emailAccountSetup?.total?.error} />;
  }

  return (
    <>
      <div className={`email-account-setup-wrapper ${className}`}>
        {showProgress && (
          <div className={`email-account-setup-progress ${scoreStatus}`}>
            <CircularProgressBar
              percentage={emailAccountSetup?.total?.score || 0}
              text={(emailAccountSetup?.total?.score || 0)?.toString()}
              pathColor={pathColor}
              trailColor={trailColor}
              textColor={textColor}
              width="5rem"
              height="5rem"
            />

            {showRefreshScoreBtn && (
              <OverlayTooltip text="Refresh Score" placement={Placement.Top}>
                <div
                  className="email-account-setup-refresh"
                  {...accessibleOnClick(onRefreshEmailAccountSetupScore)}
                >
                  <img src={Images.Refresh} alt="Refresh" />
                </div>
              </OverlayTooltip>
            )}
          </div>
        )}

        {showMetaData && (
          <>
            <h3 className="email-account-setup-title">
              {t('messages.email_account_health')}
            </h3>
            {showLastUpdatedDate && (
              <p className="email-account-setup-content">
                {t('messages.last_updated_at')} {lastChecked}
              </p>
            )}
            {showDescription && (
              <p className="email-account-setup-description">
                {t('messages.email_setup_score_description')}
              </p>
            )}
          </>
        )}

        {agencyConfig?.showChatSupport &&
          showNeedHelpCTA &&
          emailAccountSetup?.total?.score < 75 && (
            <div className="email-account-setup-help">
              <div className="email-account-setup-help-content">
                <h3 className="email-account-setup-help-title">
                  {t('messages.need_help_to_improve_your_score')}
                </h3>
                <p className="email-account-setup-help-text">
                  {t('messages.need_help_content')}
                </p>
              </div>

              <SaleshnadyButton
                variant="link"
                onClick={handleTalkToDeliverabilityExpert}
              >
                {t('messages.talk_to_deliverability_expert')}
              </SaleshnadyButton>
            </div>
          )}

        <div className="email-account-setup-factor-details">
          {emailAccountSetup && (
            <EmailAccountSetupFactors
              emailAccountSetup={emailAccountSetup}
              agencyConfig={agencyConfig}
              showActionableList={showActionableList}
              sendUpdateEmailAgeRequest={sendUpdateEmailAgeRequest}
              emailAccountId={emailAccountId}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EmailAccountSetupScore;
