import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../design-system/components/atoms/modal';
import DataTable from '../../data-table/data-table';


interface FactorDetailsModalProps {
  show: boolean;
  onClose: () => void;
  factorModalConfig: {
    title: string;
    content?: React.ReactNode;
    footer?: React.ReactNode;
    columns?: {
    title: string;
    dataIndex: string;
    key: string;
    }[];
    data?: any[];
    onLearnMore?: () => void;
  };
}

const FactorDetailsModal: React.FC<FactorDetailsModalProps> = ({
  show,
  onClose,
  factorModalConfig
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const hasValidData = Array.isArray(factorModalConfig?.columns) && Array.isArray(factorModalConfig?.data);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      onClose={handleClose}
      titleContent={factorModalConfig?.title}
      backdrop="static"
      showCloseIcon={true}
      className="factor-details-modal"
      hideCancelButton={true}
      hideSubmitButton={true}
      hideFooter={factorModalConfig?.footer ? false : true}
      footerContent={factorModalConfig?.footer}
    >
      <div>
        {factorModalConfig?.content && (
          <div className={hasValidData ? 'factor-modal-content' : ''}>
            {factorModalConfig?.content}
          </div>
        )}

        {hasValidData && (
          <DataTable 
            columns={factorModalConfig.columns}
            data={factorModalConfig.data}
          />
        )}
      </div>
    </Modal>
  );
};

export default FactorDetailsModal; 