import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';

import { IProps } from './type';

import EmailAccountsContent from './components/email-accounts-content';
import UpdateEmailAccount from './components/email-accounts-content/components/update-email-account';
import CreateEmailInfrastructure from './components/create-email-infrastructure';
import CreateConnectEmailAccount from './components/create-connect-email-account';

const EmailAccounts: React.FC<IProps> = () => (
  <Container fluid className="email-accounts-container">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route
            exact
            path="/email-accounts/create-email-accounts"
            component={CreateEmailInfrastructure}
          />
          <Route
            exact
            path="/email-accounts/:hashId/:tab"
            component={UpdateEmailAccount}
          />
          <Route
            exact
            path="/email-accounts"
            component={EmailAccountsContent}
          />
          <Route
            exact
            path="/email-accounts/create"
            component={CreateConnectEmailAccount}
          />
          <Redirect to="/email-accounts" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

export default EmailAccounts;
