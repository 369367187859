import React from 'react';
import { Trash } from '@saleshandy/icons';

import Modal from '../../../shared/design-system/ui/modal';

interface DeleteDomainModalProps {
  show: boolean;
  onSubmit: () => void;
  onClose: () => void;
  isLoading: boolean;
}

const DeleteDomainModal: React.FC<DeleteDomainModalProps> = ({
  show,
  onSubmit,
  onClose,
  isLoading,
}) => (
  <Modal
    show={show}
    className="delete-domain-confirmation-modal"
    title={
      <div className="d-flex align-items-center gap-8">
        <Trash width={24} height={24} color="#1F2937" />
        <span className="line-height-1">
          Are you sure you want to delete this domain?
        </span>
      </div>
    }
    onHide={onClose}
    primaryBtnLabel="Delete"
    secondaryBtnLabel="Cancel"
    primaryBtnClickedHandler={onSubmit}
    secondaryBtnClickedHandler={onClose}
    primaryBtnDisabled={isLoading}
    primaryBtnLoading={isLoading}
  >
    <>
      <div className="section-one">
        <p>
          You will not be charged for this domain and associated email accounts
          anymore.
        </p>
        <p>
          Please keep in mind all the sequences with this email account will not
          send any further emails from this domain’s email accounts.
        </p>
      </div>
      <div className="section-two">
        <p>
          Once the domain is deleted, it cannot be reversed. Unused days of
          domains & email accounts are non-refundable.
        </p>
      </div>
    </>
  </Modal>
);

export default DeleteDomainModal;
