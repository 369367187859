import moment from 'moment-timezone';
import { ScoreFactorStatus } from '../score-factor-detail-item/types';
import {
  SpfScore,
  CustomDomainScore,
  DailySendingQuotaScore,
  DmarcScore,
  EmailAgeScore,
  SendingIntervalScore,
  DkimScore,
  DomainBlacklistScore,
  IPBlacklistScore,
  MXRecordsScore,
} from './enums';
import { EmailAccountSetup, EmailAccountSetupScore } from './types';

export const getLastCheckedDate = (
  emailAccountSetup: EmailAccountSetup,
  userTimezone: string,
): string => {
  if (emailAccountSetup && userTimezone) {
    const momentObj = moment(emailAccountSetup?.timeStamp || new Date());
    const date = momentObj.format('MMM DD');
    const year = momentObj.format('YYYY');
    const time = momentObj.format('hh:mm a').toUpperCase();
    const timezone = `GMT${momentObj.tz(userTimezone).format('Z')}`;

    return `${date}, ${year}. at ${time} ${timezone}`;
  }
  return '';
};

export const getScoreFactorStatusForSPF = (
  score: number,
): ScoreFactorStatus => {
  if (score === SpfScore.min) {
    return ScoreFactorStatus.NotValid;
  }
  return ScoreFactorStatus.Valid;
};

export const getScoreFactorStatusForCTD = (
  score: number,
): ScoreFactorStatus => {
  if (score === CustomDomainScore.min) {
    return ScoreFactorStatus.NotValid;
  }
  return ScoreFactorStatus.Valid;
};

export const getScoreFactorStatusForDSQ = (
  score: number,
): ScoreFactorStatus => {
  if (score) {
    if (score >= DailySendingQuotaScore.max) {
      return ScoreFactorStatus.Valid;
    }
  }
  return ScoreFactorStatus.PartiallyValid;
};

export const getScoreFactorStatusForDMARC = (
  score: number,
): ScoreFactorStatus => {
  if (score === DmarcScore.min) {
    return ScoreFactorStatus.NotValid;
  }
  return ScoreFactorStatus.Valid;
};

export const getScoreFactorStatusForDKIM = (score: number): ScoreFactorStatus => {
  if (score && score !== DkimScore.min) {
    return ScoreFactorStatus.Valid;
  }
  return ScoreFactorStatus.NotValid;
};

export const getScoreFactorStatusForDomainBlacklist= (
  score: number,
): ScoreFactorStatus => {
  if (score) {
    if(score === DomainBlacklistScore.max) {
      return ScoreFactorStatus.Valid;
    }
    if(score < DomainBlacklistScore.max && score > DomainBlacklistScore.mid) {
      return ScoreFactorStatus.PartiallyValid;
    }
  }
  return ScoreFactorStatus.NotValid;
};

export const getScoreFactorStatusForIPBlacklist= (
  score: number,
): ScoreFactorStatus => {
  if (score) {
    if(score === IPBlacklistScore.max) {  
      return ScoreFactorStatus.Valid;
    }
    if(score < IPBlacklistScore.max && score > IPBlacklistScore.mid) {
      return ScoreFactorStatus.PartiallyValid;
    }
  }
  return ScoreFactorStatus.NotValid;
};

export const getScoreFactorStatusForMXRecords = (
  score: number,
): ScoreFactorStatus => {
  if (score && score <= MXRecordsScore.max && score > MXRecordsScore.min) {
    return ScoreFactorStatus.Valid;
  }
  return ScoreFactorStatus.NotValid;
};

export const getScoreFactorStatusForEmailAge = (
  score: number,
): ScoreFactorStatus => {
  if(score) {
    if (score >= EmailAgeScore.max) {
      return ScoreFactorStatus.Valid;
    }
  }
  return ScoreFactorStatus.PartiallyValid;
};

export const getScoreFactorStatusForSendingInterval = (
  score: number,
): ScoreFactorStatus => {
  if (score) {
    if (score >= SendingIntervalScore.max) {
      return ScoreFactorStatus.Valid;
    }
  }
  return ScoreFactorStatus.PartiallyValid;
};

export const getEmailAccountSetupProgress = (
  emailAccountSetup: EmailAccountSetup,
) => {
  if (emailAccountSetup !== null) {
    if (emailAccountSetup?.total?.score <= 50) {
      return {
        scoreStatus: 'poor',
        pathColor: '#DC2626',
        trailColor: '#FEE2E2',
        textColor: '#DC2626',
      };
    }

    if (
      emailAccountSetup?.total?.score > 50 &&
      emailAccountSetup?.total?.score <= 70
    ) {
      return {
        scoreStatus: 'average',
        pathColor: '#F59E0B',
        trailColor: '#FEF3C7',
        textColor: '#F59E0B',
      };
    }

    if (
      emailAccountSetup?.total?.score > 70 &&
      emailAccountSetup?.total?.score <= 100
    ) {
      return {
        scoreStatus: 'excellent',
        pathColor: '#2DB482',
        trailColor: '#D1FAE5',
        textColor: '#2DB482',
      };
    }
  }
  return {
    scoreStatus: 'default',
    pathColor: '#ddd',
    trailColor: '#ddd',
    textColor: '#ddd',
  };
};

export const getEmailAccountHealthProgress = (
  emailAccountSetup: EmailAccountSetupScore,
) => {
  if (emailAccountSetup !== null) {
    if (emailAccountSetup?.totalScore <= 50) {
      return {
        scoreStatus: 'poor',
        pathColor: '#DC2626',
        trailColor: '#FEE2E2',
        textColor: '#DC2626',
      };
    }

    if (
      emailAccountSetup?.totalScore > 50 &&
      emailAccountSetup?.totalScore <= 70
    ) {
      return {
        scoreStatus: 'average',
        pathColor: '#F59E0B',
        trailColor: '#FEF3C7',
        textColor: '#F59E0B',
      };
    }

    if (
      emailAccountSetup?.totalScore > 70 &&
      emailAccountSetup?.totalScore <= 100
    ) {
      return {
        scoreStatus: 'excellent',
        pathColor: '#2DB482',
        trailColor: '#D1FAE5',
        textColor: '#2DB482',
      };
    }
  }
  return {
    scoreStatus: 'default',
    pathColor: '#ddd',
    trailColor: '#ddd',
    textColor: '#ddd',
  };
};

export const getEmailAgeDropdownSelectedKey = (score: number): string => {
  if (score === EmailAgeScore.min) {
    return '0';
  }
  if (score === EmailAgeScore.mid) {
    return '1';
  }
  return '2';
};
export const getBlacklistedCount = (blacklistData: {status: string, blacklist: string[]}[]) => {
  let blacklistedRecord = blacklistData?.filter((item) => item.status === 'listed')[0];
  return blacklistedRecord?.blacklist?.length;
}