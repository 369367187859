import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import LogoBranding from './logo-branding';
import {
  deleteResourceRequest,
  editCompanyNameRequest,
  editResourceRequest,
  getResourceRequest,
  saveCompanyNameRequest,
  uploadResourceRequest,
} from '../../extra-actions';
import { ResourceType } from '../../utils/enums';
import { resetResourceState } from '../../whitelabel-slice';
import { getIsRequestPending } from '../../../../../../shared/utils';

const mapStateToProps = (state: RootState) => ({
  isLoading: getIsRequestPending(state.whitelabel.getResourceRequest.status),
  getResourceRequestStatus: state.whitelabel.getResourceRequest.status,
  resources: state.whitelabel.resources,
  saveCompanyNameRequestStatus: state.whitelabel.saveCompanyNameRequest.status,
  saveCompanyNameRequestMessage:
    state.whitelabel.saveCompanyNameRequest.message,
  editCompanyNameRequestStatus: state.whitelabel.editCompanyNameRequest.status,
  editCompanyNameRequestMessage:
    state.whitelabel.editCompanyNameRequest.message,
  saveResourceRequestStatus: state.whitelabel.uploadResourceRequest.status,
  deleteResourceRequestStatus: state.whitelabel.deleteResourceRequest.status,
  uploadResourceRequestStatus: state.whitelabel.uploadResourceRequest.status,
  uploadResourceRequestMessage: state.whitelabel.uploadResourceRequest.message,
  editResourceRequestStatus: state.whitelabel.editResourceRequest.status,
  editResourceRequestMessage: state.whitelabel.editResourceRequest.message,

  planType: state.home.subscription?.planType,
  planId: state.home.subscription?.planId,
});

const mapDispatchToProps = {
  getResource: () => getResourceRequest(),
  saveCompanyNameRequest: (companyName: string) =>
    saveCompanyNameRequest({ companyName }),
  editCompanyNameRequest: (companyName: string) =>
    editCompanyNameRequest({ companyName }),
  saveResourceRequest: (resource: File, resourceType: ResourceType) =>
    uploadResourceRequest({ resource, resourceType }),
  editResourceRequest: (resource: File, resourceType: ResourceType) =>
    editResourceRequest({ resource, resourceType }),
  deleteResourceRequest: (resourceType: ResourceType) =>
    deleteResourceRequest({ resourceType }),
  resetResourceState: () => resetResourceState(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(LogoBranding);
