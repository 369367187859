import React from 'react';
import { AlertTriangle } from '@saleshandy/icons';
import Alert from '../../../../../../../shared/design-system/ui/alert';

const LowerLimitErrorNudge = ({ t, lowerLimitError }) =>
  lowerLimitError ? (
    <div className="row mt-2">
      <div className="col-md-10">
        <Alert
          variant="warning"
          description={
            <span>
              {t('messages.lower_value_limit_then_ramp_up_limit_error')}
            </span>
          }
          icon={AlertTriangle}
        />
      </div>
    </div>
  ) : null;
export default LowerLimitErrorNudge;
