import React, { useCallback } from 'react';
import { Copy, Check } from '@saleshandy/icons';
import classNames from 'classnames';
import { OverlayTooltip, Placement } from '../../../design-system/components/overlay';
import { useCopyToClipboard } from '../../../hooks/use-copy-to-clipboard';

interface CopyButtonProps {
  text: string;
  className?: string;
  onCopySuccess?: () => void;
  onCopyError?: (error: Error) => void;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  text,
  className = '',
  onCopySuccess,
  onCopyError
}) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({
    onSuccess: onCopySuccess,
    onError: onCopyError
  });

  const handleCopy = useCallback(() => {
    if (!isCopied) {
      copyToClipboard(text);
    }
  }, [isCopied, text, copyToClipboard]);

  return (
    <OverlayTooltip
      placement={Placement.Top}
      text={isCopied ? 'Copied!' : 'Click to copy'}
      className="click-to-copy-tooltip"
      rootClose
    >
      <button
        type="button"
        className={classNames('copy-button', className, {
          'copied': isCopied
        })}
        onClick={handleCopy}
        disabled={isCopied}
        aria-label={isCopied ? 'Copied to clipboard' : 'Copy to clipboard'}
      >
        {isCopied ? (
          <Check className="copy-icon" aria-hidden="true" />
        ) : (
          <Copy className="copy-icon" aria-hidden="true" />
        )}
        <span>{isCopied ? 'Copied' : 'Copy'}</span>
      </button>
    </OverlayTooltip>
  );
};

export default React.memo(CopyButton);