import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ScoreFactorDetailItem from '../score-factor-detail-item';
import YoutubeVideoModal from '../../design-system/components/molecules/youtube-video-modal';

import { supportUrls } from '../../utils/urls';
import { EmailAccountSetupFactorsProps } from './types';
import {
  SpfScore,
  CustomDomainScore,
  DailySendingQuotaScore,
  DmarcScore,
  EmailAgeScore,
  SendingIntervalScore,
  DkimScore,
} from './enums';
import {
  getBlacklistedCount,
  getEmailAgeDropdownSelectedKey,
  getScoreFactorStatusForCTD,
  getScoreFactorStatusForDKIM,
  getScoreFactorStatusForDMARC,
  getScoreFactorStatusForDSQ,
  getScoreFactorStatusForDomainBlacklist,
  getScoreFactorStatusForEmailAge,
  getScoreFactorStatusForIPBlacklist,
  getScoreFactorStatusForMXRecords,
  getScoreFactorStatusForSPF,
  getScoreFactorStatusForSendingInterval,
} from './helper';
import {
  getCTDDescription,
  getDBLDescription,
  getDMARCContent,
  getDMARCDescription,
  getDSQDescription,
  getDkimDescription,
  getDomainBlacklistContent,
  getDomainBlacklistFooter,
  getEmailAgeDescription,
  getIPBLDescription,
  getIPBlacklistContent,
  getIPBlacklistFooter,
  getMXRecordsDescription,
  getSPFContent,
  getSPFDescription,
  getSendingIntervalDescription,
} from './get-descriptions';
import { RouteParams } from '../../../components/email-accounts/components/email-accounts-content/components/update-email-account/types';
import FactorDetailsModal from './components/factor-details-modal';
import { ScoreFactorStatus } from '../score-factor-detail-item/types';

const EmailAccountSetupFactors: React.FC<EmailAccountSetupFactorsProps> = ({
  emailAccountSetup,
  agencyConfig,
  showActionableList,
  sendUpdateEmailAgeRequest,
  emailAccountId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const location = useLocation<{ factorType: string }>();
  const [showYoutubeVideoModal, setShowYoutubeVideoModal] = useState(false);
  const [youtubeVideoURL, setYoutubeVideoURL] = useState('');
  const [showFactorModal, setShowFactorModal] = useState(false);
  const [factorModalConfig, setFactorModalConfig] = useState<{
    title: string;
    columns?: {
      title: string;
      dataIndex: string;
      key: string;
      align?: string;
    }[];
    data?: {
      status?: string;
      blacklist?: string;
      hostname?: string;
      ipAddresses?: string;
    }[];
    content?: string | React.ReactNode;
    footer?: string | React.ReactNode;
    onLearnMore?: () => void;
  }>(null);

  const showYoutubeVideoHandler = (url: string) => {
    setYoutubeVideoURL(url);
    setShowYoutubeVideoModal(true);
  };

  const hideYoutubeVideoHandler = () => {
    setShowYoutubeVideoModal(false);
  };

  const openUrl = (url: string) => {
    window.open(url, '_blank');
  };

  const navigateToUrl = (path: string) => {
    history.push(path);
  };

  const navigateToSendingSettings = (item) =>
    navigateToUrl(
      `/email-accounts/${params.hashId || item.hashId}/sending-settings`,
    );

  const handleShowDomainBlacklist = () => {
    const domainBlacklistData = emailAccountSetup?.domainBlacklist?.data?.flatMap(entry => 
      entry.blacklist.map(blacklistItem => ({
          status: entry.status,
          blacklist: blacklistItem
      }))
    );

    if(domainBlacklistData.length > 0) {
      setFactorModalConfig({
        title: t('messages.domain_blacklist_title'),
        content: (getDomainBlacklistContent(emailAccountSetup?.domainBlacklist)),
        columns: [
          {
            title: '',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
          },
          {
            title: t('messages.modal_col_blacklist'),
            dataIndex: 'blacklist',
            key: 'blacklist',
            align: 'left',
          },
        ],
        footer: getBlacklistedCount(emailAccountSetup?.domainBlacklist?.data) > 0 ? getDomainBlacklistFooter(() => showYoutubeVideoHandler(supportUrls.ctdYtUrl)) : null,
        data: domainBlacklistData,
        onLearnMore: () => {
          window.open(supportUrls.spfYtUrl, '_blank');
          setShowFactorModal(false);
        },
      });
      setShowFactorModal(true);
    }

  };

  const handleShowIPBlacklist = () => {
    const ipBlacklistData = emailAccountSetup?.ipBlacklist?.data?.flatMap(entry => 
      entry.blacklist.map(blacklistItem => ({
          status: entry.status,
          blacklist: blacklistItem
      }))
    );

    if(ipBlacklistData.length > 0) {
      setFactorModalConfig({
        title: t('messages.ip_blacklist_title'),
        content: (getIPBlacklistContent(emailAccountSetup?.ipBlacklist)),
        columns: [
          {
            title: '',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
          },
          {
            title: t('messages.modal_col_blacklist'),
            dataIndex: 'blacklist',
            key: 'blacklist',
            align: 'left',
          },
        ],
        footer: getBlacklistedCount(emailAccountSetup?.ipBlacklist?.data) > 0 ? getIPBlacklistFooter(() => showYoutubeVideoHandler(supportUrls.spfYtUrl)) : null,
        data: ipBlacklistData,
        onLearnMore: () => {
          window.open(supportUrls.spfYtUrl, '_blank');
          setShowFactorModal(false);
        },
      });
      setShowFactorModal(true);
    }
  };

  const handleShowMXRecords = () => {
    setFactorModalConfig({
      title: t('messages.mx_records_title'),
      columns: [
        {
          title: t('messages.modal_col_hostname'),
          dataIndex: 'hostname',
          key: 'hostname',
          align: 'left',
        },
        {
          title: t('messages.modal_col_ipaddress'),
          dataIndex: 'ip',
          key: 'ip',
          align: 'left',
        },
      ],
      data: emailAccountSetup?.mxRecord?.data,
      onLearnMore: () => {
        window.open(supportUrls.spfYtUrl, '_blank');
        setShowFactorModal(false);
      },
    });
    setShowFactorModal(true);
  };

  const handleShowDMARC = () => {
    if(emailAccountSetup?.dmarc?.score === DmarcScore.min) {
      openUrl(supportUrls.dmarcBlog)
    } else {
      setFactorModalConfig({
        title: t('messages.dmarc_title'),
        content: (getDMARCContent(emailAccountSetup?.dmarc?.data?.dmarcRecord)),
      });
      setShowFactorModal(true);
    }
  };

  const handleShowSPF = () => {
    if(emailAccountSetup?.spf?.score === SpfScore.min) {
      openUrl(supportUrls.spfBlog)
    } else {
      setFactorModalConfig({
        title: t('messages.spf_title'),
        content: (getSPFContent(emailAccountSetup?.spf?.data?.spfRecord)),
      });
      setShowFactorModal(true);
    }
  };

  useEffect(() => {
    if (location.state?.factorType) {
      switch (location.state?.factorType) {
        case 'spf':
          handleShowSPF();
          break;
        case 'dmarc':
          handleShowDMARC();
          break;
        case 'domainBlacklist':
          handleShowDomainBlacklist();
          break;
        case 'ipBlacklist':
          handleShowIPBlacklist();
          break;
        case 'mxRecords':
          handleShowMXRecords();
          break;
        case 'dkim':
          openUrl(supportUrls.spfBlog);
          break;
        case 'customTrackingDomain':
          openUrl(supportUrls.ctdBLog);
          break;
        case 'dailySendingQuota':
          navigateToSendingSettings(null);
          break;
        case 'interval':
          navigateToSendingSettings(null);
          break;
        case 'emailAccountAge':
          openUrl(supportUrls.emailAgeBlog);
          break;
      } 
    }
  }, [location.state?.factorType]);

  return (
    <>
      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.spf?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForSPF(
          emailAccountSetup?.spf?.score,
        )}
        title="SPF Set-up"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.spfYtUrl)}
        youtubeIconTooltipText="Learn how to set SPF Records"
        description={getSPFDescription(emailAccountSetup?.spf?.score)}
        hasActionable={true}
        actionableText={emailAccountSetup?.spf?.score !== SpfScore.min ? "Show Set-up" : "Learn to set SPF"}
        actionableHandler={handleShowSPF}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.spf?.actionables}
        isChevronRightVisible={emailAccountSetup?.spf?.score !== SpfScore.min}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.dkim?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForDKIM(
          emailAccountSetup?.dkim?.score,
        )}
        title="DKIM"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.spfYtUrl)}
        youtubeIconTooltipText="Learn how to set DKIM"
        description={getDkimDescription(emailAccountSetup?.dkim?.score)}
        hasActionable={emailAccountSetup?.dkim?.score === DkimScore.min}
        actionableText="Learn to set DKIM"
        actionableHandler={() => openUrl(supportUrls.spfBlog)}
        showActionableList={showActionableList}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.customDomain?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForCTD(
          emailAccountSetup?.customDomain?.score,
        )}
        title="Custom Domain Set-up"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.ctdYtUrl)}
        youtubeIconTooltipText="Learn how to set Custom Tracking Domain"
        description={getCTDDescription(emailAccountSetup?.customDomain?.score)}
        hasActionable={
          emailAccountSetup?.customDomain?.score === CustomDomainScore.min
        }
        actionableText="Set Custom Domain"
        actionableHandler={() => navigateToUrl('/settings/custom-domain')}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.customDomain?.actionables}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.dailySendingQuota?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForDSQ(
          emailAccountSetup?.dailySendingQuota?.score,
        )}
        title="Daily Sending Quota"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.spfYtUrl)}
        youtubeIconTooltipText="Learn how to set Daily Sending Quota"
        description={getDSQDescription(
          emailAccountSetup?.dailySendingQuota?.score,
        )}
        hasActionable={
          emailAccountSetup?.dailySendingQuota?.score ===
            DailySendingQuotaScore.min ||
          emailAccountSetup?.dailySendingQuota?.score ===
            DailySendingQuotaScore.mid
        }
        actionableText="Set Quota"
        actionableHandler={navigateToSendingSettings}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.dailySendingQuota?.actionables}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.dmarc?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForDMARC(
          emailAccountSetup?.dmarc?.score,
        )}
        title="DMARC Set-up"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() =>
          showYoutubeVideoHandler(supportUrls.dmarcYtUrl)
        }
        youtubeIconTooltipText="Learn how to set DMARC"
        description={getDMARCDescription(emailAccountSetup?.dmarc?.score)}
        hasActionable={true}
        actionableText={emailAccountSetup?.dmarc?.score !== DmarcScore.min ? "Show Set-up" : "Learn to set DMARC"}
        actionableHandler={handleShowDMARC}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.dmarc?.actionables}
        isChevronRightVisible={emailAccountSetup?.dmarc?.score !== DmarcScore.min}
      />
  
      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.domainBlacklist?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForDomainBlacklist(
          emailAccountSetup?.domainBlacklist?.score,
        )}
        title="Domain Blacklist"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.spfYtUrl)}
        youtubeIconTooltipText="Learn how to set Domain Blacklist"
        description={getDBLDescription(emailAccountSetup?.domainBlacklist?.score)}
        hasActionable={emailAccountSetup?.domainBlacklist?.inProgress ? false : emailAccountSetup?.domainBlacklist?.data?.length > 0 ? true : false}
        actionableText={getBlacklistedCount(emailAccountSetup?.domainBlacklist?.data) > 0 ?
          `View Blacklisted (${getBlacklistedCount(emailAccountSetup?.domainBlacklist?.data)})` :
          "View" 
        }
        actionableHandler={handleShowDomainBlacklist}
        showActionableList={showActionableList}
        isChevronRightVisible={emailAccountSetup?.domainBlacklist ? true : false}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.ipBlacklist?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForIPBlacklist(
          emailAccountSetup?.ipBlacklist?.score,
        )}
        title="IP Blacklist"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.spfYtUrl)}
        youtubeIconTooltipText="Learn how to set IP Blacklist"
        description={getIPBLDescription(emailAccountSetup?.ipBlacklist?.score)}
        hasActionable={emailAccountSetup?.ipBlacklist?.inProgress ? false : emailAccountSetup?.ipBlacklist?.data?.length > 0 ? true : false}
        actionableText={getBlacklistedCount(emailAccountSetup?.ipBlacklist?.data) > 0 ?
          `View Blacklisted (${getBlacklistedCount(emailAccountSetup?.ipBlacklist?.data)})` :
          "View"
        }
        actionableHandler={handleShowIPBlacklist}
        showActionableList={showActionableList}
        isChevronRightVisible={emailAccountSetup?.ipBlacklist ? true : false}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.mxRecord?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForMXRecords(
          emailAccountSetup?.mxRecord?.score,
        )}
        title="MX Records"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.spfYtUrl)}
        youtubeIconTooltipText="Learn how to set MX Records"
        description={getMXRecordsDescription(emailAccountSetup?.mxRecord?.score)}
        hasActionable={emailAccountSetup?.mxRecord?.data?.length > 0 ? true : false}
        actionableText="View MX Records"
        actionableHandler={handleShowMXRecords}
        showActionableList={showActionableList}
        isChevronRightVisible={true}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.emailAccountAge?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForEmailAge(
          emailAccountSetup?.emailAccountAge?.score,
        )}
        title="Email Age"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() =>
          showYoutubeVideoHandler(supportUrls.emailAgeYtUrl)
        }
        youtubeIconTooltipText="Learn how to warm-up Email Age"
        description={getEmailAgeDescription(
          emailAccountSetup?.emailAccountAge?.score,
        )}
        hasActionable={
          emailAccountSetup?.emailAccountAge?.score === EmailAgeScore.min ||
          emailAccountSetup?.emailAccountAge?.score === EmailAgeScore.mid
        }
        actionableType="select"
        actionableText=""
        actionableHandler={(ageInMonths) => {
          sendUpdateEmailAgeRequest({
            emailAccountId: emailAccountId,
            ageInMonths,
          });
        }}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.emailAccountAge?.actionables}
        showActionWithListOnly={showActionableList}
        dropdownSelectedKey={getEmailAgeDropdownSelectedKey(
          emailAccountSetup?.emailAccountAge?.score,
        )}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={emailAccountSetup?.interval?.inProgress ? ScoreFactorStatus.InProgress : getScoreFactorStatusForSendingInterval(
          emailAccountSetup?.interval?.score,
        )}
        title="Sending Interval"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.spfYtUrl)}
        youtubeIconTooltipText="Learn how to set Interval"
        description={getSendingIntervalDescription(
          emailAccountSetup?.interval?.score,
        )}
        hasActionable={
          emailAccountSetup?.interval?.score === SendingIntervalScore.min ||
          emailAccountSetup?.interval?.score === SendingIntervalScore.mid
        }
        actionableText="Change Interval"
        actionableHandler={navigateToSendingSettings}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.interval?.actionables}
      />

      <YoutubeVideoModal
        show={showYoutubeVideoModal}
        youtubeVideoUrl={youtubeVideoURL}
        onClose={hideYoutubeVideoHandler}
      />

      <FactorDetailsModal
        show={showFactorModal}
        onClose={() => setShowFactorModal(false)}
        factorModalConfig={factorModalConfig}
      />
    </>
  );
};

export default EmailAccountSetupFactors;
