import { SequenceScoreErrorType } from '../../../components/sequence/enums';
import { SequenceScoreError } from '../../../components/sequence/types';
import history from '../../history';

export const getSequenceScoreErrorMessage = (
  error: SequenceScoreError,
  component: string,
) => {
  const somethingWentWrong = 'Something went wrong!';

  if (
    error.errorCode === SequenceScoreErrorType.FirstStepNotFound &&
    component === 'email-writing-score'
  ) {
    return {
      title:
        'Email writing score is a consolidated score of all the parameters which affect your sequence performance.',
      description: 'Create your first step to see your email writing score.',
      showBtn: true,
      btnLabel: 'Add new step',
      btnHandler: () => history.push('./steps?addNewStep=true'),
    };
  }

  if (error.errorCode === SequenceScoreErrorType.FirstStepNotFound) {
    return {
      title:
        'Sequence score is a consolidated score of all the parameters which affect your sequence performance.',
      description: 'Create your first step to see your sequence setup score.',
      showBtn: true,
      btnLabel: 'Add new step',
      btnHandler: () => history.push('./steps?addNewStep=true'),
    };
  }

  if (
    error.errorCode === SequenceScoreErrorType.NoEmailAccountConnectedToSequence
  ) {
    return {
      title:
        'Email setup score indicates the health of email accounts linked in the sequence & is a vital part of the overall sequence setup.',
      description:
        'This sequence has no email account connected. Add an email account to this sequence.',
      showBtn: true,
      btnLabel: 'Add new email account',
      btnHandler: () => history.push('./settings?linkEmailAccount=true'),
    };
  }
  if (
    error.errorCode === SequenceScoreErrorType.MaximumEmailContentSizeExceeded
  ) {
    return {
      title: somethingWentWrong,
      description: 'Size of one or more of your variant is greater then 500kb',
      showBtn: false,
      btnLabel: '',
      btnHandler: null,
    };
  }
  if (error.errorCode === SequenceScoreErrorType.FirstStepDataNotAvailable) {
    return {
      title: somethingWentWrong,
      description: error.msg,
      showBtn: true,
      btnLabel: 'Calculate Email Writing Setup Score',
      btnHandler: null,
    };
  }

  return {
    title: somethingWentWrong,
    description: error.msg,
    showBtn: false,
    btnLabel: 'Try Again',
    btnHandler: null,
  };
};
