import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../../../../shared/design-system/components/atoms/modal';
import Button from '../../../../../../../../shared/design-system/components/atoms/button';
import { Images } from '../../../../../../../../shared/app-constants';

const SaveRampUpConfirmation = ({
  show,
  onSubmit,
  onClose,
  isRequestPending,
  confirmationModalText,
}) => {
  const { t } = useTranslation();

  const onSubmitHandler = () => {
    onSubmit();
  };

  return (
    <Modal
      show={show}
      className="confirm-modal-width confirmation-modal"
      titleContent={
        <div className="confirmation-modal-header">
          <img src={Images.AlertTriangleRed} alt="Alert" />
          <h2>{t('messages.save_rampup_confirmation_title')}</h2>
        </div>
      }
      showCloseIcon
      onSubmit={onSubmitHandler}
      onClose={onClose}
      submitButtonText={t('labels.yes')}
      cancelButtonText={t('labels.no')}
      cancelButtonVarient={Button.Variant.Outlined}
      backdrop="static"
      submitButtonClassName="header-btn"
      isSubmitDisabled={isRequestPending}
      isSubmitLoading={isRequestPending}
    >
      <div className="confirmation-modal-content">
        <p className="confirmation-modal-text">
          {t('messages.save_rampup_confirmation_content_1')}{' '}
          {t(`messages.${confirmationModalText}`)}{' '}
          {t('messages.save_rampup_confirmation_content_2')}{' '}
        </p>
        <p className="confirmation-modal-text">
          {t('messages.save_rampup_confirmation_content_3')}
        </p>
      </div>
    </Modal>
  );
};

export default SaveRampUpConfirmation;
