import { Check, Checks } from '@saleshandy/icons';
import React from 'react';

const NotificationBanner = ({ variant = 'primary', message, submessage }) => {
  const baseStyles = {
    padding: '1rem 1.25rem',
    borderRadius: '.5rem',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '.75rem',
    fontFamily: 'Arial, sans-serif',
  };

  const variantStyles = {
    primary: {
      backgroundColor: '#EFF6FF',
      color: '#1E40AF',
    },
    success: {
      backgroundColor: '#ECFDF5',
      color: '#166534',
    },
  };

  const textStyles = {
    flex: 1,
  };

  const renderIcon = () => {
    if (variant === 'success') {
      return <Checks width={18} height={18} className="green-txt-14" />;
    }
    return <Check width={18} height={18} className="blue-txt-11" />;
  };

  return (
    <div style={{ ...baseStyles, ...variantStyles[variant] }} data-banner>
      {renderIcon()}
      <div style={textStyles}>
        <div className="semibold-1 line-height-18 popover-arrow-color-txt">
          {message}
        </div>
        {typeof submessage === 'string' ? (
          <div className="regular-1 line-height-18 popover-arrow-color-txt">
            {submessage}
          </div>
        ) : (
          submessage
        )}
      </div>
    </div>
  );
};

export default NotificationBanner;
