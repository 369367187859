/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';
import { SortOrder } from 'react-bootstrap-table-next';
import { Check, Cross, InfoCircle, RotateClockwiseDots, Trulyinbox } from '@saleshandy/icons';

import { EmailAccount } from '../../../../../types/email-account';
import { EmailAccountsSortBy } from '../../../../../enums/email-account';
import { Column } from '../../../../../../../shared/design-system/components/organisms/table/types';

import EmailAccountSwitch from '../fields/email-account-switch';
import SenderNameField from '../fields/sender-name-field';
import SetupScoreField from '../fields/setup-score-field';
import ActiveSequencesField from '../fields/active-sequences-field';
import EmailSentField from '../fields/email-sent-field';
import AddedByField from '../fields/added-by-field';
import {
  OverlayPopover,
  OverlayTooltip,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';
import { getClientName } from '../../../../../../agency-client-management/helpers/helpers';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { isAgency } from '../../../../../../../shared/utils/user-details';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import ProspectTagsRow from '../../../../../../../shared/design-system/components/molecules/prospect-tags-row';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import EmailWarmupStatusCell from '../components/email-warmup-status-cell';
import { supportUrls } from '../../../../../../../shared/utils/urls';
import { setEmailAccountsSearchQuery } from '../../../utils/helpers';
import { useHistory } from 'react-router';
import { UpdateEmailTabKeys } from '../../update-email-account/types';

type PopoverKey = 'spf' | 'dkim' | 'dmarc' | 'domainBlacklist' | 'ipBlacklist' | 'mxRecord' | 'customDomain';

interface PopoverContent {
  title: string;
  description: string;
  learnMoreUrl: string;
}

const POPOVER_CONTENT: Record<PopoverKey, PopoverContent> = {
  spf: {
    title: 'SPF',
    description: 'Ensures that only authorized servers can send emails on behalf of your domain. Prevents spoofing.',
    learnMoreUrl: supportUrls.emailAccountSpf
  },
  dkim: {
    title: 'DKIM', 
    description: 'Adds a digital signature to emails to confirm they\'re sent by an authorized sender and haven\'t been altered.',
    learnMoreUrl: supportUrls.emailAccountDmarc
  },
  dmarc: {
    title: 'DMARC',
    description: 'Aligns SPF and DKIM policies, instructing mail servers on how to handle emails that fail authentication. Provides reports for visibility.',
    learnMoreUrl: supportUrls.emailAccountDmarc
  },
  domainBlacklist: {
    title: 'Domain Blacklist',
    description: 'Occurs when a domain is flagged for spam, leading to its emails being blocked or sent to spam folders.',
    learnMoreUrl: supportUrls.emailAccountDmarc
  },
  ipBlacklist: {
    title: 'IP Blacklist',
    description: 'Occurs when an IP address is flagged for suspicious activity, preventing it from sending emails or accessing services.',
    learnMoreUrl: supportUrls.emailAccountDmarc
  },
  mxRecord: {
    title: 'MX Record',
    description: 'Determines which mail servers are responsible for handling incoming emails for your domain.',
    learnMoreUrl: supportUrls.emailAccountDmarc
  },
  customDomain: {
    title: 'Custom Domain',
    description: 'A custom tracking domain ensures that tracking links match your domain, preventing spam filters from flagging mismatched domains.',
    learnMoreUrl: supportUrls.emailAccountCustomDomain
  }
};

const InfoPopover = ({ 
  popoverKey, 
  isOpen, 
  onToggle,
  tooltipRef 
}: {
  popoverKey: PopoverKey;
  isOpen: boolean;
  onToggle: (show: boolean) => void;
  tooltipRef: (el: HTMLElement | null) => void;
}) => {
  const content = POPOVER_CONTENT[popoverKey];
  
  return (
    <div ref={tooltipRef}>
      <OverlayPopover
        className="permission-popover"
        show={isOpen}
        onToggle={onToggle}
        placement={Placement.Bottom}
        content={
          <>
            <div className="content">
              {content.description}{' '}
              <a href={content.learnMoreUrl} target="_blank" rel="noreferrer" className="link" style={{ whiteSpace: 'nowrap' }}>
                Learn More
              </a>
            </div>
            <div className="d-flex justify-content-center" {...accessibleOnClick(() => onToggle(false))}>
              <Cross className="cross-icon" />
            </div>
          </>
        }
      >
        <InfoCircle
          className={`info-icon-popover-btn cursor-pointer ${isOpen ? 'isInfoIconVisible' : 'isInfoIconNotVisible'}`}
        />
      </OverlayPopover>
    </div>
  );
};

export const getEmailAccountsColumns = ({
  itemCount,
  onSort,
  onEmailAccountsSwitchChangeHandler,
  redirectToGeneralSettings,
  onSmtpImapModalShow,
  hideColumn,
}: {
  itemCount: number;
  onSort: (sortBy: EmailAccountsSortBy, order: SortOrder) => void;
  onEmailAccountsSwitchChangeHandler: (emailAccount: EmailAccount) => void;
  redirectToGeneralSettings: (emailAccount: EmailAccount) => void;
  onSmtpImapModalShow: (emailAccount: EmailAccount) => void;
  hideColumn: {
    isWarmupStatusVisible: boolean;
    isDeliverabilityStatusVisible: boolean;
  };
}): Column[] => {
  const canDisconnectEmailAccount = hasPermission(
    Permissions.EMAIL_ACCOUNT_DISCONNECT,
  );
  const isClientAssociatedVisible =
    hasPermission(Permissions.CLIENT_READ) && isAgency();

  const [activePopover, setActivePopover] = useState<PopoverKey | null>(null);
  const tooltipRefs = useRef<Record<PopoverKey, HTMLElement | null>>({} as Record<PopoverKey, HTMLElement | null>);
  
  const history = useHistory();
  const handleRedirect = (hashId: string, factorType: string) => {
    setEmailAccountsSearchQuery(history.location.search);
    history.push({
      pathname: `/settings/email-accounts/${hashId}/${UpdateEmailTabKeys.EMAIL_SETUP_SCORE}`,
      state: { factorType }
    });
  };

  useEffect(() => {
    if (!activePopover) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (!tooltipRefs.current[activePopover]?.contains(event.target as Node)) {
        setActivePopover(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activePopover]);

  useEffect(() => {
    return () => {
      tooltipRefs.current = {} as Record<PopoverKey, HTMLElement | null>;
    };
  }, []);

  return [
    canDisconnectEmailAccount && {
      dataField: 'status',
      text: '',
      headerAlign: 'start',
      headerClasses: 'status-header',
      cellClasses: 'status-cell email-account-switch-cell',
      component: (_cell, row) => (
        <EmailAccountSwitch
          emailAccount={row}
          onChangeHandler={onEmailAccountsSwitchChangeHandler}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={80} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={180} height={14} />,
    },
    {
      dataField: 'fromName',
      text: 'Sender Name',
      headerAlign: 'start',
      headerClasses: `name-header ${
        canDisconnectEmailAccount ? 'with-toggle' : ''
      }`,
      cellClasses: `sender-name-cell name-cell ${
        canDisconnectEmailAccount ? 'with-toggle' : ''
      }`,
      component: (_cell, row) => (
        <SenderNameField
          emailAccount={row}
          redirectToGeneralSettings={redirectToGeneralSettings}
          onSmtpImapModalShow={onSmtpImapModalShow}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={80} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={180} height={14} />,
    },
    {
      dataField: 'activeSequences',
      text: 'Active Sequences',
      headerAlign: 'start',
      headerClasses: 'active-sequence-cell-header',
      cellClasses: 'active-sequences-cell active-sequence-cell-header',
      component: (_cell, row, rowIndex) => (
        <ActiveSequencesField
          emailAccount={row}
          rowNumber={rowIndex + 1}
          itemCount={itemCount}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    hideColumn?.isWarmupStatusVisible && {
      dataField: 'warmupStatus',
      text: 'Warm-up Status',
      headerComponent: () => (
        <div className="trulyinbox">
          <Trulyinbox className="icon-trulyinbox" />
          <span>Warm-up Status</span>
        </div>
      ),
      headerAlign: 'start',
      cellClasses: 'warmup-status-cell',
      component: (cell) => <EmailWarmupStatusCell warmupStatus={cell} />,
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    hideColumn?.isDeliverabilityStatusVisible && {
      dataField: 'deliverabilityRate',
      text: 'Deliverability Score',
      headerComponent: () => (
        <div className="trulyinbox">
          <Trulyinbox className="icon-trulyinbox" />
          <span>Deliverability Score</span>
        </div>
      ),
      sort: true,
      onSort: (_field, order) =>
        onSort(EmailAccountsSortBy.DeliverabilityRate, order),
      headerAlign: 'start',
      cellClasses: 'deliverability-score-cell',
      component: (cell) => (
        <span className={cell && 'font-weight-medium'}>
          {cell ? `${cell}%` : '-'}
        </span>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'spf',
      text: 'SPF',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>SPF</span>
          <InfoPopover
            popoverKey="spf"
            isOpen={activePopover === 'spf'}
            onToggle={(show) => setActivePopover(show ? 'spf' : null)}
            tooltipRef={(el) => tooltipRefs.current.spf = el}
          />
        </div>
      ),
      headerAlign: 'start',
      component: (cell, row) => (
        <div className="cursor-pointer" onClick={() => handleRedirect(row.hashId, 'spf')}>
          {cell ? (
            <Check className="check-cross-icon" color="green" />
          ) : (
            <Cross className="check-cross-icon" color="red" />
          )}
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'dkim',
      text: 'DKIM',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>DKIM</span>
          <InfoPopover
            popoverKey="dkim"
            isOpen={activePopover === 'dkim'}
            onToggle={(show) => setActivePopover(show ? 'dkim' : null)}
            tooltipRef={(el) => tooltipRefs.current.dkim = el}
          />
        </div>
      ),
      headerAlign: 'start',
      component: (cell, row) => (
        <div className="cursor-pointer" onClick={() => handleRedirect(row.hashId, 'dkim')}>
          {cell ? (
            <Check className="check-cross-icon" color="green" />
          ) : (
            <Cross className="check-cross-icon" color="red" />
          )}
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'dmarc',
      text: 'DMARC',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>DMARC</span>
          <InfoPopover
            popoverKey="dmarc"
            isOpen={activePopover === 'dmarc'}
            onToggle={(show) => setActivePopover(show ? 'dmarc' : null)}
            tooltipRef={(el) => tooltipRefs.current.dmarc = el}
          />
        </div>
      ),
      headerAlign: 'start',
      component: (cell, row) => (
        <div className="cursor-pointer" onClick={() => handleRedirect(row.hashId, 'dmarc')}>
          {cell ? (
            <Check className="check-cross-icon" color="green" />
          ) : (
            <Cross className="check-cross-icon" color="red" />
          )}
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'domainBlacklist',
      text: 'Domain Blacklist',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>Domain Blacklist</span>
          <InfoPopover
            popoverKey="domainBlacklist"
            isOpen={activePopover === 'domainBlacklist'}
            onToggle={(show) => setActivePopover(show ? 'domainBlacklist' : null)}
            tooltipRef={(el) => tooltipRefs.current.domainBlacklist = el}
          />
        </div>
      ),
      headerAlign: 'start',
      component: (cell, row) => (
        cell < 0 ?
          <RotateClockwiseDots />
        :
        <div className="cursor-pointer" onClick={() => handleRedirect(row.hashId, 'domainBlacklist')}>
          <span className={cell && 'font-weight-medium'}>
            {cell && `${cell}`}
          </span>
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'ipBlacklist',
      text: 'IP Blacklist',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>IP Blacklist</span>
          <InfoPopover
            popoverKey="ipBlacklist"
            isOpen={activePopover === 'ipBlacklist'}
            onToggle={(show) => setActivePopover(show ? 'ipBlacklist' : null)}
            tooltipRef={(el) => tooltipRefs.current.ipBlacklist = el}
          />
        </div>
      ),
      headerAlign: 'start',
      component: (cell, row) => (
        cell < 0 ?
          <RotateClockwiseDots />
        :
        <div className="cursor-pointer" onClick={() => handleRedirect(row.hashId, 'ipBlacklist')}>
          <span className={cell && 'font-weight-medium'}>
            {cell && `${cell}`}
          </span>
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'mxRecord',
      text: 'MX Record',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>MX Record</span>
          <InfoPopover
            popoverKey="mxRecord"
            isOpen={activePopover === 'mxRecord'}
            onToggle={(show) => setActivePopover(show ? 'mxRecord' : null)}
            tooltipRef={(el) => tooltipRefs.current.mxRecord = el}
          />
        </div>
      ),
      headerAlign: 'start',
      component: (cell, row) => (
        <div className="cursor-pointer" onClick={() => handleRedirect(row.hashId, 'mxRecords')}>
          {cell ? (
            <Check className="check-cross-icon" color="green" />
          ) : (
            <Cross className="check-cross-icon" color="red" />
          )}
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'customTrackingDomain',
      text: 'Custom Domain',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>Custom Domain</span>
          <InfoPopover
            popoverKey="customDomain"
            isOpen={activePopover === 'customDomain'}
            onToggle={(show) => setActivePopover(show ? 'customDomain' : null)}
            tooltipRef={(el) => tooltipRefs.current.customDomain = el}
          />
        </div>
      ),
      headerAlign: 'start',
      component: (cell, row) => (
        <div className="cursor-pointer" onClick={() => handleRedirect(row.hashId, 'customTrackingDomain')}>
          {cell ? (
            <Check className="check-cross-icon" color="green" />
          ) : (
            <Cross className="check-cross-icon" color="red" />
          )}
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'tags',
      text: 'Tags',
      headerAlign: 'left',
      cellClasses: 'tag-detail',
      style: () => ({ width: '5rem' }),
      component: (cell) => <ProspectTagsRow tags={cell} />,
      headerLoadingSkeleton: <SkeletonLoading width={29} height={14} />,
      cellLoadingSkeleton: (
        <div className="d-flex">
          <SkeletonLoading width={84} height={24} />
          <div className="ml-2">
            <SkeletonLoading width={64} height={24} />
          </div>
        </div>
      ),
    },
    hasPermission(
      Permissions.EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE,
    ) && {
      dataField: 'emailSent',
      text: 'Email Sent',
      headerAlign: 'start',
      cellClasses: 'email-sent-cell',
      headerStyle: () => ({ minWidth: '10.5rem', width: '10.5rem' }),
      component: (_cell, row, rowIndex) => (
        <EmailSentField
          emailAccount={row}
          rowNumber={rowIndex + 1}
          itemCount={itemCount}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_SETUP_SCORE) && {
      dataField: 'healthScore',
      text: 'Setup Score',
      headerAlign: 'start',
      cellClasses: 'setup-score-cell',
      sort: true,
      onSort: (_field, order) => onSort(EmailAccountsSortBy.SetupScore, order),
      component: (_cell, row) => <SetupScoreField emailAccount={row} />,
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    ...(isClientAssociatedVisible
      ? [
          {
            dataField: 'client',
            text: 'Client Associated',
            headerAlign: 'start',
            cellClasses: 'email-account-client-associated',
            headerStyle: () => ({ minWidth: '10.5rem', width: '10.5rem' }),
            component: (cell) => {
              if (cell) {
                const { firstName, companyName, email } = cell;
                if (firstName && companyName && email) {
                  return (
                    <div className="sequence-client-associated-name">
                      <OverlayTooltip
                        text={getClientName(cell, 60, {
                          email: true,
                          deleted: true,
                        })}
                        placement={Placement.BottomStart}
                        className="email-account-client-associated-tooltip"
                      >
                        <p>
                          {getClientName(cell, 23, {
                            company: true,
                          })}
                        </p>
                      </OverlayTooltip>
                    </div>
                  );
                }
              }
              return '-';
            },
            headerLoadingSkeleton: <SkeletonLoading width={68} height={14} />,
            cellLoadingSkeleton: <SkeletonLoading width={76} height={14} />,
          },
        ]
      : []),
    {
      dataField: 'user',
      text: 'Added By',
      headerAlign: 'start',
      cellClasses: 'added-by-cell',
      component: (_cell, row) => <AddedByField emailAccount={row} />,
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
  ].filter((col) => col);
};
