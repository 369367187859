import React from 'react';
import classNames from 'classnames';
import { Check } from '@saleshandy/icons';

import { EmailInfrastructureStep } from '../../../enums/create-email-infrastructure';

type TabLabelProps = {
  label: string;
  isActive: boolean;
  isCompleted: boolean;
};

const TabLabel: React.FC<TabLabelProps> = ({
  label,
  isActive,
  isCompleted,
}) => {
  const labelTextClassName = classNames(['label-text', { active: isActive }]);

  return (
    <div className="create-email-infrastructure__tabs--item--label">
      {isCompleted ? (
        <div className="step-complete d-flex justify-content-center align-items-center">
          <Check width={14} height={14} />
        </div>
      ) : (
        <div className="step-incomplete" />
      )}
      <span className={labelTextClassName}>{label}</span>
    </div>
  );
};

type IProps = {
  tab: {
    key: EmailInfrastructureStep;
    label: string;
    isCompleted: boolean;
  };
  activeKey: EmailInfrastructureStep;
};

const CreateEmailInfrastructureTabs: React.FC<IProps> = ({
  tab,
  activeKey,
}) => (
  <div className="create-email-infrastructure__tabs--item">
    <TabLabel
      label={tab.label}
      isActive={tab.key === activeKey}
      isCompleted={tab.isCompleted}
    />
  </div>
);

export default CreateEmailInfrastructureTabs;
