/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { find, some } from 'lodash';
import { Check, Pencil, Plus } from '@saleshandy/icons';
import type { IProps } from './purchase-domains-tab-container';
import { EmailAccountInfrastructurePlanType } from '../../../../enums/create-email-infrastructure';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import SearchDomains from '../search-domains';
import SuggestedDomainsEmailsTable from '../suggested-domains-emails-table';
import ForwadingDomainModal from '../../../../modals/forwarding-domain-modal';
import { isRequestPending } from '../../../../../settings/components/billing-subscription/utils/helper';
import { executeOnRequestStatus } from '../../../../../../shared/utils';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { getDomainsLoadingData } from '../../utils/domainUtils';
import { Images } from '../../../../../../shared/app-constants';
import NoSearchResult from '../no-search-result';

const PurchaseDomainsTab: React.FC<IProps> = ({
  domains,
  domainPlans,
  emailAccountInfrastructureCart,
  emailAccountInfrastructureServiceProvider,
  sendDomainSearchRequest,
  getDomainSearchRequestStatus,
  resetGetDomainSearchRequest,
  emailAccountInfrastructureForwardingDomain,
  updateEmailAccountInfrastructureForwardingDomain,
  addDomainToCart,
  removeDomainFromCart,
  showSetForwardingDomainModal,
  setShowSetForwardingDomainModal,
  handleNextButtonClickOnPurchaseDomains,
}) => {
  const [
    showAddForwardingDomainModal,
    setShowAddForwardingDomainModal,
  ] = useState(false);
  const [search, setSearch] = useState('');

  const isSearchTriggeredRef = useRef(false);

  const domainPrice = useMemo(
    () =>
      find(domainPlans, {
        planType: EmailAccountInfrastructurePlanType.DomainSetup,
      })?.amount || 0,
    [domainPlans],
  );

  const handleToggleDomainSelection = (
    name: string,
    price: number,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      removeDomainFromCart(name);
    } else {
      addDomainToCart({ name, price });
    }
  };

  const columns = useMemo(
    () => [
      {
        key: 'name',
        header: 'Domains',
        component: ({ name, isLoading }) =>
          isLoading ? name : <span>{name}</span>,
      },
      {
        key: 'price',
        header: 'Price per Year',
        component: ({ price, isLoading }) =>
          isLoading ? price : <span>${price}</span>,
      },
      {
        key: 'action',
        header: '',
        component: ({ name, isSelected, isLoading, price }) =>
          isLoading ? (
            isSelected
          ) : (
            <>
              {isSelected ? (
                <OverlayTooltip text="Remove from Cart" key={`${name}-remove`}>
                  <Check
                    key={`${name}-remove`}
                    className="remove-from-cart-icon"
                    color="green"
                    width={20}
                    height={20}
                    onClick={() =>
                      handleToggleDomainSelection(name, price, isSelected)
                    }
                  />
                </OverlayTooltip>
              ) : (
                <OverlayTooltip text="Add to Cart" key={`${name}-add`}>
                  <Plus
                    key={`${name}-add`}
                    className="add-to-cart-icon"
                    width={20}
                    height={20}
                    onClick={() =>
                      handleToggleDomainSelection(name, price, isSelected)
                    }
                  />
                </OverlayTooltip>
              )}
            </>
          ),
      },
    ],
    [handleToggleDomainSelection],
  );

  const data = useMemo(
    () =>
      domains.map(({ name }) => ({
        name,
        price: domainPrice,
        isSelected: some(emailAccountInfrastructureCart.domains, {
          name,
        }),
      })),
    [domains, emailAccountInfrastructureCart.domains],
  );

  const isLoading = isRequestPending(getDomainSearchRequestStatus);

  const loadingData = getDomainsLoadingData();

  const handleOnSearch = (searchString: string) => {
    isSearchTriggeredRef.current = true;
    sendDomainSearchRequest({
      domain: searchString,
      emailServiceProvider: emailAccountInfrastructureServiceProvider,
      page: 1,
    });
  };

  const openAddForwardingDomainModal = () => {
    if (emailAccountInfrastructureCart?.domains?.length > 0) {
      setShowAddForwardingDomainModal(true);
    }
  };

  const closeAddForwardingDomainModal = () => {
    setShowAddForwardingDomainModal(false);
    if (showSetForwardingDomainModal) {
      setShowSetForwardingDomainModal(false);
    }
  };

  const handleUpdateEmailAccountInfrastructureForwardingDomain = (
    value: string,
  ) => {
    updateEmailAccountInfrastructureForwardingDomain(value);
    setShowAddForwardingDomainModal(false);
    if (showSetForwardingDomainModal) {
      handleNextButtonClickOnPurchaseDomains();
    }
  };

  const getActionComponent = () =>
    emailAccountInfrastructureForwardingDomain ? (
      <div className="update-forwarding-link">
        <p>Forwarding domain is set</p>
        <Pencil width={16} height={16} onClick={openAddForwardingDomainModal} />
      </div>
    ) : (
      <div
        className={`set-forwarding-link ${
          emailAccountInfrastructureCart?.domains?.length === 0
            ? 'disabled'
            : ''
        }`}
        {...accessibleOnClick(openAddForwardingDomainModal)}
      >
        Set Forwarding Domain
      </div>
    );

  useEffect(() => {
    if (showSetForwardingDomainModal) {
      openAddForwardingDomainModal();
    }
  }, [showSetForwardingDomainModal]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getDomainSearchRequestStatus,
      onSuccess: () => {
        setSearch('');
        resetGetDomainSearchRequest();
      },
      onFailed: () => {
        resetGetDomainSearchRequest();
      },
    });
  }, [getDomainSearchRequestStatus]);

  const renderContent = () => {
    if (!isSearchTriggeredRef.current && !isLoading) {
      return (
        <NoSearchResult
          imageSrc={Images.EmptyListSearchDomain}
          headerText="Search, explore and get secondary domains"
          messageText="List of secondary domains will appear here once you enter your primary
        domain in the search box"
        />
      );
    }

    if (isLoading) {
      return (
        <SuggestedDomainsEmailsTable
          columns={columns}
          data={loadingData}
          tableTitle={<p>Suggested Domains</p>}
          actionComponent={getActionComponent()}
          className="row-loading"
        />
      );
    }

    if (isSearchTriggeredRef.current) {
      if (domains.length > 0) {
        return (
          <SuggestedDomainsEmailsTable
            columns={columns}
            data={data}
            tableTitle={<p>Suggested Domains</p>}
            actionComponent={getActionComponent()}
            rowClassName={(row) => (row.isSelected ? 'row-selected' : '')}
            tableHeightClassname="purchase-domains-height"
          />
        );
      }
      return (
        <NoSearchResult
          imageSrc={Images.NoDomainSearchResult}
          headerText="No Search Result"
          messageText="We can’t find any domains matching your search."
        />
      );
    }

    return null;
  };

  return (
    <div className="purchase-domains-container">
      <SearchDomains
        onSearchClick={handleOnSearch}
        isLoading={isLoading}
        search={search}
        setSearch={setSearch}
      />

      {renderContent()}

      {showAddForwardingDomainModal && (
        <ForwadingDomainModal
          show={showAddForwardingDomainModal}
          onClose={closeAddForwardingDomainModal}
          emailAccountInfrastructureForwardingDomain={
            emailAccountInfrastructureForwardingDomain
          }
          updateEmailAccountInfrastructureForwardingDomain={
            handleUpdateEmailAccountInfrastructureForwardingDomain
          }
        />
      )}
    </div>
  );
};

export default PurchaseDomainsTab;
