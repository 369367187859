import _, { isEmpty } from 'lodash';
import { PerformanceStatsVariant } from '../../../shared/design-system/components/molecules/performance-stats/types';
import toaster, { Theme } from '../../../shared/toaster';
import { UserSettingCode } from '../../../shared/types/user-setting';
import { Permissions } from '../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../shared/utils/access-control/has-permission';
import { planError } from '../../../shared/utils/errors/plan-permission-error/plan-error';
import getEmailAccountMethod from '../../../shared/utils/get-email-account-method';
import {
  getIsRequestPending,
  getIsRequestSucceeded,
} from '../../../shared/utils/get-request-status';
import { SubscriptionPlans } from '../../../shared/utils/subscription-plans';
import {
  EmailAccountMethod,
  EmailAccountType,
  EmailServiceProvider,
} from '../../email-accounts/enums/email-account';
import { EmailAccountWarmupSortKey, EmailWarmupStatus } from '../enums';
import { EmailAccountWarmupList } from '../types';
import hasResource from '../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../shared/utils/access-control/enums/resource-identifiers';

export const getEmailWarmupReportDeliverabilityPercentage = (
  deliverabilityReport,
) => {
  if (deliverabilityReport) {
    if (Number(deliverabilityReport.deliverabilityPercentage) === 0) {
      return '-';
    }

    return `${deliverabilityReport.deliverabilityPercentage}%`;
  }
  return '-';
};

export const getSeriesForDeliverabilityChart = (deliverabilityReport) => {
  if (deliverabilityReport) {
    const total = Number(deliverabilityReport.total);
    const inbox = Number(deliverabilityReport.inbox);
    const spam = Number(deliverabilityReport.spam);

    if (total === 0) {
      return {
        series: [0, 0],
        colors: ['#059669', '#B91C1C'],
      };
    }

    const inboxPercentage = Math.round((inbox * 100) / total);
    const spamPercentage = Math.round((spam * 100) / total);

    // 100% Inbox Ratio
    if (inboxPercentage > 0 && spamPercentage === 0) {
      return {
        series: [inboxPercentage],
        colors: ['#059669'],
      };
    }

    // 100% Spam Ratio
    if (inboxPercentage === 0 && spamPercentage > 0) {
      return {
        series: [inboxPercentage, spamPercentage],
        colors: ['#B91C1C'],
      };
    }

    // Mixed Ration
    return {
      series: [inboxPercentage, spamPercentage],
      colors: ['#059669', '#B91C1C'],
    };
  }

  return {
    series: [0, 0],
    colors: ['#059669', '#B91C1C'],
  };
};

export const emailWarmupSettingsInputHandler = (
  value,
  minValue,
  maxValue,
  setValue,
  setError,
  t,
) => {
  if (!value || value < minValue) {
    setValue(value);
    setError(
      `${t('messages.zero_value_error_1')} ${minValue} ${t(
        'messages.zero_value_error_2',
      )}`,
    );
    return;
  }
  if (value > maxValue) {
    setValue(value);
    setError(`${t('messages.max_value_error')} ${maxValue}`);
    return;
  }
  setValue(value);
  setError('');
};

export const senderNameInputHandler = (value, setValue, setError, t) => {
  if (value.length < 3 || value.length > 80) {
    setValue(value);
    setError(t('messages.char_limit_error'));
    return;
  }
  setValue(value);
  setError('');
};

export const getIsEmailAccountWarmupSettingsSubmitDiabled = ({
  startWithEmailsPerDayError,
  increaseByEmailsEveryDayError,
  maximumEmailsToBeSentPerDayError,
  replyRateError,
  senderNameError,
  isDirty,
}): boolean =>
  startWithEmailsPerDayError ||
  increaseByEmailsEveryDayError ||
  maximumEmailsToBeSentPerDayError ||
  replyRateError ||
  senderNameError ||
  isDirty;

export const getPerformanceVariantForEmailDeliverabilityStats = (
  performance: number,
): PerformanceStatsVariant => {
  if (performance > 0) {
    return PerformanceStatsVariant.INCREMENT;
  }

  if (performance < 0) {
    return PerformanceStatsVariant.DECREMENT;
  }

  return null;
};

export const getPositiveValue = (value: number): number => {
  if (value < 0) {
    return value * -1;
  }

  return value;
};

export const getIsPieChartDataEmpty = (series: number[]): boolean => {
  if (series[0] === 0 && series[1] === 0) {
    return true;
  }

  return false;
};

export const getSortByKeyFilter = (key: string): EmailAccountWarmupSortKey => {
  if (key === 'sentToday') {
    return EmailAccountWarmupSortKey.SentToday;
  }
  if (key === 'receivedToday') {
    return EmailAccountWarmupSortKey.ReceivedToday;
  }

  return EmailAccountWarmupSortKey.Deliverability;
};

export const getEmailAccountFromList = (
  id: number,
  emails: EmailAccountWarmupList[],
) => emails?.filter((email) => email.id === id)[0];

export const getEmailWarmupStatus = (
  id: number,
  emails: EmailAccountWarmupList[],
): boolean => {
  const emailAccount = getEmailAccountFromList(id, emails);

  return Number(emailAccount?.warmupStatus) === EmailWarmupStatus.Running;
};

export const connectEmailAccountHandler = ({
  id,
  sendTurnOnOffEmailAccountWarmupRequest,
}) => {
  sendTurnOnOffEmailAccountWarmupRequest({
    id,
  });
};

export const reconnectEmailAccountHandler = ({
  subscriptionPlan,
  emailAccountId,
  emailAccountMethod,
  showConnectSmtpImapEmailAccountModal,
  sendConnectEmailAccountRequest,
}) => {
  if (!hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT)) {
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      planError(5000);
    } else {
      planError(5001);
    }
  } else if (emailAccountMethod === EmailAccountMethod.SmtpImap) {
    showConnectSmtpImapEmailAccountModal();
  } else {
    sendConnectEmailAccountRequest(emailAccountMethod, emailAccountId);
  }
};

export const onTunrOnOffRequestSuccessHandler = ({
  disconnectEmailWarmupModal,
  hideDisconnectEmailWarmupModal,
  t,
}) => {
  if (disconnectEmailWarmupModal) {
    hideDisconnectEmailWarmupModal();
  }
  toaster.success(
    disconnectEmailWarmupModal
      ? t('messages.email_warmup_paused_successfully')
      : t('messages.email_warmup_enabled_successfully'),
    {
      theme: Theme.New,
    },
  );
};

export const showRequestError = (error) => {
  if (error) {
    toaster.error(error.message, {
      theme: Theme.New,
      delay: 11000,
      showCloseIcon: true,
    });
  }
};

export const getShouldShowNoResult = ({
  isLoading,
  formattedData,
  filters,
}): boolean =>
  !isLoading && formattedData.length === 0 && filters.search !== '';

export const getShouldHideTable = ({
  isLoading,
  formattedData,
  filters,
}): boolean =>
  !isLoading && formattedData.length === 0 && filters.search === '';

export const isInitialAndCurrentFiltersSame = (initialFilters, filters) =>
  JSON.stringify(initialFilters) === JSON.stringify(filters);

export const getIsInitialRequestPending = ({
  getEmailAccountsInWarmupRequestStatus,
  isFilterDirty,
}): boolean =>
  getIsRequestPending(getEmailAccountsInWarmupRequestStatus) &&
  !isFilterDirty.current;

export const emailWarmupToggleHandler = ({
  row,
  check,
  onConnectEmailWarmup,
  showReconnectEmailAccountModal,
  showDisconnectEmailWarmupModal,
  showEmailWarmupEnableConsent,
  showEnableEmailWarmupModal,
  showTrulyInboxRefModal,
}) => {
  if (
    !row.isToggled &&
    check &&
    (row?.type === EmailAccountType.Gmail ||
      row?.emailServiceProvider === EmailServiceProvider.Gsuite)
  ) {
    // * Email warm-up status is false
    // * User trying to turn it on
    // * But the Email of type Gmail is not supported for Warmup
    // * So showing user an error for the same
    showTrulyInboxRefModal();
    return;
  }

  if (Number(row.warmupStatus) === EmailWarmupStatus.Suspended) {
    toaster.error(
      'This email is suspended from the warm-up pool due to high bounce rate. Contact support.',
      { theme: Theme.New },
    );
  } else if (!row.isToggled && check && !row.status) {
    // * Email warm-up status is false
    // * User trying to turn it on
    // * But user email is dissconnected in SH Account
    // * So showing user a modal to reconnect email account
    showReconnectEmailAccountModal(row.id, getEmailAccountMethod(row));
  } else if (!row.isToggled && check) {
    // * Email warm-up status is false
    // * User trying to turn it on
    // * And user email is connected in SH Account
    // * So checking for Email Warmup Enable Consent
    if (showEmailWarmupEnableConsent) {
      // * Email Warmup Enable Consent is true
      // * So making api call to enable email warmup
      showEnableEmailWarmupModal(row.id);
    } else {
      // * Email Warmup Enable Consent is false
      // * So showing user a modal to before enabling the email warmup
      onConnectEmailWarmup(row.id);
    }
  } else if (row.isToggled && !check) {
    // * Email warm-up status is true
    // * User trying to turn it off
    // * So showing user a confirmation modal before disabling email warmup
    showDisconnectEmailWarmupModal(row.id);
  }
};

export const getRecommendedLabelText = (value, t): string =>
  `${t('messages.recommended')} ${value}`;

export const getTooltipText = (): string =>
  hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
    ? 'Upgrade your plan to access advanced warm-up setting'
    : 'Please contact admin and request to upgrade your plan to access advanced warm-up setting';

export const setEmailWarmupSettingsInputValue = ({
  getEmailAccountWarmupSettingsRequestStatus,
  emailAccountWarmupSettings,
  setStartWithEmailsPerDay,
  setIncreaseByEmailsEveryDay,
  setMaximumEmailsToBeSentPerDay,
  setReplyRate,
  setSenderName,
}) => {
  if (
    getIsRequestSucceeded(getEmailAccountWarmupSettingsRequestStatus) &&
    emailAccountWarmupSettings
  ) {
    setStartWithEmailsPerDay(
      emailAccountWarmupSettings.warmUpInitialSendingLimit,
    );
    setIncreaseByEmailsEveryDay(
      emailAccountWarmupSettings.increaseEmailsByNumber,
    );
    setMaximumEmailsToBeSentPerDay(emailAccountWarmupSettings.maxSendingLimit);
    setReplyRate(emailAccountWarmupSettings.replyRate);
    setSenderName(emailAccountWarmupSettings.senderName);
  }
};

export const resetEmailWarmupSettingsInputValue = ({
  setStartWithEmailsPerDayError,
  setIncreaseByEmailsEveryDayError,
  setMaximumEmailsToBeSentPerDayError,
  setReplyRateError,
  setSenderNameError,
}) => {
  setStartWithEmailsPerDayError('');
  setIncreaseByEmailsEveryDayError('');
  setMaximumEmailsToBeSentPerDayError('');
  setReplyRateError('');
  setSenderNameError('');
};

export const getDataFieldForTable = (sortByKey: EmailAccountWarmupSortKey) => {
  if (sortByKey === EmailAccountWarmupSortKey.Deliverability) {
    return 'deliverabilityRate';
  }
  if (sortByKey === EmailAccountWarmupSortKey.SentToday) {
    return 'sentToday';
  }
  if (sortByKey === EmailAccountWarmupSortKey.ReceivedToday) {
    return 'receivedToday';
  }
  return '';
};

export const getEnableEmailWarmupConsent = (meta) => {
  if (!isEmpty(meta)) {
    const enableEmailWarmupConsentValue =
      _.find(meta, { code: UserSettingCode.ShowEmailWarmupEnableConsent })
        ?.value || 1;

    return Boolean(Number(enableEmailWarmupConsentValue));
  }

  return true;
};
