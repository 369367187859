import React from 'react';

import type { IProps } from './email-accounts-header-container';
import {
  OverlayPopover,
  Placement,
  TriggerType,
} from '../../../../../../shared/design-system/components/overlay';

const EmailAccountsHeader: React.FC<IProps> = ({ emailsCount }) => {
  const renderEmailAccounts = (
    type: 'active' | 'inactive' | 'paused',
    label: string,
    count: number,
  ) => (
    <div className="connect-email-accounts-item">
      <div className="connect-email-accounts-item--inner">
        <div className={type} />
        <p>{label}</p>
      </div>
      <p>{count}</p>
    </div>
  );

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="email-accounts-header d-flex align-items-center">
        <p className="email-accounts-header--title">Email Accounts</p>

        <OverlayPopover
          className="p-0 connected-email-accounts-popover"
          content={
            <div className="connected-email-accounts">
              {renderEmailAccounts(
                'active',
                'Active Accounts',
                emailsCount.connectedEmailsCount,
              )}
              {renderEmailAccounts(
                'inactive',
                'Inactive Accounts',
                emailsCount.disconnectedEmailsCount,
              )}
              {renderEmailAccounts(
                'paused',
                'Paused Accounts',
                emailsCount.pausedEmailsCount,
              )}
            </div>
          }
          trigger={TriggerType.Hover}
          placement={Placement.BottomStart}
        >
          <div className="connected-email-accounts-badge">
            <span>
              {emailsCount.connectedEmailsCount +
                (emailsCount.disconnectedEmailsCount +
                  emailsCount.pausedEmailsCount)}
            </span>
          </div>
        </OverlayPopover>
      </div>
    </div>
  );
};

export default EmailAccountsHeader;
