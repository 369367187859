import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';

import CreateEmailInfrastructure from './create-email-infrastructure';

import { getDomainPlansRequest } from '../../extra-actions';
import {
  addEmailAccountToEmailAccountInfrastructureCart,
  removeDomainFromEmailAccountInfrastructureCart,
  removeEmailAccountFromEmailAccountInfrastructureCart,
  resetEmailAccountInfrastructure,
  resetEmailAccountInfrastructureCart,
  setEmailAccountInfrastructureDataFromLS,
  setEmailAccountInfrastructureForwardingDomain,
} from '../../email-accounts-slice';

const mapStateToProps = (state: RootState) => ({
  firstName: state.home.firstName,
  lastName: state.home.lastName,
  userEmail: state.home.email,

  emailAccountInfrastructureServiceProvider:
    state.emailAccount.emailAccountInfrastructureServiceProvider,
  emailAccountInfrastructureCart:
    state.emailAccount.emailAccountInfrastructureCart,
  emailAccountInfrastructureForwardingDomain:
    state.emailAccount.emailAccountInfrastructureForwardingDomain,
  domains: state.emailAccount.domains,
  domainPlans: state.emailAccount.domainPlans,
  getDomainSearchRequestStatus:
    state.emailAccount.getDomainSearchRequest.status,
  hasErrorsInContactDetailsForm:
    state.emailAccount.hasErrorsInContactDetailsForm,
  getDomainPlansRequestStatus: state.emailAccount.getDomainPlansRequest.status,
});

const mapDispatchToProps = {
  sendGetDomainPlansRequest: () => getDomainPlansRequest(),

  removeDomainFromEmailAccountInfrastructureCart: (payload: string) =>
    removeDomainFromEmailAccountInfrastructureCart(payload),

  addEmailAccountToEmailAccountInfrastructureCart: (payload) =>
    addEmailAccountToEmailAccountInfrastructureCart(payload),
  removeEmailAccountFromEmailAccountInfrastructureCart: (payload: string) =>
    removeEmailAccountFromEmailAccountInfrastructureCart(payload),

  resetEmailAccountInfrastructureCart: () =>
    resetEmailAccountInfrastructureCart(),
  resetEmailAccountInfrastructure: () => resetEmailAccountInfrastructure(),

  updateEmailAccountInfrastructureForwardingDomain: (payload: string) =>
    setEmailAccountInfrastructureForwardingDomain(payload),

  setEmailAccountInfrastructureDataFromLS: () =>
    setEmailAccountInfrastructureDataFromLS(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(CreateEmailInfrastructure);
