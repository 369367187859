import React from 'react';
import { EmailServiceProvider } from '../../../../enums/email-account';

const errorDivContent = (
  hardLimit: number,
  onConnectAnotherEmailAccount,
  emailServiceProvider: EmailServiceProvider,
  t,
) => {
  let tempEmailServiceProvider = emailServiceProvider as string;

  if (emailServiceProvider === EmailServiceProvider.Gsuite) {
    tempEmailServiceProvider = EmailServiceProvider.Gmail;
  } else if (emailServiceProvider === EmailServiceProvider.Microsoft) {
    tempEmailServiceProvider = 'Outlook';
  } else if (emailServiceProvider === EmailServiceProvider.O365) {
    tempEmailServiceProvider = EmailServiceProvider.Microsoft;
  }

  return emailServiceProvider !== EmailServiceProvider.Other ? (
    <span>
      <span className="text-caplitalize">{tempEmailServiceProvider} </span>
      {tempEmailServiceProvider
        ? t('messages.hard_limit_error_content_1')
        : t('messages.hard_limit_error_content_capital_1')}{' '}
      {hardLimit} {t('messages.hard_limit_error_content_3')}
      <button
        type="button"
        className="link-button"
        onClick={onConnectAnotherEmailAccount}
      >
        {t('messages.hard_limit_error_content_5')}
      </button>
      {t('messages.hard_limit_error_content_4')}
    </span>
  ) : (
    <span>
      {'Your deliverability '} {t('messages.hard_limit_error_content_2')}{' '}
      {hardLimit} {t('messages.hard_limit_error_content_3')}
      <button
        type="button"
        className="link-button"
        onClick={onConnectAnotherEmailAccount}
      >
        {t('messages.hard_limit_error_content_5')}
      </button>
      {t('messages.hard_limit_error_content_4')}
    </span>
  );
};

export default errorDivContent;
