import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import {
  EmailAccountSetupScorePayload,
  EmailTimeIntervalSettingsRequest,
  UpdateEmailAccountGeneralSettingsRequestPayload,
  UpdateEmailAccountRampUpSettingsRequestPayload,
} from '../../../../types/request-payload';
import {
  connectEmailAccountRequest,
  connectSmtpImapAccountRequest,
  disconnectEmailAccountRequest,
  emailTimeIntervalSettingsRequest,
  getEmailAccountSetupScoreRequest,
  getEmailAccountRequest,
  getEmailAccountSettingsRequest,
  getSmtpImapAccountDetailsRequest,
  imageUploadRequest,
  testIMAPConnectionRequest,
  testSMTPConnectionRequest,
  updateEmailAccountRampUpSettingsRequest,
  updateEmailAccountRequest,
  updateSmtpImapAccountDetailsRequest,
} from '../../../../extra-actions';
import {
  resetEmailAccountSetupState,
  resetEmailAccountRampUpSettingsRequestState,
  resetEmailAccountAndSettings,
  resetUpdateSmtpImapAccountDetails,
  resetSmtpImapTestConnection,
  resetSmtpIMapConnectResponse,
  changeEmailAccountStatus,
} from '../../../../email-accounts-slice';
import BulkEmailUpdateModal from './bulk-email-update-modal';
import { EmailAccountMethod } from '../../../../enums/email-account';

import {
  ConnectSmtpImapAccountRequest,
  TestConnectionRequest,
  UpdateSMTPIMAPAccountRequest,
} from '../../../../types/smtp-imap';
import { Status } from '../../../../../../shared/enums/status';

import {
  GetAgencyClientDropdownRequestPayload,
  GetSelectedAgencyClientRequestPayload,
} from '../../../../../agency-client-management/types/request-payload';
import {
  getAgencyClientsDropdown,
  getSelectedAgencyClient,
} from '../../../../../agency-client-management/extra-actions';
import { FeatureUsage } from '../../../../../../shared/types/feature-usage';
import { getCustomDomainsListRequest } from '../../../../../settings/components/custom-domain/extra-actions';
import { EmailAccountsFilters } from '../../../../types/email-account';

const mapStateToProps = (state: RootState) => ({
  updateEmailAccountRampUpSettingsRequestStatus:
    state.emailAccount.updateEmailAccountRampUpSettingsRequest.status,
  updateEmailAccountRampUpSettingsRequestMessage:
    state.emailAccount.updateEmailAccountRampUpSettingsRequest.message,
  updateEmailAccountRampUpSettingsRequestError:
    state.emailAccount.updateEmailAccountRampUpSettingsRequest.error,

  getEmailAccountRequestStatus:
    state.emailAccount.getEmailAccountRequest.status,
  getEmailAccountRequestMessage:
    state.emailAccount.getEmailAccountRequest.message,
  getEmailAccountRequestError: state.emailAccount.getEmailAccountRequest.error,

  getEmailAccountSettingsRequestStatus:
    state.emailAccount.getEmailAccountSettingsRequest.status,
  getEmailAccountSettingsRequestMessage:
    state.emailAccount.getEmailAccountSettingsRequest.message,
  getEmailAccountSettingsRequestError:
    state.emailAccount.getEmailAccountSettingsRequest.error,

  getEmailAccountSetupScoreRequest:
    state.emailAccount.getEmailAccountSetupScoreRequest.status,
  getEmailAccountSetupScoreRequestMessage:
    state.emailAccount.getEmailAccountSetupScoreRequest.message,
  getEmailAccountSetupScoreRequestError:
    state.emailAccount.getEmailAccountSetupScoreRequest.error,

  connectEmailAccountRequestStatus:
    state.emailAccount.connectEmailAccountRequest.status,
  connectEmailAccountRequestMessage:
    state.emailAccount.connectEmailAccountRequest.message,
  connectEmailAccountRequestError:
    state.emailAccount.connectEmailAccountRequest.error,

  updateEmailAccountRequestStatus:
    state.emailAccount.updateEmailAccountRequest.status,
  updateEmailAccountRequestMessage:
    state.emailAccount.updateEmailAccountRequest.message,
  updateEmailAccountRequestError:
    state.emailAccount.updateEmailAccountRequest.error,

  disconnectEmailAccountRequestStatus:
    state.emailAccount.disconnectEmailAccountRequest.status,
  disconnectEmailAccountRequestMessage:
    state.emailAccount.disconnectEmailAccountRequest.message,
  disconnectEmailAccountRequestError:
    state.emailAccount.disconnectEmailAccountRequest.error,

  getTestSMTPConnectionRequestStatus:
    state.emailAccount.testSMTPConnectionRequest.status,
  getTestIMAPConnectionRequestStatus:
    state.emailAccount.testIMAPConnectionRequest.status,
  getTestSMTPConnectionRequestMessage:
    state.emailAccount.testSMTPConnectionRequest.message,
  getTestIMAPConnectionRequestMessage:
    state.emailAccount.testIMAPConnectionRequest.message,
  getTestSMTPConnectionRequestError:
    state.emailAccount.testSMTPConnectionRequest.error,
  getTestIMAPConnectionRequestError:
    state.emailAccount.testIMAPConnectionRequest.error,
  sendTestSMTPConnectionResponse: state.emailAccount.testSMTPConnectionResponse,
  sendTestIMAPConnectionResponse: state.emailAccount.testIMAPConnectionResponse,

  getConnectSmtpImapRequestStatus:
    state.emailAccount.connectSmtpImapAccountRequest.status,
  getConnectSmtpImapRequestMessage:
    state.emailAccount.connectSmtpImapAccountRequest.message,
  getConnectSmtpImapRequestError:
    state.emailAccount.connectSmtpImapAccountRequest.error,
  sendConnectSmtpImapResponse:
    state.emailAccount.connectSmtpImapAccountResponse,

  sendGetSmtpImapAccountDetailsRequestStatus:
    state.emailAccount.getSmtpImapAccountDetailsRequest.status,
  sendGetSmtpImapAccountDetailsResponse:
    state.emailAccount.sendGetSmtpImapAccountDetailsResponse,

  sendUpdateSmtpImapAccountRequestStatus:
    state.emailAccount.updateSmtpImapAccountDetailsRequest.status,
  sendUpdateEmailAccountRequestMessage:
    state.emailAccount.updateSmtpImapAccountDetailsRequest.message,
  sendUpdateSmtpImapAccountDetailsResponse:
    state.emailAccount.sendUpdateSmtpImapAccountDetailsResponse,

  emailTimeIntervalSettingsRequestStatus:
    state.emailAccount.emailTimeIntervalSettingsRequest.status,
  emailTimeIntervalSettingsRequestMessage:
    state.emailAccount.emailTimeIntervalSettingsRequest.message,

  authUrl: state.emailAccount.authUrl,
  isLoading: state.emailAccount.isLoading,
  customDomainList: state.customDomain.customDomainsList,
  emailAccount: state.emailAccount.emailAccount,
  emailAccountSettings: state.emailAccount.emailAccountSettings,
  emailAccountSetup: state.emailAccount.emailAccountSetup,
  subscriptionPlan: state.home.subscription?.planCode,
  agencyConfig: state.home.agencyConfig,

  selectedEmailAccounts: state.emailAccount.selectedEmailAccounts,
  bulkSelectedEmailAccounts: state.emailAccount.bulkSelectedEmailAccounts,

  getAgencyClientsDropdownRequestStatus:
    state.agencyClient.getAgencyClientsDropdownRequest.status,
  getAgencyClientsDropdownRequestMessage:
    state.agencyClient.getAgencyClientsDropdownRequest.message,
  getAgencyClientsDropdownRequestError:
    state.agencyClient.getAgencyClientsDropdownRequest.error,
  clients: state.agencyClient.clients,

  selectedAgencyClient: state.agencyClient.selectedAgencyClient,
  getSelectedAgencyClientsDropdownRequestStatus:
    state.agencyClient.selectedAgencyClientRequest.status,
  getSelectedAgencyClientsDropdownRequestMessage:
    state.agencyClient.selectedAgencyClientRequest.message,
  getSelectedAgencyClientsDropdownRequestError:
    state.agencyClient.selectedAgencyClientRequest.error,
});

const mapDispatchToProps = {
  sendGetEmailAccountRequest: (id: string | number) =>
    getEmailAccountRequest(id),

  sendGetEmailAccountSettingsRequest: (id: string | number) =>
    getEmailAccountSettingsRequest(id),

  sendGetCustomDomainsListRequest: () => getCustomDomainsListRequest(),

  sendUpdateEmailAccountRampUpSettingsRequest: (
    payload: UpdateEmailAccountRampUpSettingsRequestPayload,
  ) => updateEmailAccountRampUpSettingsRequest(payload),

  sendGetEmailAccountSetupScoreRequest: (
    payload: EmailAccountSetupScorePayload,
  ) => getEmailAccountSetupScoreRequest(payload),

  resetEmailAccountSetupState: () => resetEmailAccountSetupState(),

  resetEmailAccountAndSettings: () => resetEmailAccountAndSettings(),

  changeEmailAccountStatus: (status: Status) =>
    changeEmailAccountStatus(status),

  resetEmailAccountRampUpSettingsRequestState: () =>
    resetEmailAccountRampUpSettingsRequestState(),

  sendUpdateEmailAccountRequest: (
    emailAccountId: number,
    payload: UpdateEmailAccountGeneralSettingsRequestPayload,
  ) => updateEmailAccountRequest({ emailAccountId, ...payload }),

  sendEmailTimeIntervalRequest: (
    emailAccountId: number,
    payload: EmailTimeIntervalSettingsRequest,
  ) => emailTimeIntervalSettingsRequest({ emailAccountId, ...payload }),

  sendImageUploadRequest: (formData, onUploadProgress) =>
    imageUploadRequest({ formData, onUploadProgress }),

  sendConnectEmailAccountRequest: (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => connectEmailAccountRequest({ method, emailAccountId }),

  sendDisconnectEmailAccountRequest: (emailAccountId: number) =>
    disconnectEmailAccountRequest(emailAccountId),

  sendGetSmtpImapAccountDetailsRequest: (emailAccountId: number) =>
    getSmtpImapAccountDetailsRequest(emailAccountId),
  sendUpdateSmtpImapAccountDetailsRequest: (
    emailAccountId: number,
    payload: UpdateSMTPIMAPAccountRequest,
  ) => updateSmtpImapAccountDetailsRequest({ emailAccountId, payload }),

  sendTestSMTPConnectionRequest: (payload: TestConnectionRequest) =>
    testSMTPConnectionRequest(payload),
  sendTestIMAPConnectionRequest: (payload: TestConnectionRequest) =>
    testIMAPConnectionRequest(payload),
  sendConnectSmtpImapAccountRequest: (payload: ConnectSmtpImapAccountRequest) =>
    connectSmtpImapAccountRequest(payload),
  resetSmtpImapTestConnection: () => resetSmtpImapTestConnection(),
  resetSmtpIMapConnectResponse: () => resetSmtpIMapConnectResponse(),
  resetUpdateSmtpImapAccountDetails: () => resetUpdateSmtpImapAccountDetails(),
  sendGetAgencyClientsDropdownRequest: (
    payload: GetAgencyClientDropdownRequestPayload,
  ) => getAgencyClientsDropdown(payload),
  sendGetSelectedAgencyClientRequest: (
    payload: GetSelectedAgencyClientRequestPayload,
  ) => getSelectedAgencyClient(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onClose: () => void;
  onShowBulkEmailUpdateConfirmationModal: () => void;
  showBulkEmailAccountUpdateConfirmationModal?: boolean;
  onHideBulkEmailUpdateConfirmationModal?: () => void;
  sendBulkEmailAccountUpdateRequest?: (payload) => void;
  filters: EmailAccountsFilters;
  featureUsages: FeatureUsage[];
};

export default connector(BulkEmailUpdateModal);
