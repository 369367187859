const onboarding = {
  step3: {
    hi: 'Hi',
    welcome_to_saleshandy:
      'Welcome to Saleshandy v3.0! Your new place for sales engagement.',
    all_of: `All of your email communication can be managed using Sequences -
    multi-touch drip campaigns to automate your salesbook and boost
    productivity.
 `,
    lets_quickly: `Let’s quickly see how to use it.    
 `,
    lets_start: `Let's Start`,
  },
  step4: {
    set_up: `Set up the communication flow. If you don’t have any particular campaign
    in mind right now, you can explore our sample Sequence.`,
  },
  step5: {
    this_is_where: `This is where you can analyse the Prospects and track engagement of your Sequence.
`,
    we_will_come: `We will come back to it in detail after we create Steps and launch the
    Sequence.`,
    great: `🎉 Great!`,
  },
  step6: {
    start_by_prospects: `Start by adding Prospects with whom you would like to start engaging
    with emails.`,
  },
  step7: {
    steps_created: `Steps created`,
    prospects_added: `Prospects added`,
    activate_the_sequence: `Activate the Sequence to send the step-wise emails to your prospects.
    You can then check Performance of the Sequence in`,
    tabs: `tabs`,
    you_are_all_set: `You are all set!`,
  },
  step8: {
    way_to_go: `Way to go!!`,
    for_every_sequence: `For every Sequence you can monitor Performance of the Sequence in`,
    check_our_tutorials: `Check our tutorials to learn best practices to use Saleshandy to boost
    your business.`,
  },
};

const emptyList = {
  emailsTab: {
    you_asked: `You asked and we listened. It’s official.`,
    email_tab_another_way: `Emails tab, another way to efficiently track your emails,
    comes out on October 5th, 2021.`,
    through_this: `Through this, you could now easily manage & monitor your email
    activities. With the email tab you will be able to see how
    many emails are sent and the schedule of upcoming emails.`,
    please_click_on: `Please click on`,
    link_to_view: `link to view our product roadmap and provide us with any
    additional feedback that you have for us to help shape our
    product better for our users.`,
  },
  templates: {
    start_templates: 'Start With Templates',
    templates_help:
      'Templates will help you manage your content so that you create your sequences faster than ever',
    add_templates: 'New Template',
  },
};

const emails = {
  send_email_subject: 'Send the email now ?',
  send_email: 'Send Email',
};

const templates = {
  clone_warning: 'Are you sure you want to clone the template',
  clone_template: 'Clone Template',
  delete_warning:
    'Are you sure you want to delete this template? The template will be deleted permanently and you can’t undo this action.',
  delete_template: 'Delete Template',
  share_warning:
    'Everyone in your team can use this template once you share it. Do you want to continue?',
  share_template: 'Share Template',
  enter_subject: 'Please enter subject',
};

const tags = {
  tags_title: 'Add or Remove Tags',
  no_tags_found_title: 'No tags defined yet.',
  no_tags_found_sub_title: 'Go ahead, add your first tag! 🔥',
  tags_placeholder: 'Enter a tag',
  tags_removeMode: 'Applied tags will be displayed here',
  add_tag: '+ Add Tags',
  prospects_updated: 'Prospects Updated Successfully',
};

const header = {
  mark_all_as_read: 'Mark all as Read',
  resume: 'Resume',
  reconnect: 'Reconnect',
  download: 'Download',
  viewLeads: 'View',
  notifications: 'Notifications',
  profile_settings: 'Profile Settings',
  feature_request: 'Feature Request',
  become_our_affiliate: 'Become Our Affiliate',
  lead_finder_credits: 'Lead Finder Credits',
  email_verification: 'Email Verification Credits',
  total_prospects: 'Total Prospects',
  monthly_emails_sent: 'Monthly Emails Sent',
  help: 'Help',
  login: 'Login',
  logout: 'Log Out',
  emailDisconnected: 'Email Disconnected',
  sequencePaused: 'Sequence Paused',
  changeLog: `What’s new`,
  scheduleADemo: 'Schedule a Demo',
  noNotifications: 'No new notifications yet',
  sequenceProspectExportForClient: 'Sequence Bulk Prospect Selection Report',
  sequenceReportClient: 'Your Sequence Report is Ready',
  uniboxConversationForClient: 'Bulk conversation Select Report',
  emailConnectionReport: 'Your Email Connection Report is Ready',
  emailAccountBulkConnectClient: 'Your Email Connection Report is Ready',
  prospectVerify: 'Your Email Verification Report is ready',
  sequenceBulkProspectClient: 'Sequence Bulk Prospect selection report',
  whitelabelDomainSet: 'Brand domain is Whitelabeled',
  dnsExport: 'Your DNC Export File is ready',
  dnsImport: 'Your DNC Import File is ready',
  sequenceProspectImport: 'Your Prospect Import File is ready',
  viewDraft: 'View Draft',
  uniboxScheduleCanceled: 'Scheduled email not sent from Unified Inbox',
  sequenceProspectBulkAction: 'Sequence Bulk Prospect selection report',
  'prospect-bulk-action': 'Prospect Bulk selection report',
  leadRevealCompleted: 'Bulk-reveal request is completed',
  leadRevealCompletedNoEmailOrPhone: 'Bulk-reveal request is completed',
  leads: 'View',
  activate: 'Activate',
  emailAccountPaused: 'Email Account Paused',
  domainRegistrationInProgress: 'Domain Registration In Progress',
  domainRegistrationComplete: 'Domain Registration Complete',
  viewEmailAccounts: 'View Email Accounts',
  prospectImportAsync: 'Prospect import is in progress',
  sequenceProspectImportAsync: 'Prospect import is in progress',
  prospectImportAsyncCompleted: 'Prospect import is completed',
  sequenceProspectImportAsyncCompleted: 'Prospect import is completed',
  leadRevealCompletedAsync: 'Lead Reveal is in Progress',
  leadRevealCompletedNoEmailOrPhoneAsync: 'Lead Reveal is in Progress',
  leadRevealCompletedAsyncProcess: 'Lead Reveal is completed',
  leadRevealCompletedNoEmailOrPhoneAsyncProcess: 'Lead Reveal is completed',
  viewList: 'View List',
  viewLead: 'View Leads',
};

const blacklist = {
  valid_domain: 'Enter the valid domain name in the format domain.com',
  blacklist_domains: 'Blacklist Domains',
  blacklist_domain_sub_header:
    'Prevent emails going to Prospects in specific domains.',
  blacklist_domain_instruction:
    'Use a comma to separate multiple domains. For example: gmail.com, facebook.org, outlook.com',
};

const profileProgress = {
  // keeping object keys for profile progress steps to be same
  // as receiving in api response payload
  'watch-video': 'Watch Video',
  'connect-email': 'Connect Email',
  'create-sequence': 'Create Sequence',
  'add-prospects': 'Add Prospects',
  'activate-sequence': 'Activate Sequence',
  hello: 'Hello',
  getting_started: 'Getting Started',
  you_are_gonna_be_a_saleshandy_superstar:
    'You’re gonna be a Saleshandy superstar',
  completed: 'Completed',
  nice_work: 'Nice Work',
  going_good: 'Going Good',
  excellent_work: 'Excellent Work',
  activate_sequence_message:
    'Turn on this toggle to activate the sequence and start outreaching to your prospects.',
  learn_create_sequence: 'Learn how to create a sequence in 3 minutes',
};

const watchVideoModal = {
  create_sequence: 'Create Sequence',
  watch_later: 'Watch Later',
  watch_video_description_1:
    'Before you get started, we recommend watching this video to learn',
  watch_video_description_2:
    'how to set up and run your first email sequence with Saleshandy.',
};

const apiKey = {
  api_key_home_part_1:
    'Your API keys need to be treated as securely as any other password. Refer ',
  api_key_home_part_2: `api docs`,
  api_key_home_part_3: ' for integration',
  enter_your_label: 'Enter your label',
  save_filter_lable: 'Enter a filter name',
  label: 'Label',
  invalid_label_input: 'Invalid label input',
  only_letters_allowed: 'Only characters, numbers, & hyphens are allowed',
  label_less_than: 'Label must be less than 25 character',
  api_keys_empty: 'API Keys are empty',
  please_add_api_keys: 'Please add API Keys',
};

const sequencePauseMessages = {
  bounce_limit_reached:
    'This sequence is paused because of the high number of bounced emails. We suggest you verify the prospects before resuming the sequence.',
  email_account_disconnected:
    'This sequence is paused because your email account was disconnected. Please visit Settings to reconnect and start sending emails again.',
  plan_expired:
    'This sequence is paused because your plan was expired. Please upgrade your account to resume sequence if you have not upgraded your account yet.',
  manually_paused:
    'You have manually paused your sequence. Activate your sequence to start sending emails again.',
  email_sending_limit_reached:
    "This sequence is paused because you have reached your provider's sending limit. Please resume after 24 hours of your sequence paused date.",
  trial_quota_exhausted:
    'The sequence is paused because you have exhausted the trial plan quota.',
  plan_paused:
    'You subscription is paused due to which your sequences has been paused.',
  agency_client_deleted:
    'This sequence is paused because the client is deleted. Please resume the sequence with a toggle button.',
  agency_client_disabled:
    'This sequence is paused because the client is disabled. Please resume the sequence with a toggle button.',
  email_account_deleted:
    'This sequence is paused because the email account was deleted.',
  plan_downgraded:
    'You are currently on starter plan due to which all your sequences are paused. Update your existing plan to start sending emails.',
  over_limit_connected_emails:
    'Your sequence is paused because it has more than 3 email accounts connected which is not allowed in the current plan, Please upgrade to activate the sequence.',
  email_account_paused:
    'This sequence is paused because your email account is paused. Please visit Settings -> Email account and activate it again to start sending emails.',
  email_account_removed:
    'This sequence is paused because your active email account is removed. Please visit Settings -> Email account and activate it again to start sending emails.',
};

const emailEditorModals = {
  test_email_title: 'Send test email',
  test_email_content:
    'Preview email will be delivered to this email address. You can also send to multiple email addresses by separating them with comma. Maximum number allowed is 5 emails.',
  email_account_note_content:
    'Note: You might lose your currently unsaved email data if you click on connect now. Click on cancel to save the email text and avoid losing data. ',
  email_account_disconnected_title: 'Email account disconnected',
  email_account_disconnected_content:
    'Your email account is disconnected. Please connect your email account to continue sending emails.',
  email_account_not_connected_title: 'Email account not connected',
  email_account_not_connected_content:
    'Please select an email account from sequence settings to continue sending emails.',
  email_not_added_title: 'Email account not added',
  email_not_added_content:
    'Your email account has not been added. Please connect your email account to continue sending emails.',
  confirmation_modal_title: 'Want to close the step without saving changes?',
  confirmation_modal_content:
    'Closing the editor would discard the unsaved changes made in the email. If you want to save those changes, click Cancel and then the Save button.',
  confirmation_modal_content_2:
    'Closing the editor would discard the unsaved changes made in the step. If you want to save those changes, click Cancel and then the Save button.',
};

const coldEmailTips = {
  content_guide: 'Content Guide',
  cold_email_tips: 'Cold Email Tips',
  cold_email_tip_1_title: 'Personalize the subject line using merge tags',
  cold_email_tip_1_content:
    'An email with personalized subject lines gets 6% higher open rates.',
  cold_email_tip_2_title: 'Add preheader text to summarise the email',
  cold_email_tip_2_content:
    'Emails with relevant preheaders get 10% higher open rates.',
  cold_email_tip_3_title: 'Learn how to write effective cold emails',
  cold_email_tip_3_content_1: 'Click',
  cold_email_tip_3_content_2: 'here',
  cold_email_tip_3_content_3:
    'to read the ultimate guide to writing cold emails',
  cold_email_tip_4_title: 'Use Spintax to maximize deliverability',
  cold_email_tip_4_content_1:
    'Spintax allows you to generate multiple variants in one email.',
  cold_email_tip_4_content_2: 'Learn more',
};

const emailSuggestions = {
  subject_title: 'Subject Length',
  character: 'character',
  characters: 'characters',
  subject_helper_text: '30-60 characters suggested.',
  word_title: 'Word Count',
  word_helper_text: '50-200 words suggested.',
  word_helper_text_1: '25-149 words suggested.',
  personalization_title: 'Personalization',
  merge_tag: 'merge tag',
  merge_tags: 'merge tags',
  personalization_helper_text: '2 or more merge tags suggested.',
  link_title: 'Links',
  link: 'link',
  links: 'links',
  link_helper_text: 'We suggest minimal usage.',
  spammy_title: 'Spamminess',
  word: 'word',
  words: 'words',
  spammy_helper_text: 'We suggest minimal usage.',
};

const needHelpCta = {
  need_help: 'Need Help?',
  cta_1: 'You can',
  cta_2: 'initiate a chat',
  cta_3: 'to get assistance from us.',
  cta_4: 'schedule a call',
};

const customTrackingDomainMessages = {
  turn_on_line_tracking: 'Turn on Link Tracking?',
  link_tracking_disabled: 'Link Tracking Disabled',
  domain_not_set_part_1:
    'We recommend you set up a custom domain to track links using branded URLs.',
  domain_not_set_part_2: 'It helps you improve the email delivery rate.',
  domain_not_set_part_3: 'Do you still want to turn on the link tracking?',
  domain_set_part_1:
    'We recommend disabling link tracking for a better email delivery rate.',
  domain_set_part_2: 'Enabling link tracking may decrease the open rate by 5%.',
  domain_set_part_3: 'Do you still want to turn it on?',
  delete_custom_domain_title: 'Do you want to delete this custom domain?',
  delete_custom_domain_title_2: 'You cannot delete this custom domain!',
  delete_default_custom_domain_content_1:
    'You are trying to delete default custom tracking domain, upon deleting this system will set new default custom tracking domain.',
  delete_default_custom_domain_content_2:
    'Deleting a custom tracking domain can harm your email deliverability.',
  delete_default_custom_domain_content_3:
    'You are trying to delete default custom tracking domain. Upon deleting this, system will need to set new default custom tracking domain.',
  delete_default_custom_domain_content_4:
    'Please add new custom tracking domain in order to delete this.',
};

const riskyProspectsMessages = {
  do_you_want_to_send_emails_to_risky_prospects:
    'Do you want to send emails to Risky Prospects?',
  risky_prospect_model_title_1:
    'To maintain an optimal sender reputation, we suggest not sending emails to risky prospects, as there are high chances of emails getting bounced.',
};

const planSummary = {
  userAccounts: 'User Accounts',
  emailAccounts: 'Email Accounts',
  newUserSeats: 'New User Seats',
  newEmailSeats: 'New Email Seats',
};

const timeIntervalSettings = {
  time_interval_error: 'Time interval should be between 20 to 2000 seconds',
  same_time_interval_error: 'There should be a minimum interval of 10 seconds',
  time_interval_warning:
    'Sending too many emails in an hour may lead to suspension or blacklisting of the account. We suggest keeping this interval at 60-190 seconds for optimal deliverability.',
};

const rampUpSettings = {
  emails_per_day_error:
    'The value could not be greater than the maximum number of emails per day.',
  emails_per_day_percent_error:
    'The percentage value must be between 1 and 100.',
  daily_rampup_title: 'Daily Ramp-Up (to warmup email account)',
  daily_rampup_tooltip_text_1:
    'We suggest you enable this option to gradually increase the daily sending quota as it helps you warm up your email account.',
  daily_rampup_tooltip_text_2:
    'You can turn it off any time to move to a static daily quota.',
  daily_rampup_tooltip_text_3: 'Click here',
  daily_rampup_tooltip_text_4: 'to learn more.',
  emails_per_day_start: 'Start with',
  emails_per_day_end: 'emails per day.',
  emails_per_day_suggestion: '(We suggest to start with 5)',
  emails_per_day_percent_start: 'Increase by',
  emails_per_day_percent_end: 'percent per day.',
  emails_per_day_percent_suggestion: '(We suggest 10-20%)',
  rampup_results: 'Total number of emails to be sent today is',
  disable_rampup_confirmation_title:
    'Are you sure you want to deactivate Ramp-Up?',
  disable_rampup_confirmation_content_1:
    'Deactivating Ramp-up in the middle may increase your email sending activity steeply,',
  disable_rampup_confirmation_content_2:
    'which may affect your sequence performance. We recommend allowing Ramp-up to gradually',
  disable_rampup_confirmation_content_3:
    'increase the sending until it reaches the maximum number of emails per days limit set by you.',
  ramp_up_limit_reached:
    'Ramp-up was disabled after it reached the maximum number of emails per day limit.',
  save_rampup_confirmation_title: 'Are you sure you want to change the value?',
  save_rampup_confirmation_content_1:
    'Upon saving the changes, updated sending settings will be applicable from',
  save_rampup_confirmation_content_2: 'onwards. ',
  save_rampup_confirmation_content_3: 'Do you want to save it?',
  today: 'today',
  tomorrow: 'tomorrow',
};

const emailAccountTableHeader = {
  sender: 'Sender',
  sending_limit: 'Sending Limit',
  setup_score: 'Setup Score',
  connected_by: 'Connected By',
  actions: 'Actions',
};

const unsubscribeLinkModal = {
  unsubscribe_link_modal_title:
    'Are you sure you wish to remove unsubscribe link?',
  unsubscribe_link_modal_message_one:
    'We strongly recommend to keep unsubscribe link in the emails to reduce spam complaints by recipients.',
  unsubscribe_link_modal_message_two:
    'Disabling this option may affect your email deliverability negatively.',
};

const emailHealthScore = {
  email_disconnected_message:
    'Email is disconnected. Connect it for accurate setup score.',
  learn_how_to_set_this: 'Learn How to set this',
  learn_how_to_warm_up: 'Learn How to warm-up',
  spf_result_message_1:
    'SPF (Sender Policy Framework) is an essential record for email authentication. ',
  spf_result_message_2: 'It vastly affects your email deliverability. ',
  spf_result_message_3:
    'We strongly suggest you set it up before you start sending emails. ',
  ctd_error_result_message_1: 'Custom tracking domain is a feature ',
  ctd_error_result_message_2:
    'that allows you to replace generic tracking domain with your domain ',
  ctd_error_result_message_3:
    'which eventually helps you improve your email deliverability. ',
  ctd_error_result_message_4:
    'We strongly recommend you set it up before you start sending emails.',
  dmarc_result_message_1:
    'DMARC is a standard email authentication method, and it helps mail administrators prevent hackers ',
  dmarc_result_message_2:
    'and other attackers from spoofing their organization and domain. ',
  dmarc_result_message_3:
    "It's suggested to set it up for optimum email results.",
  email_age_result_message_1:
    'Your email account is old enough to get decent deliverability.',
  email_age_result_message_2:
    ' We suggest you use our email ramp-up feature if you wish to build a good sender reputation.',
  email_age_error_result_message_1:
    'Newly created email accounts are vulnerable to spam filters. ',
  email_age_error_result_message_2:
    'The email provider may also restrict them if you attempt to ',
  email_age_error_result_message_3:
    'send a high number of emails from a relatively new email account.',
  email_age_error_result_message_4:
    'We suggest that users keep the lowest sending quota and ',
  email_age_error_result_message_5:
    'use our email ramp-up feature to gradually increase the sending quota.',
  email_age_error_result_message_6:
    'If you believe that your email is old, please disconnect the email &',
  email_age_error_result_message_7:
    'reconnect the email and check the health score again.',
  check_deliverability_loading_1:
    'We are checking the latest health score for this email account ',
  check_deliverability_loading_2:
    'which might take up to 2 minutes. Please wait while we fetch the details. ',
  check_deliverability_1:
    "We allows you to assess your email account's healthiness on ",
  check_deliverability_2:
    "various aspects to know if it's good enough to send emails. ",
  check_deliverability_3:
    'We also highlight the issues you need to fix to achieve the best results.',
  need_help_to_improve_your_score: 'Need help to improve your score?',
  need_help_content:
    'Improve your email setup score by scheduling a call with our email deliverability expert.',
  talk_to_deliverability_expert: 'Talk to Deliverability Experts',
  check_deliverability_score: 'Check Deliverability Score',
  email_setup_score: 'Email Setup Score',
  email_account_health: 'Email Account Health',
  last_updated_at: 'Last updated at',
  email_setup_score_description:
    'This score measures the ability of an email account to set up and configure properly. Also, it evaluates authentication protocols and best practices suggested by expert to maintain perfect email health.',
  sequence_setup_score: 'Sequence Setup Score',
  sequence_setup_score_description:
    'This score is calculated based upon sequence configuration and best practices. Also, it evaluates some key parameters to run successful sequence.',
  email_writing_score: 'Email Writing Score',
  email_writing_score_description:
    'This score measures the effectiveness of a content. Also, it evaluates the possible red flags and suggest some best practices to write a perfect cold email.',
  check_deliverability_manually_fetch:
    'Saleshandy could not fetch health score for this email. Please try again.',
  changes_updated_successfully: 'Changes updated successfully.',
  daily_ramp_up_settings_updated_successfully:
    'Daily ramp-up settings updated successfully',
  sending_limit_updated_text_1:
    'Sending limit updated. Now, you can increase the total number of emails per day upto ',
  sending_limit_updated_text_2: ' emails.',
  lower_value_limit_then_ramp_up_limit_error:
    'This value should not be less than the email ramp-up value to be sent today.',
  soft_limit_modal_title: 'Suggested sending limit exceeded',
  soft_limit_modal_content_1: 'We recommend sending less than',
  soft_limit_modal_content_2: 'emails a day from a',
  soft_limit_modal_content_3: 'free',
  soft_limit_modal_content_4: 'account.',
  soft_limit_modal_content_5:
    'Please connect a business email for higher sending limits.',
  soft_limit_modal_content_6: 'I understand the risk of sending more than',
  soft_limit_modal_content_7: 'emails',
  soft_limit_modal_content_8: 'from this email account',
  soft_limit_modal_content_9: 'emails a day',
  email_per_day_success_message:
    'Total number of emails per day updated successfully',
  zero_limit_error: 'Sending limit should be atleast 1',
  learn_how_to_set: 'Learn how to set',
  hard_limit_error_content_1: 'might block your account if you send more than',
  hard_limit_error_content_capital_1:
    'This might block your account if you send more than',
  hard_limit_error_content_2: 'might be affected if you send more than',
  hard_limit_error_content_3: 'emails. We recommend',
  hard_limit_error_content_4: 'to send more emails.',
  hard_limit_error_content_5: 'connecting another email account',
  access_token_error_description_1: 'Your account has been disconnected',
  access_token_error_description_2:
    'Please reconnect it to fetch latest health score.',
  get_it_done_by_experts: 'Get it done by Experts',
};

const prospectFilterLabels = {
  filters: 'Filters',
  owners: 'Owners',
  tags: 'Tags',
  sequence: 'Sequence',
  status: 'Status',
  verification_status: 'Verification Status',
  created_date: 'Created Date',
  outcomes: 'Outcomes',
  creation_source: 'Creation Source',
  current_step: 'Current Step',
  imported_file: 'Imported File',
};

const noSeatAvailableEmail = {
  no_seat_available_email_subject_1: 'Email account seats exhausted.',
  no_seat_available_email_subject_2: 'Need to upgrade to add more email seats',
  no_seat_available_email_body_1:
    'It appears that our subscription has exhausted',
  no_seat_available_email_body_2:
    'the quota of connecting email accounts as per the current',
  no_seat_available_email_body_3:
    'subscription and therefore, I am unable to connect my email.',
  no_seat_available_email_body_4:
    'I’d request you to upgrade the current subscription to add extra email account seats.',
};

const saleshandyUsageLabels = {
  sales_team: 'Sales Team',
  marketing_team: 'Marketing Team',
  agency_consultant: 'Agency / Consultant',
  recruiters: 'Recruiters',
  other: 'Other',
};

const teamSizeLabels = {
  team_1_10: '1-10',
  team_11_50: '11-50',
  team_51_200: '51-200',
  team_201_500: '201-500',
  team_501_1000: '501-1000',
  team_1000_plus: '1000+',
};

const industryLabels = {
  it_services: 'it services',
  saas: 'saas',
  human_resources: 'human resources',
  marketing_advertising: 'marketing & advertising',
  internet: 'internet',
  other: 'other',
};

const SMTPIMAPConnection = {
  issue_with_smtp_connection: 'Issue with SMTP connection.',
  issue_with_imap_connection: 'Issue with IMAP connection.',
};

const tipsToConnectSMTPIMAPEmail = {
  tips_title: 'Tips to successfully connect your email account:',
  smtp_tips_one: 'Ensure your email account is enabled for SMTP.',
  smtp_tips_two: 'Ensure that 2FA is not enabled on your email account.',
  smtp_tips_three:
    'If 2FA is enabled, please use app-password instead of email password.',
  imap_tips_one: 'Ensure your email account is enabled for IMAP.',
  imap_tips_two:
    'Ensure that use your email account credentials for IMAP connection.',
  smtp_gmail_tips_one_a: 'We strongly recommend creating an',
  smtp_gmail_tips_one_b: 'app password',
  smtp_gmail_tips_one_c:
    'to connect your Gmail account through SMPT/IMAP method.',
  smtp_gmail_tips_two:
    'Enabling 2-step verification is mandatory to create an app password for a Gmail account.',
  smtp_gmail_tips_three_a: 'Click here',
  smtp_gmail_tips_three_b:
    'to learn how to create & use an app password for connecting your Gmail account through SMTP/IMAP method.',
  smtp_gmail_tips_three_c: 'Learn from video.',
  imap_gmail_tips_one: 'Make sure that IMAP is enabled for this Gmail mailbox.',
  imap_gmail_tips_two: 'You can use the same app password to connect the IMAP.',
};

const mailboxEmails = {
  track_your_first_email: 'Track your first email',
  install_saleshandy_connect:
    'Install Saleshandy Connect extension and start tracking individual emails.',
  see_how_extension_works: 'See how the extension works',
  install_chrome_extrension: 'Install Chrome Extension',
  delete_mailbox_email: 'Do you want to delete this email entry?',
  delete_mailbox_email_description:
    'All the email tracking-related information for this email will be erased and can’t be recovered.',
  email_deleted_successfully: 'Email has been deleted successfully.',
  notification_turned_on_tooltip_text: 'Turn off notification',
  notification_turned_off_tooltip_text: 'Turn on notification',
  notification_turned_on_successfully:
    'Notification has been turned on successfully.',
  notification_turned_off_successfully:
    'Notification has been turned off successfully.',
};

const chromeExtensionSettings = {
  desktop_notification: 'Desktop Notifications',
  track_email_by_my_team: 'Track emails by my team',
  track_email_by_my_team_tooltip:
    'This option allows you to turn on/off email tracking for individual emails sent to your team members.',
  test_notification: 'Test Notification',
  test_notification_tooltip: "If you're facing some issues with notifications,",
  link_click_here: 'click here',
  notification_body: 'This is a sample notification for Saleshandy activity.',
  notification_not_supported:
    'This browser does not support desktop notifications.',
  notification_denied_message_1:
    'You have denied notifications for Saleshandy,',
  notification_denied_message_2:
    'for a step by step guide to enable notifications again.',
  settings_saved: 'Settings saved.',
};

const emailWarmup = {
  start_with_email_per_day: 'Start with emails/day',
  increase_by_emails_every_day: 'Increase by emails every day',
  maximum_emails_sent_per_day: 'Maximum emails to be sent per day',
  reply_rate: 'Reply rate',
  sender_name: 'Sender name',
  recommended: 'Recommended',
  zero_value_error_1: 'The value could not be less than',
  zero_value_error_2: 'or in fraction.',
  max_value_error: 'The value should not be more than',
  char_limit_error: 'The value should be in between 3 to 80 characters.',
  email_warmup_paused_successfully:
    'Email Warm-up has been paused successfully.',
  email_warmup_enabled_successfully: 'Email Warm-up enabled successfully.',
  reconnect_email_account_title: 'Your email account is disconnected!',
  reconnect_email_account_content:
    "You can't turn on email warm-up for a disconnected email account. Please reconnect it first.",
  disconnect_email_account_title:
    'Do you want to turn off email warm-up for this email account?',
  disconnect_email_account_content:
    'Email Warm-up lets you build & maintain an optimal sender reputation. Removing it may impact deliverability in the future.',
  email_account_is_connected: 'Email account is connected.',
  email_account_is_disconnected: 'Email account is disconnected.',
  email_warmup_empty_list_title:
    'Build your email reputation with our email warm-up & never land in spam again.',
  email_warmup_empty_list_desc: 'Add your first email account to get started.',
  see_how_warmup_works: 'See how warm-up works',
  add_new_email_account: 'Add new Email Account',
  email_warmup_settings_updated_successfully:
    'Email warmup settings updated successfully.',
  deliverability: 'Deliverability',
  total: 'Total',
  inbox: 'Inbox',
  spam: 'Spam',
  received_today: 'Received Today',
  received_in_last_seven_days: 'Received in last 7 days',
  received_in_last_thirty_days: 'Received in last 30 days',
  sent_today: 'Sent Today',
  sent_in_last_seven_days: 'Sent in last 7 days',
  sent_in_last_thirty_days: 'Sent in last 30 days',
  click_here_to_turn_on_warmup:
    'Click here to turn on the email warm-up for this email account.',
  click_here_to_turn_off_warmup:
    'Click here to turn off the email warm-up for this email account.',
  running: 'Running',
  warmup_settings: 'Warm-up Settings',
  enable_email_warmup_title: 'Activate email warm-up for this email account?',
  enable_email_warmup_desc_1:
    'Enabling warm-up would allow Saleshandy to send warm-up emails from this email account,',
  enable_email_warmup_desc_2:
    'and you will also receive warm-up emails from other Saleshandy users.',
  dont_show_this_message_again: 'Don’t show me this message again.',
};

const errorBoundary = {
  error_boundary_title: 'Oops! It seems something broke down at our end',
  error_boundary_sub_text_one: 'Engineering team is auto-notified.',
  error_boundary_sub_text_two: 'We request you to re-check in some time or',
  return_to_application: 'Return to the Application',
};

const emailAccountType = {
  google: 'Gmail & Google Workspace',
  microsoft: 'Exchange, O365, Outlook & Hotmail',
  smtp_imap: 'Any other Email Service provider account',
};

const nativeAttachment = {
  unsupported_file_type:
    'The file type you are trying to add is not supported.',
  email_content_size_exceeds: 'Your total email size should not exceed',
  number_of_attachments_allowed_1: 'More than',
  number_of_attachments_allowed_2: 'attachments cannot be uploaded.',
  attachment_removed_successfully: 'Attachment has been removed successfully.',
};

const adminSettings = {
  are_you_sure_you_want_to_turn_this_on:
    'Are you sure you want to turn this on?',
  are_you_sure_you_want_to_turn_this_off:
    'Are you sure you want to turn this off?',
  turning_this_toggle_on_will_allow_you:
    'Turning this toggle on will allow you',
  turning_this_toggle_off_will_not_allow_you:
    'Turning this toggle off will not allow you',
  to_have_one_prospect_in_multiple_sequences_simultaneously:
    'to have one prospect in multiple sequences simultaneously.',
  and_your_team_member_to_verify_prospects_while_importing_them:
    'and your team member to verify prospects while importing them.',
  change_will_not_affect:
    'This change will not affect the existing prospects already present in multiple sequences.',
  change_will_apply_to_future_imports:
    'This change will apply to all your future imports.',
  prospect_sharing_toggle_off:
    'Do you want to enable prospect sharing for team members?',
  prospect_sharing_toggle_on:
    'Do you want to disable prospect sharing for team members?',
  auto_add_to_prospect_on_lead_reveal_toggle_on:
    'Do you want to Save revealed leads from lead finder as prospects automatically',
  auto_add_to_prospect_on_lead_reveal_toggle_off:
    'Do you want to disable Save revealed leads from lead finder as prospects automatically',
  to_auto_add_to_prospect_on_lead_reveal:
    'save revealed leads from lead finder as prospects automatically.',
};

// Outreach Basic Restriction
const outreachBasicRestrictionMsg = {
  team_invite_msg_1:
    'Your current plan doesn’t allow you to invite the team members.',
  team_invite_msg_2:
    'Please upgrade to Outreach Pro or higher plans to invite unlimited team members.',
  api_key_msg_1: 'Your current plan doesn’t allow you to use the API key.',
  api_key_msg_2:
    'Please upgrade to the Outreach Pro or higher plans to create unlimited API keys for seamless integrations.',
  team_create_msg_1: 'Your current plan doesn’t allow you to create team.',
  team_create_msg_2:
    'Please upgrade to the Outreach Pro or higher plans to create team & invite unlimited team members.',
  step_variant_msg_1:
    'The Outreach Starter doesn’t allow you to create multiple variants in a step.',
  step_variant_msg_2:
    'Please upgrade to the Outreach Pro or Higher plans to create more variants.',
};

const sequenceScore = {
  score_poor_1:
    "This indicates significant issues with your sequence, and it's highly unlikely to produce the desired results.",
  score_poor_2:
    "It's imperative that you take immediate action based on feedback and rectify the problems highlighted.",
  score_below_average_1:
    'This suggests that some issues with your sequence need to be addressed.',
  score_below_average_2:
    'Your sequence is unlikely to get good results. We strongly recommend you take prompt actions based on feedback & fix mentioned issues.',
  score_average_1:
    "While it's not a bad score, it does suggest some areas that need improvement.",
  score_average_2:
    "It's essential to carefully consider the feedback provided and work on enhancing your sequence to achieve better results.",
  score_good_1:
    'This indicates that your sequence is well-constructed and has the potential to produce good results.',
  score_good_2:
    'However, there might be some minor areas for improvement, and we suggest you consider the feedback and make necessary changes.',
  score_very_good_1:
    'This suggests that your sequence is well-constructed and is likely to produce excellent results.',
  score_very_good_2:
    'While there is always room for improvement, you should be proud of your work and consider the feedback provided to make it even better.',
  score_excellent_1:
    'This indicates that your sequence is exceptionally well-constructed and is expected to produce outstanding results.',
  score_excellent_2:
    'Congratulations! You should consider this a significant achievement and keep up the good work.',
};

const emailAccountSetupFactor = {
  domain_blacklist_title: 'Domains Blacklist Check',
  ip_blacklist_title: 'IP Blacklist Check',
  mx_records_title: 'MX Record',
  dmarc_title: 'DMARC Set-up',
  spf_title: 'SPF Set-up',
  modal_col_status: 'Status',
  modal_col_blacklist: 'Blacklist',
  modal_col_reason: 'Reason',
  modal_col_response_time: 'Response Time',
  modal_col_learn_more: 'Learn More',
  modal_col_hostname: 'Hostname',
  modal_col_ipaddress: 'IP Address',
};


// eslint-disable-next-line import/prefer-default-export
export const en = {
  labels: {
    selected: 'Selected',
    apply: 'Apply',
    select_all: 'Select All',
    reports: 'Reports',
    duration: 'Duration',
    this_month: 'This Month',
    last_month: 'Last Month',
    last_15_days: 'Last 15 Days',
    last_7_days: 'Last 7 Days',
    last_14_days: 'Last 14 Days',
    last_30_days: 'Last 30 Days',
    last_90_days: 'Last 90 Days',
    this_week: 'This Week',
    last_week: 'Last Week',
    yesterday: 'Yesterday',
    today: 'Today',
    add_email_account: 'Add email account',
    add_prospects: 'Add Prospects',
    add_prospect: 'Add Prospect',
    do_later: `I'll do it later`,
    select_email_service: 'Select the email service you use',
    sequence_settings: 'Sequence Settings',
    save: 'Save',
    add: 'Add',
    email_account: 'Email Account',
    safety_settings: 'Safety Settings',
    sending_priority: 'Sending Priority',
    average_deal_value_settings: 'Estimated Deal Value',
    schedule: 'Schedule',
    step: 'Step',
    prospects: 'Prospects',
    prospectsAdded: 'Prospects Added',
    emailsSent: 'Emails Sent',
    opened: 'Opened',
    clicked: 'Clicked',
    link_clicked: 'Link Clicked',
    sent: 'Sent',
    replied: 'Replied',
    bounced: 'Bounced',
    unsubscribed: 'Unsubscribed',
    activity: 'Activity',
    sequences: 'Sequences',
    profile: 'Profile',
    cancel: 'Cancel',
    save_filterBtn: 'Save Filter',
    okay: 'Okay',
    verify: 'Verify',
    export: 'Export',
    emails: 'Emails',
    email: 'Email',
    totalProspects: 'Total Prospects',
    no: 'No',
    yes: 'Yes',
    delete: 'Delete',
    title: 'Title',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    all: 'All',
    no_data_available: 'No Data Available!',
    create_and_add_to_sequence: 'Create & Add to sequence',
    day: 'day',
    days: 'days',
    upgrade: 'Upgrade',
    upgrade_now: 'Upgrade Now',
    upgrade_plan: 'Upgrade Plan',
    downgrade_plan: 'Downgrade Plan',
    current_plan: 'Current Plan',
    add_more_slots: 'Add More Slots',
    sub_total: 'Sub-total',
    total_amount: 'Total Amount',
    total_amount_to_pay_today: 'Total Amount to pay today',
    discount: 'Discount',
    total_after_discount: 'Total after discount',
    total_per_year: 'Total Per Year',
    total_per_month: 'Total Per Month',
    cancel_subscription: 'Cancel Subscription',
    reactivate_subscription: 'Reactivate Subscription',
    resume_subscription: 'Resume Subscription',
    bill_summary: 'Bill Summary',
    plan_name: 'Plan Name',
    pay_now: 'Pay Now',
    monthly_billing: 'Monthly Billing',
    annually_billing: 'Annually Billing',
    upgrade_subscription: 'Upgrade Subscription',
    modify_subscription: 'Modify Subscription',
    search_hit_enter: 'Search',
    search: 'Search..',
    done: 'Done',
    ...header,
    payment_failed: 'Payment Failed',
    retry_payment: 'Retry Payment',
    contact_support: 'contact support',
    Contact_Support: 'Contact Support',
    Contact_Owner: 'Contact Owner',
    resume_email_sending: 'Resume Email Sending',

    i_will_use_legacy_version: 'I’ll use legacy version',
    clear_all: 'Clear all',
    apply_filters: 'Apply Filters',
    create: 'Create',
    close: 'Close',
    copy: 'Copy',
    create_api_key: 'Create API Key',
    unsubscribe_success_message:
      'Unsubscribe message has been saved successfully.',
    unsubscribe_error:
      'Unsubscribe tag is required to have the custom unsubscribe text.',
    have_a_promo_code: 'Have a Promo Code?',
    remove_discount_code: 'Remove Discount Code',
    need_help: 'Need help?',
    unsubscribe: 'Unsubscribe',
    remove_delete: 'Remove/Delete',
    send: 'Send',
    connect_now: 'Connect Now',
    continue: 'Continue',
    unsubscribe_prospect: 'Unsubscribe Prospects',
    delete_prospect: 'Delete Prospect',
    get_contact_info: 'Get Contact Information',
    save_filter: 'Save Filter',
    edit_filter: 'Edit Filter',
    delete_filter: 'Delete this saved filter',
    api_key: 'API Key',
    integration: 'Integrations',
    webhook: 'Webhook',
    my_profile: 'My Profile',
    email_accounts: 'Email Accounts',
    schedules: 'Schedules',
    outOfOffice: 'Out of Office',
    team: 'Team',
    users_and_teams: 'Users & Teams',
    custom_fields: 'Custom Fields',
    system_fields: 'System Fields',
    add_prospect_fields: 'Add Prospect Fields',
    prospect_label_error:
      'Label must be shorter than or equals to 100 characters.',
    prospect_fallback_text_error:
      'Fallback text must be shorter than or equal to 255 characters.',
    prospect_fields: 'Prospect Fields',
    prospect_outcomes: 'Prospect Outcomes',
    custom_outcomes: 'Custom Outcome',
    system_outcomes: 'System Outcome',
    custom_outcomes_empty_list_description:
      'Start organizing your prospects with custom outcomes.',
    custom_outcomes_empty_list_title: 'Create custom outcomes',
    add_prospect_outcomes: 'Custom Outcome',
    update_system_outcome: 'Update System Outcome',
    update_custom_outcome: 'Update Custom Outcome',
    custom_tracking_domain: 'Custom Tracking Domain',
    admin_settings: 'Admin Settings',
    blacklist_domains: 'Blacklist Domains',
    do_not_contact_list: 'Do Not Contact List',
    billing_and_subscription: 'Billing & Subscription',
    prospect_search_placeholder: 'Search...',
    sequence: 'Sequence',
    status: 'Status',
    prospect_selected: 'Prospects are selected.',
    email_account_selected: 'Email Account(s) selected',
    sequence_statistics: 'Sequence Statistics',
    purchase_credits: 'Purchase Credits',
    buy_now: 'Buy Now',
    contact_admin: 'Contact Admin',
    contact_admin_reach_out: 'Reach out to your admin',
    buy_credits: 'Buy Credits',
    verification_status_settings: 'Verification Status',
    verify_now: 'Verify Now',
    verifying: 'Verifying',
    already_paid: 'Already Paid',
    total_to_be_paid: 'Total to be paid',
    days_billing: 'days billing',
    save_and_pay: 'Save & Pay',
    note: 'Note',
    ...planSummary,
    pre_header_placeholder: 'Enter a pre-header...',
    pre_header: 'Pre-Header',
    pre_header_label: 'Set Pre-Header',
    pre_header_recommendation:
      'Recommendation: Use from 40 to 130 characters in the pre-header.',
    pre_header_error: 'You are not allowed to enter more than 150 characters',
    pre_header_tooltip_1: `A preheader is an email text displayed when an unopened email is previewed in the inbox.`,
    pre_header_tooltip_2: `It's shown after the subject line ends. The visibility may vary for each email client.`,
    create_new_prospect: 'Create New Prospect',
    email_sending_limit_header: 'Total number of emails per day',
    bulk_email_sending_limit_header: 'Maximum emails per day',
    email_sending_limit_caption:
      'Maximum number of emails that could be sent via this email account per day.',
    check_new_score: 'Check New Score',
    delete_email: 'Do you want to delete this email account?',
    mark_as_replied: 'mark as replied',
    remove_from_sequence: 'remove from sequence',
    move_to_step: 'move to step',
    mark_as_finished: 'mark as finished',
    no_results_found: 'No Results Found!',
    empty_prospects: 'Prospects are empty',
    ...planSummary,
    click_here: 'Click here',
    ...prospectFilterLabels,
    upgrade_to_saleshandy_pro_email_sub: 'Upgrade to PRO',
    upgrade_required: 'Upgrade required',
    ...planSummary,
    ...emailAccountTableHeader,
    turn_off_email_tracking_model_title: 'Turn off Email Open Tracking?',
    turn_off_mark_prospect_as_replied_model_title:
      'Turn off mark Prospect as replied?',
    your_subscription_end: 'Your subscription has ended',
    your_trail_expired: 'Your trial is expired',
    use_unsubscribe_link_in_email: 'Use Unsubscribe Link in Emails',
    canceled: 'Canceled',
    paused: 'Paused',
    ...saleshandyUsageLabels,
    ...teamSizeLabels,
    ...industryLabels,
    invoice: 'Invoices',
    open_billing_portal: 'Open Billing Portal',
    opt_out_option: 'Opt-out option',
    update_system_field: 'Update System Field',
    update_custom_field: 'Update Custom Field',
    get_started: 'Get Started',
    edit_email_settings: 'Edit Email Settings',
    error_occured: 'Error occured!',
    connect: 'Connect',
    chrome_extension: 'Chrome Extension',
    view_sample_sequence: 'View sample sequence',
    create_new_sequence: 'Create a new sequence',
    take_action_now_cta: 'Take action now',
    refresh: 'Refresh',
    enable: 'Enable',
    see_all_features: 'See our complete feature comparison ',
    most_value: 'MOST VALUABLE',
    most_popular: 'MOST POPULAR',
    unused_balance: 'Unused balance',
    credits: 'Credits',
    manage_billing: 'Manage Billing',
    add_remove_slots: 'Add or Remove Slots',
    update_card_details: 'Update Card / Invoices',
    next: 'Next',
    thanks: 'Thanks',
    regards: 'Regards',
    upgrade_to_saleshandy_email_sub:
      'Request to upgrade our Saleshandy account.',
    got_it: 'Got it',
    update_settings: 'Update Settings',
    disconnect_email_accounts: 'Disconnect Email Accounts',
    delete_email_accounts: 'Delete Email Accounts',
    add_remove_tags: 'Add & Remove Tags',
    bulk_update_settings: 'Bulk update Settings',
    refresh_setup_score: 'Refresh Setup Score',
    this_email_was_previously_bounced: 'This email was previously bounced',
    delete_client: 'Delete Client',
    schedule_demo: 'Schedule a Demo',
    cold_email_masterclass: 'Cold Email Masterclass',
    download_chrome_plugin: 'Download Chrome Plugin',
  },
  messages: {
    NOTE: 'NOTE',
    and: 'and',
    this: 'this',
    min_max_interval_limit:
      'Minimum and Maximum interval limit for sending automated emails',
    warning_cannot_add_to_sequence:
      'Contacts that already exist or Unsubscribed/Bounced can not be added to the Sequence',
    update_custom_field_warning:
      'If you edit the custom field, it may hinder the email personalization',
    warning_cannot_add_to_sequence_search_and_add:
      "Please note that Unsubscribed/Bounced contacts won't be added to the sequence",
    email_sent_on: 'Email Sent on',
    email_opened_on: 'Email Opened on',
    link_clicked_on: 'Link Clicked on',
    email_replied_on: 'Email Replied on',
    connect_email_account_description: `Adding email account is necessary for sending any Sequence to your
    prospects. We recommend you to do it now for a valuable onboarding.
    You can always edit, remove, or add new account later.`,
    thats_all_for_now: "That's all for now!!",
    no_activity_data: 'No activity found',
    assign_prospect_to_sequence: 'Assign Prospect to Sequences',
    prospect_sequences_are_empty: "Prospect's Sequences are Empty",
    cannot_change_setting_once_sequence_has_started:
      'You can not change settings once the sequence has started',
    help_remove_invalid_prospect:
      'Email verification will help you remove invalid prospects from your sequence.',
    two_types_of_prospect:
      'Below are the two types of prospects to whom we recommend you send emails.',
    valid_prospect: 'Valid Prospects',
    high_delivery_chances: 'Email Delivery chances are very high.',
    risky_prospect: 'Risky Prospects',
    low_delivery_chances: 'Email Delivery chances are very low.',
    email_verification: 'Email Verification',
    email_account_select_message:
      'Connect your Email account from which you want to send email from Saleshandy.',
    SMTP_account_select_message:
      'Choose an option to connect email accounts either in bulk or individually.',
    email_account_connect_note:
      "Note: We don't store your mailbox data & you can remove your email account at any time.",
    verification_started: 'Verification Started',
    please_connect_email: 'Add your email account to start a sequence',
    adding_email_account:
      'Adding email account is necessary for sending any Sequence to your prospects. We recommend you to do it now for a valuable onboarding. You can always edit, remove, or add new account later.',
    we_do_not_store: `We don’t store any data from your email service provider for purposes other than providing you analytics.`,
    create_and_add_prospect_successfully:
      'Create & Add prospect to sequence successfully',
    provide_field_name: 'Please provide a field name',
    provide_outcome_name: 'Please provide an outcome name',
    outcome_field_name: 'Character limit 1 to 20 allowed',
    invalid_outcome_name: 'Invalid character found : Comma (,)',
    provide_valid_fallback_text: 'Please provide valid fallback text',
    no_data_available: `Data isn’t updated in this section, It will show up here once
    the prospect takes an action`,
    data_not_updated_for_selection: `Data for this selection is not yet updated!`,
    your_plan_expired_text: 'Your Outreach trial expires in',
    your_plan_expired_text_0_day: 'Your Outreach trial has expired',
    subscription_warning:
      'Your free trial has expired. To continue, upgrade your plan.',
    lead_finder_low_credits:
      "You don't have enough lead finder credits to view contact information for selected leads.",
    plan_expired_message: 'Uh-Oh! Your trial has expired',
    plan_expired_message_content:
      'To create sequences you need to upgrade your account.',
    plan_expired_active_sequence_message:
      "You're not allowed to activate the sequence",
    plan_expired_active_sequence_content_1:
      'You are not allowed to activate the sequence as you are in free plan.',
    plan_expired_active_sequence_content_2:
      'To activate sequence you have to upgrade your account.',
    plan_expired_add_email_message:
      "You're not allowed to add the email accounts",
    plan_expired_add_email_content_1:
      'You are not allowed to add the email accounts as you are in free plan.',
    plan_expired_add_email_content_2:
      'To add more email accounts you have to upgrade your account.',
    plan_expired_connect_email_message:
      "You're not allowed to connect the email accounts",
    plan_expired_connect_email_content_1:
      'You are not allowed to connect the email accounts as you are in free plan.',
    plan_expired_connect_email_content_2:
      'To connect more email accounts you have to upgrade your account.',
    plan_expired_invite_member_message:
      "You're not allowed to invite the team members",
    plan_expired_invite_member_content_1:
      'You are not allowed to invite the team members as you are in free plan.',
    plan_expired_invite_member_content_2:
      'To invite team members you have to upgrade your account.',
    plan_expired_clone_sequence_message: 'Upgrade the plan to clone a sequence',
    plan_expired_create_api_key: 'Upgrade the plan to create API key',
    email_verification_title:
      'After Import, We will run email verification which helps you remove invalid prospects from your sequence. Below are the two type of prospects to whom we recommend you to send emails.',
    number_input_alert_message:
      'Note:- You cannot downgrade the no. of accounts in the case when you have more connected numbers. If want to add more delete the connected ones and add in place of that or upgrade it.',
    upgrade_plan_header_text:
      'Pro plan includes every feature plus unlimited sequences,  prospects, and templates.',
    trial_plan_message:
      'You are in the free trial plan and your account will be restricted after the trial period. To continue, please upgrade your plan.',
    free_plan_message:
      'Your free trial has expired. To continue, upgrade your plan',
    pro_to_free_plan_message:
      'Your subscription has ended and you are on a free plan. To continue, please upgrade your plan.',
    payment_failed_message:
      'Your payment was not successfully processed. Please',
    upgrade_plan_contact_admin:
      'Your free trial has expired. To continue contact your administrator.',
    make_changes_to_daily_sending_limit:
      'Are you sure you want to make changes to the daily sending limit?',
    you_have_already_sent: 'You have already sent',
    emails_today: 'emails today. You can send',
    more_emails_today: 'more emails today.',
    limit_restoration_tomorrow:
      'emails which is exceeding your email sending limit for today. Your daily sending limit will be restored tomorrow.',
    include_risky_prospects: 'Include risky prospects in the sequence',
    smtp_message_one:
      'We support all ESPs and you can connect it with SMTP/IMAP setup.',
    smtp_message_two:
      'You can connect SMTP/IMAP account with video guidance so that email account can be linked without difficulties.',
    smtp_message_three:
      'Add SMTP/IMAP email account from settings and execute your sequences seamlessly.',
    delete_email_account_message:
      'Once you delete an email account, all the sequences using the email account will be paused. If any sequence is not started, you can change the email account and resume it later. Other sequences can not be activated later.',
    delete_default_email_account_message:
      'It seems like you are trying to delete a default email account. Please mark another email account as default to delete this account.',
    disconnect_email_account_message:
      'Once you disconnect the email account, Your all sequences using this email account will be paused.',
    disable_agency_client_modal_message:
      'Once disabled, the client user won’t be able to log in. All sequences associated with this client will be paused.',
    create_api_token_label:
      'Choose a label that is short, memorable and easy for you to remember.',
    contact_infromation_massage_one:
      'You have selected 1 leads to reveal contact information.',
    contact_infromation_massage_two: 'Are you sure, you want to continue?',
    delete_modal_massage_one: "This action can't be reversed.",
    delete_modal_massage_two: 'Are you sure, you want to continue?',
    show_api_token:
      "Make sure you copy your new API key. You won't be able to see this key again.",
    delete_api_token_part_one:
      'Are you sure you want to permanently delete your API key ',
    delete_api_token_part_two: '? Deleting an API key is not reversible.',
    api_key_copied_to_clipboard: 'API key copied to clipboard',
    your_new_api_key: 'Your new API Key',
    your_api_key_deleted: 'Your API key has been deleted',
    delete_api_key: 'Delete API Key',
    reached_email_sending_limit_message_one:
      "You've reached the email sending limit of 200 emails during the free trial period.",
    reached_email_sending_limit_message_two:
      'Upgrade your plan to continue creating new sequences.',
    reached_email_sending_limit_message_for_member:
      'Contact your admin to upgrade your plan.',
    user_subscription_text: 'Please enter the number of slots you want to add.',
    email_account_subscription_text:
      'Enter the number of additional email accounts you want to connect.',
    user_plan_description:
      'Add slots to be able to connect additional email accounts and add team members.',
    email_account_plan_description:
      'This allows you to connect additional email accounts in the same account. Each user gets one email account by default.',
    your_plan_details: 'Your Saleshandy Plan Details',
    slots_info:
      '*Purchased slots are the shared quota of email accounts between you and your team members. Your team size (including you) can be extended upto purchased slots.',
    unsubscribe_prospect_message_one:
      'Are you sure you want to unsubscribe the selected prospects.',
    unsubscribe_prospect_message_two:
      'If you unsubscribe the prospects, you will not be able to send emails to the prospects.',
    delete_prospect_message_one:
      'Are you sure you want to delete the selected prospects.',
    delete_prospect_message_two:
      'If you delete the prospects, you will permanently lose your prospects.',
    delete_mail_modal_title: 'Delete this email address',
    delete_mail_modal_content:
      "It will remove the selected email address from this prospect profile. The prospect won't be deleted. Are you sure you want to continue?",
    confirmation_mail_modal_title: 'Changing the primary email of prospect?',
    confirmation_mail_modal_content:
      'The system will send all upcoming emails to the new primary email address. It might affect the previous email thread. Are you sure you want to update it?',
    delete_phone_number_modal_title: 'Delete this phone number',
    delete_phone_number_modal_content:
      "It will remove the selected phone number from this prospect profile. The prospect won't be deleted. Are you sure you want to continue?",
    confirmation_phone_number_modal_title: 'Changing the default phone number?',
    confirmation_phone_number_modal_content:
      'The system uses merge tag of default phone number. Updating it will change the phone number value for this prospect.',
    active: 'Active',
    paused: 'Paused',
    draft: 'Draft',
    email_verification_error: 'Email Verification Error',
    low_ev_credits:
      'As the EV credits required to verify emails are low. So you need to buy more credits to verify emails.',
    verification_credits: 'Verification Credits',
    buy_credits: 'Buy Credits',
    skip_verification: 'Skip Verification',
    purchase_ev_credits_agreement:
      'By purchasing credits, I agree that email verification credits are non-refundable.',
    email_verification_action_required_message:
      'As the EV credits required to verify emails are low. So you need to buy more credits to verify emails. EV credits available are shown below:-',
    fallback_text_info:
      'A fallback text is a substitute text when the custom field is empty.',
    prospect_not_selected_warning:
      'Please select at least 1 prospect to complete the action',
    report_graph_tooltip:
      'Compare between all metrics of the selected sequences within the given time period.',
    verify_status_banner_message:
      'Sending emails to unverified prospects may result in a high number of bounced emails, which significantly affects your email reputation.',
    verify_status_pending_banner_message:
      'Prospect Verification has been started. You will receive an email once the verification is completed',
    modify_subscription_note:
      'The latest email account seat removal will apply to your billing after the current billing cycle.',
    turn_off_mark_prospect_as_replied_message:
      'Disabling this option will let system send follow-ups to prospects even after they reply to your emails.',
    are_you_sure_want_to_turn_it_off: 'Are you sure want to turn it off?',
    track_email_opens_model_message:
      'Disabling this option will stop email open tracking for all the emails in this sequence.',
    unverified_prospect_banner_message:
      'Sending emails to unverified prospects may result in a high number of bounced emails, which significantly affects your email reputation. Click here to know more.',
    ev_credits_payment_failed_message:
      'Payment failed! Please retry again after some time from billing section.',
    verification_started_content:
      'You will receive an email once the verification is completed',
    buy_email_credits_sequence_settings_message:
      'To verify email address, you need to buy email verification credits.',
    upgrade_to_pro_email_body:
      "It appears that our account is downgraded to the free plan. I'd request you to take prompt action to upgrade our account.",
    subscription_ended_message: 'Your subscription has ended. To continue,',
    trail_expired: 'Your trial has expired. To continue,',
    trail_plan_expired_email_account_page_message:
      'Your trial has expired and your email accounts are disconnected. To reconnect the email,',
    custom_fields_empty_list_description:
      'You can add numeric and text custom fields.',
    custom_fields_empty_list_description2:
      'Custom fields can be used to store the data of prospects and sending highly personalized emails.',
    custom_fields_empty_list_title: 'Create your first custom field',
    custom_field_update_successfully: 'Custom field updated successfully',
    system_field_update_successfully: 'System field updated successfully',
    custom_outcome_update_successfully: 'Custom outcome updated successfully',
    system_outcome_update_successfully: 'System outcome updated successfully',
    plan_expired_team_page_message:
      'Your subscription has ended. To invite team members,',
    subscription_expired_email_account_page_message:
      'Your subscription has ended and your email accounts are disconnected. To reconnect the email,',
    trial_plan_expired_team_page_message:
      'Your trial has expired. To invite team members,',
    delete_email_message_one:
      'Once you delete an email account, all the sequences using the email account will be paused.',
    delete_email_message_two:
      'If any sequence is not started, you can change the email account and resume it later. ',
    delete_email_message_three: 'Other sequences can not be activated later.',
    step_1:
      "Add a follow-up step to increase your sequence's reply rate by 40%.",
    step_2:
      "Often it’s the follow-up that makes prospects reply. Let's add one more step.",
    step_3: 'Add one more follow-up step to further increase the reply rates.',
    upgrade_plan_for_add_email_account:
      'Your account is on the free plan now and your admin need to upgrade your account to reconnect this email account.',
    no_prospect_found_in_sequence: 'No prospect was found in the sequence',
    general_email_sub_for_purchase_ev:
      'Email Verification Credit Purchase Request',
    email_sub_for_purchase_ev_from_sequence_settings:
      'Buy credits to verify prospect',
    upgrade_plan_for_add_email_account_admin:
      'Your account is on the free plan now and you need to upgrade your account to reconnect this email account.',
    admin_block_page_message:
      'Your sequences are paused. Please upgrade your account to continue using or access the data. Need help? Click the chat icon to contact us.',
    member_block_page_message:
      'Your sequences are paused. To continue using your account, please ask the admin to resubscribe. Need help? Click the chat icon to contact us.',
    paid_plan_expired_message:
      'Your sequences are paused. Please resubscribe to continue using your account and access the data. Need help? Click the chat icon to contact us.',
    create_new_prospect_description:
      'You can create a new prospect by clicking on the button below.',
    import_prospect_and_start_engaging:
      'Import your prospects or add them manually and start engaging with them.',
    select_email_service: 'Select the email service you use',
    team_size: 'What is your company size?*',
    user_industry: 'What industry are you in?*',
    user_job_role: 'What is your job role?*',
    use_case: 'What describes your use-case best?*',
    saleshandy_usage: 'Who will be using Saleshandy?',
    subscription_canceled: 'You have canceled this subscription.',
    subscription_paused: 'Your subscription is paused',
    reactivate_subscription_message_one:
      "You have canceled your existing subscription, and it's scheduled to end after",
    reactivate_subscription_message_two:
      'Please reactivate it to modify the current subscription.',
    reactivate_subscription: 'please reactivate our saleshandy subscription',
    reactivate_subscription_email_body:
      'it seems our Saleshandy subscription is canceled at the moment. I’d request you to reactivate it so I can access my account.',
    resume_subscription_admin_message_one:
      'Your subscription is paused that is scheduled to resume on',
    resume_subscription_admin_message_two:
      'In order to perform this activity, you need to resume your subscription.',
    resume_subscription_member_message_one:
      "Your subscription is paused by admin and it's is scheduled to resume on",
    resume_subscription_member_message_two:
      'In order to perform this activity, please ask your admin to resume your subscription.',
    resume_subscription: 'please resume our saleshandy subscription',
    resume_subscription_success_message:
      'Subscription has been resumed successfully',
    resume_subscription_email_body:
      'it seems our Saleshandy subscription is paused at the moment. I’d request you to resume it so I can access my account.',
    no_seat_available_email_modal_title: 'Add email account(s)',
    no_seat_available_email_modal__content_1:
      'You have reached a limit of adding email account(s).',
    no_seat_available_email_modal__content_2:
      'To add more email accounts you have to buy additional slots.',
    no_seat_available_team_modal_title: 'Invite team member(s)',
    no_seat_available_team_modal__content_1:
      'You have reached a limit of inviting team member(s).',
    no_seat_available_team_modal__content_2:
      'To invite more team members you have to buy additional slots.',
    plan_description:
      'You can view your invoices and the past billing history on your billing portal.',
    select_unsubscribe_message_text:
      'Select unsubscribe message type from below.',
    need_help_for_connecting_email: 'Need help connecting your email account?',
    update_smtp_imap_connect_failed:
      'All the sequences associated with this email account has been paused.',
    manually_cancel_subscription:
      'Your subscription was created manually. Please contact the support team to get your subscription canceled.',
    not_allowed_to_add_email_accounts_message_1:
      'You are not allowed to add the email accounts as you have canceled your existing subscription, and it’s scheduled to end after',
    not_allowed_to_add_email_accounts_message_2:
      'Please reactivate it to add more email accounts.',
    please_enter_valid_email: 'Please enter a valid email address',
    sender_name_limit_error: 'Maximum 50 characters allowed',
    email_account_not_found:
      'The account you are trying to reconnect is missing',
    no_result_found_title: 'Sorry, we couldn’t find what you are looking for',
    no_result_found_desc: 'Please try searching with another term',
    now_allowed_to_add_unsubscribe_contact_to_sequence:
      'It is not possible to add unsubscribed contacts to the sequence',
    view_only_access_to_user: 'User will have view only access.',
    payment_pending: 'Your subscription payment is pending.',
    initial_payment_pending_text_one: 'Your subscription payment is pending.',
    initial_payment_pending_text_two:
      'your subscription will be activated only after successful payment in the next 23 hours.',
    recurring_payment_pending_text_two:
      'to save your plan from getting cancelled and moving to the free plan in the next',
    download_chrome_plugin: 'Download Chrome Plugin',
    email_account_sending_limit_tooltip: 'Click here to modify the limit',
    email_account_setup_score_tooltip: 'Click here to view your setup score',
    dont_know_your_email_provider: "Don't know your email service provider?",
    monthly_highlight_message: 'Switch Yearly to get 3 months free',
    yearly_highlight_message: "You're saving 3 months for free",
    monthly_outreach_highlight_message: 'Switch to Yearly to get upto 20% off',
    yearly_outreach_highlight_message: 'You’re saving 20% on yearly',
    what_is_tentative_date_1:
      'This is a tentative date for scheduling tasks or emails in this step, assuming you add prospects today.',
    what_is_tentative_date_2:
      'The actual date may change as per the attached schedule, sending quota & other ongoing sequences.',
    upgrade_plan_error_email_subject: 'Request to upgrade Saleshandy plan',
    saleshandy_agency_account_upgrade_required:
      'Saleshandy Agency Account upgrade required',
    upgrade_plan_error_email_body1:
      'I am writing to request an appropriate upgrade in current Saleshandy account so that it enables me to use its best feature.',
    upgrade_plan_error_email_body2:
      'Please let me know if there is anything else that I can do to make this request happen.',
    drag_and_drop_csv: 'Drag & Drop your CSV with Prospect Details',
    drop_your_csv_here: 'Drop to upload your CSV file here.',
    email_warmup_collaboration_msg_1:
      'Saleshandy and TrulyInbox have partnered to bring you the Email Warm Up feature.',
    email_warmup_collaboration_msg_2:
      'Connect Unlimited Email Accounts and warm up your accounts for FREE!',
    view_faqs: 'View FAQs',
    go_to_trulyinbox: 'Go To TrulyInbox',
    get_contact_line1_modal:
      'You have selected 2 leads to reveal contact information.',
    get_contact_line2_modal: 'Are you sure, you want to continue?',
    agency_portal:
      'Discover the power of saleshandy agency portal, built for agencies',
    agency_portal_desc:
      'Streamline your outreach, manage multiple clients, and drive impressive results - all in one centralised platform',
    setup_score_updated_successfully: 'Setup score updated successfully',
    disconnect_email_accounts_desc:
      'Are you sure you want to disconnect all the selected email accounts?',
    delete_email_accounts_desc:
      'Are you sure you want to delete all the selected email accounts?',
    delete_client_message_one:
      'Are you sure you want to delete the selected client?',
    delete_client_message_two:
      'Once deleted, the client user won’t be able to log in and will be removed from the account permanently. All sequences associated with this client will be paused.',
    esp_matching_update_success: 'ESP Matching setting updated successfully',
    esp_matching_update_fail: 'ESP Matching setting update failed',
    ...onboarding.step3,
    ...onboarding.step4,
    ...onboarding.step5,
    ...onboarding.step6,
    ...onboarding.step7,
    ...onboarding.step8,
    ...emptyList.emailsTab,
    ...emptyList.templates,
    ...templates,
    ...emails,
    ...tags,
    ...blacklist,
    ...profileProgress,
    ...apiKey,
    ...sequencePauseMessages,
    ...emailEditorModals,
    ...coldEmailTips,
    ...emailSuggestions,
    ...needHelpCta,
    ...customTrackingDomainMessages,
    ...riskyProspectsMessages,
    ...rampUpSettings,
    ...emailHealthScore,
    ...noSeatAvailableEmail,
    ...unsubscribeLinkModal,
    ...SMTPIMAPConnection,
    ...tipsToConnectSMTPIMAPEmail,
    ...watchVideoModal,
    ...mailboxEmails,
    ...chromeExtensionSettings,
    ...emailWarmup,
    ...errorBoundary,
    ...emailAccountType,
    ...nativeAttachment,
    ...timeIntervalSettings,
    ...adminSettings,
    ...outreachBasicRestrictionMsg,
    ...sequenceScore,
    ...emailAccountSetupFactor,
  },
};
