import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import ContactDetailsTab from './contact-details-tab';
import {
  getDomainOwnerContactDetailsRequest,
  purchaseDomainRequest,
} from '../../../../extra-actions';
import {
  resetEmailAccountInfrastructure,
  resetPurchaseDomainRequest,
  updateHasErrorsInContactDetailsForm,
} from '../../../../email-accounts-slice';
import { PurchaseDomainRequestPayload } from '../../../../types/request-payload';

const mapStateToProps = (state: RootState) => ({
  emailAccounts: state.emailAccount.emailAccounts,
  domainOwnerContactDetails: state.emailAccount.domainOwnerContactDetails,
  getDomainOwnerContactDetailsRequestStatus:
    state.emailAccount.getDomainOwnerContactDetailsRequest.status,
  hasErrorsInContactDetailsForm:
    state.emailAccount.hasErrorsInContactDetailsForm,

  emailAccountInfrastructureServiceProvider:
    state.emailAccount.emailAccountInfrastructureServiceProvider,
  emailAccountInfrastructureCart:
    state.emailAccount.emailAccountInfrastructureCart,
  emailAccountInfrastructureForwardingDomain:
    state.emailAccount.emailAccountInfrastructureForwardingDomain,

  purchaseDomainRequestStatus: state.emailAccount.purchaseDomainRequest.status,
  purchaseDomainRequestError: state.emailAccount.purchaseDomainRequest.error,
  purchaseDomainResponse: state.emailAccount.purchaseDomainResponse,
});

const mapDispatchToProps = {
  sendDomainOwnerContactDetailsRequest: () =>
    getDomainOwnerContactDetailsRequest(),

  updateHasErrorsInContactDetailsForm: (hasErrors: boolean) =>
    updateHasErrorsInContactDetailsForm(hasErrors),
  sendPurchaseDomainRequest: (payload: PurchaseDomainRequestPayload) =>
    purchaseDomainRequest(payload),
  resetPurchaseDomainRequest: () => resetPurchaseDomainRequest(),
  resetEmailAccountInfrastructure: () => resetEmailAccountInfrastructure(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  showBillingSummaryModal: boolean;
  setShowBillingSummaryModal: (show: boolean) => void;

};

export default connector(ContactDetailsTab);
