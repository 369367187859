import { EmailAccountType } from '../../../components/email-accounts/enums/email-account';
import { Images } from '../../app-constants';
import { ActionableSelectDropdown } from './types';

export const getEmailAccountIcon = (type: number) =>
  type === EmailAccountType.Gmail ? Images.GoogleIcon : Images.Microsoft;

export const getEmailAgeActionableDropdownData = (): ActionableSelectDropdown[] => [
  {
    key: '0',
    label: '0 Months - 3 Months',
    value: 2,
  },
  {
    key: '1',
    label: '3 Months - 6 Months',
    value: 5,
  },
  {
    key: '2',
    label: '6 Months - 1 Year',
    value: 12,
  },
  {
    key: '3',
    label: '1 Year - 2 Year',
    value: 24,
  },
  {
    key: '4',
    label: 'More than 2 years',
    value: 30,
  },
];
