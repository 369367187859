import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import { verifyEmailAccountRequest } from '../../../email-accounts/extra-actions';
import { EmailAccountMethod } from '../../../email-accounts/enums/email-account';
import ConnectEmailAccountCallback from './connect-email-account-callback';
import { updateApplicationState } from '../../../home/home-slice';

const mapStateToProps = (state: RootState) => ({
  status: state.emailAccount.verifyEmailAccountRequest.status,
  message: state.emailAccount.verifyEmailAccountRequest.message,
  error: state.emailAccount.verifyEmailAccountRequest.error,
});

const mapDispatchToProps = {
  sendVerifyEmailAccountRequest: (
    method: EmailAccountMethod,
    code: string,
    state?: string,
  ) => verifyEmailAccountRequest({ method, code, state }),
  handleHasUserConnectedEmailAccount: (hasUserConnectedEmailAccount: boolean) =>
    updateApplicationState({ hasUserConnectedEmailAccount }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ method: EmailAccountMethod }>;

export default connector(ConnectEmailAccountCallback);
