import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import { sleep } from '../../../../shared/utils/sleep';
import {
  getAccountSubscription,
  getAccountSubscriptionPlanDetail,
  getConnectedUsersAndEmailAccounts,
  getInvoiceUrl,
  getPlans,
  purchaseSubscription,
  applyCouponCode,
  getEmailVerificationCredits,
  getEmailVerificationPlan,
  purchaseEmailVerificationCredits,
  modifySubscription,
  purchaseModifySubscription,
  reactivateSubscription,
  resumeSubscription,
  handleSubscription,
  calculatePay,
  upgradePlan,
  downgradePlan,
  purchaseLtdPlan,
  upgradeLtdPlan,
  modifyLtdPlan,
  getLeadFinderSubscription,
  getLeadFinderPlans,
  purchaseLeadFinderSubscription,
  purchaseLeadFinderModifySubscription,
  handleLeadFinderChurnkeySubscription,
  reactivateLeadFinderSubscription,
  resumeLeadFinderSubscription,
  purchaseHistory,
  purchaseDetailsById,
} from './helpers/billing-subscription.api';
import {
  ApplyCouponCodeRequest,
  GetInvoiceURLRequest,
  PurchaseEmailVerificationCreditsRequest,
  ModifySubscriptionRequest,
  PurchaseSubscriptionRequest,
  PurchaseModifySubscriptionRequest,
  ReactivateResumeSubscriptionRequest,
  HandleSubscriptionRequest,
  CalculatePayRequest,
  UpgradePlanRequest,
  DowngradePlanRequest,
  LTDPurchasePayload,
  LTDModifyPayload,
  LTDUpgradePayload,
} from './types';
import { GetOrderDetailsByIdPayload } from './types/purchase-history';

export const getAccountSubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/getAccountSubscriptionRequest', async (args, thunkAPI) => {
  try {
    return await getAccountSubscription();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAccountSubscriptionPlanRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('/getAccountSubscriptionPlanRequest', async (args, thunkAPI) => {
  try {
    return await getAccountSubscriptionPlanDetail(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getLeadFinderSubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/getLeadFinderSubscriptionRequest', async (args, thunkAPI) => {
  try {
    return await getLeadFinderSubscription();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getPlansRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/getPlansRequest', async (args, thunkAPI) => {
  try {
    return await getPlans();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getLeadFinderPlansRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/getLeadFinderPlansRequest', async (args, thunkAPI) => {
  try {
    return await getLeadFinderPlans();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getInvoiceURLRequest = createAsyncThunk<
  ResponseSuccess,
  GetInvoiceURLRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/getInvoiceURLRequest', async (args, thunkAPI) => {
  try {
    return await getInvoiceUrl(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const purchaseSubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  PurchaseSubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/purchaseSubscriptionRequest', async (args, thunkAPI) => {
  try {
    const data = await purchaseSubscription(args);
    await sleep(5000);
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const purchaseLeadFinderSubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  PurchaseSubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>(
  'subscription/purchaseLeadFinderSubscriptionRequest',
  async (args, thunkAPI) => {
    try {
      const data = await purchaseLeadFinderSubscription(args);
      await sleep(5000);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getConnectedUsersAndEmailAccountsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>(
  'accounts/getConnectedUsersAndEmailAccountsRequest',
  async (args, thunkAPI) => {
    try {
      return await getConnectedUsersAndEmailAccounts();
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const applyCouponCodeRequest = createAsyncThunk<
  ResponseSuccess,
  ApplyCouponCodeRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/applyCouponCodeRequest', async (args, thunkAPI) => {
  try {
    return await applyCouponCode(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailVerificationCreditsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('email-verification-credits', async (args, thunkAPI) => {
  try {
    return await getEmailVerificationCredits();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailVerificationPlanRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('email-verification-plans', async (args, thunkAPI) => {
  try {
    return await getEmailVerificationPlan();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const purchaseEmailVerificationCreditsRequest = createAsyncThunk<
  ResponseSuccess,
  PurchaseEmailVerificationCreditsRequest,
  { rejectValue: ResponseErrorWithHandled }
>('email-verification-credits/purchase', async (args, thunkAPI) => {
  try {
    return await purchaseEmailVerificationCredits(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const modifySubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  ModifySubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/modifySubscription', async (args, thunkAPI) => {
  try {
    return await modifySubscription(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const purchaseModifySubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  PurchaseModifySubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/purchaseModifySubscriptionRequest', async (args, thunkAPI) => {
  try {
    return await purchaseModifySubscription(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const purchaseLeadFinderModifySubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  PurchaseModifySubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>(
  'subscription/purchaseLeadFinderModifySubscriptionRequest',
  async (args, thunkAPI) => {
    try {
      return await purchaseLeadFinderModifySubscription(args);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const reactivateSubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  ReactivateResumeSubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/reactivateSubscriptionRequest', async (args, thunkAPI) => {
  try {
    return await reactivateSubscription(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const reactivateLeadFinderSubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  ReactivateResumeSubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>(
  'subscription/reactivateLeadFinderSubscriptionRequest',
  async (args, thunkAPI) => {
    try {
      return await reactivateLeadFinderSubscription(args);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const resumeSubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  ReactivateResumeSubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/resumeSubscriptionRequest', async (args, thunkAPI) => {
  try {
    return await resumeSubscription(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const resumeLeadFinderSubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  ReactivateResumeSubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>(
  'subscription/resumeLeadFinderSubscriptionRequest',
  async (args, thunkAPI) => {
    try {
      return await resumeLeadFinderSubscription(args);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const handleSubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  HandleSubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/handleSubscriptionRequest', async (args, thunkAPI) => {
  try {
    return await handleSubscription(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const handleLeadFinderChurnkeySubscriptionRequest = createAsyncThunk<
  ResponseSuccess,
  HandleSubscriptionRequest,
  { rejectValue: ResponseErrorWithHandled }
>(
  'subscription/handleLeadFinderChurnkeySubscriptionRequest',
  async (args, thunkAPI) => {
    try {
      return await handleLeadFinderChurnkeySubscription(args);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const calculatePayRequest = createAsyncThunk<
  ResponseSuccess,
  CalculatePayRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/calculatePayRequest', async (args, thunkAPI) => {
  try {
    return await calculatePay(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const upgradePlanRequest = createAsyncThunk<
  ResponseSuccess,
  UpgradePlanRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/upgradePlanRequest', async (args, thunkAPI) => {
  try {
    return await upgradePlan(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const downgradePlanRequest = createAsyncThunk<
  ResponseSuccess,
  DowngradePlanRequest,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/downgradePlanRequest', async (args, thunkAPI) => {
  try {
    return await downgradePlan(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const purchaseLtdPlanRequest = createAsyncThunk<
  ResponseSuccess,
  LTDPurchasePayload,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/purchaseLtdPlanRequest', async (args, thunkAPI) => {
  try {
    return await purchaseLtdPlan(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const upgradeLtdPlanRequest = createAsyncThunk<
  ResponseSuccess,
  LTDUpgradePayload,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/upgradeLtdPlanRequest', async (args, thunkAPI) => {
  try {
    return await upgradeLtdPlan(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const modifyLtdPlanRequest = createAsyncThunk<
  ResponseSuccess,
  LTDModifyPayload,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/modifyLtdPlanRequest', async (args, thunkAPI) => {
  try {
    return await modifyLtdPlan(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getPurchaseHistoryRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/getPurchaseHistoryRequest', async (args, thunkAPI) => {
  try {
    return await purchaseHistory();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getPurchaseDetailsByIdRequest = createAsyncThunk<
  ResponseSuccess,
  GetOrderDetailsByIdPayload,
  { rejectValue: ResponseErrorWithHandled }
>('subscription/getPurchaseDetailsByIdRequest', async (args, thunkAPI) => {
  try {
    return await purchaseDetailsById(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
