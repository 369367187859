import React from 'react';
import { AlertCircle } from '@saleshandy/icons';

import Modal from '../../../shared/design-system/ui/modal';

import { CreateEmailAccountInfrastructureCart } from '../types/create-email-infrastructure';

interface ClearAllCartModalProps {
  show: boolean;
  onSubmit: () => void;
  onClose: () => void;
  emailAccountInfrastructureCart: CreateEmailAccountInfrastructureCart;
}

const ClearAllCartModal: React.FC<ClearAllCartModalProps> = ({
  show,
  onSubmit,
  onClose,
  emailAccountInfrastructureCart,
}) => (
  <Modal
    show={show}
    className="create-email-infrastructure-confirmation-modal"
    title={
      <div className="d-flex align-items-center gap-10">
        <AlertCircle width={24} height={24} color="#D97706" />
        <span className="line-height-1">{`Remove Domains ${
          emailAccountInfrastructureCart.emailAccounts.length > 0
            ? 'and Email Accounts'
            : ''
        }`}</span>
      </div>
    }
    onHide={onClose}
    primaryBtnLabel="Yes"
    secondaryBtnLabel="Cancel"
    primaryBtnClickedHandler={onSubmit}
    secondaryBtnClickedHandler={onClose}
  >
    <p>
      {`Are you sure you want to remove all domains ${
        emailAccountInfrastructureCart.emailAccounts.length > 0
          ? 'and email accounts'
          : ''
      } from your cart?`}
    </p>
  </Modal>
);

export default ClearAllCartModal;
