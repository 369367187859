import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import * as Icons from '../../shared/svg';
import YoutubeVideoModal from '../../shared/design-system/components/molecules/youtube-video-modal';
import { supportUrls } from '../../shared/utils/urls';
import { getHasVisitedAgencyPortal, setHasVisitedAgencyPortal } from './helper';
import type { IProps } from './agency-portal-container';
import { AnalyticsEvents } from '../../shared/enums/analytics';
import {
  getUserEmail,
  getUserSignUpDate,
  getUserUseCase,
  getUserIndustry,
  getUserJobRole,
} from '../../shared/utils/user-details';
import ImageIcon from '../../shared/components/images/image-icon';
import { Images } from '../../shared/app-constants';

const AgencyPortal: React.FC<IProps> = ({
  trackingId,
  planName,

  hasVisitedAgencyPortal,
  handleHasVisitedAgencyPortal,
}) => {
  const { t } = useTranslation();

  const [watchDemoVideoModal, setWatchDemoVideoModal] = useState<boolean>(
    false,
  );

  const onShowWatchDemoVideoModal = () => {
    setWatchDemoVideoModal(true);
  };

  const onHideWatchDemoVideoModal = () => {
    setWatchDemoVideoModal(false);
  };

  const oGetStartedClicked = () => {
    const userEmail = getUserEmail() || '';
    const splitUserEmail = userEmail?.split('@');
    const emailDomain =
      splitUserEmail && splitUserEmail.length > 1 ? splitUserEmail?.[1] : '';

    window.analytics?.track?.({
      userId: trackingId,
      event: AnalyticsEvents.ClickedGetStartedOnAgencyTab,
      properties: {
        current_plan: planName,
        signup_date: getUserSignUpDate(),
        use_case: getUserUseCase(),
        industry: getUserIndustry(),
        job_role: getUserJobRole(),
        email_domain: emailDomain,
      },
    });

    window.open(supportUrls.agencyPortalSignupURL, '_blank');
  };

  useEffect(() => {
    const hasVisitedAgencyPortalInLS = getHasVisitedAgencyPortal();
    if (
      !hasVisitedAgencyPortalInLS ||
      hasVisitedAgencyPortalInLS === 'false' ||
      !hasVisitedAgencyPortal
    ) {
      setHasVisitedAgencyPortal('true');
      handleHasVisitedAgencyPortal(true);
    }
  }, []);

  return (
    <div className="agency-portal">
      <div className="agency-portal--container">
        <div className="agency-portal--image">
          <ImageIcon src={Images.AgencyPortal} />
        </div>
        <h1>{t('messages.agency_portal')}</h1>
        <p>{t('messages.agency_portal_desc')}</p>
        <div className="action-btn">
          <Button variant="secondary" onClick={onShowWatchDemoVideoModal}>
            <Icons.PlayCircle /> <span className="ml-2">Watch Demo</span>
          </Button>
          <Button onClick={oGetStartedClicked}>Get Started</Button>
        </div>
      </div>

      <YoutubeVideoModal
        show={watchDemoVideoModal}
        onClose={onHideWatchDemoVideoModal}
        titleContent="Quick Guide to Saleshandy Agency Portal"
        youtubeVideoUrl={supportUrls.agencyPortalVideoGuide}
      />
    </div>
  );
};

export default AgencyPortal;
