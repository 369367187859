import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';

import { DeleteDomainRequestPayload, GetDomainsRequestPayload } from './types';

import { getDomains, deleteDomain } from './helpers/domains.api';

export const getDomainsRequest = createAsyncThunk<
  ResponseSuccess,
  GetDomainsRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('domains/getDomainsRequest', async (payload, thunkAPI) => {
  try {
    return await getDomains(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});

export const deleteDomainRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteDomainRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('domains/deleteDomainRequest', async (payload, thunkAPI) => {
  try {
    return await deleteDomain(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});
