import React, { useState } from 'react';

import { DomainsFilters } from '../types';

import SearchInput from '../../../shared/design-system/components/atoms/search-input';
import { IconPlace } from '../../../shared/design-system/components/input/enums';

type DomainsActionsProps = {
  domainsFilters: DomainsFilters;
  onDomainsFiltersChange: (filters: Partial<DomainsFilters>) => void;
};

const DomainsActions: React.FC<DomainsActionsProps> = ({
  domainsFilters,
  onDomainsFiltersChange,
}) => {
  const [search, setSearch] = useState(domainsFilters.search || '');

  const onSearch = () => {
    onDomainsFiltersChange({ search, page: 1 });
  };

  const onClearSearch = () => {
    setSearch('');
    onDomainsFiltersChange({ search: '', page: 1 });
  };

  return (
    <div className="domains-actions-container">
      <SearchInput
        searchValue={search}
        onSearch={onSearch}
        onClearSearch={onClearSearch}
        setSearchValue={setSearch}
        searchIconPlace={IconPlace.Left}
        searchIconFixed
        placeholder="Search"
      />
    </div>
  );
};

export default DomainsActions;
