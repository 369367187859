const SpammyWords = [
  'As seen on',
  'Buy',
  'Buy direct',
  'Buying judgments',
  'Order',
  'Order status',
  'Orders shipped by shopper',
  'Dig up dirt on friends',
  'Meet singles',
  'XXX',
  'Near you',
  'Additional income',
  'Be your own boss',
  'Compete for your business',
  'Double your',
  'Earn $',
  'Earn extra cash',
  'Earn per week',
  'Expect to earn',
  'Extra income',
  'Home based',
  'Home employment',
  'Homebased business',
  'Income from home',
  'Make $',
  'Make money',
  'Money making',
  'Online biz opportunity',
  'Online degree',
  'Opportunity',
  'Potential earnings',
  'University diplomas',
  'While you sleep',
  'Work at home',
  'Work from home',
  '$$$',
  'Bargain',
  'Beneficiary',
  'Best price',
  'Big bucks',
  'Cash',
  'Cash bonus',
  'Cashcashcash',
  'Cents on the dollar',
  'Cheap',
  'Check',
  'Claims',
  'Compare rates',
  'Credit',
  'Credit bureaus',
  'Discount',
  'Earn',
  'Easy terms',
  'F r e e',
  'Fast cash',
  'For just $XXX',
  'Hidden assets',
  'hidden charges',
  'Income',
  'Incredible deal',
  'Insurance',
  'Investment',
  'Loans',
  'Lowest price',
  'Million dollars',
  'Money',
  'Money back',
  'Mortgage',
  'Mortgage rates',
  'No cost',
  'No fees',
  'One hundred percent free',
  'Only $',
  'Pennies a day',
  'Profits',
  'Pure profit',
  'Refinance',
  'Save $',
  'Save big money',
  'Save up to',
  'Serious cash',
  'Subject to credit',
  'They keep your money — no refund!',
  'Unsecured credit',
  'Unsecured debt',
  'US dollars',
  'Why pay more?',
  'Accept credit cards',
  'Check or money order',
  'Credit card offers',
  'Explode your business',
  'Full refund',
  'Investment decision',
  'No credit check',
  'No hidden Costs',
  'No investment',
  'Requires initial investment',
  'Sent in compliance',
  'Stock alert',
  'Stock disclaimer statement',
  'Stock pick',
  'Calling creditors',
  'Collect child support',
  'Consolidate debt and credit',
  'Consolidate your debt',
  'Eliminate bad credit',
  'Eliminate debt',
  'Financially independent',
  'Get out of debt',
  'Get paid',
  'Lower interest rate',
  'Lower monthly payment',
  'Lower your mortgage rate',
  'Lowest insurance rates',
  'Pre-approved',
  'Refinance home',
  'Social security number',
  'Your income',
  'Freedom',
  'Miracle',
  'Passwords',
  'Dear [email/friend/somebody]',
  'Ad',
  'Auto email removal',
  'Bulk email',
  'Click',
  'Click below',
  'Click here',
  'Click to remove',
  'Direct email',
  'Direct marketing',
  'Email harvest',
  'Email marketing',
  'Increase sales',
  'Increase traffic',
  'Increase your sales',
  'Internet market',
  'Internet marketing',
  'Marketing',
  'Marketing solutions',
  'Mass email',
  'Month trial offer',
  'More Internet Traffic',
  'Multi level marketing',
  'Notspam',
  'One time mailing',
  'Online marketing',
  'Opt in',
  'Removal instructions',
  'The following form',
  'Undisclosed recipient',
  'Will not believe your eyes',
  'Cures baldness',
  'Fast Viagra delivery',
  'Human growth hormone',
  'Lose weight spam',
  'Online pharmacy',
  'Removes wrinkles',
  'Reverses aging',
  'Stop snoring',
  'Valium',
  'Viagra',
  'Vicodin',
  'Xanax',
  '100% free',
  '50% off',
  'Billion',
  'Billion dollars',
  'Join millions',
  'Join millions of Americans',
  'One hundred percent guaranteed',
  'Cannot be combined with any other offer',
  'Deal',
  'Financial freedom',
  'Guarantee',
  'Have you been turned down?',
  'Long distance phone offer',
  'Mail in order form',
  'Nigerian',
  'No age restrictions',
  'No-obligation',
  'Off shore',
  'Offer',
  'Prize',
  'Prizes',
  'Shopping spree',
  'Trial',
  'Unlimited',
  'Unsolicited',
  'Vacation offers',
  'Warranty',
  'Weekend getaway',
  'What are you waiting for?',
  'Who really wins?',
  'Win',
  'Winner',
  'Winning',
  'Won',
  'You are a winner!',
  'You have been selected',
  'You’re a Winner!',
  'Cancel at any time',
  'Copy accurately',
  'Give it away',
  'Print form signature',
  'Print out and fax',
  'See for yourself',
  'Sign up free today',
  'Free',
  'Free access',
  'Free cell phone',
  'Free consultation',
  'Free DVD',
  'Free gift',
  'Free grant money',
  'Free hosting',
  'Free installation',
  'Free Instant',
  'Free investment',
  'Free leads',
  'Free membership',
  'Free money',
  'Free offer',
  'Free preview',
  'Free priority mail',
  'Free quote',
  'Free sample',
  'Free trial',
  'Free website',
  'All natural',
  'Certified',
  'Fantastic deal',
  'For free',
  'Guaranteed',
  'Outstanding values',
  'Promise you',
  'Real thing',
  'Risk free',
  'Satisfaction guaranteed',
  'Act now!',
  'Apply now',
  'Apply online',
  'Call free',
  'Call now',
  'Do it today',
  'For instant access',
  'For Only',
  'Get it now',
  'Get started now',
  'Great offer',
  'Info you requested',
  'Information you requested',
  'Instant',
  'Limited time',
  'New customers only',
  'Offer expires',
  'Once in lifetime',
  'One time',
  'Order now',
  'Order today',
  'Please read',
  'Special promotion',
  'Supplies are limited',
  'Take action now',
  'Time limited',
  'Urgent',
  'While supplies last',
  'Bonus',
  'Brand new pager',
  'Cable converter',
  'Casino',
  'Celebrity',
  'Copy DVDs',
  'Luxury car',
  '100% more',
  'Consolidate debt',
  'Double your cash',
  'Double your income',
  'Earn money',
  'Extra cash',
  'Free info',
  'Giveaway',
  'Lower rates',
  'Once in a lifetime',
  'Risk-free',
  'Act now',
  'Exclusive deal',
  'Sign up free',
  'You are a winner',
  'Confidentiality',
  'Multi-level marketing',
  'No hidden fees',
  'This isn’t a scam',
  'This isn’t junk',
  'This isn’t spam',
  'Marketing solution',
];

export default SpammyWords;
