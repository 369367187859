import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IProps } from './connect-email-account-restriction-container';

import toaster from '../../../../shared/toaster';

import RestrictionErrorModal from '../../../../shared/components/restriction-error-modal';
import NoSeatAvailableForEmailModal from '../email-accounts-content/components/no-seat-available-for-email-modal';
import ReactivateSubscriptionModal from '../../../settings/components/billing-subscription/components/reactivate-subscription-modal';

import SubscriptionStatus from '../../../settings/components/billing-subscription/enums/subscription-status';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { isValueBasedPlan } from '../../../settings/components/billing-subscription/components/outreach-plans/utils/helper';
import {
  contactAdmin,
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
  SubscriptionPlans,
  SubscriptionPlanTitle,
} from '../../../../shared/utils';

const ConnectEmailAccountRestriction: React.FC<IProps> = ({
  handleOnSubmit,

  children,
  firstName,
  planType,
  subscriptionPlan,
  subscriptionEndDate,
  subscriptionPlanStatus,
  totalEmailAccounts,
  slots,
  customerId,

  sendGetReactivateSubscriptionRequestStatus,
  getConnectedUsersAndEmailAccountsRequestStatus,
  getConnectedUsersAndEmailAccountsRequestError,
  sendGetConnectedUsersAndEmailAccountsRequest,
  resetConnectedUsersAndEmailAccountsRequest,
  sendReactivateSubscriptionRequest,

  sendGetReactivateSubscriptionResponse,
  sendGetReactivateSubscriptionRequestError,
  resetReactivateSubscriptionResponse,
  sendGetUserSettingsRequest,
}) => {
  const { t } = useTranslation();

  const [
    showReactivateSubscriptionModal,
    setShowReactivateSubscriptionModal,
  ] = useState(false);
  const [restrictionErrorModalMeta, setRestrictionErrorModalMeta] = useState({
    show: false,
    planName: '',
  });
  const [
    showNoSeatAvailableForEmailModal,
    setShowNoSeatAvailableForEmailModal,
  ] = useState(false);

  const hideRestrictionErrorModal = () => {
    setRestrictionErrorModalMeta({
      planName: '',
      show: false,
    });
  };

  const hideReactivateSubscriptionModal = () => {
    setShowReactivateSubscriptionModal(false);
  };

  const hideNoSeatAvailableForEmailModal = () => {
    setShowNoSeatAvailableForEmailModal(false);
  };

  const reactivateSubscription = () => {
    const emailBody = `Hello ${firstName}, %0D%0A %0D%0A ${t(
      'messages.reactivate_subscription_email_body',
    )}`;
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      sendReactivateSubscriptionRequest({ customerId });
    } else {
      contactAdmin(t('messages.reactivate_subscription'), emailBody);
    }
  };

  const handleOnNext = () => {
    if (
      subscriptionPlan !== SubscriptionPlans.FREE &&
      subscriptionPlan !== SubscriptionPlans.TRIAL &&
      Number(totalEmailAccounts) >= slots &&
      !isValueBasedPlan(planType)
    ) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        if (subscriptionPlanStatus === SubscriptionStatus.Canceled) {
          setShowReactivateSubscriptionModal(true);
          return;
        }
        if (subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME) {
          setRestrictionErrorModalMeta({
            show: true,
            planName: SubscriptionPlanTitle.EmailOutreach,
          });
          return;
        }
        if (
          subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME
        ) {
          setRestrictionErrorModalMeta({
            show: true,
            planName: SubscriptionPlanTitle.EmailOutreachPro,
          });
          return;
        }
      }
      setShowNoSeatAvailableForEmailModal(true);
    } else {
      handleOnSubmit();
    }
  };

  const handleClick = () => {
    if (hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT)) {
      if (subscriptionPlan === SubscriptionPlans.TRIAL) {
        handleOnNext();
      } else {
        hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
          sendGetConnectedUsersAndEmailAccountsRequest();
      }
    }
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: getConnectedUsersAndEmailAccountsRequestStatus,
      onSuccess: () => {
        handleOnNext();
        resetConnectedUsersAndEmailAccountsRequest();
      },
      onFailed: () => {
        resetConnectedUsersAndEmailAccountsRequest();
        executeOnErrorWithErrorCheck({
          error: getConnectedUsersAndEmailAccountsRequestError,
          onError: () => {
            toaster.error(
              getConnectedUsersAndEmailAccountsRequestError.message,
            );
          },
        });
      },
    });
  }, [getConnectedUsersAndEmailAccountsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: sendGetReactivateSubscriptionRequestStatus,
      onSuccess: () => {
        hideReactivateSubscriptionModal();
        sendGetUserSettingsRequest();
        resetReactivateSubscriptionResponse();
        toaster.success(sendGetReactivateSubscriptionResponse?.message);
      },
      onFailed: () => {
        hideReactivateSubscriptionModal();
        executeOnErrorWithErrorCheck({
          error: sendGetReactivateSubscriptionRequestError,
          onError: () => {
            toaster.error(sendGetReactivateSubscriptionRequestError.message);
          },
        });
      },
    });
  }, [sendGetReactivateSubscriptionRequestStatus]);

  const isLoading = getIsRequestPending(
    getConnectedUsersAndEmailAccountsRequestStatus,
  );

  return (
    <>
      {children({ onClick: handleClick, isLoading })}

      <ReactivateSubscriptionModal
        show={showReactivateSubscriptionModal}
        onClose={hideReactivateSubscriptionModal}
        onReactivateSubscription={reactivateSubscription}
        subscriptionEndDate={subscriptionEndDate}
        isRequestPending={
          sendGetReactivateSubscriptionRequestStatus === RequestStatus.Pending
        }
        reactivateSubscriptionModalMessage1="not_allowed_to_add_email_accounts_message_1"
        reactivateSubscriptionModalMessage2="not_allowed_to_add_email_accounts_message_2"
        reactivateSubscriptionModalHeaderMessage="no_seat_available_email_modal_title"
        submitButtonText={
          hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
            ? 'reactivate_subscription'
            : 'contact_admin'
        }
      />

      {restrictionErrorModalMeta.show && (
        <RestrictionErrorModal
          show={restrictionErrorModalMeta.show}
          onClose={hideRestrictionErrorModal}
          planName={restrictionErrorModalMeta.planName}
          restrictionOn="email slots"
          feature="email accounts"
          firstName={firstName}
        />
      )}

      <NoSeatAvailableForEmailModal
        show={showNoSeatAvailableForEmailModal}
        onClose={hideNoSeatAvailableForEmailModal}
        firstName={firstName}
      />
    </>
  );
};

export default ConnectEmailAccountRestriction;
