import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

import { EmailAccountSetupProps } from '../types';
import { getIsRequestPending } from '../../../../../../../shared/utils/get-request-status';

import EmailAccountSetupScore from '../../../../../../../shared/components/email-account-setup-score';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
} from '../../../../../../../shared/utils/execute-on-request-status';
import toaster, { Theme } from '../../../../../../../shared/toaster';
import {
  clearShouldRefreshEmailSetupScoreValue,
  getShouldRefreshEmailSetupScoreValue,
} from '../helpers';

const EmailAccountSetup: React.FC<EmailAccountSetupProps> = ({
  emailAccountId,
  emailAccountSetup,
  userTimezone,
  agencyConfig,
  getEmailAccountSetupScoreRequest,
  getEmailAccountSetupScoreRequestMessage,
  getEmailAccountSetupScoreRequestError,
  sendGetEmailAccountSetupScoreRequest,
  resetEmailAccountSetupState,
}) => {
  const onRefreshEmailAccountSetupScore = () => {
    sendGetEmailAccountSetupScoreRequest({
      emailAccountId,
      refresh: true,
    });
  };

  useEffect(() => {
    if (emailAccountId) {
      sendGetEmailAccountSetupScoreRequest({
        emailAccountId
      });

      clearShouldRefreshEmailSetupScoreValue();
    }

    return () => {
      resetEmailAccountSetupState();
    };
  }, [emailAccountId]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getEmailAccountSetupScoreRequest,
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: getEmailAccountSetupScoreRequestError,
          onError: () => {
            toaster.error(getEmailAccountSetupScoreRequestMessage, {
              theme: Theme.New,
            });
            resetEmailAccountSetupState();
          },
        });
      },
    });
  }, [getEmailAccountSetupScoreRequest]);

  return getIsRequestPending(getEmailAccountSetupScoreRequest) ? (
    <div className="update-email-account-loading email-tab">
      <Spinner
        className="update-email-account-loading-spinner"
        animation="border"
      />
    </div>
  ) : (
    <EmailAccountSetupScore
      emailAccountSetup={emailAccountSetup}
      onRefreshEmailAccountSetupScore={onRefreshEmailAccountSetupScore}
      userTimezone={userTimezone}
      agencyConfig={agencyConfig}
      showProgress
      showMetaData
      className="email-account-settings"
      getItDoneByExpertsButtonVisible={true}
      emailAccountId={emailAccountId}
    />
  );
};

export default EmailAccountSetup;
