import React from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { Button } from '@saleshandy/design-system';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import { Images } from '../../shared/app-constants';

const EmailAccountsMovedNotification = () => {
  const history = useHistory();

  const navigateToEmailAccountsTab = () => {
    history.push(`/email-accounts?showEmailAccount=true`);
  };

  return (
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <div className="email-accounts-notification-container">
            <div className="notification-wrapper">
              <img
                src={Images.MovedEmailAccounts}
                alt="Email Accounts Moved"
                className="notification-image"
              />
              <div className="notification-content">
                <p className="notification-title">
                  Email Accounts settings have been moved to main navigation
                </p>
                <p className="notification-description">
                  Streamline your outreach, manage multiple clients, and drive
                  impressive results – all in one centralized platform.
                </p>
                <Button
                  className="notification-action-btn"
                  onClick={navigateToEmailAccountsTab}
                >
                  Add New Email
                </Button>
              </div>
            </div>
          </div>
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  );
};

export default EmailAccountsMovedNotification;
